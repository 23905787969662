import { useMemo } from 'react';
import { useAuthContext } from '@/modules/auth';
import { useMyCompany } from '@/modules/company/queries';
import { useMeQuery } from '@/modules/me';
import { CompanyStatusEnum } from '@/modules/company/types';
import { UserTypeEnum, type UserType } from '../types';

export const useUserType = (
  user_type: UserType | undefined,
  id: number | undefined,
  company_id?: number | null,
) => {
  const { isAuthenticated } = useAuthContext();
  const { data: me } = useMeQuery(isAuthenticated);

  const enabled = user_type === UserTypeEnum.Company && id === me?.id;
  const { data } = useMyCompany(enabled);

  const type: UserType = useMemo(() => {
    if (user_type === UserTypeEnum.Individual) return UserTypeEnum.Individual;

    if (company_id) return UserTypeEnum.Company;

    return data?.status === CompanyStatusEnum.Approved ? UserTypeEnum.Company : UserTypeEnum.Individual;
  }, [user_type, data, company_id]);

  return { type, companyData: data };
};
