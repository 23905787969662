import { InputText, SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';

import {
  useEffect, useRef, useState, SyntheticEvent,
} from 'react';
import styles from './search-text.module.scss';

/**
 * @param {string[]} value - Текущий список значений.
 * @param {(value: string[]) => void} onChange - Функция-обработчик изменения списка значений.
 * @param {string} [placeholder] - Placeholder для InputText.
 *
 * Параметры ниже контролируют, нужно ли сохранять значение в поле ввода.
 * @param {boolean} [foundValue] - Индикатор наличия найденного значения.
 * @param {boolean} [showNotFoundText=false] - Флаг для отображения сообщения "Ничего не найдено".
 * @param {boolean} [clearTextInput=false] - Флаг для очистки текстового ввода после отправки.
 */

type SearchTextProps = {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  foundValue?: boolean;
  showNotFoundText?: boolean;
  clearTextInput?: boolean;
};
export const SearchText = ({
  onChange, value, placeholder, foundValue, showNotFoundText = false, clearTextInput = false,
}: SearchTextProps) => {
  const [innerValue, setInnerValue] = useState<string>('');
  const [innerValueSubmitted, setInnerValueSubmitted] = useState<boolean>(false);
  const bottomSensorRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!bottomSensorRef.current || !value.length) return;
    bottomSensorRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [value.length]);

  useEffect(() => {
    if (foundValue || !clearTextInput) {
      setInnerValue('');
    }
    setInnerValueSubmitted(false);
  }, [clearTextInput, foundValue, innerValueSubmitted]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    const innerValueStr = innerValue.trim();
    const alreadyExist = value.filter((v) => v.toLowerCase() === innerValue.toLowerCase()).length > 0;
    if (innerValueStr && !alreadyExist) {
      onChange([...value, innerValueStr]);
      setInnerValueSubmitted(true);
    } else {
      setInnerValue('');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <InputText
            onChange={(e) => setInnerValue(e.target.value)}
            value={innerValue}
            onBlurCapture={handleSubmit}
            name="search"
            className={styles.input}
            placeholder={placeholder || t('filters-components:search-text.placeholder')}
          />
          {!!innerValue && (
            <button type="submit" className={styles.addBtn}><SvgIcon name="plus-with-bg" /></button>
          )}
        </form>
      </div>
      {(!foundValue && showNotFoundText) && <p className={styles.nothingFound}>{`${t('common:nothingFound')} :(`}</p>}

      {!!value?.length && (
        <>
          <div className={styles.items}>
            {value.map((item, index) => (
              <div key={index}>
                <div className={styles.itemsName}>{item}</div>
                <button onClick={() => onChange(value.filter((v) => v !== item))}><SvgIcon name="close" /></button>
              </div>
            ))}
          </div>
          <div ref={bottomSensorRef} />
        </>
      )}

    </div>
  );
};
