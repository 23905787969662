import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { Category, useCategories } from '@/modules/categories';
import type { City } from '@/modules/locations';
import { useLocationsListQuery } from '@/modules/locations';
import { SvgIcon } from '@/modules/shared/components/svg-icon';
import s from './footer.module.scss';
import {
  EMAIL, PHONE_NUMBER, RIGHTS, companyLinks, regions, media, UNSHOW_DEFAULT_FOOTER,
} from './footer.const';

// убрать FooterMobileProps, data и regions, когда будет не нужен storybook
type FooterProps = {
  data: Category[];
};

export const Footer = ({ data }: FooterProps) => {
  const { t } = useTranslation();
  const { categories } = useCategories();
  const { data: locations } = useLocationsListQuery();
  const cities: City[] = useMemo(
    () => (categories.length ? locations.data : regions).map(
      (city: City) => (t(`locations:${city.codename}.${city.codename}`)),
    ),
    [locations, t],
  );
  const ACTIVE_COUNTRY = `${t('locations:thailand.thailand')}: ${cities.join(', ')}`;

  const { isTablet, isMobile } = useMatchMedia();
  const router = useRouter();
  const unshowDefaultFooter = UNSHOW_DEFAULT_FOOTER.some((path) => router.pathname.includes(path));

  if (unshowDefaultFooter && (isMobile || isTablet)) { // скрываем дефолтный футер в мобильной (планшетной) версии
    return null;
  }

  return (
    <footer className={s.footer_container}>
      <nav className={s.footer_nav}>
        <Link href="/" className={s.logo}>
          <img
            src="/assets/icons/logo-main-medium.svg"
            alt="bazaar"
          />
        </Link>
        <ul className={s.contact}>
          <h2>{t('common:footer.contact')}</h2>
          <li>
            <span className={s.phone}>
              <SvgIcon name="phone" />
              <span>{PHONE_NUMBER}</span>
            </span>
          </li>
          <li>
            <Link href={`mailto:${EMAIL}`}>
              <SvgIcon name="mail" />
              <span>{EMAIL}</span>
            </Link>
          </li>
        </ul>
        <div className={s.company}>
          <h2>{t('common:footer.company')}</h2>
          <ul>
            {companyLinks.map((el) => (
              <li key={el}>
                <Link href={`/bazaar/${el}`} target="_blank">
                  {t(`common:footer.${el}`)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={s.categories}>
          <h2>{t('common:categories')}</h2>
          <ul>
            {(categories.length ? categories : data)?.filter(
              (el) => el.codename !== 'home' && el.codename !== 'excursions',
            )
              .map((el) => (
                <Link key={el.id} href={el.pathname}>
                  <li>{el.title}</li>
                </Link>
              ))}
          </ul>
        </div>
        <div className={s.social_block}>
          <h2>{t('common:footer.media')}</h2>
          <div className={s.social}>
            {media.map((el) => (
              <Link key={el.name} href={el.path} target="_blank">
                <SvgIcon name={el.name} />
              </Link>
            ))}
          </div>
        </div>
      </nav>
      <div className={s.footer_ribbon}>
        <span>{ACTIVE_COUNTRY}</span>
        <span>{RIGHTS}</span>
      </div>
    </footer>
  );
};
