import { UAParser } from 'ua-parser-js';
import React from 'react';
import type { AppContext } from 'next/app';

export const withInitialDeviceType = (Component) => {
  const Wrapper = (props) => <Component {...props} />;
  Wrapper.getInitialProps = async (app: AppContext) => {
    const pageProps = await Component.getInitialProps?.(app);

    try {
      const ua = new UAParser(app.ctx.req?.headers['user-agent']);
      const uaInitialDeviceType = ua.getDevice().type || 'desktop';

      const initialMatchMedia = {
        isMobile: uaInitialDeviceType === 'mobile',
        isTablet: uaInitialDeviceType === 'tablet',
        isDesktop: uaInitialDeviceType === 'desktop',
      };

      return { ...pageProps, initialMatchMedia };
    } catch (e) {
      return pageProps;
    }
  };
  return Wrapper;
};
