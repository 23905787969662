export const COMPLAINT_AD_KEY = ['complaint-ad'];
export const COMPLAINT_EVENT_KEY = ['complaint-event'];
export const COMPLAINTS_QUERY_KEY = ['complaints-list'];
export const RESOLVE_COMPLAINT_AD_KEY = ['resolve-ad-complaint'];
export const RESOLVE_COMPLAINT_EVENT_KEY = ['resolve-event-complaint'];

export const COMPLAINT_STATUS = {
  pending: 'complaintStatus.pending',
  accepted: 'complaintStatus.accepted',
  rejected: 'complaintStatus.rejected',
  Нет: 'complaintStatus.rejected',
  Забанен: 'complaintStatus.accepted',
};
