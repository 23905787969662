import React, {
  useRef, useEffect, useImperativeHandle, forwardRef, useCallback, useLayoutEffect,
} from 'react';
import clsx from 'clsx';

import { useOverlayScrollbars } from 'overlayscrollbars-react';

import styles from './auto-scroll-container.module.scss';

interface AutoScrollContainerProps {
  children: React.ReactNode;
  size?: 'large';
  scrollBehavior?: 'auto' | 'smooth' | 'instant';
  className?: string;
  offset?: number;
  style?: React.CSSProperties;
}

export const AutoScrollContainer = forwardRef<{
  scrollToBottom:(behavior?: AutoScrollContainerProps['scrollBehavior']) => void;
  getElement: () => HTMLElement | undefined
}, AutoScrollContainerProps>(({
      size = 'large',
      children,
      scrollBehavior = 'instant',
      className,
      offset = 0,
      style,
    }, ref) => {
      const containerRef = useRef<HTMLDivElement>(null);
      const [initialize, osInstance] = useOverlayScrollbars();

      const isScrollAtBottom = useRef(true);
      const prevScrollHeight = useRef(0);
      const prevScrollTop = useRef(0);

      useEffect(() => {
        initialize(containerRef.current as HTMLElement);
      }, [initialize]);

      const getElement = useCallback(() => {
        const { scrollOffsetElement } = osInstance()?.elements() || {};

        return scrollOffsetElement;
      }, [osInstance]);

      const scrollToBottom = useCallback((behavior = scrollBehavior) => {
        const scrollOffsetElement = getElement();
        if (
          !scrollOffsetElement
            || (scrollOffsetElement.scrollTop === scrollOffsetElement.scrollHeight - scrollOffsetElement.clientHeight)
        ) return;

        scrollOffsetElement.scrollTo({
          top: scrollOffsetElement.scrollHeight - scrollOffsetElement.clientHeight,
          behavior,
        });
      }, [getElement, scrollBehavior]);

      useEffect(() => {
        const scrollOffsetElement = getElement();

        const handle = () => {
          /**
           * Если мы внизу с погрешность 20 пикселей, все равно считаем что мы внизу
           */
          isScrollAtBottom.current = Math.abs(
            (scrollOffsetElement!.scrollTop + scrollOffsetElement!.clientHeight) - scrollOffsetElement!.scrollHeight,
          ) < 20;
          prevScrollTop.current = scrollOffsetElement!.scrollTop;
        };

        scrollOffsetElement?.addEventListener('scroll', handle, { passive: true });
        return () => {
          scrollOffsetElement?.removeEventListener('scroll', handle);
        };
      }, [getElement]);

      useEffect(() => {
        const scrollOffsetElement = getElement();

        prevScrollHeight.current = scrollOffsetElement!.scrollHeight;
      }, [children, getElement]);

      useLayoutEffect(() => {
        const scrollOffsetElement = getElement();
        if (!scrollOffsetElement) return;


        if (isScrollAtBottom.current) {
          /**
           * Если мы находимся внизу, то всегда автоматически скроллим вниз
           */
          scrollOffsetElement.scrollTo({
            top: scrollOffsetElement.scrollHeight - scrollOffsetElement!.clientHeight,
            behavior: 'instant',
          });
        } else {
          /**
           * Если мы сами руками просколлили вверх, то в таких случаях автоматически не скроллим,
           * а просто пытаемся восстановить скролл который уже был до этого.
           * Этот кейс важен для пагинации сообщения и для случаев когда пользователю приходит новое сообщение
           */


          /**
           * Если новый скролл (scrollOffsetElement.scrollTop) меньше отступа который вызывает загрузку новых сообщений,
           * мы должны сделать отступ руками на высоту контента которая добавилась в контейнер,
           * иначе пользователь будет ловить бесконечную пагинацию всей истории
           */
          const newContentHeight = scrollOffsetElement.scrollHeight - prevScrollHeight.current;
          const top = scrollOffsetElement.scrollTop <= offset && newContentHeight !== 0
            ? newContentHeight : scrollOffsetElement.scrollTop;

          /**
           * Скролл не изменился при рендере, не меняем, значит перерендерилось что-то не влияющее на высоту
           * Может быть какой то стейт не влияющий на скролл типа isLoading
           */
          if (top === prevScrollTop.current) return;

          scrollOffsetElement.scrollTo({
            top,
            behavior: 'instant',
          });
        }
      }, [children, getElement, offset]);

      useImperativeHandle(ref, () => ({
        scrollToBottom,
        getElement,
      }), [getElement, scrollToBottom]);

      return (
        <div
          ref={containerRef}
          style={{
            height: '100%', width: '100%', overflow: 'hidden', ...style,
          }}
          className={clsx(styles.scroll, className, {
            [styles.scroll_large]: size === 'large',
          })}
        >
          {children}
        </div>
      );
    });
