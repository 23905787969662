import {
  fetchList, fetchAnnouncement, api, createWithoutToast,
} from '@/modules/shared/api';
import { IListRequestConfig } from '@/modules/shared/api/helpers';
import { ImageFormat, EntityImage } from '@/modules/shared/types';
import { CreateAdTypes } from '../components/create-ad/types';
import { endpoints } from './endpoints';
import { IAd } from '../types';

interface CreateImagesInput {
  formats: ImageFormat[];
  announcement?: string;
}

interface ToggleAdVisibility {
  id: number[];
  action: string;
}

export const AdsApi = {
  list: async (config: IListRequestConfig = {}) => {
    if (config?.filters?.city === 'allcities') {
      delete config.filters.city;
    }

    const data = await fetchList<{ results: IAd[] }>(
      endpoints.list,
      config,
    );
    return {
      ...data,
      data: data.results,
    };
  },

  myAdsList: async (config: IListRequestConfig = {}) => {
    const data = await fetchList<{ results: IAd[] }>(endpoints.myAdsList, config);
    return {
      ...data,
      data: data.results,
    };
  },

  create: async (ad: CreateAdTypes) => {
    const { data } = await createWithoutToast<CreateAdTypes, { data: IAd }>(endpoints.create, ad);
    return data;
  },

  createImages: async (images: CreateImagesInput) => {
    const data = await createWithoutToast<CreateImagesInput, EntityImage[]>(
      endpoints.createImages,
      images,
    );
    return data;
  },

  detail: async (id: string) => {
    const data = await fetchAnnouncement(endpoints.detail, id);

    return data;
  },

  toggleAdVisibility: async ({ id, action }: ToggleAdVisibility) => {
    const { data } = await api.put(endpoints.toggleAdVisibility(), {
      announcement_ids: id,
      action,
    });
    return data;
  },

  recommendedAds: async (config: IListRequestConfig = {}) => {
    const data = await fetchList<{ results: IAd[] }>(endpoints.recommendedAds, config);
    return {
      ...data,
      data: data.results,
    };
  },

  similarAds: async (config: { id: string }) => {
    const { id, ...restConfig } = config;
    const data = await fetchList<{ results: IAd[] }>(() => endpoints.similarAds(config.id), restConfig);
    return {
      ...data,
      data: data.results,
    };
  },
};
