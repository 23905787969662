import { NextSeo, NextSeoProps } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';

export const Seo = (props: NextSeoProps) => {
  const { t } = useTranslation('seo');
  const { title = t('defaultTitle') } = props;

  return (
    <NextSeo
      title={title}
      {...props}
    />
  );
};
