
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'overlayscrollbars/overlayscrollbars.css';
import Head from 'next/head';
import Layout from '@/components/shared/layout/Layout';
import { CityPrefixProvider } from '@/context/CityPrefixContext';
import '@/styles/globals.scss';
import { CategoriesProvider, withPreloadedCategories } from '@/modules/categories';
import { EventsTrackingProvider } from '@/modules/events-tracking';
import { setupQueryClient, useCheckVersion } from '@/modules/app';
import { AuthProvider, useAuthContext } from '@/modules/auth';
import { MeProvider } from '@/modules/me';
import { EventProvider } from '@/modules/events';
import { StyledEngineProvider } from '@mui/material/styles';
import { FavoritesProvider } from '@/modules/favorites';
import { ChatDataProvider } from '@/modules/chat';
import { MatchMediaProvider } from '@/modules/shared/hooks/use-match-media.provider';
import { BlackFridayContainer } from '@/modules/contests/black-friday/ui/container';
import { withInitialDeviceType } from '@/modules/shared/hoc/with-initial-device-type';
import { withFeatureFlag } from '@/modules/shared/feature-flag';
const queryClient = setupQueryClient();
function App({ Component, pageProps, preloadedCategories, initialMatchMedia, }) {
    useCheckVersion();
    const { isAuthenticated } = useAuthContext();
    return (<MatchMediaProvider initial={initialMatchMedia}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ChatDataProvider>
            <MeProvider isAuthenticated={isAuthenticated}>
              <CityPrefixProvider>
                <CategoriesProvider initData={preloadedCategories}>
                  <EventProvider>
                    <FavoritesProvider>
                      <StyledEngineProvider injectFirst>
                        <Head>
                          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
                        </Head>
                        <Layout>
                          <EventsTrackingProvider />
                          <ToastContainer />
                          <Component {...pageProps}/>
                        </Layout>
                        <BlackFridayContainer />
                      </StyledEngineProvider>
                    </FavoritesProvider>
                  </EventProvider>
                </CategoriesProvider>
              </CityPrefixProvider>
            </MeProvider>
          </ChatDataProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MatchMediaProvider>);
}
const __Next_Translate__Page__193bf89ad17__ = withFeatureFlag(withInitialDeviceType(withPreloadedCategories(App)));

    export default __appWithI18n(__Next_Translate__Page__193bf89ad17__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  