import React, { useMemo } from 'react';
import {
  Button, Checkbox, Sorting, SvgIcon,
} from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getDeclension } from '@/modules/shared/helpers';
import { useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { AdTab } from '../my-ads-types';
import styles from '../my-ads-page.module.scss';

interface MyAdsHeaderProps {
  adType: AdTab;
  selectedAds: number[];
  isAllSelected: boolean;
  onOpenModal: () => void;
  adCounts: { active: number; hidden: number };
  onToggleSelectAll: (isSelected: boolean) => void;
}

export const MyAdsHeader = ({
  adType,
  adCounts,
  selectedAds,
  onOpenModal,
  isAllSelected,
  onToggleSelectAll,
}: MyAdsHeaderProps) => {
  const { t } = useTranslation('userProfile');
  const { isMobile, isDesktop } = useMatchMedia();

  const { setValue } = useFormContext<{ sort: string }>();
  const selectedSort = useWatch({ name: 'sort' });

  const currentAdCount = adCounts[adType];

  const adCountText = useMemo(() => {
    const declensionKey = adType === 'active' ? 'myAds.ads' : 'myAds.hiddenAds';
    return getDeclension({
      count: currentAdCount,
      t,
      singular: `${declensionKey}.singular`,
      few: `${declensionKey}.few`,
      many: `${declensionKey}.many`,
    });
  }, [adType, currentAdCount, t]);

  const sortOptions = useMemo(() => [
    { value: '-created_at', label: t('common:sorting.sortingModal.newest') },
    { value: 'created_at', label: t('common:sorting.sortingModal.oldest') },
    { value: '-price', label: t('common:sorting.sortingModal.decreasing') },
    { value: 'price', label: t('common:sorting.sortingModal.increasing') },
  ], [t]);

  const actionConfig = {
    active: { icon: 'eye-opened', text: t('myAds.hide') },
    hidden: { icon: 'eye-closed', text: t('myAds.open') },
    drafts: { icon: 'trash-bin', text: t('myAds.delete') },
  }[adType];

  return (
    <>
      {isDesktop && (
        <div className={styles.my_ads_list_headline}>
          <div className={styles.sorting}>
            <h4>
              {currentAdCount > 0 && currentAdCount}
              {' '}
              {adCountText}
            </h4>
            <Sorting
              value={selectedSort}
              setValue={(value) => setValue('sort', value)}
              options={sortOptions}
            />
          </div>
          {adType === 'active' && (
            <div className={styles.hiding}>
              <Checkbox
                checkboxSize="24"
                checked={isAllSelected}
                onChange={(e) => onToggleSelectAll(e.target.checked)}
              />
              <Button
                as="button"
                buttonSize="xs"
                buttonColor="grey"
                appearance="primary"
                className={styles.hide}
                disabled={selectedAds.length === 0}
                onClick={onOpenModal}
              >
                <SvgIcon name={actionConfig.icon} />
                {actionConfig.text}
              </Button>
            </div>
          )}
        </div>
      )}

      {!isDesktop && (
        <div className={styles.my_ads_list_mobile_headline}>
          <Sorting
            value={selectedSort}
            setValue={(value) => setValue('sort', value)}
            options={sortOptions}
          />
          <div className={styles.hiding}>
            <Button
              as="button"
              buttonSize={isMobile ? 's' : 'xs'}
              buttonColor={isMobile ? 'blue' : 'grey'}
              appearance={isMobile ? 'secondary' : 'primary'}
              className={styles.hiding_button}
              disabled={selectedAds.length === 0}
              onClick={onOpenModal}
            >
              <SvgIcon name={actionConfig.icon} />
              {actionConfig.text}
            </Button>
            <Checkbox
              checkboxSize="24"
              checked={isAllSelected}
              onChange={(e) => onToggleSelectAll(e.target.checked)}
            />
          </div>
        </div>
      )}
    </>
  );
};
