import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { Tag } from '../tag/tag.component';
import { Button } from '../button/button.component';
import styles from './tag-selector.module.scss';

interface TagSelectorProps {
  options: string[];
  value?: string[];
  onChange?: (selectedTags: string[]) => void;
}

export const TagSelector = ({ options, value = [], onChange }: TagSelectorProps) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const text = isMobile ? t('common:hide') : t('common:showLess');

  const translatedOptions = useMemo(() => options.map((choice) => ({
    value: choice,
    label: choice,
  })), [options]);

  const handleTagClick = useCallback((tag: string) => {
    const updatedSelectedTags = value.includes(tag)
      ? value.filter((val) => val !== tag)
      : [...value, tag];

    onChange?.(updatedSelectedTags);
  }, [onChange, value]);

  const displayedOptions = showAll ? translatedOptions : translatedOptions.slice(0, 10);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tags_list}>
        {displayedOptions.map((item) => (
          <Tag
            key={item.value}
            label={item.label}
            type="tagMobile_white"
            className={styles.tag}
            onClick={() => handleTagClick(item.value)}
            color={value.includes(item.value) ? 'light-green' : 'white'}
          />
        ))}
        {translatedOptions.length > 10 && (
          <Button
            buttonSize="xs"
            buttonColor="green"
            appearance="secondary"
            className={styles.button}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? text : t('common:showMore')}
          </Button>
        )}
      </div>
    </div>
  );
};
