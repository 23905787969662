import {
  Dispatch, SetStateAction, useCallback, useEffect, useRef, useState,
} from 'react';
import { useSwipeable } from 'react-swipeable';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { scrollElementIntoViewCenter } from '../../helpers';

import { ItemLoader } from '../loaders';
import { SvgIcon } from '../svg-icon';
import { Modal } from '../modal';

import styles from './gallery-modal.module.scss';

const Loader = () => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <ItemLoader isLoading size={60} />
  </Box>
);


export type GalleryModalProps = {
  initImageIndex?: number,
  images: {
    image: string,
    thumbnail?: string,
  }[];
  open?: boolean,
  currentImageIndex: number,
  setCurrentImageIndex: (fn: ((value: number) => void) | number) => void,
  onClose?: () => void,
  setStateKeyboard?: Dispatch<SetStateAction<boolean>> // сеттер useState из компонента GalleryBlock, тк слушатель клавиатуры тут
};

export const GalleryModal = ({
  images,
  open,
  onClose,
  setStateKeyboard,
  currentImageIndex: currentIndex,
  setCurrentImageIndex: setCurrentIndex,
}: GalleryModalProps) => {
  const navigationRefs = useRef<Record<number, HTMLDivElement | null>>({});
  const { isMobile } = useMatchMedia();
  const [preloading, setPreloading] = useState(false);

  const handlePrev = useCallback(() => {
    if (!images.length || images.length === 1) return;
    setPreloading(true);
    setStateKeyboard?.(true);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  }, [setCurrentIndex, images.length]);

  const handleNext = useCallback(() => {
    if (!images.length || images.length === 1) return;
    setPreloading(true);
    setStateKeyboard?.(true);
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, [setCurrentIndex, images.length]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key == 'ArrowLeft') {
        handlePrev();
      } else if (e.key == 'ArrowRight') {
        handleNext();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handlePrev, handleNext]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  useEffect(() => {
    scrollElementIntoViewCenter(navigationRefs.current[currentIndex]);
  }, [currentIndex]);

  const showArrow = images.length > 1;

  return (
    <Modal
      isClean
      isDark={isMobile}
      headerBordered
      title={isMobile ? `${currentIndex + 1} из ${images.length}` : ''}
      showBackButton={false}
      open={open}
      onClose={onClose}
      footer={(
        <div className={styles.footer}>
          {images.map((src, index) => (
            <div
              key={index}
              ref={(element) => {
                navigationRefs.current[index] = element;
              }}
              onClick={() => setCurrentIndex(index)}
              className={clsx(styles.footer__item, {
                [styles.footer__item_active]: currentIndex === index,
              })}
              style={{
                backgroundImage: `url(${src.thumbnail || src.image})`,
              }}
            />
          ))}
        </div>
      )}
      fullHeight
    >
      <div
        {...swipeHandlers}
        className={clsx(styles.container, {
          [styles.container_hideArrow]: !showArrow,
        })}
      >
        <button className={styles.prev} onClick={handlePrev}>
          <SvgIcon name="arrow-forward-left" />
        </button>
        <div className={styles.item}>
          <div className={styles.preloader}>
            {preloading && <Loader />}
          </div>
          <img
            loading="lazy"
            className={styles.image}
            src={images[currentIndex]?.image}
            onLoad={() => setPreloading(false)}
          />
        </div>
        <button className={styles.next} onClick={handleNext}>
          <SvgIcon name="arrow-forward-right" />
        </button>
      </div>
    </Modal>
  );
};
