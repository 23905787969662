import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  list: () => `${baseEndpointV1}/event/list`,
  destroy: (event) => `${baseEndpointV1}/event/delete/${event.id}/`,
  createEvent: () => `${baseEndpointV1}/event/create/`,
  createImage: () => `${baseEndpointV1}/event/images/create/`,
  detail: (id) => `${baseEndpointV1}/event/detail/${id}`,
  myEvents: (isActive) => {
    const baseUrl = `${baseEndpointV1}/event/list/my/`;
    if (typeof isActive === 'string') {
      return `${baseUrl}?is_active=${isActive}`;
    }
    return baseUrl;
  },
  toggleEvent: (id) => `${baseEndpointV1}/event/update/${id}/`,
};
