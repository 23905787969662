import React, {
  useCallback, useMemo, useEffect,
} from 'react';
import { Box } from '@mui/material';
import { IAd } from '@/modules/ads/types';
import { useMatchMedia } from '@/modules/shared/hooks';
import {
  ItemLoader, Pagination,
} from '@/modules/shared/components';
import { MyAdCard } from './my-ad-card.component';
import { MobileMyAdCard } from './mobile-my-ad-card.component';
import styles from '../my-ads-page.module.scss';

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return null;
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
      <ItemLoader isLoading size="50px" />
    </Box>
  );
};

interface InfinityAds {
  count: number;
  counts: { active: number; inactive: number };
  results: IAd[];
}
interface MyAdsListProps {
  adType: string;
  hasMore?: boolean;
  totalPages: number;
  isLoading: boolean;
  currentPage: number;
  selectedAds: number[];
  isAllSelected: boolean;
  ads: IAd[] | InfinityAds[];
  fetchMore?: () => void;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  onSelectAd: (id: React.SetStateAction<number[]>) => void;
  handleSetPage: (value: number) => void;
  isInfinity?: ((node: HTMLElement | null) => void) | undefined;
  onIsAllSelected: (value: React.SetStateAction<boolean>) => void;
}

export const MyAdsList = ({
  ads,
  adType,
  hasMore,
  isLoading,
  totalPages,
  currentPage,
  selectedAds,
  isAllSelected,
  fetchMore,
  onSelectAd,
  setShowModal,
  isInfinity,
  handleSetPage,
  onIsAllSelected,
}: MyAdsListProps) => {
  const { isDesktop } = useMatchMedia();

  const currentAds = useMemo(() => {
    if (isDesktop) return ads;
    if (ads && Array.isArray(ads) && ads[0] && 'results' in ads[0]) {
      return ads[0].results;
    }
    return ads;
  }, [isDesktop, ads]);

  useEffect(() => {
    if (isAllSelected) {
      if (isDesktop) {
        onSelectAd(currentAds?.map((ad) => ad.id));
      } else {
        const infinityAds = ads.flatMap((ad) => ad.results || []);
        onSelectAd(infinityAds.map((ad) => ad.id));
      }
    } else {
      onSelectAd([]);
    }
  }, [
    isAllSelected,
    currentAds,
    onSelectAd,
    ads,
    isDesktop,
  ]);

  const onSelect = useCallback((id: number) => {
    onSelectAd((prevChosen) => {
      if (prevChosen.includes(id)) {
        const newChosen = prevChosen.filter((item) => item !== id);
        if (newChosen.length === 0) {
          onIsAllSelected(false);
        }
        return newChosen;
      }
      const newChosen = [...prevChosen, id];
      if (newChosen.length === currentAds.length) {
        onIsAllSelected(true);
      }
      return newChosen;
    });
  }, [currentAds, onSelectAd, onIsAllSelected]);

  const handleHideAd = useCallback((id: number) => {
    setShowModal(true);
    onSelectAd((prevChosen) => (
      prevChosen.includes(id)
        ? prevChosen.filter((hiddenId) => hiddenId !== id)
        : [...prevChosen, id]
    ));
  }, [onSelectAd, setShowModal]);

  if (currentAds?.length === 0) return null;

  return (
    <Pagination
      itemsPerPage={10}
      hasMore={hasMore}
      fetchMore={fetchMore}
      totalPages={totalPages}
      className={styles.list}
      currentPage={currentPage}
      handleSetPage={handleSetPage}
    >
      {isDesktop ? (
        <>
          {currentAds?.map((ad) => (
            <MyAdCard
              key={ad.id}
              ad={ad}
              adType={adType}
              onSelect={onSelect}
              onClick={handleHideAd}
              isSelected={selectedAds}
            />
          ))}
          <Loader isLoading={isLoading} />
        </>
      ) : (
        <>
          {ads?.map((page, i) => (
            <React.Fragment key={i}>
              {page?.results?.map((ad, j) => (
                <React.Fragment key={ad.id}>
                  <MobileMyAdCard
                    ad={ad}
                    adType={adType}
                    onSelect={onSelect}
                    onClick={handleHideAd}
                    isChoosing={isAllSelected}
                    isSelected={selectedAds}
                  />
                  {i + 1 === ads?.length && page.data.length === j + 1 ? (
                    <div ref={isInfinity} />
                  ) : null}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <Loader isLoading={isLoading} />
        </>
      )}
    </Pagination>
  );
};
