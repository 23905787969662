import { useMutation, useQueryClient } from '@tanstack/react-query';
import { invalidateQueriesByKeys } from '@/modules/shared/helpers';
import { EventsApi } from '../api';
import { MY_ACTIVE_EVENTS, MY_INACTIVE_EVENTS } from '../consts';

export const useToggleEventMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: EventsApi.toggleEvent,
    onSuccess: async () => {
      await invalidateQueriesByKeys(queryClient, [MY_ACTIVE_EVENTS, MY_INACTIVE_EVENTS]);
    },
    onError: (e) => console.error('Event hiding or activation error:', e),
  });
};
