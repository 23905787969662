import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from '@/modules/shared/components';
import { CompanyCredentialsForm } from '../../types';
import { MAX_LENGTH } from '../../company-credentials/const';
import s from '../modal.module.scss';

type ModalTextareaProps = {
  value: string;
  onBlur: () => void;
  onFocus?: () => void;
};

export const ModalTextarea = ({ value, onBlur, onFocus }: ModalTextareaProps) => {
  const { control, formState: { errors } } = useFormContext<CompanyCredentialsForm>();

  return (
    <Controller
      name="description"
      control={control}
      render={({ field }) => (
        <Textarea
          {...field}
          name="description"
          value={value}
          classNameTextarea={s.area}
          hasErrors={!!errors.description}
          onBlur={onBlur}
          maxChars={MAX_LENGTH}
          countChars
          onFocus={() => onFocus?.()}
        />
      )}
    />
  );
};
