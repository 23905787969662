import {
  fetchList, destroy, create, saveEntityImages, api, fetchAnnouncement,
} from '@/modules/shared/api';
import { endpoints } from './endpoints';


const fetchListFactory = async (endpoint, config) => {
  const data = await fetchList(endpoint, config);
  return {
    ...data,
    data: data.results,
  };
};


export class EventsApi {
  static list = async (config = {}) => fetchListFactory(endpoints.list, config);

  static myEvents = async (config, isActive) => {
    let endpoint;

    if (isActive === undefined) {
      endpoint = endpoints.myEvents;
    } else {
      endpoint = () => endpoints.myEvents(isActive);
    }

    return fetchListFactory(endpoint, config);
  }

  static destroy = async (config) => destroy(endpoints.destroy, config);

  static delete = async (event) => {
    const { data } = await api.put(endpoints.destroy(event), {
      deactivation_reason: 'rules_or_laws_violation',
    });
    return data;
  }

  static create = async ({ images, ...body }) => {
    const savedImages = await saveEntityImages(
      images,
      (formats) => create(endpoints.createImage, { formats }),
    );
    const { data } = await api.post(endpoints.createEvent(), {
      ...body,
      images: savedImages,
      event_time: body.event_time || undefined,
    });
    return data;
  };

  static detail = async (id) => {
    const data = await fetchAnnouncement(endpoints.detail, id);
    return data;
  };

  static toggleEvent = async ({ id, is_active }) => {
    const { data } = await api.patch(endpoints.toggleEvent(id), { is_active });
    return data;
  };
}
