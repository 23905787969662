import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import { AuthProtectedLink } from '@/modules/auth';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { Button, SvgIcon } from '@/modules/shared/components';
import { useMediaQuery } from '@react-hookz/web';
import { LocationClarification } from '../index-page';
import { HeaderProps } from './types';
import styles from './primary-event-header.module.scss';
import { HeaderLinks } from './header-links.component';

export const PrimaryEventHeader = ({
  showCityModal,
  setShowCityModal,
  linkRef,
}: HeaderProps) => {
  const { isMobile } = useMatchMedia();
  const { t, lang } = useTranslation('');
  const router = useRouter();
  const { cityPrefix } = useCityPrefix();
  const isLocationModalAsIcon = useMediaQuery('(max-width: 510px)');


  return (
    <>
      <HeaderLinks linkRef={linkRef} />
      <div className={clsx('container', styles.primary_event)}>
        <div className={styles.logo}>
          <Link href={`/${router.locale || 'en'}/${cityPrefix}/posters`}>
            <SvgIcon name={lang === 'ru' ? 'logo-poster-2' : 'logo-poster-2-en'} />
          </Link>
        </div>

        <div className={styles.section}>
          {!isMobile && (
            <LocationClarification
              isShowModal={showCityModal}
              onChange={() => setShowCityModal(!showCityModal)}
              iconOnly={isLocationModalAsIcon}
            />
          )}

          <AuthProtectedLink
            href="/events/addevents"
            className={styles.button_add}
          >
            <Button
              appearance={isMobile ? 'secondary' : 'primary'}
              buttonSize={isMobile ? 's' : 'xs'}
              buttonColor="blue"
              className={styles.button_add}
            >
              <SvgIcon name="plus" />
              {t('events:addEvent')}
            </Button>
          </AuthProtectedLink>
        </div>
      </div>
    </>
  );
};
