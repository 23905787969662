import { listQueryFactory } from '@/modules/shared/queries';
import { MY_ACTIVE_EVENTS, MY_INACTIVE_EVENTS } from '../consts';
import { EventsApi } from '../api';

/**
 * Если не передать isActive, то получим все мероприятия. И активные, и не активные.
 * При передаче isActive, то получим либо активные, либо не активные;
 * initialData - начальные данные для серверного запроса на странице src/pages/user/posters.tsx.
 * Чтобы вызвать хук и передать опции:
 * const {} = useMyEventsList()({
 *   pageSize: 10, и тд
 * });
 * */

export const useMyEventsList = (isActive?: 'true' | 'false') => {
  const queryKey = isActive === undefined
    ? [MY_ACTIVE_EVENTS, MY_INACTIVE_EVENTS]
    : [isActive == 'true' ? MY_ACTIVE_EVENTS : MY_INACTIVE_EVENTS];

  return listQueryFactory(
    (config) => EventsApi.myEvents(config, isActive),
    queryKey,
  );
};
