import React, { memo } from 'react';
import { CreationLocation } from '@/modules/locations';
import { CreationContacts } from '@/modules/shared/components/creation-contacts';
import { useUserType } from '@/modules/users/hooks';
import { type UserType, UserTypeEnum } from '@/modules/users';
import { useCreateAdActionsContext, useCreateAdContext } from '../../context';
import { CATEGORIES_WITHOUT_DISTRICT } from '../../const';

interface Props {
  user_type: UserType | undefined;
  id: number | undefined;
  company_id?: number | null;
}

export const ContactInformation = memo((props: Props) => {
  const { user_type = UserTypeEnum.Individual, id, company_id } = props;
  const { type } = useUserType(user_type, id, company_id);
  const { setIsPhoneVerified } = useCreateAdActionsContext();
  const { mainStep, category } = useCreateAdContext();

  const districtRequired = !CATEGORIES_WITHOUT_DISTRICT.includes(category?.codename || '');

  return (
    // тк в CreationContacts => PhoneContact указаны типы только для контактов, а не всей формы,
    // то при размонтировании пропадают заполненные значения
    // https://bazaar.youtrack.cloud/issue/bw-1953/FECreationAdPage-Ne-sohranyayutsya-dannye-v-pervom-shage-sozdaniya-obuyavleniya-pri-vozvrate-nazad
    <div style={{ display: mainStep === 2 ? 'block' : 'none' }}>
      <CreationLocation user_type={type} districtRequired={districtRequired} />
      <CreationContacts setIsAdPhoneVerified={setIsPhoneVerified} />
    </div>
  );
});
