import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import Cookie from 'js-cookie';
import { profileBasePath } from '@/modules/shared/const';
import useTranslation from 'next-translate/useTranslation';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { User, UserTypeEnum } from '@/modules/users';
import { useMatchMedia } from '@/modules/shared/hooks';
import {
  Button, FloatNumber, SvgIcon, UserAvatar, UserLabel, UserRating,
} from '@/modules/shared/components';
import { formatDateMonthYear, getDeclension, getUserCompanyData } from '@/modules/shared/helpers';
import { Company } from '../../../company/types';
import styles from './profile-card.module.scss';

interface ProfileCardProps {
  data: User;
  companyData?: Company;
  openModal?: () => void;
  scrollToReviews: () => void;
  onShowReviews?: () => void;
}

export const ProfileCard = ({
  data, companyData, openModal, scrollToReviews, onShowReviews,
}: ProfileCardProps) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showNumberAfterAuth, setShowNumberAfterAuth] = useState(false);
  const { t } = useTranslation('');
  const { isMobile, isDesktop, isTablet } = useMatchMedia();
  const { isAuthenticated } = useAuthContext();
  const openAuthModal = useOpenAuthModal();
  const [isClientLoaded, setIsClientLoaded] = useState(false);

  const isCompany = data.user_type === UserTypeEnum.Company && data.company_id;
  const phoneButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  const isMessageToMyself = (() => Cookie.get('user_id') === String(data.id))();
  const isHiddenPhoneNumber = data?.phone_number_is_hidden;
  const companyLinks = companyData?.social_networks;
  const dateOnBazaar = data.created_at;
  const companyHasPhoneNumber = companyData?.contact_info?.phone_number || data?.phone_number;

  useEffect(() => {
    setIsClientLoaded(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated && showNumberAfterAuth && !isHiddenPhoneNumber) {
      setShowPhoneNumber(true);
      setShowNumberAfterAuth(false);
    }
  }, [isAuthenticated, showNumberAfterAuth, isHiddenPhoneNumber]);

  useEffect(() => {
    if (phoneButtonRef.current && isClientLoaded) {
      if (!isDesktop && !isHiddenPhoneNumber && phoneButtonRef.current instanceof HTMLAnchorElement) {
        phoneButtonRef.current.setAttribute('href', `tel:${companyHasPhoneNumber}`);
      } else {
        phoneButtonRef.current.removeAttribute('href');
      }
    }
  }, [isDesktop, companyHasPhoneNumber, isClientLoaded, isHiddenPhoneNumber]);

  const averageRating = useMemo(() => getDeclension({
    count: data?.reviews_received_count,
    t,
    singular: 'common:reviews.one',
    few: 'common:reviews.few',
    many: 'common:reviews.many',
  }), [data, t]);

  const date = useMemo(() => formatDateMonthYear({
    date: dateOnBazaar || '',
    t,
    translateMonth: 'common:months',
    translateYear: 'common:year',
  }), [t, dateOnBazaar]);

  const onShowPhoneNumber = useCallback(() => {
    if (isAuthenticated && !isHiddenPhoneNumber) {
      setShowPhoneNumber((prev) => !prev);
    } else {
      openAuthModal('login');
      setShowNumberAfterAuth(true);
    }
  }, [isAuthenticated, openAuthModal, isHiddenPhoneNumber]);

  const handleChatButtonClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isAuthenticated) {
      event.preventDefault();
      openAuthModal('login');
      return;
    }
    if (isMessageToMyself) {
      event.preventDefault();
    }
  }, [isAuthenticated, isMessageToMyself, openAuthModal]);

  const getPhoneButtonText = useCallback(() => {
    if (isDesktop) {
      if (showPhoneNumber && !isMessageToMyself && !isHiddenPhoneNumber) {
        return companyHasPhoneNumber;
      }
      return t('userProfile:phoneNumber');
    }
    return t('userProfile:call');
  }, [companyHasPhoneNumber, isDesktop, isMessageToMyself, showPhoneNumber, t, isHiddenPhoneNumber]);

  const getPhoneButtonHref = useCallback(() => {
    if (!isDesktop && isAuthenticated && !isMessageToMyself && !isHiddenPhoneNumber) {
      return `tel:${companyHasPhoneNumber}`;
    }
    return undefined;
  }, [companyHasPhoneNumber, isAuthenticated, isDesktop, isMessageToMyself, isHiddenPhoneNumber]);

  const { name, avatar } = getUserCompanyData(companyData, data);

  return (
    <div className={styles.wrapper}>
      <div className={styles.profile_card}>
        <UserAvatar
          srcAvatar={avatar}
          diameter={isTablet ? '44px' : '66px'}
          userName={name}
        />
        <div className={styles.profile_card_content}>
          <div className={styles.user}>
            <div className={styles.user_name}>
              <p>{companyData ? companyData?.name : data?.full_name}</p>
              {isMobile && (
                <span>
                  {data?.is_online ? t('common:online') : t('common:noOnline')}
                </span>
              )}
            </div>
            <UserLabel companyId={data?.company_id} />
          </div>
          <div className={styles.information}>
            <div className={styles.user}>
              <div className={styles.user_block}>
                <span className={styles.user_rating}>
                  <FloatNumber value={data.average_rating} precision={1} showRatingSuffix={false} />
                </span>
                <UserRating rating={data?.average_rating} sizeStar="16px" />
              </div>
              <p className={styles.user_reviews} onClick={isDesktop ? scrollToReviews : onShowReviews}>
                {averageRating}
              </p>
            </div>
            {isMobile && isCompany ? (
              <button className={styles.company_info} onClick={openModal}>
                <SvgIcon name="info" />
                {t('company:info')}
              </button>
            ) : (
              <p className={styles.created}>
                {t('common:onBazaar')}
                {' '}
                <span>{date}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      {isMobile && isCompany && companyLinks?.telegram && (
        <Button
          as="link"
          fullWidth
          target="_blank"
          buttonColor="grey"
          appearance="primary"
          className={styles.telegram}
          buttonSize="s"
          href={`https://t.me/${companyLinks?.telegram}`}
        >
          {t('userProfile:telegram')}
        </Button>
      )}
      <div className={styles.contact_buttons}>
        <Button
          fullWidth
          as="link"
          appearance="primary"
          className={styles.chat}
          buttonSize={isClientLoaded && isDesktop ? 'm' : 's'}
          buttonColor={isMessageToMyself ? 'grey' : 'blue'}
          href={`/${profileBasePath}/chat/user/${data.id}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleChatButtonClick(event)}
        >
          {isDesktop ? t('userProfile:message') : t('userProfile:write')}
        </Button>
        {isClientLoaded && companyHasPhoneNumber && (
          <Button
            fullWidth
            appearance="primary"
            ref={phoneButtonRef}
            className={styles.phone}
            onClick={onShowPhoneNumber}
            as={isDesktop ? 'button' : 'link'}
            buttonSize={isDesktop ? 'm' : 's'}
            buttonColor={isMessageToMyself || isHiddenPhoneNumber ? 'grey' : 'green'}
            onDoubleClick={() => setShowPhoneNumber(false)}
            href={getPhoneButtonHref()}
          >
            {getPhoneButtonText()}
          </Button>
        )}
        {!isMobile && isCompany && companyLinks?.telegram && (
          <Button
            as="link"
            fullWidth
            target="_blank"
            buttonColor="grey"
            appearance="primary"
            className={styles.telegram}
            buttonSize={isDesktop ? 'm' : 's'}
            href={`https://t.me/${companyLinks?.telegram}`}
          >
            {t('userProfile:telegram')}
          </Button>
        )}
      </div>
    </div>
  );
};
