import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import {
  BUSINESS_LINK, profileBasePath, VERIFIED_COMPANIES_LINK,
} from '@/modules/shared/const';
import { useAuthContext } from '@/modules/auth';
import s from './header-links.module.scss';

export const HeaderLinks = ({ linkRef }: { linkRef?: React.RefObject<HTMLUListElement> }) => {
  const { t } = useTranslation('common');
  const { isAuthenticated } = useAuthContext();
  const isUser = isAuthenticated ? `/${profileBasePath}/chat/support` : '/support/form';

  return (
    <ul className={s.list} ref={linkRef}>
      <Link href={VERIFIED_COMPANIES_LINK} target="_blank">
        <li>{t('header.partners')}</li>
      </Link>
      <Link href={BUSINESS_LINK} target="_blank">
        <li>{t('header.companies')}</li>
      </Link>
      <Link href={isUser}>
        <li>{t('header.support')}</li>
      </Link>
    </ul>
  );
};
