import React, { useMemo, useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { Button, Switcher } from '@/modules/shared/components';
import { InputText } from '@/modules/shared/components/inputs/input-text';
import { SettingsApi } from './api';
import { useGetPrivacies, useChangePrivacies } from './hooks';
import { DeletionModals } from './components';
import { PrivaciesTypes } from './types';
import styles from './personal-settings.module.scss';

export const PersonalSettings = () => {
  const { t } = useTranslation('userSettings');
  const [isDeletion, setIsDeletion] = useState(false);
  const { isDesktop } = useMatchMedia();
  const [isError, setIsError] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { privacies: initialPrivacies } = useGetPrivacies();
  const { changePrivacies } = useChangePrivacies();
  const [privacies, setPrivacies] = useState(initialPrivacies);

  useEffect(() => {
    if (initialPrivacies) {
      setPrivacies(initialPrivacies);
    }
  }, [initialPrivacies]);

  useEffect(() => {
    if (!isDesktop) {
      const headerElement = document.querySelector('header');
      if (headerElement) {
        const { height } = headerElement.getBoundingClientRect();
        document.documentElement.style.setProperty('--header-height', `${height + 16}px`); // 16 отступ от хедера
        document.documentElement.style.setProperty('--global-container-height', '100dvh');
      }
    }
  }, [isDesktop]);

  const schema = useMemo(() => yup.object().shape({
    old_password: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('currentPass') }))
      .min(8, t('forms:validationErrors.passwordTooShort', { length: 8 })),
    new_password: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('newPass') }))
      .min(8, t('forms:validationErrors.passwordTooShort', { length: 8 })),
  }), [t]);

  const {
    control,
    handleSubmit,
    formState: {
      errors, isDirty, isValid,
    },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await SettingsApi.changePassword(data);
      reset({ old_password: '', new_password: '' });
      setIsError(false);
      setIsPasswordChanged(true);
    } catch (error) {
      if (error.response?.status === 400) {
        setIsError(true);
        setIsPasswordChanged(false);
      }
    }
  };

  const handlePrivacyChange = (key: keyof PrivaciesTypes, value: boolean) => {
    setPrivacies((prev) => ({ ...prev, [key]: value }));

    changePrivacies({ [key]: value } as Pick<PrivaciesTypes, keyof PrivaciesTypes>, {
      onSuccess: (updatedData) => {
        setPrivacies((prev) => ({ ...prev, ...updatedData }));
      },
    });
  };

  return (
    <>
      <section className={styles.personal_settings}>
        <div className={styles.password_change_form}>
          <div className={styles.title}>
            <h2>{t('changePass')}</h2>
            <p>{t('warningMsg')}</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fields}>
              <label>
                {t('currentPass')}
                <Controller
                  control={control}
                  name="old_password"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      fullWidth
                      type="password"
                      error={!!errors.old_password}
                    />
                  )}
                />
              </label>
              <label>
                {t('newPass')}
                <Controller
                  control={control}
                  name="new_password"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      fullWidth
                      type="password"
                      error={!!errors.new_password}
                    />
                  )}
                />
              </label>
            </div>
            {isError && !isPasswordChanged && (<p className={styles.error}>{t('forms:validationErrors.password_incorrect')}</p>)}
            {isPasswordChanged && (<p className={styles.success}>{t('passwordSaved')}</p>)}
            {isDesktop && (
              <Button
                as="button"
                buttonSize="s"
                buttonColor="green"
                appearance="primary"
                fullWidth
                type="submit"
                disabled={!isValid}
                className={styles.save_new_password}
              >
                {t('save')}
              </Button>
            )}
          </form>
        </div>

        <div className={styles.personal_settings_privacy}>
          <h2>{t('privacy')}</h2>
          <div className={styles.change_settings}>
            <div className={styles.option}>
              <p>{t('hidePhone')}</p>
              <Switcher
                checked={privacies?.phone_number_is_hidden}
                onChange={() => handlePrivacyChange('phone_number_is_hidden', !privacies.phone_number_is_hidden)}
              />
            </div>
            {/* FIXME: Пока решили убрать до редизайна */}
            {/* <div className={styles.option}>
              <p>{t('allowMessages')}</p>
              <Switcher
                checked={privacies?.messages_are_allowed}
                onChange={() => handlePrivacyChange('messages_are_allowed', !privacies.messages_are_allowed)}
              />
            </div> */}
          </div>
        </div>

        <Button
          as="button"
          buttonSize="m"
          buttonColor="white"
          appearance="secondary"
          className={styles.delete_account}
          onClick={() => setIsDeletion(!isDeletion)}
        >
          {t('deleteAccount')}
        </Button>
        {!isDesktop && isDirty && (
          <Button
            as="button"
            buttonSize="s"
            buttonColor="green"
            appearance="primary"
            fullWidth
            type="submit"
            disabled={!isValid}
            className={styles.mobile_save_new_password}
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </Button>
        )}
      </section>
      {isDeletion && (
        <DeletionModals
          isOpen={isDeletion}
          onClose={() => setIsDeletion(!isDeletion)}
        />
      )}
    </>
  );
};
