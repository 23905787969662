import useTranslation from 'next-translate/useTranslation';

export const useExcursions = () => {
  const { t } = useTranslation('ad-parameters');

  const mapTransfer = (value: string) => ({ value, label: t(`excursions.transfer.options.${value}`) });
  const mapAgeRestriction = (value:string) => ({ value, label: value });
  const mapDuration = (value: string) => ({ value, label: t(`excursions.duration.options.${value}`) });
  const mapLanguages = (value: string) => ({ value, label: t(`excursions.language.options.${value}`) });
  const mapGuide = (value: string) => ({ value, label: t(`excursions.guide.options.${value}`) });

  return {
    transfer: ['included', 'not_included'].map(mapTransfer),
    age_restriction: ['0+', '3+', '6+', '12+', '18+', '21+'].map(
      mapAgeRestriction,
    ),
    duration_of_excursion: ['day', 'half_day', 'few_days'].map(mapDuration),
    guide: ['included', 'not_included'].map(mapGuide),
    language: ['english', 'russian', 'other'].map(mapLanguages),
  };
};
