import { useEffect, useState } from 'react';
import i18n from '@/lib/i18n';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useMeContext } from '@/modules/me/providers';
import { api, baseEndpointV1 } from '@/modules/shared/api';

const useUserAccount = () => {
  const [loading, setLoading] = useState(false);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [error, setError] = useState(null);
  const { locale } = useRouter();

  // as for now keep using useUserAccount,
  // but in future completely migrate the functionality to MeProvider
  const { me, isLoading: isFetchingUserData, refetch } = useMeContext();
  const [userData, setUserData] = useState(me);

  useEffect(() => {
    setUserData(me);
  }, [me]);

  const updateUser = async (updateData) => {
    try {
      setUpdateUserLoading(true);
      const response = await api.patch(`${baseEndpointV1}/users/profile/`, updateData);

      if (response.status === 200) {
        toast.success(i18n.toast.Successfully[locale]);
        await refetch();
      }
    } catch (err) {
      setError(err);
    } finally {
      setUpdateUserLoading(false);
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      await api.delete(`${baseEndpointV1}/announcement/delete/${id}/`);

      const updatedAnnouncements = userData.not_active_announcements.filter(
        (announcement) => announcement.id !== id,
      );
      setUserData((prevData) => ({
        ...prevData,
        not_active_announcements: updatedAnnouncements,
      }));
    } catch (err) {
      console.error('Error deleting announcement:', err);
    }
  };

  const deleteAnnouncementAdmin = async (id) => {
    try {
      await api.delete(`${baseEndpointV1}/admin_panel/del_ann/${id}/`);

      const updatedAnnouncements = userData.not_active_announcements.filter(
        (announcement) => announcement.id !== id,
      );
      setUserData((prevData) => ({
        ...prevData,
        not_active_announcements: updatedAnnouncements,
      }));
    } catch (err) {
      console.error('Error deleting announcement:', err);
    }
  };

  const deactivateAnnouncement = async (id) => {
    try {
      const response = await api.patch(`${baseEndpointV1}/announcement/update/${id}/`, { is_active: false });

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при деактивации!');
      }

      const updatedActiveAnnouncements = userData.announcements.filter(
        (announcement) => announcement.id !== id,
      );
      const updatedNotActiveAnnouncements = userData.not_active_announcements
        .concat([response.data]);
      setUserData((prevData) => ({
        ...prevData,
        announcements: updatedActiveAnnouncements,
        not_active_announcements: updatedNotActiveAnnouncements,
      }));

      toast.success(i18n.toast.Thedeactivated[locale]);
    } catch (err) {
      console.error('Error deactivating announcement:', err);
    }
  };

  const banAnnouncement = async (id) => {
    try {
      const response = await api.patch(`${baseEndpointV1}/announcement/update/${id}/`, {
        is_active: false,
        is_banned: true,
        deactivation_reason_codename: 'rule_violation',
      });

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при деактивации!');
      }

      toast.success('Объявление забанено!');
    } catch (err) {
      console.error('Error deactivating announcement:', err);
    }
  };

  const activateAnnouncement = async (id) => {
    try {
      const response = await api.patch(`${baseEndpointV1}/announcement/update/${id}/`, { is_active: true });

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при активации!');
      }

      const updatedNotActiveAnnouncements = userData.not_active_announcements.filter(
        (announcement) => announcement.id !== id,
      );
      const updatedActiveAnnouncements = userData.announcements.concat([
        response.data,
      ]);
      setUserData((prevData) => ({
        ...prevData,
        announcements: updatedActiveAnnouncements,
        not_active_announcements: updatedNotActiveAnnouncements,
      }));
      refetch();

      toast.success(i18n.toast.Advertisement[locale]);
    } catch (err) {
      console.error('Error activating announcement:', err);
    }
  };

  return {
    loading: loading || isFetchingUserData,
    updateUserLoading,
    error,
    deleteAnnouncement,
    updateUser,
    deactivateAnnouncement,
    banAnnouncement,
    activateAnnouncement,
    deleteAnnouncementAdmin,
    userData,
    refetch,
  };
};

export default useUserAccount;
