import { memo } from 'react';
import { FormState } from '@/modules/shared/hooks';
import { TagSelector } from '@/modules/shared/components';

import { FiltersMap } from '../types';
import { FilterRow } from '../components/filter-row';
import { withDynamicFieldFormGuard } from '../hoc/withDynamicFieldFormGuard';
import { withErrorBoundary } from '../hoc/withErrorBoundary';

type TagSelectorProps = {
  filter: FiltersMap['checkbox_list'],
  form: FormState<'age_restriction'>
};

export const TagField = withErrorBoundary(withDynamicFieldFormGuard<TagSelectorProps>(memo(({ t, filter: { choices }, form }) => (
  <FilterRow title={t('filters:age_restriction.title')}>
    <TagSelector
      options={choices}
      value={form.value.age_restriction}
      onChange={form.setter.age_restriction}
    />
  </FilterRow>
), (prev, next) => (
  prev.form.value.age_restriction === next.form.value.age_restriction
    && prev.filter === next.filter
    && prev.t === next.t
))));
