import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import { LeaveFeedbackModal } from '@/modules/review';
import { profileBasePath } from '@/modules/shared/const';
import { REVIEW_IS_REQUESTED, REVIEW_IS_SUGGESTED, THANK_YOU_FOR_REVIEW } from '../../../const';
import type { AdDataForFeedback } from '../../../types';
import type { RecipientMessage, SenderMessage } from './message.component';
import styles from './system-message.module.scss';

export type SystemMessageProps = {
  msg: RecipientMessage | SenderMessage;
  senderType: 'sender' | 'recipient';
  adData?: AdDataForFeedback;
};

export const SystemMessage = ({ msg, senderType, adData }: SystemMessageProps) => {
  const { t, lang } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenFeedbackModal = useCallback(() => {
    setOpenModal((prev) => !prev);
  }, [openModal]);

  const reviewRequestSection = (title: string) => (
    <>
      <div className={styles.wrapper}>
        <p className={clsx(styles.description)}>{t(`chat:review.${title}`)}</p>
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          className={styles.button}
          onClick={handleOpenFeedbackModal}
        >
          {t('chat:leaveFeedback')}
        </Button>
      </div>

      {adData && openModal && (
        <LeaveFeedbackModal
          isOpen={openModal}
          suggestion={adData}
          onClose={handleOpenFeedbackModal}
        />
      )}
    </>
  );


  if (msg.message === REVIEW_IS_REQUESTED) {
    if (senderType === 'sender') {
      return <p className={clsx(styles.description, styles.wrapper)}>{t('chat:review.requestedByYou')}</p>;
    }

    return reviewRequestSection('requestedBySeller');
  }


  if (msg.message === REVIEW_IS_SUGGESTED) {
    return reviewRequestSection('suggested');
  }

  if (msg.message === THANK_YOU_FOR_REVIEW) {
    if (senderType === 'recipient') {
      return <p className={clsx(styles.description, styles.wrapper)}>{t('chat:review.reviewThankYouMessage')}</p>;
    }


    return (
      <div className={styles.wrapper}>
        <p className={clsx(styles.description)}>{t('chat:review.reviewNotificationForSeller')}</p>
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          className={styles.button}
          as="link"
          href={`/${lang}/${profileBasePath}/rating`}
        >
          {t('chat:review.goToReviewsButton')}
        </Button>
      </div>
    );
  }

  return null;
};
