import { useQuery } from '@tanstack/react-query';
import { IReviews } from '@/modules/shared/types';
import { ReviewApi } from '../api';

const queryKey = ['reviews'];

interface IUseReviewsListQueryParams {
  id: number;
  filters?: Record<string, string>;
}

export const useReviewsListQuery = ({ id, filters = {} }: IUseReviewsListQueryParams) => useQuery<IReviews[]>({
  queryKey: [...queryKey, id, filters],
  queryFn: () => ReviewApi.userReviewsList(id, filters),
});
