import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { useAuthContext } from '@/modules/auth';
import { useOpenAuthModal } from '@/modules/auth/hooks';

export type ContactsPosterProps = {
  phone_number?: string;
  telegram?: string;
  instagram?: string;
  whatsapp?: string;
};

export const useContactsPoster = ({
  phone_number,
  telegram,
  instagram,
  whatsapp,
}: ContactsPosterProps) => {
  const { isAuthenticated } = useAuthContext();
  const openAuthModal = useOpenAuthModal();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  const handleClick = (event) => {
    if (!isAuthenticated) {
      event.preventDefault();
      openAuthModal('login');
    }
  };

  const contactData = [
    {
      key: 'phone',
      href: phone_number ? `tel:${phone_number}` : undefined,
      icon: 'phone-bg',
    },
    {
      key: 'telegram',
      href: telegram
        ? telegram.includes('https://t.me')
          ? telegram
          : `https://t.me/${telegram}`
        : undefined,
      icon: 'telegram',
    },
    {
      key: 'instagram',
      href: instagram
        ? instagram.includes('https://www.instagram')
          ? instagram
          : `https://www.instagram.com/${instagram}`
        : undefined,
      icon: 'instagram',
    },
    {
      key: 'whatsapp',
      href: whatsapp
        ? whatsapp.includes('https://wa.me')
          ? whatsapp
          : `https://wa.me/${whatsapp}`
        : undefined,
      icon: 'whats-app',
    },
  ];

  if (!isAuthenticated || !hydrated) {
    return contactData
      .filter(({ href }) => !!href)
      .map(({ key, icon }) => (
        <SvgIcon name={icon} onClick={handleClick} key={key} />
      ));
  }

  const contacts = contactData
    .filter(({ href }) => !!href)
    .map(({ key, href, icon }) => (
      <a href={href} target="_blank" rel="noreferrer" key={key}>
        <SvgIcon name={icon} />
      </a>
    ));

  return contacts.length > 0 ? contacts : null;
};
