import React, {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import { useRouter } from 'next/router';
import Cookie from 'js-cookie';
import { useMeContext } from '@/modules/me';
import { saveEntityImages } from '@/modules/shared/api';
import { IAd } from '@/modules/ads/types';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_KEY, useInfinityMessages } from '../hooks/use-infinity-messages';
import {
  AdDataForFeedback, ChatSupportList, DraftMessage, MessageDetails, UserContact,
} from '../types';
import { ChatTop } from './chat/chat-top';
import { MessagesContainer } from './chat/messages-container';
import { ChatChoosePhoto, ChatTextField } from './chat/chat-input';
import { useNetworkStatus } from '../hooks';
import styles from './chat-page.module.scss';
import { ChatApi } from '../api';

type ChatPageProps = {
  selectedUserData: UserContact | ChatSupportList;
  ad?: IAd | undefined;
  isSupport?: boolean;
};

export const ChatPage = ({
  selectedUserData, ad, isSupport = false,
}: ChatPageProps) => {
  const { isDesktop } = useMatchMedia();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { query, isReady } = router;
  const [value, setValue] = useState<string>('');
  const [messages, setMessages] = useState<MessageDetails[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [image, setImage] = useState<File>();
  const [isPreloading, setIsPreloading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [draftMessages, setDraftMessages] = useState<DraftMessage[]>([]);
  const [errorMessages, setErrorMessages] = useState<DraftMessage[]>([]);
  const refScrollContainer = useRef<{
    scrollToBottom:(behavior?: 'auto' | 'smooth' | 'instant') => void,
    getElement: () => HTMLElement | undefined
  }>(null);


  const createImageApi = useCallback(async (formats) => ChatApi.createMessageImages({ formats }), []);

  const timeString = new Date().toString();

  const ws = useRef<WebSocket | null>(null);
  const refetchTimer = useRef<NodeJS.Timeout | null>(null);
  const { me } = useMeContext();
  const refQueueReadMessages = useRef<number[]>([]);

  const isConnected = useNetworkStatus();
  const meID = me?.id;
  const adID = Array.isArray(query.ad) ? query.ad[0] : query.ad;
  const recipientId = query?.id || selectedUserData?.id;

  const {
    pages: preloadedMessages, hasMore, fetchMore, isLoading: isLoadingMessages,
  } = useInfinityMessages({
    filters: {
      me: meID,
      announcement: adID,
      recipient: recipientId,
    },
  });

  useEffect(() => {
    if (!isLoading) return;
    if (!isLoadingMessages) {
      setIsLoading(false);
    }
  }, [isLoading, isLoadingMessages]);

  useEffect(() => () => {
    queryClient.removeQueries({
      predicate: (item) => item.queryKey.includes(BASE_KEY),
    });
  }, [queryClient]);

  const infinityOffset: number = useMemo(() => {
    if (isDesktop) return 500;
    const clientHeight = refScrollContainer.current?.getElement()?.clientHeight || 500;

    return clientHeight / 2;
  }, [isDesktop]);

  const refLoadMore = useInfiniteListRef(hasMore, fetchMore, isLoadingMessages, {
    root: refScrollContainer.current?.getElement(),
    rootMargin: `${infinityOffset}px`,
  });


  const messagesWithPreloaded = useMemo(() => {
    const _messages = preloadedMessages.reduce((memo, messageValue) => [
      ...messageValue.results.toReversed(),
      ...memo,
    ], []);

    return _messages.concat(messages);
  }, [messages, preloadedMessages]);

  const draftMessage = useMemo(() => (
    {
      message: image ? '' : newMessage,
      timestamp: timeString,
      images: image ? [image] : [],
    }
  ), [image, newMessage, timeString]);

  useEffect(() => {
    if (ws.current) return;

    if (!isReady) return;
    setIsLoading(true);

    const accessToken = Cookie.get('access_token') || '';


    if (recipientId && (!ws.current || (ws.current as WebSocket).readyState === WebSocket.CLOSED)) {
      const wsUrl = adID
        ? `${process.env.NEXT_PUBLIC_WS_API_URL}/chat/${recipientId}/${adID}/?token=${accessToken}`
        : `${process.env.NEXT_PUBLIC_WS_API_URL}/chat/${recipientId}/?token=${accessToken}`;

      ws.current = new WebSocket(wsUrl);

      ws.current.onopen = () => {
        setTimeout(() => {
          if (
            ws.current
            && ws.current.readyState === WebSocket.OPEN
            && messages.length === 0
          ) {
            refQueueReadMessages.current.forEach((messageId) => {
              ws.current?.send(JSON.stringify({
                type: 'updated_message',
                message_id: messageId,
                action: 'read',
              }));
            });
            refQueueReadMessages.current = [];
            setIsPreloading(false);
          }
        }, 1000);
      };

      ws.current.onmessage = (e) => {
        const data = JSON.parse(e.data);

        setMessages((prevMessages) => {
          const newMessagesState = [...prevMessages, data];

          if (data.type === 'updated_message') {
            return newMessagesState.map((item) => {
              if (item.id === data.message.id) {
                return data.message;
              }
              return item;
            });
          }
          return newMessagesState;
        });

        setIsPreloading(false);
        setDraftMessages([]);
        setErrorMessages([]);
      };

      ws.current.onclose = () => {
        setIsLoading(false);
        setIsPreloading(false);
        setDraftMessages([]);
        setErrorMessages([]);
      };

      ws.current.onerror = (err) => {
        console.error('WebSocket Error:', err);
        setIsLoading(false);
        setIsPreloading(false);
        setDraftMessages([]);
        setErrorMessages((prev) => [...prev, draftMessage]);
      };
    }

    return () => {
      if (ws.current) {
        ws.current.onopen = null;
        ws.current.onmessage = null;
        ws.current.onclose = null;
        ws.current.onerror = null;
        ws.current.close();

        ws.current = null;
      }
    };
  }, [adID, recipientId]);

  const sendMessage = useCallback(async (img?: File) => {
    const trimmedMessage = newMessage.trim();
    setValue('');
    if (img) setImage(img);

    if (trimmedMessage !== '' || img) {
      setIsPreloading(true);
      setDraftMessages((prev) => [...prev, {
        message: img ? '' : trimmedMessage,
        timestamp: timeString,
        images: img ? [img] : [],
      }]);
    }
    if (
      (trimmedMessage !== '' || img)
      && ws.current
      && ws.current.readyState === WebSocket.OPEN
      && isConnected
    ) {
      try {
        const imageData = img ? await saveEntityImages([img], createImageApi) : [];
        const messageData = img ? {
          images: imageData,
          message: '',
          senderId: meID,
        } : {
          message: trimmedMessage,
          senderId: meID,
        };
        ws.current.send(JSON.stringify(messageData));
        setNewMessage('');
      } catch (error) {
        setErrorMessages((prev) => [...prev, {
          message: img ? '' : trimmedMessage,
          timestamp: timeString,
          images: img ? [img] : [],
        }]);
      }
    }

    if (!isConnected) {
      setTimeout(() => {
        setErrorMessages((prev) => [...prev, {
          message: img ? '' : trimmedMessage,
          timestamp: timeString,
          images: img ? [img] : [],
        }]);
        setIsPreloading(false);
        setDraftMessages([]);
      }, 2000);
    }
  }, [isConnected, newMessage, meID, timeString]);

  const handleErrorClick = () => {
    setDraftMessages(() => [draftMessage]);
    setErrorMessages([]);
    setIsPreloading(true);

    if (refetchTimer.current) {
      clearTimeout(refetchTimer.current);
    }

    // Пытаемся отправить сообщение после небольшой задержки
    refetchTimer.current = setTimeout(() => {
      if (isConnected) {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          try {
            const messageData = {
              message: newMessage,
              senderId: meID,
            };
            ws.current.send(JSON.stringify(messageData));
            // Очищаем черновики после успешной отправки
            setDraftMessages([]);
            setNewMessage('');
            setIsPreloading(false); // Останавливаем предзагрузку
          } catch (error) {
            // Если отправка не удалась, добавляем сообщение в ошибки
            setErrorMessages((prev) => [...prev, draftMessage]);
            setIsPreloading(false); // Останавливаем предзагрузку
          }
        } else {
          // Если WebSocket не открыт, добавляем сообщение в ошибки
          setErrorMessages((prev) => [...prev, draftMessage]);
          setIsPreloading(false); // Останавливаем предзагрузку
        }
      } else {
        // Если сеть не подключена, добавляем сообщение в ошибки
        setErrorMessages((prev) => [...prev, draftMessage]);
        setIsPreloading(false); // Останавливаем предзагрузку
      }
    }, 2000);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    },
    [sendMessage],
  );

  const handleSendMessageRead = useCallback((messageId: number) => {
    if (ws.current?.readyState !== WebSocket.OPEN) {
      refQueueReadMessages.current.push(messageId);
      return;
    }

    ws.current?.send(JSON.stringify({
      type: 'updated_message',
      message_id: messageId,
      action: 'read',
    }));
  }, []);

  const handleChange = useCallback((valueInput:string) => {
    refScrollContainer.current?.scrollToBottom('auto');
    setValue(valueInput);
    setNewMessage(valueInput);
  }, []);


  const adDataForFeedback = useMemo(() => {
    if (ad) {
      return {
        user: ad.user_data,
        announcement: {
          user: ad.user,
          id: ad.id,
          title: ad.title,
          price_type: ad.price_type,
          price: ad.price,
          main_picture: {
            thumbnail: ad?.images.find((img) => img.id === ad?.main_picture)?.thumbnail || '',
          },
        },
      };
    }
  }, [ad]) as AdDataForFeedback;

  return (
    <div className={styles.chat}>
      <div className={styles.chat_top}>
        <ChatTop
          recipient={!isSupport ? (selectedUserData as UserContact) : null}
          ad={ad}
          typeChat={isSupport ? 'support' : 'user'}
        />
      </div>
      <MessagesContainer
        scrollInfinityOffset={infinityOffset}
        onChangeReadStatus={handleSendMessageRead}
        autoScrollRef={refScrollContainer}
        isLoading={isLoading}
        refLoadMore={refLoadMore}
        messages={messagesWithPreloaded}
        userId={meID ?? null}
        adData={adDataForFeedback}
        isPreloading={isPreloading}
        errorMessages={errorMessages}
        draftMessages={draftMessages}
        onErrorClick={handleErrorClick}
        isSupport={isSupport}
      />

      <div className={styles.send_message}>
        <ChatChoosePhoto sendMessage={sendMessage} />
        <ChatTextField
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          sendMessage={sendMessage}
          value={value}
        />
      </div>
    </div>
  );
};
