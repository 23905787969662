import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  list: () => `${baseEndpointV1}/announcement/list`,
  myAdsList: () => `${baseEndpointV1}/announcement/my`,
  create: () => `${baseEndpointV1}/announcement/create/`,
  createImages: () => `${baseEndpointV1}/announcement/images/create/`,
  detail: (id: string) => `${baseEndpointV1}/announcement/detail/${id}/`,
  toggleAdVisibility: () => `${baseEndpointV1}/announcement/bulk_update/visibility/`,
  recommendedAds: () => `${baseEndpointV1}/announcement/recommended/list/`,
  similarAds: (id: string) => `${baseEndpointV1}/announcement/similar/${id}/`,
};
