import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormState, useWatch } from 'react-hook-form';
import { Button, SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { ReviewFormTypes, AnnouncementReviewData } from '../../types';
import {
  MakeGrade, LeaveComment, Buttons, AdDescription, LoadPhotos,
} from './step-two-components';
import styles from '../leave-feedback-modal.module.scss';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  adDetail: AnnouncementReviewData | undefined;
}

export const StepTwo = ({ setStep, adDetail }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const { errors, isSubmitting } = useFormState();
  const rating = useWatch<ReviewFormTypes>({ name: 'rating' });
  const [isConverting, setIsConverting] = useState(false);
  // setValue('photos', ...) в LoadPhotos может не успеть отработать,
  // блокируем кнопку отправки, пока фото обрабатываются

  return (
    <div className={styles.step_two}>
      <h2>{t('review:yourReview')}</h2>
      <AdDescription adDetail={adDetail} />

      <div className={styles.info}>
        <div className={styles.svg_wrapper}>
          <SvgIcon name="info" />
        </div>
        <p>{t('review:info')}</p>
      </div>

      <MakeGrade />
      <LeaveComment />
      <LoadPhotos setIsConverting={setIsConverting} />

      {!isMobile ? (
        <Buttons setStep={setStep} isConverting={isConverting} />
      ) : (
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="m"
          type="submit"
          disabled={
            !rating || isSubmitting || isConverting || Boolean(errors.photos)
          }
        >
          {isSubmitting ? t('common:submitting') : t('common:submit')}
        </Button>
      )}
    </div>
  );
};
