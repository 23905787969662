import React, { forwardRef, CSSProperties } from 'react';
import clsx from 'clsx';
import { useTranslateLocation } from '@/modules/locations';
import { SvgIcon } from '@/modules/shared/components';
import styles from './geolocation-pointer.module.scss';

interface GeolocationProps {
  city: string;
  district?: string;
  iconOnly?: boolean;
  classNameIcon?: string | CSSProperties;
  onClick?: () => void;
}

export const GeolocationPointer = forwardRef<HTMLButtonElement, GeolocationProps>(
  ({
    city, district, iconOnly = false, classNameIcon, onClick,
  }, ref) => {
    const translatedLocation = useTranslateLocation(city, district);

    return (
      <button
        type="button"
        className={clsx(styles.geo_pointer, classNameIcon)}
        onClick={onClick}
        ref={ref}
        data-testid="city_selector_button"
      >
        <SvgIcon name="local-map" />
        {!iconOnly && (<p>{translatedLocation}</p>)}
      </button>
    );
  },
);
