import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, SvgIcon } from '@/modules/shared/components';
import { IAd } from '@/modules/ads/types';
import { AdCard } from '../../index-page/ad-card';
import styles from '../ad-page.module.scss';

interface Props {
  ad: IAd;
  adOwnerAdsList: IAd[];
  companyId: number | undefined | null;
}

export const AllSellerAds = ({ adOwnerAdsList, ad, companyId }: Props) => {
  const { t } = useTranslation('adv');

  return (
    <section className={styles.all_sellers_ads}>
      <div className={styles.all_sellers_ads_header}>
        <h2>{t('allSellerAds')}</h2>
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="xs"
          as="link"
          href={companyId ? `/companies/${companyId}` : `/users/${ad.user}`}
        >
          {t('lookAll')}
          <SvgIcon name="arrow-forward-new-desing" />
        </Button>
      </div>
      <div className={styles.all_sellers_ads_list}>
        {adOwnerAdsList.map((item: IAd, index: number) => (
          <AdCard key={index} ad={item} extendedCard={false} />
        ))}
      </div>
    </section>
  );
};
