import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useRouter } from 'next/router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { Button, SvgIcon } from '@/modules/shared/components';
import { useToggleMyAdVisibility } from '@/modules/ads/mutations';
import Loadingmain from '@/components/shared/skeleton/loadingmain';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { useMyAdsInfiniteListQuery, useMyAdsListQuery } from '@/modules/ads/queries';
import {
  MyAdsList, MyAdsTab, NotAnyAds, MyAdsHeader, ModalConfirmAd,
} from './components';
import { AdTab } from './my-ads-types';
import styles from './my-ads-page.module.scss';

const ITEMS_PER_PAGE = 10;

export const MyAdsPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const { mutate: toggleAdVisibility } = useToggleMyAdVisibility();

  const [currentPages, setCurrentPages] = useState({
    active: 1,
    hidden: 1,
  });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAds, setSelectedAds] = useState<number[]>([]);
  const [quantity, setQuantity] = useState({ active: 0, hidden: 0 });
  const [adType, setAdType] = useState(router.query.tab as AdTab || 'active');

  const formMethods = useForm({ defaultValues: { sort: '-created_at' } });
  const { setValue, control } = formMethods;
  const selectedSort = useWatch({ control, name: 'sort' });

  const filters = useMemo(() => {
    const statusMap = { active: 'True', hidden: 'False' };
    return { is_active: statusMap[adType] ?? undefined };
  }, [adType]);

  const {
    data, isLoading: desktopLoading, initialLoading,
  } = useMyAdsListQuery({
    pageSize: ITEMS_PER_PAGE,
    order: selectedSort,
    page: currentPages[adType],
    filters,
    enabled: isDesktop,
  });

  const {
    pages, isLoading, hasMore, fetchMore,
  } = useMyAdsInfiniteListQuery({
    pageSize: 10,
    order: selectedSort,
    filters,
    disabled: isDesktop,
  });
  const lastListItemRef = useInfiniteListRef(hasMore, fetchMore, isLoading);

  useEffect(() => {
    if (isLoading || desktopLoading) return;
    const counts = isDesktop ? data?.counts || {} : pages?.[0]?.counts || {};
    const { active, inactive } = counts;
    setQuantity({ active, hidden: inactive });
  }, [data?.counts, pages, isDesktop, desktopLoading, isLoading]);

  useEffect(() => {
    const savedPages = JSON.parse(sessionStorage.getItem('returning-my-ads-page') || '{}');
    if (savedPages[adType]) {
      setCurrentPages((prevPages) => ({
        ...prevPages,
        [adType]: savedPages[adType],
      }));
    }
  }, [adType]);

  useEffect(() => {
    if (selectedSort !== '-created_at') {
      setCurrentPages((prevPages) => ({
        ...prevPages,
        [adType]: 1,
      }));
    }
  }, [selectedSort, adType]);

  useEffect(() => {
    if (data.counts?.active === 0 && data.counts?.inactive === 0) return;
    if (!router.asPath.includes(adType)) {
      router.replace({ pathname: router.pathname, query: { tab: adType } });
    }
  }, [router, adType, data]);

  const myAds = useMemo(() => (isDesktop ? data?.results : pages), [isDesktop, data, pages]);

  const handleSetPage = useCallback((tab: AdTab, page: number) => {
    setCurrentPages((prevPages) => {
      const newPages = { ...prevPages, [tab]: page };
      sessionStorage.setItem('returning-my-ads-page', JSON.stringify(newPages));
      return newPages;
    });
  }, []);

  const handleChangeAdTab = useCallback((status: AdTab) => {
    setAdType(status);
    setSelectedAds([]);
    setIsAllSelected(false);
    if (selectedSort !== '-created_at') setValue('sort', '-created_at');
  }, [setValue, selectedSort]);

  const handleToggleVisibility = useCallback(() => {
    const action = adType === 'active' ? 'hide' : 'activate';
    /*
    * Прежде чем отправлять запрос, нужно обновить номер последней страницы
    * чтобы корректно обновить список объявлений
    * */
    if (currentPages[adType] !== 1) {
      const lastPageAdsQuantity = quantity[adType] % ITEMS_PER_PAGE || ITEMS_PER_PAGE;
      const lastPage = Math.ceil((quantity[adType] - selectedAds.length) / ITEMS_PER_PAGE);
      if ((currentPages[adType] > lastPage) && (selectedAds.length === lastPageAdsQuantity)) {
        // если мы на последней странице и выбраны все объявления, тогда обновляем номер последней страницы
        setCurrentPages((prevPages) => {
          const updatedPages = { ...prevPages, [adType]: lastPage };
          sessionStorage.setItem('returning-my-ads-page', JSON.stringify(updatedPages));
          return updatedPages;
        });
      }
    }

    toggleAdVisibility({ id: selectedAds!, action });
    setIsAllSelected(false);
  }, [adType, toggleAdVisibility, selectedAds, quantity, currentPages]);

  if (initialLoading) return <Loadingmain />;
  if (!(desktopLoading || isLoading) && quantity?.active === 0 && quantity?.hidden === 0) {
    return <NotAnyAds />;
  }

  return (
    <FormProvider {...formMethods}>
      <section className={styles.my_ads_page}>
        <MyAdsTab
          tab={adType}
          quantity={quantity}
          isLoading={desktopLoading || isLoading}
          handleChangeAdTab={handleChangeAdTab}
        />
        <div className={styles.my_ads_list}>
          {quantity[adType] !== 0 && (
            <MyAdsHeader
              adType={adType}
              adCounts={quantity}
              selectedAds={selectedAds}
              isAllSelected={isAllSelected}
              onToggleSelectAll={setIsAllSelected}
              onOpenModal={() => setShowModal(!showModal)}
            />
          )}
          <MyAdsList
            ads={myAds}
            adType={adType}
            hasMore={hasMore}
            isLoading={desktopLoading || isLoading}
            fetchMore={fetchMore}
            currentPage={currentPages[adType]}
            selectedAds={selectedAds}
            onSelectAd={setSelectedAds}
            isInfinity={lastListItemRef}
            setShowModal={setShowModal}
            isAllSelected={isAllSelected}
            totalPages={data?.total_pages}
            onIsAllSelected={setIsAllSelected}
            handleSetPage={(page) => handleSetPage(adType, page)}
          />
        </div>
        {!isDesktop && (
          <div className={styles.button_add}>
            <Button
              as="link"
              fullWidth
              buttonSize="s"
              buttonColor="green"
              appearance="primary"
              href="/product/create"
            >
              <SvgIcon name="plus" />
              {t('common:header.placeAd')}
            </Button>
          </div>
        )}
        {showModal && (
          <ModalConfirmAd
            adType={adType}
            isOpen={!!showModal}
            onClose={() => setShowModal(false)}
            toggleAdVisibility={handleToggleVisibility}
            isSelected={selectedAds.length > 1 || isAllSelected}
          />
        )}
      </section>
    </FormProvider>
  );
};
