import { EntityImage } from '@/modules/shared/types';

export type Company = {
  id: number;
  status: CompanyStatus;
  verified_at: string;
  logo: CompanyLogo;
  user: number;
  name: string;
  description: string;
  banners: EntityImage[] | null;
  custom_page_url: string | null;
  tax_id: string;
  social_networks: SocialNetworks;
  contact_info: {
    email: string | null;
    phone_number: string | null;
  }
};

export type CompanyLogo = {
  id: number;
  image: string;
  thumbnail: string;
  user: number;
  image_signed_url: string;
  thumbnail_signed_url: string;
};

export type SocialNetworks = {
  whatsapp: string | null;
  instagram: string | null;
  telegram: string | null;
};

export enum CompanyStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Proceeding = 'proceeding',
}

export type CompanyStatus =
  | CompanyStatusEnum.Pending
  | CompanyStatusEnum.Approved
  | CompanyStatusEnum.Rejected
  | CompanyStatusEnum.Proceeding;
