import React, { useMemo } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Category, useCategoryTop, useCategories } from '@/modules/categories';
import { VERIFIED_COMPANIES_LINK } from '@/modules/shared/const';
import { SvgIcon } from '@/modules/shared/components';
import { CategoryCard } from '../category-card';
import styles from './category-block-home.module.scss';


const orderedCategories = [
  'transport', 'personal-items', 'real-estate', 'electronics', 'services', 'home', 'animals',
];

export const CategoryBlockHome = () => {
  const { getCategoryByCode } = useCategories();
  const { data } = useCategoryTop(11);
  const { t } = useTranslation('');
  const { isMobile, isTablet } = useMatchMedia();

  const sortedDataCards = useMemo(() => {
    if (!data) return [];
    // Создаем объекты для категорий в нужном порядке
    const filteredDataCards = orderedCategories
      .map((category) => data?.find((card) => card.codename === category))
      .filter((card): card is Category => !!card);

    return filteredDataCards
      .map((item) => getCategoryByCode(item.codename))
      .filter((card): card is Category => !!card);
  }, [data, getCategoryByCode]);

  const svgName = (() => {
    if (isMobile) {
      return 'check-circle-54x56';
    }
    if (isTablet) {
      return 'check-circle-72x77';
    }
    return 'check-circle-73x75';
  })();

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <Link
          data-testid="title_all"
          className={styles.companies}
          href={VERIFIED_COMPANIES_LINK}
        >
          {t('index-page:verifiedCompanies')}
          <SvgIcon name={svgName} className={styles.svg} />
        </Link>
        {sortedDataCards.slice(0, 3).map((card) => (
          <CategoryCard data={card} key={card.id} />
        ))}
      </div>
      <div className={styles.row}>
        {sortedDataCards.slice(3).map((card) => (
          <CategoryCard data={card} key={card.id} />
        ))}
      </div>
    </div>
  );
};
