import { useMemo } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { CreateAdTypes } from '../types';
import { useCreateAdContext } from '../context';

export const useIsStepValid = () => {
  const {
    mainStep, isPhoneVerified, isAdditionalSpecifications, images, category,
  } = useCreateAdContext();
  const { errors } = useFormState<CreateAdTypes>();

  const {
    city,
    district,
    title,
    description,
    price,
    price_type,
  } = useWatch<CreateAdTypes>();

  const isContactInfoValid = useMemo(() => {
    if (category?.codename === 'services' || category?.codename === 'excursions') {
      return Boolean(city && isPhoneVerified);
    }
    return Boolean(city && district && isPhoneVerified);
  }, [city, district, isPhoneVerified, category]);

  const isDescriptionValid = useMemo(() => (
    title?.trim()
    && description?.trim()
    && description?.length >= 20
    && (price_type !== 'for_money' || price?.trim())
    && images.length >= 1 && images.length <= 10
    && !(errors?.description || errors?.title)
  ), [title, description, price_type, price, errors, images.length]);

  const isDisabled = useMemo(() => {
    if (mainStep === 2 && !isContactInfoValid) {
      return true;
    }
    if (mainStep === 3 && !isDescriptionValid && isAdditionalSpecifications) {
      return true;
    }
    if (mainStep === 4 && !isDescriptionValid && !isAdditionalSpecifications) {
      return true;
    }
    return false;
  }, [mainStep, isContactInfoValid, isDescriptionValid, isAdditionalSpecifications]);

  return {
    isDisabled,
  };
};
