import React, {
  useState, useEffect, useCallback, useMemo, useLayoutEffect,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, OverflowMenu } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useReviewStatusQuery } from '@/modules/review/queries';
import { useCreateRequestReviewMutation } from '@/modules/review/mutations';
import styles from './button-request-review.module.scss';

export type ButtonRequestReviewProps = {
  reviewerId: number;
  adId: number;
};


export const ButtonRequestReview = ({ reviewerId, adId }:ButtonRequestReviewProps) => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { mutate } = useCreateRequestReviewMutation();

  const [requestReviewMobile, setRequestReviewMobile] = useState<string | null>(null);
  const [reviewExists, setReviewExists] = useState<boolean>(true);

  const handleClickRequestReview = useCallback(() => {
    mutate({
      reviewer: reviewerId,
      announcement: adId,
    });
  }, [reviewerId, adId]);

  const { data: reviewStatus } = useReviewStatusQuery({ reviewerId, adId });

  useLayoutEffect(() => {
    if (reviewStatus && (
      reviewStatus.review_exists || reviewStatus.review_request_exists || reviewStatus.review_suggestion_exists
    )) {
      setReviewExists(true);
    } else {
      setReviewExists(false);
    }
  }, [reviewerId, adId, reviewStatus]);


  const listMenu = useMemo(() => [
    {
      icon: 'reviews16',
      title: 'chat:requestReview',
      value: 'requestReview',
      disabled: reviewExists,
    },
  ], [reviewExists]);

  useEffect(() => {
    if (requestReviewMobile === 'requestReview') {
      handleClickRequestReview();
    }
  }, [requestReviewMobile]);

  return (
    <>
      {isDesktop && (
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="s"
          className={styles.buttonRequest}
          onClick={handleClickRequestReview}
          disabled={reviewExists}
        >
          {t('chat:requestReview')}
        </Button>
      )}
      {(isMobile || isTablet) && (
        <OverflowMenu list={listMenu} action={setRequestReviewMobile} />
      )}
    </>
  );
};
