import { Fragment } from 'react';

export const TextByLines = ({ text }: { text: string }) => text
  .split(/\r\n|\r|\n/) // чтобы учесть все варианты разрыва строки
  .map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
