import * as yup from 'yup';


export const featuresSchema = yup.array().of(yup.object().shape({
  enabled: yup.boolean().required(),
  params: yup.object().shape({}).optional(),
  name: yup.string().required(),
})).default([]);


