import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RESOLVE_COMPLAINT_AD_KEY, COMPLAINTS_QUERY_KEY } from '../consts';
import type { AdComplaint } from '../types';
import { ComplaintApi } from '../api';

export const useResolveAdComplaint = () => {
  const queryClient = useQueryClient();

  const {
    mutate, isPending, error,
  } = useMutation<AdComplaint, Error, AdComplaint>({
    mutationKey: RESOLVE_COMPLAINT_AD_KEY,
    mutationFn: (complaintData) => ComplaintApi.resolveAdComplaint(complaintData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: COMPLAINTS_QUERY_KEY,
      });
    },
  });

  return {
    mutate,
    isLoading: isPending,
    error,
  };
};
