import React from 'react';
import styles from './spinner.module.scss';

export type SpinnerProps = {
  color?: string;
  size: number;
};

export const Spinner = ({ color = '#fff', size = 80 }) => (
  <div
    className={styles['lds-spinner']}
    style={{
      color,
      '--size': `${size}px`,
    } as React.CSSProperties}
  >
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);
