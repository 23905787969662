import React, { useEffect } from 'react';
import { EntityImage } from '@/modules/shared/types';
import {
  AdDataForFeedback, DraftMessage, MessageDetails, MessageType,
} from '../../../types';
import { UserMessage } from './user-message.component';
import { SystemMessage } from './system-message.component';

export type RecipientMessage = MessageDetails;
export type SenderMessage = {
  message: string,
  timestamp: string,
  images?: EntityImage[],
} | DraftMessage;

export type MessageProps = {
  msg: RecipientMessage | SenderMessage;
  senderType: 'sender' | 'recipient';
  messageType?: MessageType;
  messageStatus?: string;
  onErrorClick?: () => void;
  onChangeReadStatus?: (msgId: number) => void;
  isSupport?: boolean;
  adData?: AdDataForFeedback;
};

export const Message = ({
  msg, senderType, messageType = 'from_user', messageStatus, onErrorClick, isSupport = false, adData, onChangeReadStatus,
}: MessageProps) => {
  useEffect(() => {
    if (senderType !== 'recipient' || !('is_read' in msg) || ('is_read' in msg && msg.is_read)) return;

    /**
     * Сейчас считаем что сообщение прочитано как только оно отрендерилось, но в будущем
     * можно будет добавить проверки активна ли вкладка, видно ли сообщение пользователю с учетом скролла и так далее
     */
    onChangeReadStatus?.(msg.id);
  }, [msg, onChangeReadStatus, senderType]);

  if (messageType === 'from_user') {
    return (
      <UserMessage
        msg={msg}
        senderType={senderType}
        messageStatus={messageStatus}
        onErrorClick={onErrorClick}
        isSupport={isSupport}
      />
    );
  }

  if (messageType === 'system') {
    return (
      <SystemMessage msg={msg} senderType={senderType} adData={adData} />
    );
  }

  return null;
};
