import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { EventsApi } from '../api';

export const useDeleteEventMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: EventsApi.delete,
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: ['events-admin-list'],
      });
      toast.success('Мероприятие не прошло модерацию!');
    },
    onError: (err) => toast.error(`Error deactivating event: ${err.message}`),
  });
};
