import React, {
  useCallback, useMemo, useState, useRef,
} from 'react';
import { Box } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import {
  Checkbox, FloatNumber, ItemLoader, Sorting, UserRating,
} from '@/modules/shared/components';
import { Button } from '@/modules/shared/components/button';
import { getDeclension } from '@/modules/shared/helpers';
import { useMatchMedia } from '@/modules/shared/hooks';
import { IReviews } from '@/modules/shared/types';
import { ReviewItem } from './review-item.component';
import { StarLine } from './star-line.component';
import { MAX_REVIEWS } from './const.helper';
import { useReviewsListQuery } from '../../queries';
import s from './review-block.module.scss';

interface StaticReviewData {
  averageRating: number;
  starQuantities: number[];
  initialReviewList: IReviews[];
}

type ReviewBlockProps = {
  userId: number;
};

export const ReviewBlock = ({ userId }: ReviewBlockProps) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const { isMobile } = useMatchMedia();
  const [selectedSort, setSelectedSort] = useState('default');
  const [withPhoto, setWithPhoto] = useState(false);

  const staticData = useRef<StaticReviewData>({
    averageRating: 0,
    initialReviewList: [],
    starQuantities: Array(5).fill(0),
  });

  const { data: reviewList = [], isLoading } = useReviewsListQuery({
    id: userId,
    filters: {
      ...(selectedSort !== 'default' ? { sort: selectedSort } : {}),
      ...(withPhoto ? { filter: 'image' } : {}),
    },
  });

  if (!staticData.current.initialReviewList.length && reviewList.length) {
    const totalRating = reviewList.reduce((sum, { rating }) => sum + rating, 0);

    staticData.current = {
      initialReviewList: reviewList,
      averageRating: Math.round((totalRating / reviewList.length) * 10) / 10 || 0,
      starQuantities: reviewList.reduce((acc, { rating }) => {
        acc[rating - 1] = (acc[rating - 1] || 0) + 1;
        return acc;
      }, [0, 0, 0, 0, 0]),
    };
  }

  const reviewsCount = useMemo(() => getDeclension({
    count: staticData.current.initialReviewList.length,
    t,
    singular: 'common:ratingCount.one',
    few: 'common:ratingCount.few',
    many: 'common:ratingCount.many',
  }), [staticData, t]);

  const additionalReviewsCount = useMemo(() => getDeclension({
    count: reviewList.length,
    t,
    singular: 'common:ratingCount.one',
    few: 'common:ratingCount.few',
    many: 'common:ratingCount.many',
  }), [reviewList, t]);

  const options = useMemo(() => ([
    { value: 'default', label: t('common:sorting.sortingModal.default') },
    { value: '-created_at', label: t('common:sorting.sortingModal.newest') },
    { value: 'created_at', label: t('common:sorting.sortingModal.oldest') },
    { value: '-rating', label: t('common:sorting.sortingModal.highly_rated') },
    { value: 'rating', label: t('common:sorting.sortingModal.low_rated') },
  ]), [t]);

  const toggleShow = useCallback(() => setIsShow((prev) => !prev), []);

  const itemsToShow = isShow || isMobile ? reviewList : reviewList.slice(0, MAX_REVIEWS) || [];

  return (
    <section className={s.review_block}>
      <section className={s.overview}>
        {!isMobile ? (
          <div className={s.rating}>
            <div className={s.rating_stars}>
              <span>
                <FloatNumber value={staticData.current.averageRating} precision={1} />
              </span>
              <UserRating rating={staticData.current.averageRating} sizeStar="32px" />
            </div>
            <p>{reviewsCount}</p>
          </div>
        ) : (
          <div className={s.rating}>
            <span>
              <FloatNumber value={staticData.current.averageRating} precision={1} />
            </span>
            <div className={s.rating_stars}>
              <UserRating rating={staticData.current.averageRating} sizeStar="32px" />
              <p>{reviewsCount}</p>
            </div>
          </div>
        )}
        <div className={s.scales}>
          {new Array(5).fill(5).map((el, i) => el - i).map((el) => (
            <StarLine
              key={el}
              rating={el}
              quantity={staticData.current.starQuantities[el - 1]}
              totalQuantity={staticData.current.initialReviewList.length}
            />
          ))}
        </div>
      </section>
      {staticData.current.initialReviewList.length > 0 && (
        <header className={s.header}>
          {!isMobile && <h3>{additionalReviewsCount}</h3>}
          <div className={s.block_filter_sorting}>
            <button className={s.filter} type="button" onClick={() => setWithPhoto(!withPhoto)}>
              <Checkbox
                checkboxSize="24"
                checked={withPhoto}
                onChange={(e) => {
                  e.stopPropagation();
                  setWithPhoto(!withPhoto);
                }}
              />
              {t('common:withPhoto')}
            </button>
            <Sorting value={selectedSort} setValue={setSelectedSort} options={options} />
          </div>
        </header>
      )}
      {(reviewList.length > 0 || withPhoto) && !isLoading && (
        <section className={s.review_list}>
          <main className={s.main}>
            {itemsToShow?.map((item) => (
              <ReviewItem key={item.id} item={item} />
            ))}
          </main>
          <footer className={s.footer}>
            {!isMobile && reviewList.length > MAX_REVIEWS && (
              <Button
                appearance="primary"
                buttonColor="blue"
                buttonSize="s"
                onClick={toggleShow}
              >
                {t(`common:${isShow ? 'hide' : 'showMore'}`)}
              </Button>
            )}
          </footer>
        </section>
      )}
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <ItemLoader isLoading size="50px" />
        </Box>
      )}
    </section>
  );
};
