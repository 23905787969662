import React, {
  KeyboardEvent,
  ClipboardEvent,
  FormEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { useMatchMedia } from '../../hooks';
import styles from './textarea.module.scss';

export interface TextareaProps {
  name?: string;
  value?: string;
  placeholder?: string;
  handleChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  onInput?: (e: FormEvent<HTMLTextAreaElement>) => void;
  classNameTextarea?: string;
  maxChars?: number;
  countChars?: boolean;
  hasErrors?: boolean;
  heightScroll?: number;
  size?: 'width_12';
  enableMaxHeight?: boolean;
}
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({
  name,
  value,
  placeholder,
  handleChange,
  onFocus,
  onBlur,
  onKeyDown,
  onPaste,
  onInput,
  classNameTextarea,
  maxChars = 10000,
  countChars = false,
  hasErrors = false,
  heightScroll = 220,
  size = 'width_12',
  enableMaxHeight = false,
  ...props
}: TextareaProps, ref) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation('');

  const osRef = useRef<OverlayScrollbarsComponentRef>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => ({
    ...textareaRef.current!,
    focus: () => textareaRef.current?.focus(),
  }));

  useEffect(() => {
    if (isDesktop && osRef.current) {
      const osInstance = osRef.current.osInstance();
      if (osInstance) {
        const { scrollOffsetElement } = osInstance.elements();

        const cursorPosition = textareaRef.current?.selectionStart ?? 0;

        // Прокрутка вниз только если курсор находится в нижней части текста
        if (cursorPosition === value?.length) {
          scrollOffsetElement.scrollTo({
            top: scrollOffsetElement.scrollHeight - scrollOffsetElement!.clientHeight,
            behavior: 'instant',
          });
        }
      }
    }
  }, [value, isDesktop]);

  const renderTextarea = () => (
    <BaseTextareaAutosize
      aria-label="custom textarea"
      ref={textareaRef}
      name={name}
      value={value}
      onFocus={() => onFocus?.()}
      onChange={(e) => handleChange?.(e.target.value)}
      onBlur={() => onBlur?.()}
      onKeyDown={(e) => onKeyDown?.(e)}
      onPaste={(e) => onPaste?.(e)}
      onInput={(e) => onInput?.(e)}
      placeholder={placeholder || t('common:enter')}
      maxLength={maxChars}
      spellCheck
      wrap="soft"
      className={clsx(styles.textarea, classNameTextarea, {
        [styles.textarea_padding]: countChars && isDesktop,
      })}
      {...props}
    />
  );

  return (
    <div
      onClick={() => textareaRef.current?.focus()}
      style={{
        ...(enableMaxHeight
          ? { maxHeight: `${heightScroll}px` }
          : { height: `${heightScroll}px` }
        ),
      }}
      className={clsx(styles.container, { [styles.error]: hasErrors })}
    >
      {isDesktop && (
        <OverlayScrollbarsComponent
          ref={osRef}
          className={clsx(styles.overlay_scrollbars, {
            [styles.overlay_scrollbars_width_12]: size === 'width_12',
          })}
        >
          {renderTextarea()}
        </OverlayScrollbarsComponent>
      )}

      {(isMobile || isTablet) && (
        <div className={styles.container_wrapper_mobile}>{renderTextarea()}</div>
      )}

      {countChars && (
        <div className={styles.charCounter}>
          {`${(value?.length ?? 0).toLocaleString('ru-RU')}/${maxChars.toLocaleString('ru-RU')}`}
        </div>
      )}
    </div>
  );
});
