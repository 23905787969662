import {
  memo, useCallback, useMemo, useState,
} from 'react';

import { FormState } from '@/modules/shared/hooks';

import { CheckboxList } from '../../components/checkbox-list';

import { FiltersMap } from '../../types';
import { withDynamicFieldFormGuard } from '../../hoc/withDynamicFieldFormGuard';
import { FilterRow } from '../../components/filter-row';
import { SearchText } from '../../components/search-text';
import { withErrorBoundary } from '../../hoc/withErrorBoundary';


type BrandTypeFieldProps = {
  form: FormState<'brand'>;
  filter: FiltersMap['checkbox_list'];
  enableSearch?: boolean;
};

export const BrandTypeField = withErrorBoundary(withDynamicFieldFormGuard<BrandTypeFieldProps>(memo(({
  t,
  form,
  filter: { choices },
  enableSearch = true,
}) => {
  const value = form.value.brand || [];
  const onChange = form.setter.brand!;
  const [minimized, setMinimized] = useState(true);
  const [foundValue, setFoundValue] = useState<boolean>(true);

  const items = useMemo(
    () => {
      const valueIndex = {};

      return choices.reduce((memoValue, code) => {
        if ((minimized && memoValue.length >= Math.max(5, value.length)) || valueIndex[code]) return memoValue;
        memoValue.push({
          label: t(`filters:checkbox-list.brand.options.${code}`, null, {
            fallback: code,
          }),
          value: code,
        });
        valueIndex[code] = true;

        return memoValue;
      }, value.map((code) => {
        valueIndex[code] = true;

        return ({
          label: t(`filters:checkbox-list.brand.options.${code}`, null, {
            fallback: code,
          }),
          value: code,
        });
      }) as { label: string, value: string }[]);
    },
    [choices, minimized, t, value],
  );

  const handleSearchChange = useCallback((newSearchValue: string[]) => {
    const uniqueSearchValues = Array.from(new Set(newSearchValue.map((v) => v.toLowerCase())));

    onChange(uniqueSearchValues.reduce((acc, newValue) => {
      setFoundValue(false);

      const foundItem = choices.find(
        (item) => item.toLowerCase().includes(newValue.toLowerCase()),
      );

      if (foundItem) {
        acc.push(foundItem);
        setFoundValue(true);
      }

      return acc;
    }, [] as string[]));
  }, [onChange, items, setFoundValue]);

  if (!t('filters:checkbox-list.brand.title', null, {
    default: false,
  }) || !items.length) {
    console.log('Unknown filters:checkbox-list.brand.title');
    return null;
  }

  return (
    <FilterRow title={t('filters:checkbox-list.brand.title')}>
      {enableSearch && (
      <SearchText
        onChange={handleSearchChange}
        value={value}
        placeholder={t('filters:checkbox-list.brand.placeholder')}
        foundValue={foundValue}
        showNotFoundText
        clearTextInput
      />
      )}

      <CheckboxList
        value={value}
        name="brand"
        minimized={minimized}
        showMoreOnClick={() => setMinimized((v) => !v)}
        items={items}
        choices={choices}
        onChange={onChange}
      />
    </FilterRow>
  );
}, (prev, next) => (
  prev.form.value.brand === next.form.value.brand
    && prev.filter === next.filter
    && prev.t === next.t
))));
