import useTranslation from 'next-translate/useTranslation';
import { formatNumber } from '@/modules/shared/helpers';

type ValueProps = {
  property: string;
  value: string | number | boolean | null;
  subcategory: string;
};

export const Value = ({ property, value, subcategory }: ValueProps) => {
  const { t, lang } = useTranslation('');

  const getTextValue = (key: string, val: string | number) => {
    switch (key) {
      case 'color':
        return t(`common:colors.${val}`);
      case 'brand':
        return val.toString().toLowerCase() === 'other' || val === 'Другой'
          ? t('specifications:other')
          : val;
      case 'type_electronic':
        return t(`specifications:conditions.${val}`);
      case 'engine_capacity':
        return `${val} ${t(`specifications:${subcategory === 'cars' ? 'l' : 'cm3'}`)}`;
      case 'mileage':
        return `${formatNumber(+val, lang)} ${t('specifications:km')}`;
      case 'year':
      case 'manufacturer':
        return val.toString();
      case 'size':
        return t(`ad-parameters:personal-items.${subcategory}.size.${val.toString().replaceAll('.', ',')}`);
      case 'gender':
        return t(`ad-parameters:personal-items.${subcategory}.gender.${val}`);
      case 'living_space':
      case 'commercial_area':
      case 'land_area':
        return `${val} ${t('specifications:m2')}`;
      case 'transfer':
        return t(`ad-parameters:excursions.transfer.options.${val}`);
      case 'guide':
        return t(`ad-parameters:excursions.guide.options.${val}`);
      case 'age_restriction':
        return val;
      default:
        return t(`filters:checkbox-list.${key}.options.${val}`);
    }
  };

  return (
    <p>
      <b>{`${t(`specifications:${property}`)}: `}</b>
      {getTextValue(property, value as string | number)}
    </p>
  );
};
