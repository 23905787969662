import React, { memo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { InfiniteListOfAdsLayout } from '@/modules/shared/components';
import { useInfinitySimilarAdsList } from '@/modules/ads/queries';
import { IAd } from '@/modules/ads/types';
import { AdCard } from '../index-page';

interface SimilarAdsProps {
  adId: number;
}

export const SimilarAds = memo(({ adId }: SimilarAdsProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();

  const {
    pages: similarPages,
    fetchMore,
    hasMore,
    isLoading: similarLoading,
  } = useInfinitySimilarAdsList(String(adId));

  const infiniteListRef = useInfiniteListRef(hasMore, fetchMore, similarLoading);

  return (
    <>
      {similarPages?.length && similarPages[0].data.length > 0 && (
      <InfiniteListOfAdsLayout
        title={t('adv:similarAds')}
        infiniteListRef={infiniteListRef}
        marginTop={!isDesktop}
      >
        {similarPages.map((page) => page?.data?.map((similarAd: IAd) => (
          <AdCard key={similarAd.id} ad={similarAd} />
        )))}
      </InfiniteListOfAdsLayout>
      )}
    </>
  );
});
