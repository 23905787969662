import { NextPage } from 'next';
import { isServerSide } from '@/modules/shared/helpers';

import { AppContext } from 'next/app';
import { CategoriesApi } from '../api';

export const withPreloadedCategories = (Component: NextPage) => {
  const Wrapper = (props) => <Component {...props} />;
  Wrapper.getInitialProps = (async () => {
    if (isServerSide()) {
      try {
        const preloadedCategories = (await CategoriesApi.list()).data;
        return { preloadedCategories };
      } catch {
        console.log('Не удалось подгрузить категории на стороне SSR');
      }
    }

    return {};
  }) as (app: AppContext) => Promise<object>;

  Wrapper.displayName = `withPreloadedCategories(${Component.displayName})`;

  return Wrapper;
};
