import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { getDeclension } from '@/modules/shared/helpers';
import { ItemLoader } from '@/modules/shared/components';
import styles from './ads-infinity-page.module.scss';

interface TitleProps {
  isLoading: boolean;
  search: string | string[] | undefined;
  totalCount: number | undefined;
  categoryItemTitle: string | undefined;
  subCategoryItemTitle: string | undefined;
  underSubCategoryItemTitle:string | undefined;
}

export const Title = memo((props: TitleProps) => {
  const {
    categoryItemTitle,
    subCategoryItemTitle,
    underSubCategoryItemTitle,
    totalCount,
    search,
    isLoading,
  } = props;
  const { t } = useTranslation('common');
  const { isMobile, isDesktop } = useMatchMedia();

  const quantity = useMemo(() => getDeclension({
    count: Number(totalCount) || 0,
    t,
    singular: 'countAds.singular',
    few: 'countAds.few',
    many: 'countAds.many',
  }), [t, totalCount]);

  const searchString = typeof search === 'string' ? search : (search && search.join(', '));

  return (
    <>
      {search || Number(totalCount) === 0 ? (
        <div className={clsx(styles.no_results, {
          [styles.title]: Number(totalCount) > 0,
        })}
        >
          {!isMobile && search && (
            <h1>
              {t('common:searchBlock.searchByRequest', { search: searchString })}
              {Number(totalCount) === 0 && t('common:searchBlock.searchNotFound')}
            </h1>
          )}
          {!isMobile && !search && (<h1>{t('notFound:noAdsData')}</h1>)}
          {!isLoading && Number(totalCount) === 0 && (<img src="/assets/mascots/rabbit-not-found.svg" alt="not-found" />)}
          {isMobile && search && (
            <p>
              {t('common:searchBlock.searchByRequest', { search: searchString })}
              {Number(totalCount) === 0 && t('common:searchBlock.searchNotFound')}
            </p>
          )}
          {isMobile && !search && (<h1>{t('notFound:noAdsData')}</h1>)}
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <ItemLoader isLoading size="50px" />
            </Box>
          )}
        </div>
      ) : (
        isDesktop && (
          <>
            <div className={styles.title}>
              <h1>
                {underSubCategoryItemTitle || subCategoryItemTitle || categoryItemTitle || t('searchBlock.allAds')}
              </h1>
              <span>{quantity}</span>
            </div>
            {isLoading && (totalCount === 0 || totalCount === undefined) && (
              <Box display="flex" justifyContent="center">
                <ItemLoader isLoading size="50px" />
              </Box>
            )}
          </>
        )
      )}
    </>
  );
});
