import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  chatList: (params = '') => `${baseEndpointV1}/messaging/chat/${params ? `?${params}` : ''}`,
  createChatImage: () => `${baseEndpointV1}/messaging/chat/message/images/`,
  chatMessages: ({
    recipient, announcement,
  }: {
    recipient: number,
    announcement?: number,
  }) => `${baseEndpointV1}/messaging/chat/message/?page_size=50&recipient=${recipient}${announcement ? `&announcement=${announcement}` : ''}`,
};
