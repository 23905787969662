import React from 'react';
import { components } from 'react-select';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { SvgIcon } from '../svg-icon';
import styles from './select.module.scss';

/**
 * Переопределяем базовые компоненты react-select
 */

export const customComponents = {
  Control: (props) => {
    const {
      children, innerRef, innerProps, selectProps,
    } = props;
    const { size } = selectProps;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={clsx(styles.control, styles[`size_${size}`])}
      >
        {children}
      </div>
    );
  },
  DropdownIndicator: (props) => (
    // применяем атрибутные классы, так как обычные классы не работают
    <components.DropdownIndicator {...props}>
      <SvgIcon
        name="arrow-bottom"
        className={styles.arrow}
        style={{
          fontSize: '24px',
          transition: 'transform 0.3s ease',
          transform: props.isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
    </components.DropdownIndicator>
  ),
  IndicatorSeparator: () => null,
  ClearIndicator: (props) => (
    <components.ClearIndicator {...props}>
      <SvgIcon name="close" className={styles.close} style={{ width: '24px', height: '24px' }} />
    </components.ClearIndicator>
  ),
  Menu: (props) => (
    <components.Menu {...props} className={styles.customMenu}>
      {props.children}
    </components.Menu>
  ),
  MenuList: (props) => {
    const { minShowItems, children } = props;
    const childrenCount = React.Children.count(props.children);

    return (
      <OverlayScrollbarsComponent
        className={clsx(styles.scrollbar, { [styles.scrollable]: childrenCount > minShowItems })}
      >
        {children}
      </OverlayScrollbarsComponent>
    );
  },
  Option: (props) => {
    // меняем дефолтные опции, чтобы прокинуть галочку на отмеченную опцию
    const {
      data, innerRef, innerProps, isSelected,
    } = props;

    return (
      <div ref={innerRef} {...innerProps} className={styles.option}>
        {isSelected && <SvgIcon name="check" className={styles.check} />}
        {data.label}
      </div>
    );
  },
};
