import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';
import { AdComplaint, EventComplaint } from '../types';

export const ComplaintApi = {
  list: async () => {
    const { data } = await api.get(endpoints.list());
    return data;
  },

  resolveAdComplaint: async (complaint: AdComplaint) => {
    const { data } = await api.patch(endpoints.resolveAdComplaint(complaint.id), complaint);
    return data;
  },

  resolveEventComplaint: async (complaint: EventComplaint) => {
    const { data } = await api.patch(endpoints.resolveEventComplaint(complaint.id), complaint);
    return data;
  },
};
