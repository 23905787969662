import { useContext, useMemo } from 'react';
import { featureContext } from '../context';

export const useFeatureFlag = (name: string) => {
  const featureMap = useContext(featureContext);

  const feature = useMemo(
    () => featureMap.get(name) || {
      enabled: false as const,
      params: undefined,
    },
    [featureMap, name],
  );

  return [feature.enabled, feature.params] as const;
};
