import React, {
  useRef, useState, useEffect, useCallback, ReactNode,
} from 'react';
import clsx from 'clsx';
import { ButtonIcon } from '../button-icon';
import styles from './horizontal-scroll-with-arrows.module.scss';

/**
 * Компонент `HorizontalScrollWithArrows` предоставляет горизонтальную прокрутку с кнопками стрелок для прокрутки
 * контента.
 * Стрелки показываются только при необходимости и выполняют плавную прокрутку контента влево или вправо.
 *
 * @param {ReactNode} children - Дочерние элементы для прокрутки.
 * @param {number} [buttonArrowWidth=32] - Ширина кнопок стрелок для прокрутки.
 * @param {number} [buttonArrowHeight=32] - Высота кнопок стрелок для прокрутки.
 * @param {boolean} [isShowButton=true] - Флаг, который определяет, показывать ли кнопки стрелок.
 * @param {number} [stepScroll=350] - Шаг прокрутки в пикселях.
 * @param {string} [classNameScrollArea] - Дополнительный класс для области прокрутки.
 */

export type HorizontalScrollWithArrowsProps = {
  children: ReactNode;
  buttonArrowWidth?: number;
  buttonArrowHeight?: number;
  isShowButton?: boolean;
  stepScroll?: number;
  classNameScrollArea?: string;
};

export const HorizontalScrollWithArrows = ({
  children,
  buttonArrowWidth = 32,
  buttonArrowHeight = 32,
  isShowButton = true,
  stepScroll = 350,
  classNameScrollArea,
}: HorizontalScrollWithArrowsProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const scrollLeft = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -stepScroll - buttonArrowWidth, behavior: 'smooth' });
    }
  }, [scrollContainerRef]);

  const scrollRight = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: stepScroll + buttonArrowWidth, behavior: 'smooth' });
    }
  }, [scrollContainerRef]);

  const updateArrowVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft: containerScrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

      setShowLeftArrow(containerScrollLeft > 0);
      setShowRightArrow(containerScrollLeft + clientWidth < scrollWidth - buttonArrowWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateArrowVisibility);
      updateArrowVisibility();

      return () => scrollContainer.removeEventListener('scroll', updateArrowVisibility);
    }
  }, []);

  return (
    <div className={styles.container}>
      {showLeftArrow && isShowButton && (
        <ButtonIcon
          nameIcon="banner-arrow-left"
          dataTestid="arrow_prev"
          type="rectangular"
          width={buttonArrowWidth}
          height={buttonArrowHeight}
          onClick={scrollLeft}
        />
      )}

      <div
        className={clsx(styles.content, {
          [styles.shadowLeft]: showLeftArrow && isShowButton,
          [styles.shadowRight]: showRightArrow && isShowButton,
        })}
      >
        <div className={clsx(styles.scrollArea, classNameScrollArea)} ref={scrollContainerRef}>
          {children}
        </div>
      </div>

      {showRightArrow && isShowButton && (
        <ButtonIcon
          nameIcon="banner-arrow-right"
          dataTestid="arrow_next"
          type="rectangular"
          width={buttonArrowWidth}
          height={buttonArrowHeight}
          onClick={scrollRight}
        />
      )}
    </div>
  );
};
