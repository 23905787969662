import React, {
  useState, useEffect, useCallback, useMemo, useRef,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useMatchMedia } from '@/modules/shared/hooks';
import { ButtonIcon } from '@/modules/shared/components';
import { calculateEndDate } from '../../helpers';
import { useCalendarNames } from '../../hooks';
import s from './calendar.module.scss';

const days = 31;

export const Calendar = (props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const { lang } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { monthNames } = useCalendarNames();

  const initialToday = new Date();
  const stepScroll = isMobile ? 200 : 500;

  const [periodState, setPeriodState] = useState({
    today: initialToday,
    currentDay: initialToday.getDate(),
    currentMonth: initialToday.getMonth(),
    currentYear: initialToday.getFullYear(),
    selectedDay: null as number | null,
    selectedMonth: null as number | null,
    selectedYear: null as number | null,
    startDate: initialToday,
    endDate: calculateEndDate(initialToday, days),
  });

  const prevPeriod = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -stepScroll, behavior: 'smooth' });
    }
  }, []);

  const nextPeriod = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: stepScroll, behavior: 'smooth' });
    }

    setPeriodState((prevState) => {
      const newStartDate = new Date(prevState.endDate);
      newStartDate.setDate(prevState.endDate.getDate() + 1);
      const newEndDate = calculateEndDate(newStartDate, days);

      return {
        ...prevState,
        endDate: newEndDate,
      };
    });
  }, []);

  const handleDayClick = (day: number, month: number, year: number) => {
    const isSelectedDate = periodState.selectedDay === day
      && periodState.selectedMonth === month
      && periodState.selectedYear === year;

    if (isSelectedDate) {
      setPeriodState({
        ...periodState,
        selectedDay: null,
        selectedMonth: null,
        selectedYear: null,
      });

      if (props.onDayClick) {
        props.onDayClick(null);
      }
    } else {
      setPeriodState({
        ...periodState,
        selectedDay: day,
        selectedMonth: month,
        selectedYear: year,
      });

      if (props.onDayClick) {
        const formattedMonth = (month + 1).toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
        props.onDayClick(formattedDate);
      }
    }
  };


  const getMonthsInRange = (start: Date, end: Date) => {
    const months: { year: number; month: number }[] = [];
    const current = new Date(start);

    while (current <= end) {
      months.push({
        year: current.getFullYear(),
        month: current.getMonth(),
      });
      current.setMonth(current.getMonth() + 1);
    }

    return months;
  };

  const monthsInRange = useMemo(
    () => getMonthsInRange(periodState.startDate, periodState.endDate),
    [periodState.startDate, periodState.endDate],
  );


  const renderMonth = (
    month: number,
    year: number,
    startDay: number,
    endDay: number,
    keyPrefix: string,
  ) => {
    const calendarDays: JSX.Element[] = [];
    for (let day = startDay; day <= endDay; day++) {
      const current = new Date(year, month, day);
      const isSelected = periodState.selectedDay === day
        && periodState.selectedMonth === month
        && periodState.selectedYear === year;

      let dayOfWeek = current.toLocaleDateString(lang, { weekday: 'short' });
      dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

      calendarDays.push(
        <div
          key={`${keyPrefix}-day-${day}-${isSelected ? 'selected' : 'unselected'}`}
          className={clsx(s.day, { [s.day_pressed]: isSelected })}
          onClick={() => handleDayClick(day, month, year)}
        >
          <div className={s.day_number}>{day}</div>
          <div className={s.day_week}>{dayOfWeek}</div>
        </div>,
      );
    }

    return (
      <div key={keyPrefix} className={s.month}>
        <p className={s.month_header}>
          {`${isMobile ? monthNames[month]?.toUpperCase() : monthNames[month]}`}
        </p>
        <div className={s.month_column}>{calendarDays}</div>
      </div>
    );
  };

  const { currentMonth, currentYear, today } = periodState;
  const isCurrentMonth = today.getMonth() === currentMonth && today.getFullYear() === currentYear;

  const updateArrowVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft: containerScrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(containerScrollLeft > 40);

      if (containerScrollLeft + clientWidth > scrollWidth - 70) {
        nextPeriod();
      }
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateArrowVisibility);
      updateArrowVisibility();

      return () => scrollContainer.removeEventListener('scroll', updateArrowVisibility);
    }
  }, []);


  return (
    <div className={s.container}>
      {(isTablet || isDesktop || (isMobile && showLeftArrow)) && (
        <ButtonIcon
          nameIcon="banner-arrow-left"
          dataTestid="arrow_prev"
          type="rectangular"
          notActive={!showLeftArrow && isCurrentMonth}
          onClick={prevPeriod}
          disabled={!showLeftArrow && isCurrentMonth}
          width={isMobile ? 26 : 32}
          height={isMobile ? 52 : 67}
        />
      )}

      <div className={s.calendar} ref={scrollContainerRef}>
        {monthsInRange.map(({ year, month }, index) => {
          const daysInMonth = new Date(year, month + 1, 0).getDate();
          const startDay = index === 0 ? periodState.startDate.getDate() : 1;
          const endDay = index === monthsInRange.length - 1
            ? periodState.endDate.getDate()
            : daysInMonth;

          return renderMonth(month, year, startDay, endDay, `month-${index}`);
        })}
      </div>

      <ButtonIcon
        nameIcon="banner-arrow-right"
        dataTestid="arrow_next"
        type="rectangular"
        onClick={nextPeriod}
        width={isMobile ? 26 : 32}
        height={isMobile ? 52 : 67}
      />
    </div>
  );
};
