import React, {
  useEffect, useMemo, useState,
} from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from '@/modules/shared/components/modal';
import { toast } from 'react-toastify';
import { useSearchParams } from 'next/navigation';
import { useTrackEvent } from '@/modules/events-tracking';
import { AxiosError } from 'axios';
import {
  MemoryRouter, NavigateFunction, Route, Routes, useLocation,
} from 'react-router-dom';
import { useCloseAuthModal, useOpenAuthModal } from '../../hooks';
import { AuthApi } from '../../api';
import { RegistrationType } from '../../types';


import { RouterListener } from './components/router-listener.component';
import {
  CompleteRegistrationDone,
} from './components/registration/complete-done.component';
import styles from './auth-modal.module.scss';
import { useAuthContext } from '../../contexts';
import {
  CompleteRegistrationScreen,
} from './components/registration/complete-registration.component';
import { getTokenExpiration } from '../../providers/auth.helpers';

const forbiddenNames = ['admin', 'administrator', 'root'];

export const CompleteRegistration = () => {
  const closeModal = useCloseAuthModal();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const [router, setCurrentRouter] = useState<{
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction
  }>();
  const setModalType = useOpenAuthModal();
  const { locale } = useRouter();

  const token = searchParams.get('token') || undefined;
  const phone = searchParams.get('phone') || undefined;
  const totp = searchParams.get('totp') || undefined;
  const registrationType = searchParams.get('registration-type') as RegistrationType;
  const trackEvent = useTrackEvent();

  const schema = useMemo(() => yup.object().shape({
    agreement: yup.boolean(),
    fullName: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.name') }))
      .min(2, t('forms:validationErrors.userNameTooShort'))
      .test(
        'is-not-url',
        t('forms:validationErrors.noUrlsUserName'),
        (value) => !/(https?:\/\/|www\.)/.test(value),
      )
      .trim()
      .matches(/^[\w'-\s.А-я]+$/, {
        message: t('forms:validationErrors.invalidCharsUserName'),
        excludeEmptyString: true,
      })
      .matches(/^[^._-].*[^._-]$/, {
        message: t('forms:validationErrors.invalidEdgeCharsUserName'),
        excludeEmptyString: true,
      })
      .notOneOf(forbiddenNames, t('forms:validationErrors.forbiddenName')),
    password: yup.string().required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') }))
      .min(8, t('forms:validationErrors.passwordTooShort', { length: 8 })),
  })
    .required(), [t]);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      agreement: false,
      fullName: '',
      password: '',
    },
  });
  const {
    handleSubmit,
  } = formMethods;
  const { isAuthenticated, setAccessToken } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && router?.location.pathname !== '/done') {
      closeModal();
    }
  }, [closeModal, isAuthenticated, router]);

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const { fullName, password } = data;
    const utm_term = localStorage.getItem('utm_term');
    const oid = localStorage.getItem('oid');
    const company = localStorage.getItem('company');

    const scaleo_meta_data = {
      ...(utm_term && { utm_term }),
      ...(oid && { oid }),
      ...(company && { company }),
    };

    try {
      const response = await AuthApi.completeRegistration({
        registrationType,
        phone_number: phone,
        totp,
        token,
        full_name: fullName,
        password,
        locale,
        ...(Object.keys(scaleo_meta_data).length && { scaleo_meta_data }),
      });

      if (response) {
        const { access, id } = response;
        const tokenExpiration = getTokenExpiration(access);

        Cookies.set('access_token', access, { expires: tokenExpiration });
        setAccessToken(access);
        Cookies.set('user_id', id, { expires: tokenExpiration });

        trackEvent('register');
        localStorage.removeItem('utm_term');
        localStorage.removeItem('oid');
        localStorage.removeItem('company');
        router?.nav('/done', { replace: true });
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.validation_errors?.error_code?.includes?.('TOTP_CODE_IS_EXPIRED')) {
          toast.error(t('auth:completeRegistration.errors.code.TOTP_CODE_IS_EXPIRED'));
          setModalType('register');
        } else if (e.response?.data?.validation_errors?.token?.includes?.('TOKEN_IS_EXPIRED')) {
          toast.error(t('auth:completeRegistration.errors.code.TOKEN_IS_EXPIRED'));
        } else {
          toast.error(t('auth:register.error'));
        }
      } else {
        toast.error(t('auth:register.error'));
      }
    }
  };

  if (isAuthenticated && router?.location.pathname !== '/done') {
    return null;
  }

  return (
    <Modal
      fullHeight
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
      open
      onClose={closeModal}
      showBackButton={false}
    >
      <FormProvider {...formMethods}>
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          <MemoryRouter initialEntries={['/']}>
            <RouterListener onRouteChange={setCurrentRouter} />
            <Routes>
              <Route path="/" element={<CompleteRegistrationScreen />} />
              <Route path="/done" element={<CompleteRegistrationDone />} />
            </Routes>
          </MemoryRouter>
        </form>
      </FormProvider>
    </Modal>
  );
};
