import { ComponentType, useMemo, ContextType } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Category, SubCategory, UnderSubCategory,
} from '@/modules/categories/types';
import { CategoriesApi } from '@/modules/categories/api';

import {
  FilterQuery, StateValues, useFilterFormState, filterFormContext,
} from '@/modules/shared/hooks';

import { Filters } from '../types';
import { sortFiltersByPriority } from '../sorting';
import { categoryCtx } from '../hooks/use-category-codenames';


const CONSTANT_FILTERS = ['search', 'city', 'district'];

export const withDynamicFilters = <T extends {
  category?: Category,
  subCategory?: SubCategory,
  localCityId?: number,
  underSubCategory?: UnderSubCategory
}>(Component: ComponentType<T & {
    formFields: string[]
    dynamicFilters: Filters
    onChange: (data: Record<string, unknown>) => void
  }>) => {
  const Wrapper = (props: T & {
    onChange: (data: Record<string, unknown>) => void
    defaultValue: Record<string, StateValues>
  }) => {
    const {
      category, subCategory, underSubCategory, defaultValue, onChange, localCityId,
    } = props;
    const { data } = useQuery<Filters | undefined>({
      initialData: undefined,
      placeholderData: (prev) => prev,
      queryKey: [category?.codename, subCategory?.codename, underSubCategory?.codename],
      select: sortFiltersByPriority,
      queryFn: () => CategoriesApi.loadFilters(category?.id, subCategory?.id, underSubCategory?.id),
      refetchOnWindowFocus: false,
    });

    const formFields = useMemo(() => (data || []).reduce((memo, item) => {
      if ((item.type === 'checkbox_list' || item.type === 'checkbox_list_depended_choices') && item.name) {
        memo.push(item.name);
      } else if (item && 'params' in item && item.params) {
        Object.values(item.params).forEach((v) => memo.push(v));
      }
      return memo;
    }, [...CONSTANT_FILTERS] as string[]), [data]);


    const [form, hasNotAppliedFilters, hasFilters, handleReset, handleApplyFilter] = useFilterFormState(
      formFields as FilterQuery[],
      defaultValue,
      onChange,
      { localCityId },
    );

    const categoryCtxValue = useMemo(() => {
      const codenames = [
        category?.codename,
        subCategory?.codename,
        underSubCategory?.codename,
      ].filter(Boolean);

      return { codenames, path: codenames.join('-') } as ContextType<typeof categoryCtx>;
    }, [category?.codename, subCategory?.codename, underSubCategory?.codename]);

    if (!data) return null;

    return (
      <categoryCtx.Provider value={categoryCtxValue}>
        <filterFormContext.Provider value={form}>
          <Component
            {...props}
            dynamicFilters={data}
            formFields={formFields}
            handleApplyFilter={handleApplyFilter}
            hasNotAppliedFilters={hasNotAppliedFilters}
            handleReset={handleReset}
            hasFilters={hasFilters}
          />
        </filterFormContext.Provider>
      </categoryCtx.Provider>
    );
  };
  Wrapper.displayName = `withDynamicFilters(${Component.displayName || Component.name})`;

  return Wrapper;
};
