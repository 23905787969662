import React, { useCallback, useEffect, useState } from 'react';
import { useLocationsListQuery, useMyLocationQuery } from '@/modules/locations';
import { CitySelection } from '@/modules/shared/components';
import { useGlobalData } from '@/context/GlobalContext';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import {
  setInitialCityInfo,
} from '@/modules/locations/helpers';
import { useMatchMedia } from '@/modules/shared/hooks';
import { profileBasePath } from '@/modules/shared/const';
import { useInitialCityInfoHook } from '@/modules/locations/hooks';
import { PrimaryHeader } from './primary-header.component';
import { SecondaryHeader } from './secondary-header.component';
import { PrimaryEventHeader } from './primary-event-header.component';
import { SecondaryEventHeader } from './secondary-event-header.component';
import {
  NOT_DISPLAY_PATHS,
  EVENT_DISPLAY_PATH,
  UNSHOW_DEFAULT_HEADER,
  SHOULD_FIX_HEADER,
  SHOULD_HIDE_SEARCHBAR,
} from './consts';
import s from './header.module.scss';

export const Header = () => {
  const [selectedCity, setSelectedCity] = useState('');
  const [showCityModal, setShowCityModal] = useState(false);
  const { data } = useLocationsListQuery();
  const { setHomeCity, setInitialShow } = useGlobalData();
  const { setCityPrefix } = useCityPrefix();
  const [location, setLocation] = useState<{ latitude: number, longitude: number }>();
  const [myLocationCity, setMyLocationCity] = useState<string>();
  const { data: myLocationData } = useMyLocationQuery(location);
  const router = useRouter();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { city: savedCity, userSet } = useInitialCityInfoHook();

  useEffect(() => {
    if (!userSet) {
      setInitialShow(true);
    }
  }, [setInitialShow, userSet]);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          () => { setLocation(undefined); },
        );
      } else {
        setLocation(undefined);
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    const fetchMyLocation = () => {
      const { city } = (myLocationData || {});

      // If the city was not selected by the user, update the city value in localStorage and the userSet state
      if (!userSet) {
        setInitialCityInfo({ city, userSet: false });
        setMyLocationCity(city);
      }
    };

    if (location && myLocationData) {
      fetchMyLocation();
    }
  }, [location, myLocationData]);

  useEffect(() => {
    setSelectedCity(savedCity || '');
    setHomeCity(savedCity);
    setCityPrefix(savedCity);
  }, [router.asPath, myLocationCity, savedCity, setSelectedCity, setHomeCity, setCityPrefix]);

  const onSelectCity = useCallback(async (newCity) => {
    setInitialCityInfo({ city: newCity, userSet: true });
    setSelectedCity(newCity);
    setHomeCity(newCity);
    setCityPrefix(newCity);
    setShowCityModal(false);

    if (router.pathname.startsWith('/[city]')) {
      const parts = router.asPath.split('/');
      parts[1] = newCity;
      const [path, queryString] = parts.join('/').split('?');
      const params = new URLSearchParams(queryString || '');
      /**
       * В фильтрах используются city и district для переопределения района и города для конкретного фильтра.
       * По этому при глобальном изменении города нужно очищать эти query, чтобы фильтры по локации тоже сбросились
       */
      params.delete('city');
      params.delete('district');

      await router.push(params.toString() ? `${path}?${params.toString()}` : path);
    }
  }, []);

  const hideOnMobile = NOT_DISPLAY_PATHS.some((path) => router.pathname.includes(path));
  const eventPath = EVENT_DISPLAY_PATH.some((path) => router.pathname.includes(path)) && router.pathname !== `/${profileBasePath}/posters`;
  const unshowDefaultHeader = UNSHOW_DEFAULT_HEADER.some((path) => router.pathname.includes(path));
  const shouldFixHeader = SHOULD_FIX_HEADER.some((path) => router.route === path);
  const shouldHideSeerchBar = SHOULD_HIDE_SEARCHBAR.some((path) => router.route === path);

  const primaryRef = React.useRef<HTMLDivElement>(null);
  const linkRef = React.useRef<HTMLUListElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [topOffset, setTopOffset] = useState(0);

  useEffect(() => {
    if (shouldFixHeader) {
      const ref = isDesktop ? primaryRef : linkRef;
      const handleScroll = () => {
        if (!ref.current) return;

        const headerHeight = ref.current.offsetHeight;
        const scrollPosition = window.scrollY;

        // фиксируем хедер, если прокрутили ниже середины хедера (ниже ref)
        if (scrollPosition > ref.current.offsetTop) {
          if (!isSticky) {
            setIsSticky(true);
            setTopOffset(headerHeight);
          }
        } else if (isSticky) {
          setIsSticky(false);
          setTopOffset(0);
        }
      };

      document.addEventListener('scroll', handleScroll, { passive: true });

      return () => document.removeEventListener('scroll', handleScroll);
    }
  }, [primaryRef, linkRef, shouldFixHeader, isSticky, isDesktop]);

  if (unshowDefaultHeader && !isDesktop) { // скрываем дефолтный хедер в мобильной (планшетной) версии
    return null;
  }

  return (
    <header
      className={clsx(s.header, { [s.sticky]: isSticky })}
      style={{ top: isSticky ? `-${topOffset}px` : 'auto' }}
    >
      {(!isMobile || (isMobile && !hideOnMobile)) && (
        <>
          {(isDesktop || (!eventPath && (isTablet || isMobile))) && (
            <div ref={primaryRef}>
              <PrimaryHeader
                showCityModal={showCityModal}
                setShowCityModal={setShowCityModal}
                linkRef={linkRef}
              />
            </div>
          )}
          {!eventPath && (
            <SecondaryHeader
              showCityModal={showCityModal}
              setShowCityModal={setShowCityModal}
            />
          )}
        </>
      )}
      {/* Header для страниц poster */}
      {eventPath && (isTablet || isMobile) && (
        <PrimaryEventHeader
          showCityModal={showCityModal}
          setShowCityModal={setShowCityModal}
          linkRef={linkRef}
        />
      )}
      {eventPath && !(shouldHideSeerchBar && !isDesktop) && (
        <SecondaryEventHeader
          showCityModal={showCityModal}
          setShowCityModal={setShowCityModal}
        />
      )}
      {showCityModal && (
        <CitySelection
          city={selectedCity}
          data={data.data}
          isOpen={showCityModal}
          onSelect={onSelectCity}
          onClose={() => {
            setShowCityModal(!showCityModal);
            setInitialShow(false);
          }}
        />
      )}
    </header>
  );
};
