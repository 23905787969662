import { infiniteListQueryFactory, buildListQueryKeyFactory } from '@/modules/shared/queries';
import { AdsApi } from '../api';
import { MY_ADS_INFINITY_LIST_QUERY_KEY } from '../consts';

const queryMainKeys = [MY_ADS_INFINITY_LIST_QUERY_KEY];
export const buildMyAdsListQueryKey = buildListQueryKeyFactory(queryMainKeys);
export const useMyAdsInfiniteListQuery = infiniteListQueryFactory(
  AdsApi.myAdsList,
  queryMainKeys,
);
