import { useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMyCompany } from '@/modules/company/queries';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SvgIcon } from '@/modules/shared/components';
import useUserAccount from '@/hooks/users/useUserAccount';
import Loadingmain from '@/components/shared/skeleton/loadingmain';
import { CompanyStatusEnum } from '@/modules/company/types';
import { UserTypeEnum } from '@/modules/users';
import { Verification } from './verification.component';
import { BecomeCompanyForm } from './become-company-form.component';
import s from './become-company.module.scss';

export const BecomeCompanyPage = () => {
  const { t } = useTranslation('userAccount');
  const { isMobile } = useMatchMedia();
  const [isSuccess, setIsSuccess] = useState(false);
  const { userData, loading } = useUserAccount();
  const isPerson = useMemo(() => userData?.user_type === UserTypeEnum.Individual, [userData]);
  const { data, isLoading } = useMyCompany(!isPerson);
  const [isResubmit, setIsResubmit] = useState(false);

  useEffect(() => {
    if (data?.status === CompanyStatusEnum.Pending) {
      setIsSuccess(true);
      return;
    }
    if (data?.status === CompanyStatusEnum.Proceeding) {
      setIsResubmit(true);
    }
  }, [data]);

  if (loading || isLoading) return <Loadingmain />;
  if (!userData?.can_become_company
    || (data?.status === CompanyStatusEnum.Approved
    || data?.status === CompanyStatusEnum.Rejected)) return null;

  return (
    <div className={s.personal_account_content}>
      {isSuccess ? (
        <div className={s.sent}>
          <h2 className={s.sent_header}>{t('become_company.sent._')}</h2>
          <p className={s.sent_text}>{t('become_company.sent.text1')}</p>
          <p dangerouslySetInnerHTML={{ __html: t('become_company.sent.text2') }} className={s.sent_text} />
          {isMobile && <SvgIcon name="assessment" className={s.assessment_icon} />}
        </div>
      ) : (
        <>
          <Verification />
          <BecomeCompanyForm
            setIsSuccess={setIsSuccess}
            isResubmit={isResubmit}
            companyData={data}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};
