import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useCreateAdContext } from '../../context';
import { useExcursions } from './hooks';
import {
  FieldsLayout,
  FieldLayout,
  HiddenRadiobutton,
} from './shared';

const ExcursionsToIslandsAndWalk = () => {
  const { t } = useTranslation('ad-parameters');
  const excursions = useExcursions();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <FieldLayout name="transfer" title={t('excursions.transfer.label')} required={false}>
        {excursions.transfer.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="transfer"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="age_restriction" title={t('excursions.age_restriction.label')} required={false}>
        {excursions.age_restriction.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="age_restriction"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="duration_of_excursion" title={t('excursions.duration.label')} required={false}>
        {excursions.duration_of_excursion.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="duration_of_excursion"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="guide" title={t('excursions.guide.label')} required={false}>
        {excursions.guide.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="guide"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="language" title={t('excursions.language.label')} required={false}>
        {excursions.language.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="language"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
    </FieldsLayout>
  );
};

const Shows = () => {
  const { t } = useTranslation('ad-parameters');
  const excursions = useExcursions();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <FieldLayout name="transfer" title={t('excursions.transfer.label')} required={false}>
        {excursions.transfer.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="transfer"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="age_restriction" title={t('excursions.age_restriction.label')} required={false}>
        {excursions.age_restriction.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="age_restriction"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
    </FieldsLayout>
  );
};

const Waterpark = () => {
  const { t } = useTranslation('ad-parameters');
  const excursions = useExcursions();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <FieldLayout name="transfer" title={t('excursions.transfer.label')} required={false}>
        {excursions.transfer.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="transfer"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="age_restriction" title={t('excursions.age_restriction.label')} required={false}>
        {excursions.age_restriction.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="age_restriction"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
    </FieldsLayout>
  );
};

const Zoo = () => {
  const { t } = useTranslation('ad-parameters');
  const excursions = useExcursions();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <FieldLayout name="transfer" title={t('excursions.transfer.label')} required={false}>
        {excursions.transfer.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="transfer"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="age_restriction" title={t('excursions.age_restriction.label')} required={false}>
        {excursions.age_restriction.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="age_restriction"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="guide" title={t('excursions.guide.label')} required={false}>
        {excursions.guide.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="guide"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="language" title={t('excursions.language.label')} required={false}>
        {excursions.language.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="language"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
    </FieldsLayout>
  );
};

const ExtremeActivities = () => {
  const { t } = useTranslation('ad-parameters');
  const excursions = useExcursions();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <FieldLayout name="transfer" title={t('excursions.transfer.label')} required={false}>
        {excursions.transfer.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="transfer"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout name="age_restriction" title={t('excursions.age_restriction.label')} required={false}>
        {excursions.age_restriction.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="age_restriction"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
    </FieldsLayout>
  );
};

const additionalFields = {
  'excursions-to-islands': <ExcursionsToIslandsAndWalk />,
  'walking-tours': <ExcursionsToIslandsAndWalk />,
  waterpark: <Waterpark />,
  zoo: <Zoo />,
  shows: <Shows />,
  'extreme-activities': <ExtremeActivities />,
};

// компонент с дополнительными полями для всей категории "Экскурсии"
export const Excursions = () => {
  const { subCategory } = useCreateAdContext();

  return additionalFields[subCategory!.codename];
};
