import { ChangeEvent, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import { useMatchMedia, useImageUploader } from '@/modules/shared/hooks';
import {
  FORMATS,
  MAX_SIZE_BYTES,
  MAX_SIZE_PARAM,
  MIN_SIZE_BYTES,
  MIN_SIZE_PARAM,
} from '@/modules/shared/const';
import { ItemLoader } from '@/modules/shared/components';
import type { CompanyLogo } from '@/modules/company/types';
import s from './become-company.module.scss';
import type { BecomeCompanyFormType } from './types';
import { LogoFileInput } from './logo-file-input.component';

type ChosenLogo = {
  file: File;
  url: string;
};

type CompanyLogoProps = {
  defaultLogo: CompanyLogo | undefined;
  isLoading: boolean;
};

export const CompanyLogoField = ({ defaultLogo, isLoading }: CompanyLogoProps) => {
  const { t } = useTranslation('userAccount');
  const {
    formState: { errors }, setError, clearErrors, setValue,
  } = useFormContext<BecomeCompanyFormType>();
  const { isMobile } = useMatchMedia();
  const { handleImageUpload, loading } = useImageUploader();
  const [chosenLogo, setChosenLogo] = useState<ChosenLogo>();

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.length) return;

    const value = event.target.files[0];

    const splitted = value.name.split('.');
    if (!FORMATS.includes(splitted[splitted.length - 1].toLowerCase())) {
      setError('logo', {
        type: 'format',
        message: t('forms:validationErrors.file_format'),
      });
      return;
    }
    if (value.size > MAX_SIZE_BYTES) {
      setError('logo', {
        type: 'size',
        message: t('forms:validationErrors.photoExceedsMaxSize', MAX_SIZE_PARAM),
      });
      return;
    }
    if (value.size < MIN_SIZE_BYTES) {
      setError('logo', {
        type: 'size',
        message: t('forms:validationErrors.photoBelowMinSize', MIN_SIZE_PARAM),
      });
      return;
    }
    clearErrors('logo');

    let newLogo: ChosenLogo | null = null;
    const uploadedImages = await handleImageUpload(event);
    newLogo = {
      file: uploadedImages[0] as File,
      url: URL.createObjectURL(uploadedImages[0] as File),
    };

    setChosenLogo(newLogo);
    setValue('logo', newLogo.file);

    const input = event.target as HTMLInputElement;
    input.value = '';
  };

  return (
    <div className={s.field_inner}>
      <p>{t('become_company.company_info.logo')}</p>
      <div className={s.values}>
        {isMobile && <p className={s.tip}>{t('become_company.company_info.logo_change')}</p>}
        <div className={s.input_wrapper}>
          {(!chosenLogo && !defaultLogo && !loading && !isLoading) ? (
            <LogoFileInput
              isSmall={false}
              handleChange={handleChange}
            />
          ) : (
            <div className={s.logo_preview}>
              {loading || isLoading ? (
                <ItemLoader isLoading={loading} size={65} />
              ) : (
                <>
                  <img
                    src={chosenLogo ? chosenLogo?.url : defaultLogo?.thumbnail}
                    alt="Logo Preview"
                  />
                  <LogoFileInput
                    isSmall
                    handleChange={handleChange}
                  />
                </>
              )}
            </div>
          )}
          {errors.logo && (
            <p className={s.error}>{errors.logo.message as string}</p>
          )}
        </div>
        {!isMobile && <p className={s.tip}>{t('become_company.company_info.logo_change')}</p>}
      </div>
    </div>
  );
};
