import React, { useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { AdLink } from '@/modules/ads/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getDateOfCreation, getUserCompanyData } from '@/modules/shared/helpers';
import {
  ExpandableText, GalleryModal, UserAvatar, UserCompanyRedirect, UserRating,
} from '@/modules/shared/components';
import { MAX_LETTERS_DESKTOP, MAX_LETTERS_MOBILE } from '@/modules/shared/const';
import { IReviews } from '@/modules/shared/types';
import { AnswerToReview } from './answer-to-review.component';
import { AnswerItem } from './answer-item.component';
import s from './review-item.module.scss';


type ReviewItemProps = {
  item: IReviews;
  isAnswered?: boolean;
};

export const ReviewItem = ({ item, isAnswered = false }: ReviewItemProps) => {
  const { t, lang } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index: number) => {
    setCurrentIndex(index);
    setOpenModal(true);
  };

  const adProps = useMemo(() => ({
    id: item.announcement?.id ?? 0,
    title: item.announcement?.title ?? '',
    category_codename: item.announcement?.category?.codename ?? '',
    sub_category_codename: item.announcement?.sub_category?.codename ?? '',
  }), [item.announcement]);

  const { name, avatar } = getUserCompanyData(item.reviewer?.company, item.reviewer);

  return (
    <div key={item.id} className={s.review_item}>
      <UserCompanyRedirect
        data={{ user: item.reviewer.id, company: item.reviewer.company_id }}
        className={s.reviewer}
      >
        <UserAvatar
          srcAvatar={avatar}
          userName={name}
          diameter="32px"
        />
        <p>
          {item.reviewer?.company?.name ?? item.reviewer.full_name}
          {isMobile && (
            <span className={s.date}>
              {getDateOfCreation({ date: item.created_at, t, lang })}
            </span>
          )}
        </p>
      </UserCompanyRedirect>
      <div className={s.deal}>
        <UserRating rating={item.rating} sizeStar={!isMobile ? '24px' : '16px'} />
        {item.deal_state && item.announcement?.title && (
          <>
            {!isMobile ? (
              <p className={s.deal_status}>
                {`${t(`common:dealStatus.${item.deal_state}`)}`}
                <AdLink ad={adProps}>
                  <span>{`«${item.announcement?.title}»`}</span>
                </AdLink>
              </p>
            ) : (
              <p>{t(`common:dealStatusMobile.${item.deal_state}`)}</p>
            )}
          </>
        )}
      </div>
      {isMobile && item.announcement && (
        <AdLink ad={adProps}>
          <p className={s.ad_title}>{`«${item.announcement?.title}»`}</p>
        </AdLink>
      )}
      {item.comment && (
        <ExpandableText
          text={item.comment}
          maxLength={isMobile ? MAX_LETTERS_MOBILE : MAX_LETTERS_DESKTOP}
          className={s.comment}
        />
      )}
      {!!item?.images?.length && (
        <div className={s.images}>
          {item.images.map((el, i) => (
            <img
              key={el.id}
              src={el.image}
              alt={`img-${i}`}
              onClick={() => handleClick(i)}
            />
          ))}
        </div>
      )}
      {/* дата отзыва должна быть и на странице users/id, и в карточке отзыва на странице users/rating */}
      {!isMobile && !isAnswered && (
      <span className={s.date}>
        {getDateOfCreation({ date: item.created_at, t, lang })}
      </span>
      )}
      {item.recipient_answer && <AnswerItem item={item} />}
      {/* можно отвечать на отзыв только если уже не было ответа */}
      {isAnswered && <AnswerToReview item={item} />}
      {!!item?.images?.length && (
        <GalleryModal
          currentImageIndex={currentIndex}
          setCurrentImageIndex={setCurrentIndex}
          open={openModal}
          onClose={() => setOpenModal(false)}
          images={item.images.map((el) => ({
            image: el.image,
            thumbnail: el.thumbnail,
          }))}
        />
      )}
    </div>
  );
};
