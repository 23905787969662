import React, {
  MouseEventHandler, useCallback, useEffect, useMemo, useState,
} from 'react';
import clsx from 'clsx';
import { stopEvent } from '@/modules/shared/helpers';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { IEvent } from '@/modules/events/types';
import { IAd } from '@/modules/ads/types';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { useFavoritesAdMutation, useFavoritesEventMutation } from '../mutations';
import { useFavoriteClickState } from '../hooks';
import { useFavoritesContext } from '../providers';
import styles from './favourite-button.module.scss';

interface FavoriteButtonProps {
  id: number;
  type: 'ads' | 'events';
  className?: string;
  variant?: 'classic' | 'modern';
}

export const FavoriteButton = ({
  id,
  type,
  className,
  variant = 'classic',
}: FavoriteButtonProps) => {
  const openAuthModal = useOpenAuthModal();
  const { isAuthenticated } = useAuthContext();
  const { favoritesAds, favoritesEvents } = useFavoritesContext();
  const { addFavoriteAd, deleteFavoriteAd } = useFavoritesAdMutation();
  const { addFavoriteEvent, deleteFavoriteEvent } = useFavoritesEventMutation();
  const { isMobile, isDesktop } = useMatchMedia();

  const setNotAuthenticatedClicked = useFavoriteClickState(
    id,
    type === 'ads' ? addFavoriteAd : addFavoriteEvent,
  );

  const isFavorite = useMemo(() => {
    const getFavoriteIds = (items: IAd[] | IEvent[]) => items.map((item: IAd | IEvent) => item.id);

    const favoriteIds = type === 'ads'
      ? favoritesAds.flatMap((ad) => getFavoriteIds(ad?.announcements || []))
      : getFavoriteIds(favoritesEvents);

    return favoriteIds.includes(id);
  }, [favoritesAds, favoritesEvents, id, type]);
  const [isPendingFavorite, setIsPendingFavorite] = useState<boolean>();

  useEffect(() => {
    setIsPendingFavorite(isFavorite);
  }, [isFavorite]);

  const actionMap: Record<string, (id: number) => void> = {
    ads: isFavorite ? deleteFavoriteAd : addFavoriteAd,
    events: isFavorite ? deleteFavoriteEvent : addFavoriteEvent,
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      stopEvent(event);

      if (!isAuthenticated) {
        setNotAuthenticatedClicked();
        openAuthModal('login', {
          customHeader: 'addFavorites',
        });
        return;
      }

      const action = actionMap[type];
      if (action) {
        setIsPendingFavorite((prev) => !prev);
        action(id);
      }
    },
    [
      id,
      isAuthenticated,
      isFavorite,
      openAuthModal,
      setNotAuthenticatedClicked,
      addFavoriteAd,
      deleteFavoriteAd,
      addFavoriteEvent,
      deleteFavoriteEvent,
      type,
    ],
  );

  const icon = useMemo(() => {
    if (isMobile) return 'favourite-icon-16';
    return 'favourite-icon-32';
  }, [isMobile, isDesktop]);

  return (
    <button
      onClick={handleClick}
      className={clsx(
        {
          [styles.classic]: variant === 'classic',
          [styles.modern]: variant === 'modern',
          [styles.modern_active]: variant === 'modern' && isPendingFavorite,
          [styles.modern_mobile__not_active]: variant === 'modern' && isMobile && !isPendingFavorite,
        },
        className,
      )}
      type="button"
    >
      <SvgIcon
        name={icon}
        className={clsx(styles.svg_icon, {
          [styles.svg_icon_active]: isPendingFavorite,
        })}
      />
    </button>
  );
};
