import axios, {
  AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import qs from 'qs';
import { getTokenExpiration } from '@/modules/auth/providers/auth.helpers';
import Cookie from 'js-cookie';
import { isServerSide } from '../helpers';

const baseApiParams = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
};
const clientApi = axios.create(baseApiParams);

const authApi = axios.create(baseApiParams);
const onError = async (error: Error | AxiosError, originalRequest: AxiosRequestConfig) => {
  console.error(error);
  if (error instanceof AxiosError && error.response?.status === 401) {
    const refreshToken = Cookie.get('refresh_token');
    if (refreshToken) {
      try {
        // we use authApi, because using clientApi will cause a cycle, ie it will call the same interceptor
        const { data } = await authApi.post<{ access: string }>(
          'api/users/refresh_token/',
          { refresh: refreshToken },
        );
        if (data.access) {
          const exp = getTokenExpiration(data.access);
          Cookie.set('access_token', data.access, { expires: exp });
        }
        if (originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          return await clientApi(originalRequest);
        }
      } catch (err) {
        if (err.response?.status === 401) {
          Cookie.remove('access_token');
          Cookie.remove('refresh_token');
          Cookie.remove('user_id');
          window.location.reload();
        }
        throw err;
      }
    }
  }

  throw error;
};

// Request interceptor for API calls
clientApi.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (!newConfig.headers) {
      newConfig.headers = new AxiosHeaders({
        'Content-Type': 'application/json',
      });
    }
    const accessToken = Cookie.get('access_token');
    if (accessToken && typeof newConfig.headers.Authorization !== 'string') {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  },
  async (error) => {
    const originalRequest = error.config;
    return onError(error, originalRequest);
  },
);

// Response interceptor for API calls
clientApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => onError(error, error.config),
);


const serverApi = axios.create({
  baseURL: process.env.API_URL || process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

serverApi.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (!newConfig.headers) {
      newConfig.headers = new AxiosHeaders({
        'Content-Type': 'application/json',
      });
    }
    return newConfig;
  },
);

export const apiWithoutToast = isServerSide() ? serverApi : clientApi;
