import React from 'react';
import { LangProps, languages } from '@/modules/app/components/header/types';
import clsx from 'clsx';
import s from './lang-switcher.module.scss';

export const LangSwitcher = ({ currentLang, toggleLang }: LangProps) => {
  const onChangeLang = () => {
    const newLang = languages.find((lang) => (lang.locale !== currentLang.locale));
    if (newLang) toggleLang(newLang);
  };

  return (
    <button className={s.switch} onClick={onChangeLang}>
      <span
        className={clsx(s.thumb, {
          [s.thumbMoving]: currentLang.locale === 'en',
        })}
      />
      {languages.map((item) => (
        <p
          key={item.locale}
          className={clsx(s.lang, {
            [s.active]: currentLang.locale === item.locale,
          })}
        >
          {item.label}
        </p>
      ))}
    </button>
  );
};
