import React, {
  useCallback, useEffect, useRef, CSSProperties,
} from 'react';
import { useHomeCity, useInitialCityInfoHook } from '@/modules/locations/hooks';
import { useGlobalData } from '@/context/GlobalContext';
import {
  autoUpdate, FloatingPortal, useFloating, shift, flip, FloatingArrow, arrow, offset,
} from '@floating-ui/react';
import { GeolocationPointer } from '../../geolocation-pointer';
import { ConfirmCityModal } from './confirm-city-modal.component';

interface LocClarification {
  isShowModal: boolean;
  onChange: () => void;
  iconOnly?: boolean;
  classNameIcon?: string | CSSProperties;
}

export const LocationClarification = ({
  isShowModal, onChange, iconOnly = false, classNameIcon,
}: LocClarification) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { city } = useHomeCity();
  const { userSet } = useInitialCityInfoHook();
  const { setInitialShow, initialShow } = useGlobalData();

  const handleOutsideClick = useCallback((event: Event) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setInitialShow(false);
    }
  }, [setInitialShow]);

  const handleEscPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setInitialShow(false);
    }
  }, [setInitialShow]);

  useEffect(() => {
    if (isShowModal) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscPress);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscPress);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [isShowModal, handleOutsideClick, handleEscPress]);

  const onChangeHandler = useCallback(() => {
    onChange();
    setInitialShow(false);
  }, [onChange, setInitialShow]);


  const isOpen = Boolean(!userSet && initialShow && !isShowModal);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      shift({
        padding: 10,
      }),
      offset(6),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  return (
    <>
      <GeolocationPointer
        city={city}
        onClick={onChangeHandler}
        ref={refs.setReference}
        iconOnly={iconOnly}
        classNameIcon={classNameIcon}
      />
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 1000,
            }}
          >
            <FloatingArrow strokeWidth={1} stroke="#f3f3f3" fill="white" ref={arrowRef} context={context} />
            <ConfirmCityModal
              showTranslatedCity={iconOnly}
              city={city}
              modalRef={modalRef}
              setShowModal={onChange}
              onClose={() => setInitialShow(false)}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
