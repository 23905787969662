import React, {
  FC, RefObject, useCallback, useMemo,
} from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { stopEvent } from '@/modules/shared/helpers';
import useTranslation from 'next-translate/useTranslation';
import styles from './search-suggestion.module.scss';

interface SearchProps {
  history: string[];
  onSearch: (value: string) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  inputRef: RefObject<HTMLInputElement>;
  isInputFocused: boolean;
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  clearHistory: () => void;
}

export const SearchSuggestion: FC<SearchProps> = ({
  history, onSearch, setSearch, inputRef, isInputFocused, setIsInputFocused, clearHistory,
}) => {
  const { t } = useTranslation('common');

  const handleSuggestionClick = useCallback((item: string) => {
    setSearch(item);
    setIsInputFocused(false);
    inputRef.current?.blur();
    onSearch(item);
  }, [inputRef, onSearch, setIsInputFocused, setSearch]);

  const recentHistory = useMemo(() => [...history].slice(0, 5), [history]);

  return (
    <>
      {isInputFocused && recentHistory.length > 0 && (
        <div className={styles.history} onMouseDown={stopEvent}>
          <div className={styles.history_headline}>
            <p className={styles.title}>{t('searchBlock.searched')}</p>
            <span
              className={styles.clear_history}
              onClick={clearHistory}
            >
              {t('clear')}
            </span>
          </div>
          <div className={styles.history_list}>
            <ul>
              {recentHistory.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(item)}
                >
                  <SvgIcon name="clock" style={{ fontSize: '24px' }} />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
