import React from 'react';
import clsx from 'clsx';
import styles from './button-icon.module.scss';
import { SvgIcon } from '../svg-icon';

type ButtonIconProps = {
  onClick?: () => void;
  nameIcon: string;
  dataTestid?: string;
  type?: 'circle' | 'rectangular';
  notActive?: boolean;
  disabled?: boolean;
  width?: number;
  height?: number;
};

export const ButtonIcon = ({
  onClick, dataTestid, nameIcon, type = 'circle', notActive = false, disabled = false, width, height,
} : ButtonIconProps) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <button
      data-testid={dataTestid}
      type="button"
      onClick={handleClick}
      className={clsx({
        [styles.circle]: type === 'circle',
        [styles.rectangular]: type === 'rectangular',
        [styles.rectangular_notActive]: type === 'rectangular' && notActive,
      })}
      style={{ width, height }}
      disabled={disabled}
    >
      <SvgIcon name={nameIcon} />
    </button>
  );
};
