import type { IAd } from '@/modules/ads/types';
import type { IEvent } from '@/modules/events';
import { IReviews } from '@/modules/shared/types';


// basically, it's an abstract type that should later be extended by User/Me and can be used for
// components that render this shared by these two types logic
export type BaseUser = {
  id: number;
  announcements: IAd[];
  avatar: string | null;
  full_name: string;
  average_rating: number;
  created_at: string;
  reviews: IReviews[] | [];
  events: IEvent[];
  given_reviews: IReviews[] | [];
  phone_number: string;
  email: string;
  thumbnail: string | null;
  user_type: UserType;
  company_id: number | null;
  instagram?: string | null;
  my_events_count: number;
  reviews_given_count: number;
  reviews_received_count: number;
};

export interface User extends BaseUser {
  last_online: string;
  is_online: boolean;
  phone_number: string;
  phone_number_is_hidden: boolean;
  thumbnail: string | null;
  not_active_events: IEvent[];
  events_banned: IEvent[];
  events_on_moderation: IEvent[];
  not_active_announcements: IAd[];
  announcements_banned: IAd[];
  locale: string;
  telegram: string | null; // FIXME: когда на бэке добавят телеграм, возможно поменять
}

export enum UserTypeEnum {
  Company = 'company',
  Individual = 'individual',
}

export type UserType = UserTypeEnum.Company | UserTypeEnum.Individual;
