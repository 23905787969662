import { useCallback, useRef } from 'react';

export const useInfiniteListRef = (hasMore, fetchMore, isLoading, init = {}) => {
  const observerRef = useRef();
  const { root, rootMargin } = init;

  return useCallback(
    (node) => {
      if (isLoading || !hasMore) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            fetchMore();
          }
        },
        {
          root,
          rootMargin,
        },
      );
      if (node) observerRef.current.observe(node);
    },
    [isLoading, hasMore, root, rootMargin, fetchMore],
  );
};
