import { api, create } from '@/modules/shared/api';
import { EntityImage, ImageFormat } from '@/modules/shared/types';
import { endpoints } from './endpoints';

interface CreateImagesInput {
  formats: ImageFormat[];
}

export const ChatApi = {
  loadChatMessages: async ({
    url, announcement, recipient,
  }: { announcement?: number, recipient: number, url?: string }) => {
    const response = await api.get(url || endpoints.chatMessages({
      recipient,
      announcement,
    }));


    return response.data;
  },
  createMessageImages: async (images: CreateImagesInput) => create<CreateImagesInput, EntityImage[]>(endpoints.createChatImage, images),
};
