import {
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFilters,
  InvalidateQueryFilters,
  MutationFunction,
} from '@tanstack/react-query';
import { IListData, IContext } from './react-query.types';

export const createEntityMutationFactory = <
  InputType,
  OutputType,
  ListData extends IListData<OutputType>,
>(createApi: MutationFunction<OutputType, InputType>) => {
  const useCreateMutation = (queryKeys: QueryKey[]) => {
    const queryClient = useQueryClient();

    return useMutation<OutputType, Error, InputType, Record<string, IContext<ListData>>>({
      mutationFn: createApi,
      onMutate: async (entity) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        const contexts: Record<string, IContext<ListData>> = {};

        for (const queryKey of queryKeys) {
          await queryClient.cancelQueries(queryKey as QueryFilters);

          // Snapshot the previous values
          const defaultPrevious = { data: [] } as unknown as ListData;
          const previous = queryClient.getQueryData<ListData>(queryKey) || defaultPrevious;

          // Optimistically update to the new values
          queryClient.setQueryData(queryKey, {
            ...previous,
            data: [...previous.data, entity],
          });

          // Return a context object with the snapshotted values
          contexts[JSON.stringify(queryKey)] = { previous };
        }

        return contexts;
      },
      onError: (err, _, context) => {
        for (const queryKey of queryKeys) {
          const ctx = context?.[JSON.stringify(queryKey)];
          if (ctx) {
            queryClient.setQueryData(queryKey, ctx.previous);
          }
        }
      },
      onSuccess: () => {
        for (const queryKey of queryKeys) {
          queryClient.invalidateQueries(queryKey as InvalidateQueryFilters);
        }
      },
    });
  };

  return useCreateMutation;
};
