import { OpenGraph } from 'next-seo/lib/types';
import { IAd } from '@/modules/ads/types';
import { IEvent } from '@/modules/events';
import { canonical } from './canonical';

const stripHtmlTags = (text: string): string => text.replace(/<\/?[^>]+(>|$)/g, '');

export const getOpenGraph = (ad: IAd | IEvent, path: 'adv' | 'poster', customUrl?: string): OpenGraph => {
  const imageUrl = ad.images?.[0]?.thumbnail;
  const plainText = path === 'poster' ? stripHtmlTags(ad.description) : ad.description;

  return {
    type: 'website',
    site_name: 'Bazaar',
    title: ad.title,
    description: plainText,
    url: customUrl || `${canonical.mainPage}en/${path}/${ad.id}`,
    ...(imageUrl && {
      images: [{
        url: imageUrl,
        alt: `Main picture ${ad.title}`,
        secureUrl: imageUrl,
        width: 512,
        height: 512,
        type: `image/${imageUrl.split('.')?.at(-1) || ''}`,
      }],
    }),
  };
};

export const getMainOpenGraph = (title: string, description: string): OpenGraph => {
  const url = process.env.ENV === 'production'
    ? `${canonical.mainPage}assets/images/logo_32.png`
    : 'https://dev.bazaarorigin.com/assets/images/logo_32.png';

  return {
    type: 'website',
    site_name: 'Bazaar',
    title,
    description,
    url: process.env.ENV === 'production' ? canonical.mainPage : 'https://dev.bazaarorigin.com/',
    images: [{
      url,
      alt: 'Bazaar',
      secureUrl: url,
      width: 1200,
      height: 630,
      type: 'png',
    }],
  };
};
