import { QueryClient } from '@tanstack/react-query';

/**
 * Инвалидирует запросы в кэше, ключи которых содержат хотя бы один из указанных ключей. Инвалидация не сработает, если
 * у запроса enabled: false.
 * В listQueryFactory меняются ключи и добавляются query параметры,
 * поэтому ключи имеют примерно такой вид [undefined, undefined, 10, 1, 'my-active-events'].
 * Нужно найти все кешированные запросы, которые включают в себя 'my-active-events'
 *
 * @param queryClient - экземпляр QueryClient из @tanstack/react-query
 * @param keys - массив строк, содержащих ключи для инвалидирования запросов
 * @returns Promise<void>
 */

export const invalidateQueriesByKeys = async (queryClient: QueryClient, keys: string[]) => {
  const queries = queryClient.getQueriesData({});

  const matchingKeys = queries
    .map(([key]) => key)
    .filter(
      (key) => Array.isArray(key) && keys.some((targetKey) => key.includes(targetKey)),
    );

  if (matchingKeys.length > 0) {
    await Promise.all(
      matchingKeys.map((key) => queryClient.invalidateQueries({ queryKey: key })),
    );
  }
};
