import React, { useMemo } from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { ButtonSwitcher, Button } from '@/modules/shared/components';
import { AdTab } from '../my-ads-types';
import styles from '../my-ads-page.module.scss';

interface MyAdsTabProps {
  tab: AdTab;
  isLoading: boolean;
  quantity: { active: number; hidden: number };
  handleChangeAdTab: (tab: AdTab) => void;
}

export const MyAdsTab = ({
  tab,
  quantity,
  isLoading,
  handleChangeAdTab,
}: MyAdsTabProps) => {
  const { isMobile, isDesktop } = useMatchMedia();
  const { t } = useTranslation();
  const isNoAds = !isLoading && quantity[tab] === 0;

  const buttons = useMemo(() => [
    {
      value: 'active',
      name: `${t('userProfile:myAds.active')} ${!isMobile && quantity.active ? `${quantity.active}` : ''}`,
      dataTestId: 'active',
    },
    {
      value: 'hidden',
      name: `${t('userProfile:myAds.hidden')} ${!isMobile && quantity.hidden ? `${quantity.hidden}` : ''}`,
      dataTestId: 'hidden',
    },
  ], [t, quantity, isMobile]);

  return (
    <div className={styles.my_ads_tab}>
      <ButtonSwitcher
        kind="outline"
        value={tab}
        buttons={buttons}
        onChange={handleChangeAdTab}
        buttonSize={isMobile ? undefined : 'l'}
      />
      {isNoAds && tab === 'active' && (
        <div className={styles.not_active_ads}>
          {t('userProfile:myAds.ads.none')}
          {isDesktop && (
            <Button
              appearance="primary"
              buttonColor="blue"
              buttonSize="s"
              as="link"
              href="/product/create"
            >
              {t('common:header.placeAd')}
            </Button>
          )}
        </div>
      )}
      {isNoAds && tab === 'hidden' && (
        <div className={styles.not_hidden_ads}>
          {t('userProfile:myAds.hiddenAds.none')}
        </div>
      )}
    </div>
  );
};
