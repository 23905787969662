import {
  createContext, PropsWithChildren, useEffect, useMemo, useState,
} from 'react';
import { useMediaQuery } from '@react-hookz/web';

interface MatchMediaResult {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const matchMediaContext = createContext<MatchMediaResult>({ isMobile: false, isTablet: false, isDesktop: false });

export const MatchMediaProvider = ({ children, initial }: PropsWithChildren<{
  initial?: MatchMediaResult;
}>) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const isMobile = useMediaQuery('(max-width: 480px)');
  const isTablet = useMediaQuery('(min-width: 481px) and (max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  const value = useMemo(() => {
    if (!isClient) {
      return initial || { isMobile: false, isTablet: false, isDesktop: false };
    }

    return { isMobile: !!isMobile, isTablet: !!isTablet, isDesktop: !!isDesktop };
  }, [
    isClient,
    // initial, /** специально не указываем, определяется на сервере */
    isDesktop,
    isMobile,
    isTablet,
  ]);

  return (
    <matchMediaContext.Provider value={value}>
      {children}
    </matchMediaContext.Provider>
  );
};
