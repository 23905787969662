import { ComponentType } from 'react';

import {
  CheckboxListDependedChoicesField,
} from './dynamic-fields/checkbox-list-depended-choices';
import { CheckboxListField } from './dynamic-fields/checkbox-list';
import { ButtonListField } from './dynamic-fields/buttons-list';
import { DynamicFieldsMapper } from './components/dynamic-fields-mapper';
import { ColorField } from './dynamic-fields/color';
import { PriceField } from './dynamic-fields/price';
import { RangeField } from './dynamic-fields/range';
import { FiltersMap } from './types';
import { OperationTypeField } from './dynamic-fields/checkbox-list/operation-type';
import { BrandTypeField } from './dynamic-fields/checkbox-list/brand-type';
import { LocationField } from './dynamic-fields/location';
import { TagField } from './dynamic-fields/tag';


const CHECKBOX_FILTERS: {
  [P in FiltersMap['checkbox_list']['name']]: ComponentType<{
    filter: FiltersMap['checkbox_list'];
  }>
} = {
  color: ColorField,
  size: CheckboxListField,
  type_housing: CheckboxListField,
  additional_options: CheckboxListField,
  announcement_source: CheckboxListField,
  engine_type: CheckboxListField,
  transmission: CheckboxListField,
  manufacturer: CheckboxListField,
  payment: CheckboxListField,
  number_of_rooms: ButtonListField,
  state: CheckboxListField,
  brand: BrandTypeField,
  gender: CheckboxListField,
  experience: CheckboxListField,
  schedule: CheckboxListField,
  condition: CheckboxListField,
  operation_type: OperationTypeField,
  permitted_construction: CheckboxListField,
  transfer: CheckboxListField,
  age_restriction: TagField,
  guide: CheckboxListField,
  language: CheckboxListField,
  duration_of_excursion: CheckboxListField,
  allows_pets: (props) => (
    <CheckboxListField
      resetOnWillUnmount
      isVisible={({ form }) => !!(form.value.operation_type)?.includes('rent')}
      {...props}
    />
  ),
  completion_status: (props) => (
    <CheckboxListField
      resetOnWillUnmount
      isVisible={({ form }) => !!(form.value.operation_type)?.includes('buy')}
      {...props}
    />
  ),
  ownership_right: (props) => (
    <CheckboxListField
      resetOnWillUnmount
      isVisible={({ form }) => !!(form.value.operation_type)?.includes('buy')}
      {...props}
    />
  ),
  rental_type: (props) => (
    <CheckboxListField
      resetOnWillUnmount
      isVisible={({ form }) => !!(form.value.operation_type)?.includes('rent')}
      {...props}
    />
  ),
  duration: (props) => (
    <CheckboxListField
      resetOnWillUnmount
      isVisible={({ form }) => !!(form.value.operation_type)?.includes('rent')}
      {...props}
    />
  ),
};


const RANGE_FILTERS: {
  [P in FiltersMap['range']['name']]: ComponentType<{
    filter: FiltersMap['range'];
  }>
} = {
  engine_capacity: RangeField,
  year: RangeField,
  mileage: RangeField,
  living_space: RangeField,
  land_area: RangeField,
};

export const MAP_TYPE_TO_FILTER: {
  [P in keyof FiltersMap]: ComponentType<{
    filter: FiltersMap[P];
  }>
} = {
  checkbox_list: ({ filter }) => (
    <DynamicFieldsMapper
      components={CHECKBOX_FILTERS}
      name={filter.name}
      filter={filter}
    />
  ),
  range: ({ filter }) => (
    <DynamicFieldsMapper
      components={RANGE_FILTERS}
      name={filter.name}
      filter={filter}
    />
  ),
  checkbox_list_depended_choices: CheckboxListDependedChoicesField,
  location: () => <LocationField />,
  price: PriceField,
};
