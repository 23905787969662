import React, { useEffect, useState } from 'react';
import { Modal, SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';


import { getActivationEmail } from '@/modules/shared/helpers';

import { useCloseAuthModal } from '../../hooks';

import styles from './register-confirmation.module.scss';

export const RegisterConfirmation = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | null>(null);
  const closeModal = useCloseAuthModal();

  useEffect(() => {
    setEmail(getActivationEmail());
  }, []);

  return (
    <Modal
      fullHeight
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
      open
      showBackButton={false}
      onClose={closeModal}
    >
      <div className={styles.wrapper}>
        <h2>{t('auth:registerConfirmation.title')}</h2>
        <p>
          {t('auth:registerConfirmation.description', {
            email,
          })}
        </p>
        <SvgIcon
          width="91"
          height="66"
          viewBox="0 0 91 66"
          fill="var(--bzr-secondary-very-light-grey)"
          name="mail_adaptive"
        />
      </div>
    </Modal>
  );
};
