import clsx from 'clsx';
import { UserTypeEnum } from '@/modules/users/types';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '../svg-icon';
import styles from './user-label.module.scss';

type UserLabelProps = {
  companyId: number | null;
  view?: string;
};

export const UserLabel = ({ companyId, view = '' }: UserLabelProps) => {
  const { t } = useTranslation();
  const userType = companyId ? UserTypeEnum.Company : UserTypeEnum.Individual;

  return (
    <div
      className={clsx(styles.label, styles.type, {
        [styles.type_individual]: userType === UserTypeEnum.Individual,
        [styles.mini]: view === 'mini',
      })}
    >
      <SvgIcon name={userType === UserTypeEnum.Individual ? 'user-16' : 'check-company-16'} />
      <span>{t(`common:${userType}`)}</span>
    </div>
  );
};
