import React from 'react';
import { Fade } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { LangProps, languages } from '@/modules/app/components/header/types';
import clsx from 'clsx';
import { SvgIcon } from '../svg-icon';
import styles from './lang-switcher-desktop.module.scss';

export const LangSwitcherDesktop = ({
  currentLang, onClose, toggleLang, isOpen,
}: LangProps) => (
  <ClickAwayListener onClickAway={onClose || (() => {})}>
    <Fade in={isOpen}>
      <div className={styles.modal}>
        <SvgIcon name="shape" style={{ width: '26px', height: '8px' }} />
        <div className={styles.choose_lang}>
          {languages.map((item) => (
            <button
              key={item.locale}
              onClick={() => toggleLang(item)}
              type="button"
              className={clsx(styles.lang, {
                [styles.active_lang]: currentLang.locale == item.locale,
              })}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </Fade>
  </ClickAwayListener>
);
