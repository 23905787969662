import React, { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { AxiosError } from 'axios';
import {
  StarNotification, Button, CreationTitle, CreationDescription, SvgIcon, SuccessPage,
} from '@/modules/shared/components';
import { CreationContacts } from '@/modules/shared/components/creation-contacts';
import { CreationLocation } from '@/modules/locations/components';
import { useAuthContext } from '@/modules/auth';
import { useMatchMedia } from '@/modules/shared/hooks';
import { MobileHeader } from '@/modules/app/components/header/mobile-header.component';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { useMeQuery } from '@/modules/me';
import { compressImage, getMediaUsername } from '@/modules/shared/helpers';
import {
  SIZE_TO_COMPRESS, INSTAGRAM_REGEX, TG_REGEX, WA_REGEX, profileBasePath,
} from '@/modules/shared/const';
import { ValidationError } from '@/modules/shared/types';
import { AddEventBreadCrumbs } from './add-event-bread-crumbs.component';
import { AddEventType } from '../../types';
import { EventType } from './event-type.component';
import { TicketLink } from './ticket-link.component';
import { AgeRestriction } from './age-restriction.component';
import { DateAndTime } from './date-and-time.component';
import { Price } from './price.component';
import { Images } from './images.component';
import { useCreateEventMutation } from '../../mutations';
import s from './add-event-page.module.scss';
import { getTicketLink } from '../../helpers';

export const AddEventPage = () => {
  const { t, lang } = useTranslation();
  const { cityPrefix } = useCityPrefix();
  const { isDesktop } = useMatchMedia();
  const {
    mutate: createEvent, isPending, isSuccess, error, isError,
  } = useCreateEventMutation();
  const { isAuthenticated } = useAuthContext();
  const { data: me, refetch } = useMeQuery(isAuthenticated);
  const [isSent, setIsSent] = useState(false);

  const formMethods = useForm<AddEventType>();
  const {
    handleSubmit,
    setError,
    setFocus,
    reset,
    formState: { isValid, errors },
  } = formMethods;

  const onsubmit: SubmitHandler<AddEventType> = async (data) => {
    const {
      instagram, whatsapp, telegram, price, free, images, ticket_purchase_link,
    } = data;
    let imageToSet: File | null = images[0];
    if (imageToSet && imageToSet.size > SIZE_TO_COMPRESS) {
      imageToSet = await compressImage(imageToSet);
    }

    try {
      createEvent({
        ...data,
        instagram: instagram ? getMediaUsername(instagram, INSTAGRAM_REGEX) : undefined,
        whatsapp: whatsapp ? getMediaUsername(whatsapp, WA_REGEX) : undefined,
        telegram: telegram ? getMediaUsername(telegram, TG_REGEX) : undefined,
        ticket_purchase_link: ticket_purchase_link ? getTicketLink(ticket_purchase_link) : undefined,
        price: free ? 0 : price,
        max_price: data.max_price || undefined,
        images: [imageToSet ?? images[0]],
        user: me?.id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      const responseErrors = Object.entries(error.response?.data?.validation_errors);
      responseErrors.forEach((el) => {
        setError(el[0] as keyof AddEventType, {
          type: 'manual',
          message: t(`forms:validationErrors.${(el[1] as ValidationError).code}`),
        });
      });
      return;
    }
    if (isSuccess) {
      reset();
      setIsSent(true);
    }
  }, [isSuccess, isError, error]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => (field && !!errors[field] ? field : a), null);

    if (firstError) {
      setFocus(firstError as keyof AddEventType);
    }
  }, [errors, isError]);

  return (
    <div
      className={s.create_event_wrapper}
      style={{ gap: isSent ? '0' : 'var(--bzr-spacing-large-44)' }}
    >
      <NextSeo title={t('seo:createEventPage.title')} />
      <section className={s.controls}>
        {isDesktop ? (
          <div className={s.logo} data-testid="header-logo">
            <Link href={`/${lang}/${cityPrefix}/posters`}>
              <SvgIcon name={`posters-button-${lang || 'en'}`} className={s.logo_poster} />
            </Link>
          </div>
        ) : (
          <MobileHeader
            showBackButton={!isSent}
            rightContent={(
              <Link href={`/${lang}/${cityPrefix}/posters`}>
                <SvgIcon name="close" />
              </Link>
            )}
            showOnCloseButton={false}
            title={isSent ? '' : t('events:eventCreation')}
          />
        )}
        {isDesktop && !isSent && <AddEventBreadCrumbs />}
        {isDesktop && !isSent && <h1>{t('events:eventCreation')}</h1>}
      </section>
      {!isSent ? (
        <FormProvider {...formMethods}>
          <form
            className={s.form}
            onSubmit={handleSubmit(onsubmit)}
            autoComplete="off"
            id="form-add-event"
          >
            <StarNotification />
            <EventType />
            <CreationLocation />
            <CreationContacts isEventCreation />
            <div className={s.field}>
              <h2>{t('forms:createAd.descriptionTitle')}</h2>
              <CreationTitle
                title={t('events:createEvents.title_new')}
                placeholder={t('events:createEvents.titlePlaceholder')}
              />
              <CreationDescription />
              <TicketLink />
              <AgeRestriction />
            </div>
            <DateAndTime />
            <Price />
            <Images />
            <Button
              appearance="primary"
              buttonColor="green"
              buttonSize="m"
              type="submit"
              disabled={!isValid || isPending || !!Object.keys(errors).length}
              className={s.submit}
            >
              {isPending ? t('common:submitting') : t('events:createEvents.submit')}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <SuccessPage
          setIsSent={setIsSent}
          header={t('events:createEvents.sent._')}
          infoText={t('events:createEvents.sent.content')}
          linkToAccount={`/${lang}/${profileBasePath}/posters?tab=hidden`}
          linkToAccountText={t('events:createEvents.sent.go_to_hidden')}
          refetch={refetch}
        />
      )}
    </div>
  );
};
