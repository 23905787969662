import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { ItemLoader } from '../loaders';
import { SvgIcon } from '../svg-icon';
import styles from './infinite-list-of-ads-layout.module.scss';

interface ListOfAdsLayoutProps {
  title: string;
  children: ReactNode;
  infiniteListRef?: (node: unknown) => void;
  isRecent?: boolean;
  marginTop?: boolean;
  isLoading?: boolean;
  onScroll?: (index: number) => void;
  disabledPrev?: boolean;
  disabledNext?: boolean;
}

export const InfiniteListOfAdsLayout = forwardRef<HTMLDivElement, ListOfAdsLayoutProps>(
  (props, ref?) => {
    const {
      title, children, infiniteListRef, isRecent, marginTop, isLoading = false, onScroll, disabledPrev, disabledNext,
    } = props;

    return (
      <section className={clsx(styles.ads_block, {
        [styles.recent]: isRecent,
        [styles.marginTop]: marginTop,
      })}
      >
        {isRecent ? (
          <div className={styles.header}>
            <h3>{title}</h3>
            {onScroll && (
              <div className={styles.slider}>
                <button
                  disabled={disabledPrev}
                  onClick={() => onScroll(-1)}
                  className={styles.button}
                  type="button"
                >
                  <SvgIcon name="arrow-forward-left" />
                </button>
                <button
                  disabled={disabledNext}
                  onClick={() => onScroll(1)}
                  className={styles.button}
                  type="button"
                >
                  <SvgIcon name="arrow-forward-right" />
                </button>
              </div>
            )}
          </div>
        ) : (
          <h3>{title}</h3>
        )}
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <ItemLoader isLoading size={65} />
          </Box>
        ) : (
          <div
            className={clsx(styles.ads_container, {
              [styles.ads_container__recent]: isRecent,
            })}
            ref={ref}
          >
            {children}
          </div>
        )}
        <div ref={infiniteListRef} />
      </section>
    );
  },
);
