import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm, FormProvider, SubmitHandler, Resolver,
} from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { profileBasePath } from '@/modules/shared/const';
import { MobileHeader } from '@/modules/app/components/header/mobile-header.component';
import { Seo } from '@/modules/seo';
import { Me } from '@/modules/me/types';
import { SuccessPage } from '@/modules/shared/components';
import {
  CreateAdBreadcrumbs,
  SelectCategory,
  SelectCategoryMobile,
  FormWrapper,
  DescriptionAndPhotos,
  ContactInformation,
  Details,
} from './components';
import { CreateAdContextProvider, useCreateAdActionsContext, useCreateAdContext } from './context';
import { useAdditionalSpecifications, useCreateAdSchema, useSubmitAd } from './hooks';
import { CreateAdTypes } from './types';
import styles from './create-ad.module.scss';

interface MeProps {
  me?: Me;
}
const CreateAdComponent = ({ me }: MeProps) => {
  const router = useRouter();
  const { t, lang } = useTranslation('createAd');
  const { isDesktop } = useMatchMedia();

  const { mainStep, isAdditionalSpecifications, category } = useCreateAdContext();
  const { setMainStep } = useCreateAdActionsContext();
  useAdditionalSpecifications(); // устанавливаем доп характеристики при необходимости

  const { schema } = useCreateAdSchema(category?.codename);

  const formMethods = useForm<CreateAdTypes>({
    resolver: yupResolver(schema) as Resolver<CreateAdTypes>,
    mode: 'all',
  });
  const {
    handleSubmit, resetField, reset, setError, getValues,
  } = formMethods;
  const { createAd } = useSubmitAd(reset, setError, getValues);

  const onsubmit: SubmitHandler<CreateAdTypes> = async (data) => {
    if (data.price_type !== 'for_money') {
      delete data.price;
    }

    const additional_options: string[] = [];
    Object.entries(data).forEach((el) => {
      if (el[1] === true) additional_options.push(el[0]);
    });
    data.additional_options = additional_options;

    await createAd(data);
  };

  const handleBackClick = useCallback(() => {
    if (mainStep === 4 && !isAdditionalSpecifications) {
      setMainStep(2);
      return;
    } // если нет доп характеристик, нужно пропустить mainStep === 3

    if (mainStep > 1) {
      setMainStep((prevState) => prevState - 1);
    }
  }, [isAdditionalSpecifications, mainStep]);

  const handleSuccessClick = useCallback(() => {
    reset(); // для safari еще раз сбрасываем форму
    setMainStep(1);
  }, []);

  return (
    <>
      {mainStep === 5 ? (
        <SuccessPage
          onClick={handleSuccessClick}
          header={t('successPage.title')}
          infoText={t('successPage.subTitle')}
          linkToAccount={`/${lang}/${profileBasePath}/my-ads`}
          linkToAccountText={t('successPage.goToMyAds')}
        />
      ) : (
        <section className={styles.create_ad_page}>
          {isDesktop && (
            <div className={styles.title}>
              <CreateAdBreadcrumbs resetField={resetField} />
              <h1>{t('placeAd')}</h1>
            </div>
          )}
          {!isDesktop && mainStep !== 1 && (
            <MobileHeader
              title={t('placeAd')}
              onClickBack={handleBackClick}
              onClose={() => router.push(`/${profileBasePath}/my-ads`)}
            />
          )}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onsubmit)}>
              {mainStep === 1 && isDesktop && <SelectCategory />}
              {mainStep === 1 && !isDesktop && <SelectCategoryMobile />}
              {mainStep !== 1 && (
                <FormWrapper>
                  <ContactInformation
                    user_type={me?.user_type}
                    id={me?.id}
                    company_id={me?.company_id}
                  />
                  {mainStep === 3 && isAdditionalSpecifications && <DescriptionAndPhotos />}
                  {mainStep === 4 && isAdditionalSpecifications && <Details />}
                  {mainStep === 4 && !isAdditionalSpecifications && <DescriptionAndPhotos />}
                </FormWrapper>
              )}
            </form>
          </FormProvider>
        </section>
      )}
    </>
  );
};

export const CreateAd = ({ me }: MeProps) => {
  const { t } = useTranslation('seo');

  return (
    <CreateAdContextProvider>
      <Seo
        title={t('createAdPage.title')}
        description={t('createAdPage.description')}
      />
      <CreateAdComponent me={me} />
    </CreateAdContextProvider>
  );
};
