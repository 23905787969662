export const networks = [
  {
    name: 'networks-telegram',
    path: 'https://t.me/bazaarorigin/',
    id: 'telegram',
  },
  {
    name: 'networks-youtube',
    path: 'https://youtube.com/@bazaarorigin?si=V9bfrA2_h6DJPsST',
    id: 'youtube',
  },
  {
    name: 'networks-instagram',
    path: 'https://www.instagram.com/bazaarorigin/',
    id: 'instagram',
  },
];
