export const getUserCompanyData = (companyData, userData) => {
  let name: string;
  let avatar = '';

  if (companyData) {
    name = companyData.name;
    avatar = companyData.logo?.thumbnail || '';

    return {
      name,
      avatar,
    };
  }

  name = userData.full_name;
  avatar = userData.avatar || '';

  return {
    name,
    avatar,
  };
};
