import React from 'react';
import clsx from 'clsx';
import styles from './slider-dots.module.scss';

type SliderDotsProps = {
  onChange: React.Dispatch<React.SetStateAction<number>>;
  value: number;
  current: number;
  type?: string;
};

export const SliderDots = ({
  onChange, current, value, type = '',
}: SliderDotsProps) => (
  <span
    onClick={(e) => {
      e.preventDefault();
      onChange(value);
    }}
    className={clsx(styles.dot, {
      [styles.active]: current === value,
      [styles.ad_card_dot]: type === 'ad_card',
      [styles.ad_card_dot_active]: type === 'ad_card' && current === value,
    })}
  />
);
