import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { SvgIcon, Textarea } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './chat-text-field.module.scss';

export const ChatTextField = ({
  sendMessage,
  value,
  handleChange,
  handleKeyDown,
}) => {
  const { t } = useTranslation('chat');
  const { isDesktop } = useMatchMedia();

  return (
    <div className={s.input}>
      <Textarea
        value={value}
        placeholder={t('chat.inputPlaceholder')}
        onKeyDown={handleKeyDown}
        handleChange={handleChange}
        heightScroll={isDesktop ? 300 : 130}
        enableMaxHeight
        classNameTextarea={s.textarea}
      />

      {((!isDesktop && value) || isDesktop) && (
        <button
          type="button"
          onClick={() => sendMessage()}
          className={clsx(s.input_button, { [s.input_button_active]: value.length > 0 })}
        >
          <SvgIcon name="sent-button" />
        </button>
      )}
    </div>
  );
};
