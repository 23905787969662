export default {
  notFoundPage: {
    title: {
      ru: 'Страница не найдена | Ошибка 404',
      en: 'Page not found | Error 404',
    },
    header: {
      ru: 'Страница не найдена: Когда путь потерян в цифровом лабиринте',
      en: 'Page Not found: When the path is lost in a digital maze',
    },
    description: {
      ru: 'Извините, но мы не смогли найти запрошенную страницу. Возможно, вы заблудились? Давайте вместе найдем путь обратно в мир нашего веб-портала.',
      en: 'Sorry, but we couldn\'t find the requested page. Perhaps you\'re lost? Let\'s find our way back together to the world of our web portal.',
    },
    contact: {
      ru: 'Связаться с нами',
      en: 'Contact us',
    },
    back: {
      ru: 'Вернуться домой',
      en: 'Back to Home',
    },
  },
  select: {
    ru: 'Выбрать',
    en: 'Choose',
  },
  locations: {
    phuket: {
      ru: 'Пхукет',
      en: 'Phuket',
      districts: {
        bangtao: { ru: 'Бангтао', en: 'Bangtao' },
        surin: { ru: 'Сурин', en: 'Surin' },
        kamala: { ru: 'Камала', en: 'Kamala' },
        patong: { ru: 'Патонг', en: 'Patong' },
        karon: { ru: 'Карон', en: 'Karon' },
        kata: { ru: 'Ката', en: 'Kata' },
        nai_harn: { ru: 'Най Харн', en: 'Nai Harn' },
        rawai: { ru: 'Равай', en: 'Rawai' },
        chalong: { ru: 'Чалонг', en: 'Chalong' },
        phuket_toun: { ru: 'Пхукет Таун', en: 'Phuket Town' },
        katu: { ru: 'Кату', en: 'Katu' },
        nai_yang: { ru: 'Най Янг', en: 'Nai Yang' },
        mai_khao: { ru: 'Май Кхао', en: 'Mai Khao' },
        panwa: { ru: 'Панва', en: 'Panwa' },
        koh_kaew: { ru: 'Ко Каео', en: 'Ko Kaeo' },
        pa_klok: { ru: 'Паклок', en: 'Paklok' },

      },
    },
    pattaya: {
      ru: 'Паттайя',
      en: 'Pattaya',
      districts: {
        naklua: { ru: 'Наклуа', en: 'Naklua' },
        pattaya_central: { ru: 'Центральная Паттайя', en: 'Central Pattaya' },
        pattaya_south: { ru: 'Южная Паттайя', en: 'South Pattaya' },
        pattaya_north: { ru: 'Северная Паттайя', en: 'North Pattaya' },
        jomtien: { ru: 'Джомтьен', en: 'Jomtien' },
        najomtien: { ru: 'Наджомтьен', en: 'Na Jomtien' },
        pratumnak: { ru: 'Пратумнак', en: 'Pratumnak' },
        wong_amat: { ru: 'Вонг Амат', en: 'Wong Amat' },
        huai_yai: { ru: 'Хуай Яй', en: 'Huai Yai' },
      },
    },
    samui: {
      ru: 'Самуи',
      en: 'Samui',
      districts: {
        maenam: { ru: 'Маенам', en: 'Maenam' },
        bophut: { ru: 'Бопхут', en: 'Bophut' },
        bang_rak: { ru: 'Банг Рак', en: 'Bang Rak' },
        chong_mon: { ru: 'Чонг Мон', en: 'Chong Mon' },
        chaweng: { ru: 'Чавенг', en: 'Chaweng' },
        lamai: { ru: 'Ламай', en: 'Lamai' },
        taling_ngam: { ru: 'Талинг Нгам', en: 'Taling Ngam' },
        lipa_noi: { ru: 'Липа Ной', en: 'Lipa Noi' },
        naton: { ru: 'Натон', en: 'Nathon' },
        bang_po: { ru: 'Банг По', en: 'Bang Po' },
      },
    },
    krabi: {
      ru: 'Краби',
      en: 'Krabi',
      districts: {
        mueang_krabi: { ru: 'Муанг Краби', en: 'Mueang Krabi' },
        ao_nang: { ru: 'Ао Нанг', en: 'Ao Nang' },
        ko_lanta: { ru: 'Ко Ланта', en: 'Ko Lanta' },
        ko_yao_noi: { ru: 'Ко Яо Ноя', en: 'Ko Yao Noi' },
        nopparat_thara: { ru: 'Ноппарат Тхара', en: 'Nopparat Thara' },
        hadyai: { ru: 'Хадйаи', en: 'Hadyai' },
        ao_luek: { ru: 'Ао Луек', en: 'Ao Luek' },
        ko_pipi: { ru: 'Ко Пипи', en: 'Po Pipi' },
        pranang: { ru: 'Прананг', en: 'Pranang' },
        ko_yao_yai: { ru: 'Ко Яо Яи', en: 'Ko Yao Yai' },
      },
    },
    pangan: {
      ru: 'Панган',
      en: 'Pangan',
      districts: {
        tong_sala: { ru: 'Тонг Сала', en: 'Tong Sala' },
        baan_tai: { ru: 'Баан Тай', en: 'Baan Tai' },
        shritanu: { ru: 'Шритану', en: 'Shritanu' },
        chaloklum: { ru: 'Чалоклам', en: 'Chaloklum' },
        haad_rin: { ru: 'Хаад Рин', en: 'Haad Rin' },
        tong_nai_pan: { ru: 'Тонг Най Пан', en: 'Tong Nai Pan' },
        haad_yao: { ru: 'Хаад Яо', en: 'Haad Yao' },
        makha_bay: { ru: 'Макха Бэй', en: 'Makha Bay' },
      },
    },
  },
  Phuketfashionweek2024: {
    Click: {
      ru: 'Жми на кнопку',
      en: 'Click on the button',
    },
    download: {
      ru: 'и скачивай',
      en: 'and download',
    },
    video: {
      ru: 'видео с PHUKET FASHION WEEK’ 24',
      en: 'videos from PHUKET FASHION WEEK’ 24',
    },
    downloadMobile: {
      ru: 'и скачивай видео',
      en: 'and download videos',
    },
    videoMobile: {
      ru: 'с PHUKET FASHION WEEK’ 24',
      en: 'from PHUKET FASHION WEEK’ 24',
    },
    Epic: {
      ru: 'Epic video',
      en: 'Epic video',
    },
    win: {
      ru: 'Выиграйте',
      en: 'Win',
    },
    price: {
      ru: '5,000 ฿',
      en: '5,000 ฿',
    },
    fortagging: {
      ru: 'за отметку',
      en: 'for tagging',
    },
    on: {
      ru: 'в',
      en: 'on',
    },
    highlights: {
      ru: 'Делитесь яркими моментами PHUKET FASHION WEEK’ 24',
      en: 'Share the highlights of PHUKET FASHION WEEK’ 24',
    },
    Become: {
      ru: 'Станьте другом в Instagram',
      en: 'Become a friend on Instagram',
    },
    subscribed: {
      ru: 'Если вы еще не подписаны на',
      en: 'If you are not yet subscribed to',
    },
    Bazaarofficialaccount: {
      ru: 'официальный аккаунт Bazaar',
      en: 'Bazaar official account',
    },
    onInstagram: {
      ru: 'в Instagram, то сделайте это сейчас',
      en: 'on Instagram, then do it now',
    },
    Postastorytagged: {
      ru: 'Выложите сторис с отметкой @bazaarorigin',
      en: 'Post a story tagged @bazaarorigin',
    },
    Takeacoolphoto: {
      ru: 'Сделайте крутой кадр с мероприятия',
      en: 'Take a cool photo from the event',
    },
    andshareitwit: {
      ru: 'и поделитесь им с друзьями в сторис',
      en: 'and share it with your friends in stories',
    },

    withmark: {
      ru: ' с отметкой ',
      en: 'with mark ',
    },

    Getmoney: {
      ru: 'Получите деньги',
      en: 'Get money',
    },
    Among: {
      ru: 'Среди всех отметок будут выбраны',
      en: 'Among all the marks will be selected',
    },
    Amon1: {
      ru: '5 победителей генератором случайных',
      en: '5 winners by random generator',
    },
    Among2: {
      ru: 'чисел, которые получат по 5,000฿ в подарок!',
      en: 'numbers who will receive 5,000 ฿ as a gift!',
    },

    Be: {
      ru: 'Будь в тренде! Продвигай свои товары или услуги на ',
      en: 'Be in the trend! Promote your products or services on ',
    },
    Bazaarfor: {
      ru: 'Базар для ',
      en: 'Bazaar for ',
    },
    business: {
      ru: 'бизнеса',
      en: 'business',
    },
    Advertise: {
      ru: 'Прорекламируйте свои услуги или товары по всему ',
      en: 'Advertise your services or products throughout',
    },

    Thailand: {
      ru: 'Таиланду',
      en: 'Thailand',
    },
    fromPhuket: {
      ru: ': от Пхукета, Паттайи до Краби и Панган.',
      en: ': from Phuket, Pattaya to Krabi and Koh Phangan.',
    },

    Yousimply: {
      ru: 'Вы просто размещаете объявления на ',
      en: 'You simply place ads on',
    },
    andclients: {
      ru: ' и клиенты находят вас сами, а затраты на рекламу мы берём на себя 😉',
      en: 'and clients find you themselves, and we cover the advertising costs 😉',
    },
    youregister: {
      ru: 'При регистрации до 31 марта дарим ',
      en: 'If you register before March 31st, we will give you a ',
    },

    freeadvertising: {
      ru: 'бесплатное размещение ',
      en: 'free advertising ',
    },
    mainpage: {
      ru: 'рекламного баннера на главной странице!',
      en: ' banner on the main page!',
    },

    ofpeople: {
      ru: 'людей',
      en: 'people',
    },

    bulletin: {
      ru: ' - это доска объявлений, где вы можете найти всё, что вам нужно, и продать то, что вам больше не нужно.',
      en: 'is a bulletin board where you can find everything you need and sell what you no longer need.',
    },
    products: {
      ru: 'все товары, услуги, анонсы мероприятий на одной платформе',
      en: 'all products, services, event announcements on one platform',
    },
    posting: {
      ru: 'размещение объявлений за 1 минуту',
      en: 'posting ads in 1 minute',
    },
    detailed: {
      ru: 'подробные фильтры',
      en: 'detailed filters',
    },
    multilingual: {
      ru: 'мультиязычность',
      en: 'multilingual',
    },
    chat: {
      ru: 'общение в чате, не покидая платформы',
      en: 'chat without leaving the platform',
    },
    receive: {
      ru: 'Для получения подробных условий акции напишите запрос на ',
      en: 'To receive detailed conditions of the promotion, write a request to ',
    },

    GotoInstagram: {
      ru: 'Перейти в Instagram',
      en: 'Go to Instagram',
    },
  },
  InfiniteScroll: {
    loading: {
      en: 'Loading...',
      ru: 'Загрузка...',
    },
    loadMore: {
      en: 'Load more',
      ru: 'Загрузить еще',
    },
  },
  adv: {
    ru: 'Объявления по запросу ',
    en: 'Ads for the search query ',
  },
  Form: {
    errors: {
      required: {
        en: 'Please fill out the field',
        ru: 'Пожалуйста, заполните поле',
      },
      invalidEmail: {
        en: 'Enter a valid email address',
        ru: 'Введите корректный email адрес',
      },
      minLength: (minLen) => ({
        en: `At least ${minLen} characters is required`,
        ru: `Минимум ${minLen} символов`,
      }),
      maxLength: (maxLen) => ({
        en: `Maximum length of ${maxLen} characters is exceeded`,
        ru: `Превышена максимальная длина ${maxLen} символов`,
      }),
      imageRequired: {
        en: 'Please, select an image',
        ru: 'Пожалуйста, выберите изображение',
      },
      maxItemsAmount: (max) => ({
        en: `Too many items, only ${max} is allowed`,
        ru: `Слишком много элементов, максимум - ${max}`,
      }),
      minItemsAmount: (min) => ({
        en: `Too few items, at least ${min} is required`,
        ru: `Недостаточно, необходимо минимум ${min}`,
      }),
      invalidPrice: {
        ru: 'Некорректная цена. Цена должна быть числом больше или равным 0.',
        en: 'Incorrect price. Price should be a number greater than or equal to 0.',
      },
      invalidDecimal: {
        ru: 'Цена не может иметь более двух знаков после десятичной точки.',
        en: 'The price cannot have more than two decimal places after the decimal point.',
      },
      externalLinks: (links) => {
        const joined = links.join(', ');
        return {
          ru: `Недопустимые ссылки: ${joined}`,
          en: `Prohibited links: ${joined}`,
        };
      },
      wrongImgFormat: {
        ru: 'Неверный формат изображения! Пожалуйста, попробуйте другую фотографию',
        en: 'Incorrect image format! Please try another photo',
      },
    },
  },
  Chat: {
    ru: 'Сообщений нет',
    en: 'no messages',
  },
  Photo: {
    ru: 'Фото',
    en: 'Photo',
  },
  submiting: {
    ru: 'Загрузка...',
    en: 'Loading...',
  },
  Select: {
    ru: 'Выберите под-подкатегорию',
    en: 'Select a sub-subcategory',
  },
  subcategory: {
    ru: 'Выберите под-категорию',
    en: 'Select sub-category',
  },
  toast: {
    like: {
      ru: 'Не получилось лайкнуть!',
      en: 'Failed to like!',
    },
    while: {
      ru: 'Произошла ошибка при создании объявления!',
      en: 'An error occurred while creating the ad!',
    },
    advertisementSuccess: {
      ru: 'Объявление успешно создано!',
      en: 'The advertisement has been successfully created!',
    },
    advModeration: {
      ru: 'Объявление отправлено на модерацию',
      en: 'Ad sent for moderation',
    },

    successfullycreated: {
      ru: 'успешно создана!',
      en: 'successfully created!',
    },
    Thecomplaint: {
      ru: 'Жалоба успешно принято!',
      en: 'The complaint was successfully accepted!',
    },
    accepting: {
      ru: 'Произошла ошибка при принятии жалоба!',
      en: 'An error occurred while accepting the complaint!',
    },
    errorcreating: {
      ru: 'Произошла ошибка при создании объявления!',
      en: 'An error occurred while creating the ad!',
    },
    occurred: {
      ru: 'Произошла ошибка при создании!',
      en: 'An error occurred during creation!',
    },
    codematch: {
      ru: 'Код не совпадает',
      en: 'The code doesn\'t match',
    },
    registered: {
      ru: 'Вы успешно зарегистрировались!',
      en: 'You have successfully registered!',
    },
    Please: {
      ru: 'Пожалуйста, выберите иконку для категории.',
      en: 'Please select an icon for the category.',
    },
    errorCategoryUpdate: {
      ru: 'Ошибка при обновлении категории.',
      en: 'Error updating category.',
    },
    successfullyChangedCategory: {
      ru: 'Категория успешно обновлена!',
      en: 'Category successfully updated!',
    },
    Thedeactivated: {
      ru: 'Объявление деактивированно!',
      en: 'The ad is deactivated!',
    },
    Event: {
      ru: 'Мероприятие активированно!',
      en: 'Event activated!',
    },
    poster: {
      ru: 'Афиша успешно изменено!',
      en: 'The poster has been successfully changed!',
    },
    successfully: {
      ru: 'Афиша успешно удалено!',
      en: 'Poster successfully deleted!',
    },
    moderation: {
      ru: 'Мероприятие отправлено на модерацию!',
      en: 'The event has been sent for moderation!',
    },
    error: {
      ru: 'Произошла ошибка при создании мероприятия!',
      en: 'An error occurred while creating the event!',
    },
    Error: {
      ru: 'Ошибка при удалении афишы!',
      en: 'Error when deleting poster!',
    },
    changing: {
      ru: 'Ошибка при изменении афишы!',
      en: 'Error when changing poster!',
    },
    Advertisement: {
      ru: 'Объявление активированно!',
      en: 'Advertisement activated!',
    },
    password6: {
      ru: 'Новый пароль должен содержать не менее 6 символов',
      en: 'The new password must contain at least 6 characters',
    },
    Password: {
      ru: 'Пароли не совпадают',
      en: 'Password mismatch',
    },
    changed: {
      ru: 'Пароль успешно изменен',
      en: 'Password changed successfully',
    },
    Failed: {
      ru: 'Не удалось изменить пароль',
      en: 'Failed to change password',
    },
    incorrect: {
      ru: 'Неверный текущий пароль',
      en: 'Current password is incorrect',
    },
    Successfully: {
      ru: 'Успешно изменилось',
      en: 'Successfully changed',
    },
    review: {
      ru: 'Отзыв успешно создано',
      en: 'Feedback successfully created',
    },
    reviewError: {
      ru: 'Произошла ошибка при создании отзыва!',
      en: 'There was an error when creating a review!',
    },
    support: {
      ru: 'Мы успешно получили ваше письмо',
      en: 'We have successfully received your letter',
    },
    supportUnsuccessfull: {
      ru: 'Ошибка при отправлении письмо',
      en: 'Error when sending an email',
    },
  },
  modal: {
    delete: {
      Edit: {
        ru: 'Редактировать афишу',
        en: 'Edit poster',
      },
      Deactivatead: {
        ru: 'Деактивировать объявление',
        en: 'Deactivate ad',
      },

      Deactivate: {
        ru: 'Деактивировать афишу',
        en: 'Deactivate poster',
      },
      deactivating: {
        ru: 'Причина деактивации афиши',
        en: 'Reason for deactivating the poster',
      },
      moved: {
        ru: 'Перенесли дату проведение',
        en: 'The date has been moved',
      },
      Canceled: {
        ru: 'Отменили',
        en: 'Canceled',
      },
      Cancel: {
        ru: 'Отменить',
        en: 'Cancel',
      },
      Title: {
        ru: 'Вы уверены, что хотите удалить это объявление?',
        en: 'Are you sure you want to remove this ad?',
      },
      Text: {
        ru: 'Объявление будет удалено навсегда',
        en: 'The ad will be deleted forever',
      },
      Activate: {
        ru: 'Aктивировать объявление',
        en: 'Activate ad',
      },
      Remove: {
        ru: 'Удалить объявление',
        en: 'Remove ad',
      },
    },
  },
  seo: {
    events: {
      Title: {
        ru: 'Афиша событий в Таиланде | Bazaar',
        en: 'Poster of events in Thailand | Bazaar',
      },
      Description: {
        ru: 'Чем заняться в Таиланде? Найдите для себя лучшие мероприятия в разделе "Афиша" от Bazaar: от музыкальных концертов и театральных спектаклей до выставок и фестивалей. Не пропустите самые яркие события!',
        en: 'What to do in Thailand? Find the best events in Bazaar\'s Events section, from music concerts and theater performances to exhibitions and festivals. Don\'t miss the most exciting events!',
      },
      Poster_page: {
        title: {
          ru: 'Афиша мероприятий на Bazaar',
          en: 'Event poster on Bazaar',
        },
        description: {
          ru: 'Афиша на Bazaar: актуальная информация о событиях, выступлениях и концертах в Таиланде - Разместите своё мероприятие на доске объявлений Bazaar',
          en: 'Bazaar Poster: up-to-date information about events, performances and concerts in Thailand - Post your event on Bazaar\'s message board',
        },
      },
    },
    main: {
      Title: {
        ru: 'Купить товары и заказать услуги в Таиланде | Bazaar',
        en: 'Buy goods and order services in Thailand | Bazaar',
      },
      Description: {
        ru: 'Bazaar: Ведущая платформа для размещения объявлений о товарах и услугах в Таиланде - Легкий поиск, удобство размещения объявлений и мгновенный доступ к множеству предложений',
        en: 'Bazaar: Thailand\'s leading platform for listing products and services - Easy search, convenient listing and instant access to tons of offers',
      },
    },
    catolog: {
      category: {
        Transport: {
          Title: {
            ru: 'Аренда транспорта',
            en: 'Transport rental',
          },
          Description: {
            ru: 'Аренда и прокат транспортных средств, автомобилей, мототехники, спецтехники - Ведущая платформа для размещения объявлений о товарах и услугах в ',
            en: 'Rental and rental of vehicles, cars, motorcycles, special equipment - The leading platform for posting advertisements for goods and services in ',
          },
          Auto: {
            Title: {
              ru: ' Аренда автомобилей в',
              en: 'Car rental in ',
            },
            Description: {
              ru: 'Аренда и прокат легковых автомобилей, минивэнов, внедорожников - Ведущая платформа для размещения объявлений о товарах и услугах в',
              en: 'Rental and rental of cars, minivans, SUVs - The leading platform for posting advertisements for goods and services in',
            },
          },
          Scooters: {
            Title: {
              ru: 'Продажа и аренда скутеров в ',
              en: 'Sale and rental of scooters in',
            },
            Description: {
              ru: 'Аренда мопедов в Таиланде, купить скутеры в Таиланде в объявлениях на Bazaar от частных пользователей - Широкий выбор мопедов на продажу и в аренду в вашем городе. Удобство выбора и выгодные условия.',
              en: 'Rent scooters in Thailand, buy scooters in Thailand in ads on Bazaar from private users - A wide selection of scooters for sale and rent in your city. Convenience of choice and favorable conditions.',
            },
          },
          Transfer: {
            Title: {
              ru: 'Услуги трансфера по городам ',
              en: 'Transfer services by city ',
            },
            Description: {
              ru: 'Объявления услуг трансферов в Таиланде на Bazaar - Удобные услуги трансфера в городах Таиланда до аэропорта, между городами. Бронирование трансфера по выгодным ценам с комфортом и безопасностью от пользователей платформы ',
              en: 'Advertisements of transfer services in Thailand on Bazaar - Convenient transfer services in the cities of Thailand to the airport, between cities. Booking a transfer at competitive prices with comfort and safety from platform users',
            },
          },
          Moto: {
            Title: {
              ru: 'Аренда мотоциклов и мототехники в  ',
              en: 'Rent of motorcycles and motor vehicles in',
            },
            Description: {
              ru: 'Аренда и прокат мотоциклов, мопедов и другой мототехники - Ведущая платформа для размещения объявлений о товарах и услугах в',
              en: 'Rent and rental of motorcycles, scooters and other motorized equipment - The leading platform for posting advertisements about goods and services in',
            },
          },
          Trucks: {
            Title: {
              ru: 'Аренда грузовиков, кранов и спецтехники в',
              en: 'Rental of trucks, cranes and special equipment in',
            },
            Description: {
              ru: 'Аренда и прокат грузовиков, кранов и другой спецтехники - Ведущая платформа для размещения объявлений о товарах и услугах в',
              en: 'Rental and rental of trucks, cranes and other special equipment - The leading platform for advertising goods and services in',
            },
          },
          Watervehicles: {
            Title: {
              ru: 'Аренда водного транспорта, катеров и яхт в',
              en: 'Rent of water transport, boats and yachts in',
            },
            Description: {
              ru: 'Аренда и прокат водного транспорта, катеров и яхт для водных прогулок - Ведущая платформа для размещения объявлений о товарах и услугах в ',
              en: 'Rent and rental of water transport, boats and yachts for boat trips - The leading platform for posting advertisements about goods and services in',
            },
          },
          Airvehicles: {
            Title: {
              ru: 'Аренда воздушного транспорта, вертолетов, шара в ',
              en: 'Rent of air transport, helicopters, balloons in',
            },
            Description: {
              ru: 'Аренда и прокат воздушного транспорта, вертолетов, шара - Ведущая платформа для размещения объявлений о товарах и услугах в ',
              en: 'Rental and rental of air transport, helicopters, balloons - The leading platform for posting advertisements about goods and services in',
            },
          },
        },

        Realestate: {
          Title: {
            ru: 'Недвижимость в Таиланде ',
            en: 'Property in Thailand',
          },
          Description: {
            ru: 'Широкий выбор недвижимости на Bazaar в Таиланде. От квартир и домов до земельных участков и коммерческих объектов. Найдите идеальное место для жизни или бизнеса в',
            en: 'Wide selection of real estate on Bazaar in Thailand. From apartments and houses to land plots and commercial properties. Find the perfect place to live or do business in',
          },
          buy: {
            Title: {
              ru: 'Купить жилье в',
              en: 'Buy a home in',
            },
            Description: {
              ru: 'Покупка жилья в Таиланде легко и удобно с Bazaar. Варианты недвижимости для постоянного проживания или инвестиций в различных районах в',
              en: 'Buying a home in Thailand is easy and convenient with Bazaar. Property options for permanent residence or investment in various areas in',
            },
          },
          rentdaily: {
            Title: {
              ru: 'Аренда жилья посуточно в ',
              en: 'Daily housing rental in',
            },
            Description: {
              ru: 'Аренда жилья в Таиланде посуточно -  Поиск объявлений об аренде квартир, домов и вилл на Bazaar. Удобные варианты для путешествий в',
              en: 'Daily housing rental in Thailand - Search for advertisements for rental apartments, houses and villas on Bazaar. Convenient options for traveling to',
            },
          },
          rentlongterm: {
            Title: {
              ru: 'Долгосрочная аренда жилья в ',
              en: 'Long-term rental housing in',
            },
            Description: {
              ru: 'Долгосрочная аренда жилья в Таиланде - Поиск объявлений и предложений на Bazaar. Квартиры и дома для проживания на длительный срок по доступным ценам',
              en: 'Long-term rental housing in Thailand - Search for advertisements and offers on Bazaar. Apartments and houses for long-term living at affordable prices',
            },
          },
          commercial: {
            Title: {
              ru: 'Коммерческая недвижимость в ',
              en: 'Commercial real estate in',
            },
            Description: {
              ru: 'Аренда и покупка коммерческого помещения в Таиланде - Поиск предложений и объявлений - На Bazaar представлен широкий выбор коммерческой недвижимости в Таиланде. Офисы, магазины, склады и другие объекты',
              en: 'Renting and purchasing commercial premises in Thailand - Search for offers and advertisements - Bazaar presents a wide selection of commercial real estate in Thailand. Offices, shops, warehouses and other facilities',
            },
          },
        },
        Job: {
          Title: {
            ru: 'Работа в Таиланде вакансии и резюме ',
            en: 'Work in Thailand vacancies and resumes',
          },
          Description: {
            ru: 'Поиск работы и сотрудников в Таиланде стал проще с Bazaar - У нас вы сможете найти множество вакансий и резюме в различных отраслях - открытые возможности для работы ',
            en: 'Finding jobs and employees in Thailand has become easier with Bazaar - Here you can find many vacancies and resumes in various industries - open job opportunities',
          },
          Search: {
            Title: {
              ru: 'Поиск работы, вакансии в ',
              en: 'Job search, vacancies in',
            },
            Description: {
              ru: 'Ищете работу в Таиланде? Найдите вакансию на Bazaar, либо разместите своё резюме - Широкий выбор предложений от работодателей в',
              en: 'Looking for work in Thailand? Find a vacancy on Bazaar, or post your resume - Wide selection of offers from employers in',
            },
          },
          Offer: {
            Title: {
              ru: 'Поиск сотрудников, резюме в',
              en: 'Search for employees, resumes in',
            },
            Description: {
              ru: 'Найти работников и сотрудников в Таиланде с помощью Bazaar - Резюме сотрудников и специалистов для вашей компании и ваших проектов',
              en: 'Find workers and employees in Thailand using Bazaar - Resumes of employees and specialists for your company and your projects',
            },
          },
        },
        Personal: {
          Title: {
            ru: 'Купить вещи в  ',
            en: 'Buy things in',
          },
          Description: {
            ru: 'Купить вещи в Таиланде на Bazaar - От одежды до аксессуаров - найдите всё для своего стиля и комфорта, новые вещи и бу',
            en: 'Buy things in Thailand on Bazaar - From clothes to accessories - find everything for your style and comfort, new things and used',
          },
          Shoes: {
            Title: {
              ru: 'Купить туфли женские в ',
              en: 'Buy women`s shoes in',
            },
            Description: {
              ru: 'Широкий выбор женских туфель в Таиланде для работы, мероприятий и повседневной жизни - Найдите туфли в объявлениях на Bazaar. От классики до модных трендов в ',
              en: 'Wide selection of women`s shoes in Thailand for work, events and everyday life - Find shoes in the ads on Bazaar. From classics to fashion trends in',
            },
          },
          Sandals: {
            Title: {
              ru: 'Купить босоножки женские в',
              en: 'Buy women`s sandals at',
            },
            Description: {
              ru: 'Где найти женские босоножки в Таиланде? Воспользуйтесь поиском в на Bazaar - Стильные и удобные женские босоножки для лета в объявлениях в Таиланде по доступным ценам',
              en: 'Where to find women`s sandals in Thailand? Use the search in Bazaar - Stylish and comfortable women`s sandals for summer in advertisements in Thailand at affordable prices',
            },
          },
          Bootsandlowshoes: {
            Title: {
              ru: 'Купить ботинки и полуботинки женские в',
              en: 'Buy women`s boots and low shoes in',
            },
            Description: {
              ru: 'Где купить женские ботинки в Таиланде? В объявлениях на Bazaar представлен выбор женских ботонок и полуботинок для любой погоды и стиля. Объявления от пользователей в',
              en: 'Where to buy women`s boots in Thailand? Advertisements on Bazaar present a selection of women`s boots and low shoes for any weather and style. Advertisements from users in',
            },
          },
          Kedy: {
            Title: {
              ru: 'Купить кроссовки и кеды женские в',
              en: 'Buy women`s sneakers and sneakers in',
            },
            Description: {
              ru: 'Ищите в Таиланде удобные кроссовки или стильные кеды? Просмотрите объявления на Bazaar чтобы получить обувь которая вам подойдет - Bazaar продажа и покупка вещей в',
              en: 'Looking for comfortable sneakers or stylish sneakers in Thailand? Browse ads on Bazaar to get shoes that suit you - Bazaar selling and buying things in ',
            },
          },
          Ballet: {
            Title: {
              ru: 'Купить балетки женские в ',
              en: 'Buy women`s ballet shoes in ',
            },
            Description: {
              ru: 'Удобные женские балетки в Таиланде доступны к поиску и покупке объявлениях на Bazaar. Идеальный и надежный выбор для прогулок и мероприятий в ',
              en: 'Comfortable women`s ballet shoes in Thailand are available for search and purchase in advertisements on Bazaar. An ideal and reliable choice for outings and activities in',
            },
          },
          ClogsAndMules: {
            Title: {
              ru: 'Купить сабо и мюли женские в ',
              en: 'Buy women`s clogs and mules in',
            },
            Description: {
              ru: 'Где купить женские сабо в Таиланде? Воспользуйтесь поиском объявлений от Bazaar и найдите сабо и мюли в различных стилях. Идеальный выбор для повседневной носки в',
              en: 'Where to buy women`s clogs in Thailand? Use Bazaar`s classifieds search to find clogs and mules in a variety of styles. Ideal choice for everyday wear in ',
            },
          },
          Rubber: {
            Title: {
              ru: 'Купить резиновую обувь женскую в ',
              en: 'Buy women`s rubber shoes in',
            },
            Description: {
              ru: 'Найдите подходящую женскую резиновую обувь в Таиланде для дождливой погоды в объявлениях на Bazaar. Большой выбор надежной обуви от продавцов в ',
              en: 'Find suitable women`s rubber shoes in Thailand for rainy weather in the classifieds on Bazaar. Large selection of reliable shoes from sellers in',
            },
          },
          moccasins: {
            Title: {
              ru: 'Купить мокасины и лоферы женские в ',
              en: 'Buy women`s moccasins and loafers in',
            },
            Description: {
              ru: 'Где найти женские мокасины в Таиланде? Широкий ассортимент женских мокасин и лоферов для комфорта и стиля в объявлениях на Bazaar. Отличный выбор для покупки в ',
              en: 'Where to find women`s moccasins in Thailand? A wide range of women`s moccasins and loafers for comfort and style in the ads on Bazaar. Great choice for shopping in',
            },
          },
          Slippers: {
            Title: {
              ru: 'Купить шлёпанцы и сланцы женские в',
              en: 'Buy slippers and flip-flops for women in',
            },
            Description: {
              ru: 'Где купить шлёпанцы в Таиланде? Лучший выбор женских шлёпанцев и сланцев для пляжа или дома доступен в объявлениях на Bazaar. Найдите свою идеальную пару в ',
              en: 'Where to buy flip-flops in Thailand? The best selection of women`s flip-flops and flip-flops for the beach or at home is available in the ads on Bazaar. Find your perfect match in',
            },
          },
          indoorshoes: {
            Title: {
              ru: 'Купить домашнюю обувь женскую в ',
              en: 'Buy women`s indoor shoes in ',
            },
            Description: {
              ru: 'Поиск женской обуви для дома в Таиланде - Найдите удобную женскую домашнюю обувь в объявлениях на Bazaar. Большой выбор тапочек и другой уютной обуви для дома в ',
              en: 'Search for women`s indoor shoes in Thailand - Find comfortable women`s indoor shoes in the ads on Bazaar. Large selection of slippers and other cozy footwear for home in',
            },
          },
          sportsshoes: {
            Title: {
              ru: 'Купить спортивную обувь женскую в ',
              en: 'Buy women`s sports shoes in ',
            },
            Description: {
              ru: 'Спортивная женская обувь в Таиланде - Широкий ассортимент женской спортивной обуви для любых видов спорта доступен в объявлениях на Bazaar. Найдите идеальные кроссовки или кеды в ',
              en: 'Women`s sports shoes in Thailand - A wide range of women`s sports shoes for all sports are available in the advertisements on Bazaar. Find the perfect sneakers in ',
            },
          },
          shoecareproducts: {
            Title: {
              ru: 'Средства для ухода за женской обувью в ',
              en: 'Women`s shoe care products in ',
            },
            Description: {
              ru: 'Все необходимое для ухода за женской обувью в Таиланде доступно в объявлениях на Bazaar. Найдите качественные средства по уходу от продавцов и пользователей ',
              en: 'Everything you need to care for women`s shoes in Thailand is available in ads on Bazaar. Find quality care products from sellers and users in ',
            },
          },
          Mensclothing: {
            Title: {
              ru: 'Купить одежду мужскую в ',
              en: 'Buy men`s clothing in ',
            },
            Description: {
              ru: 'Найдите в Таиланде мужскую одежду среди частных объявлений на Bazaar. Возможность выбрать из множества стилей и размеров одежды в Таиланде – от повседневной одежды до деловых костюмов',
              en: 'Find men`s clothing in Thailand among private advertisements on Bazaar. Choose from a variety of clothing styles and sizes in Thailand - from casual wear to business suits',
            },
          },
          Tshirtsformen: {
            Title: {
              ru: 'Купить кофты и футболки мужские  в Таиланде | Bazaar',
              en: 'Buy sweatshirts and T-shirts for men in',
            },
            Description: {
              ru: 'Поиск объявлений о продаже мужских кофт и футболок в Таиланде на Bazaar. Огромный выбор как для спортивных, так и повседневных вещей, доступных в ',
              en: 'Search for advertisements for the sale of men`s sweaters and T-shirts in Thailand on Bazaar. Huge selection of both sports and casual items available in ',
            },
          },
          mensjacketsandsuits: {
            Title: {
              ru: 'Купить пиджаки и костюмы мужские в',
              en: 'Buy men`s jackets and suits in ',
            },
            Description: {
              ru: 'Ищете праздничную и деловую одежду в Таиланде? На Bazaar вы найдете широкий выбор мужских пиджаков и костюмов - Отличное качество и разнообразие стилей в',
              en: 'Looking for party and business wear in Thailand? At Bazaar you will find a wide selection of men`s jackets and suits - Excellent quality and variety of styles in ',
            },
          },
          mensjeans: {
            Title: {
              ru: 'Купить джинсы мужские в ',
              en: 'Buy men`s jeans in ',
            },
            Description: {
              ru: 'Поиск мужских джинс в Таиланде - На Bazaar представлены разнообразные модели в объявлениях от частных продавцов в Таиланде, от классики до новых джинс',
              en: 'Search for men`s jeans in Thailand - Bazaar presents a variety of models in ads from private sellers in Thailand, from classics to new jeans',
            },
          },
          menstrousers: {
            Title: {
              ru: 'Купить брюки мужские в',
              en: 'Buy men`s trousers in',
            },
            Description: {
              ru: 'Поиск мужских брюк в Таиланде начинается с объявлений на Bazaar. Найдите подходящую пару среди частных объявлений, от офисных вариантов до повседневных моделей в',
              en: 'The search for men`s trousers in Thailand begins with advertisements on Bazaar. Find the right match among classified advertisements, from office options to casual styles in ',
            },
          },
          mensshirts: {
            Title: {
              ru: 'Купить рубашки мужские в',
              en: 'Buy men`s shirts in ',
            },
            Description: {
              ru: 'Поиск и покупка мужских рубашек в Таиланде - Купить рубашку через объявления на Bazaar. От бизнес-стиля до повседневных моделей, предложения от частных продавцов в',
              en: 'Search and purchase of men`s shirts in Thailand - Buy a shirt through advertisements on Bazaar. From business style to casual styles, offers from private sellers in',
            },
          },
          menstracksuits: {
            Title: {
              ru: 'Купить спортивные костюмы мужские в',
              en: 'Buy men`s tracksuits in',
            },
            Description: {
              ru: 'Поиск и покупка мужских спортивных костюмов в Таиланде в объявлениях на Bazaar. Удобство и стиль для спорта и отдыха от частных продавцов в',
              en: 'Search and purchase men`s tracksuits in Thailand in advertisements on Bazaar. Comfort and style for sports and leisure from private sellers in',
            },
          },
          mensshorts: {
            Title: {
              ru: 'Купить шорты мужские в',
              en: 'Buy men`s shorts in ',
            },
            Description: {
              ru: 'Широкий выбор мужских шорт в Таиланде для любого случая в объявлениях на Bazaar. Найдите идеальную шорты для лета, спорта или отдыха в',
              en: 'A wide selection of men`s shorts in Thailand for any occasion in the ads on Bazaar. Find the perfect shorts for summer, sports or holiday in',
            },
          },
          mensunderwear: {
            Title: {
              ru: 'Купить нижнее бельё мужское в',
              en: 'Buy men`s underwear in',
            },
            Description: {
              ru: 'Поиск мужского нижнего белья в Таиланде через объявления на Bazaar. Комфорт и качество от частных продавцов и магазинов в Таиланде – от классических до современных моделей',
              en: 'Search for men`s underwear in Thailand through advertisements on Bazaar. Comfort and quality from private sellers and shops in Thailand - from classic to modern models',
            },
          },
          beautyandhealth: {
            Title: {
              ru: 'Купить товары для красоты и здоровье в',
              en: 'Buy beauty and health products in ',
            },
            Description: {
              ru: 'Купить товары для красоты и здоровья на Bazaar - Косметика, парфюмерия, уходовые средства, средства для здоровья, подарочные наборы',
              en: 'Buy beauty and health products on Bazaar - Cosmetics, perfumes, care products, health products, gift sets',
            },
          },
          womensclothing: {
            Title: {
              ru: 'Купить одежду женскую в',
              en: 'Buy women`s clothing in',
            },
            Description: {
              ru: 'Поиск и покупка женской одежды в Таиланде -  От элегантных платьев до удобной повседневной одежды в объявлениях от продавцов на Bazaar в',
              en: 'Find and buy women`s clothing in Thailand - From elegant dresses to comfortable casual clothes, ads from sellers on Bazaar in',
            },
          },
          womensdresses: {
            Title: {
              ru: 'Купить платья женские в ',
              en: 'Buy women`s dresses in',
            },
            Description: {
              ru: 'Поиск и покупка платья в Таиланде для любого случая в объявлениях на Bazaar. Большой выбор женских платьев от вечерних до повседневных, предложенных продавцами и магазинами в',
              en: 'Search and buy dresses in Thailand for any occasion in the ads on Bazaar. A large selection of women`s dresses from evening to casual, offered by sellers and shops in ',
            },
          },
          womensskirts: {
            Title: {
              ru: 'Купить юбки женские в ',
              en: 'Buy women`s skirts in ',
            },
            Description: {
              ru: 'Широкий ассортимент женских юбок в Таиланде для каждого стиля и случая в объявлениях на Bazaar. От классических до модных моделей от продавцов и магазинов в ',
              en: 'A wide range of women`s skirts in Thailand for every style and occasion in the advertisements on Bazaar. From classic to fashion styles from sellers and shops in ',
            },
          },
          jumperssweaterscardigans: {
            Title: {
              ru: 'Купить джемперы, свитеры, кардиганы женские в',
              en: 'Buy jumpers, sweaters, cardigans for women in ',
            },
            Description: {
              ru: 'Где в Таиланде купить уютные и стильные женские джемперы, свитеры и кардиганы? Найдите их в объявлениях на Bazaar. Одежда для любого сезона в',
              en: 'Where can you buy cozy and stylish women`s jumpers, sweaters and cardigans in Thailand? Find them in advertisements on Bazaar. Clothing for any season in Thailand',
            },
          },
          womensjackets: {
            Title: {
              ru: 'Купить пиджаки и костюмы женские в',
              en: 'Buy women`s jackets and suits in ',
            },
            Description: {
              ru: 'Покупка пиджаков в Таиланде - Элегантные женские пиджаки и костюмы для офиса и особых случаев. Большой выбор от частных продавцов в Таиланде доступен в объявлениях на Bazaar',
              en: 'Buying jackets in Thailand - Elegant women`s jackets and suits for the office and special occasions. A large selection from private sellers in Thailand is available in advertisements on Bazaar',
            },
          },
          womenstrousers: {
            Title: {
              ru: 'Купить брюки женские в ',
              en: 'Buy women`s trousers in',
            },
            Description: {
              ru: 'Купить женские брюки в Таиланде - Разнообразие стилей женских брюк для работы и отдыха - От классики до современных моделей, все доступно в объявлениях на Bazaar от продавцов в ',
              en: 'Buy women`s trousers in Thailand - A variety of styles of women`s trousers for work and leisure - From classic to modern models, everything is available in ads on Bazaar from sellers in',
            },
          },
          womensjeans: {
            Title: {
              ru: 'Купить джинсы женские в ',
              en: 'Buy women`s jeans in',
            },
            Description: {
              ru: 'Купить женские джинсы в Таиланде для любого стиля в объявлениях на Bazaar - Выбирайте из множества фасонов и расцветок от продавцов и магазинов в ',
              en: 'Buy women`s jeans in Thailand for any style in the classifieds on Bazaar - Choose from a variety of styles and colors from sellers and stores in ',
            },
          },
          womensunderwear: {
            Title: {
              ru: 'Купить нижнее бельё женское в ',
              en: 'Buy women`s underwear in ',
            },
            Description: {
              ru: 'Большой выбор женского нижнего белья в Таиланде - Для комфорта и красоты можно найти в объявлениях от пользователей и магазинов на Bazaar в',
              en: 'A large selection of women`s underwear in Thailand - For comfort and beauty can be found in ads from users and stores on Bazaar in ',
            },
          },
          womenswedding: {
            Title: {
              ru: 'Купить свадебные платья женские в ',
              en: 'Buy women`s wedding dresses in ',
            },
            Description: {
              ru: 'Найти и купить свадебное платье, взять в аренду свадебное платье в Таиланде. Помощь в подготовке к свадьбе окажет вам наш поиск объявлений в ',
              en: 'Find and buy a wedding dress, rent a wedding dress in Thailand. Our search for advertisements in',
            },
          },
          shirtsandblouses: {
            Title: {
              ru: 'Купить рубашки и блузки женские в ',
              en: 'Buy women`s shirts and blouses in ',
            },
            Description: {
              ru: 'Купит блузки и рубашки в Таиланде - Элегантные и повседневные женские рубашки и блузки. Идеальные варианты для работы и отдыха от продавцов в объявлениях в ',
              en: 'Buy blouses and shirts in Thailand - Elegant and casual women`s shirts and blouses. Ideal options for work and leisure from sellers in advertisements in ',
            },
          },
          womenstopsandtshirts: {
            Title: {
              ru: 'Купить топы и футболки женские в ',
              en: 'Buy women`s tops and t-shirts in ',
            },
            Description: {
              ru: 'Стильные женские топы и футболки в Таиланде для любого случая. Большой выбор для покупки от повседневных вариантов до элегантных моделей в объявлениях в ',
              en: 'Stylish women`s tops and t-shirts in Thailand for any occasion. Large selection for purchase from casual options to elegant models in the ads in',
            },
          },
          womensswimwear: {
            Title: {
              ru: 'Купить купальники женские в ',
              en: 'Buy women`s swimwear in ',
            },
            Description: {
              ru: 'Где купить женские купальники в Таиланде? Покупка купальников для пляжа и бассейна. Разнообразие стилей и размеров в объявлениях на Bazaar от продавцов в',
              en: 'Where to buy wome`s swimwear in Thailand? Buying swimwear for the beach and pool. Variety of styles and sizes in Bazaar listings from sellers in',
            },
          },
          accessoriesaccessories: {
            Title: {
              ru: 'Купить аксессуары в',
              en: 'Buy accessories in',
            },
            Description: {
              ru: 'Большой выбор аксессуаров в Таиланде на любой вкус в объявлениях на Bazaar. От сумок до кошельков, найдите идеальное дополнение к вашему образу в',
              en: 'A large selection of accessories in Thailand for every taste in the ads on Bazaar. From bags to wallets, find the perfect complement to your outfit in ',
            },
          },
          hats: {
            Title: {
              ru: 'Купить головные уборы в ',
              en: 'Buy hats in ',
            },
            Description: {
              ru: 'Где в Таиланде купить головные уборы? Воспользуйтесь поиском объявлений на Bazaar и найдите головной убор, который подойдет именно вам - Шапки, кепки, панамы и многое другое от пользователе в ',
              en: 'Where to buy hats in Thailand? Use the Bazaar classifieds search and find the headwear that suits you - Hats, caps, Panama hats and more from a user in',
            },
          },
          glasses: {
            Title: {
              ru: 'Купить очки в',
              en: 'Buy glasses in',
            },
            Description: {
              ru: 'Где в Таиланде купит очки? Найдите очки для защиты или стиля в объявлениях на Bazaar. Широкий выбор солнцезащитных и корректирующих очков в ',
              en: 'Where can you buy glasses in Thailand? Find glasses for protection or style in the classifieds on Bazaar. Wide selection of sunglasses and prescription glasses in ',
            },
          },
          belts: {
            Title: {
              ru: 'Купить ремни, пояса, подтяжки в ',
              en: 'Buy belts, belts, suspenders in',
            },
            Description: {
              ru: 'Ремни и пояса в Таиланде - Широкий выбор ремней, поясов и подтяжек в объявлениях на Bazaar. Идеальные аксессуары для завершения вашего образа от продавцов в',
              en: 'Belts and sashes in Thailand - Wide selection of belts, sashes and suspenders in ads on Bazaar. The perfect accessories to complete your look from retailers in ',
            },
          },
          jewelry: {
            Title: {
              ru: 'Купить ювелирные украшения в',
              en: 'Buy jewelry in',
            },
            Description: {
              ru: 'Поиск ювелирных украшений в Таиланде? От кольц до сережек, золото, серебро и бижютерия - все доступно в объявлениях на Bazaar от продавцов в ',
              en: 'Looking for jewelry in Thailand? From rings to earrings, gold, silver and jewelry - everything is available in ads on Bazaar from sellers in',
            },
          },
          watches: {
            Title: {
              ru: 'Купить часы в ',
              en: 'Buy watches in',
            },
            Description: {
              ru: 'Купит наручные часы в Таиланде - Широкий выбор часов на любой вкус и с доступным бюджетои в объявлениях от пользователей на Bazaar. Найдите часы для себя или в подарок в ',
              en: 'Buy a wristwatch in Thailand - A wide selection of watches for every taste and with an affordable budget in ads from users on Bazaar. Find a watch for yourself or as a gift in',
            },
          },
          umbrellas: {
            Title: {
              ru: 'Купить зонты и дождевики в',
              en: 'Buy umbrellas and raincoats in',
            },
            Description: {
              ru: 'Купить зонт в Таиланде - Подготовьтесь к дождливой погоде с помощью зонтов и дождевиков в объявлениях на Bazaar. Подготовьтесь к сезону дождей в ',
              en: 'Buy an umbrella in Thailand - Prepare for rainy weather with umbrellas and raincoats in the ads on Bazaar. Prepare for the rainy season in',
            },
          },
          HairAccessories: {
            Title: {
              ru: 'Купить аксессуары для волос в ',
              en: 'Buy Hair Accessories in',
            },
            Description: {
              ru: 'Аксессуары для волос в Таиланде - Найдите все необходимое для создания идеальной прически. Широкий выбор аксессуаров для волос вы найдете в объявлениях на Bazaar от пользователей в ',
              en: 'Hair Accessories in Thailand - Find everything you need to create the perfect hairstyle. You will find a wide selection of hair accessories in ads on Bazaar from users in',
            },
          },
          tiesandbow: {
            Title: {
              ru: 'Купить галстуки и бабочки в ',
              en: 'Buy ties and bow ties in',
            },
            Description: {
              ru: 'Купить галстук в Таиланде - Поиск и покупка галстуков и бабочек для официальных мероприятий и деловых встреч в объявлениях на Bazaar. Аксессуары от продавцов в ',
              en: 'Buy a tie in Thailand - Search and purchase ties and bow ties for formal events and business meetings in advertisements on Bazaar. Accessories from sellers in ',
            },
          },
          stockings: {
            Title: {
              ru: 'Купить носки, чулки, колготки в ',
              en: 'Buy socks, stockings, tights in',
            },
            Description: {
              ru: 'Купить носки и чулки в Таиланде - Воспользуйтесь поиском объявлений на Bazaar - Выбор носков, чулок и колготок для любого случая и стиля от пользователей в ',
              en: 'Buy socks and stockings in Thailand - Use the ad search on Bazaar - A selection of socks, stockings and tights for any occasion and style from users in ',
            },
          },
          otheraccessories: {
            Title: {
              ru: 'Купить другое в',
              en: 'Buy other in',
            },
            Description: {
              ru: 'Различная одежда и аксессуары в Таиланде - Поиск объявлений о продаже вещей на Bazaar. Найдите интересные для вас вещи и товары в ',
              en: 'Various clothes and accessories in Thailand - Search for advertisements for the sale of items on Bazaar. Find things and goods that interest you in ',
            },
          },

          Clothes: {
            Title: {
              ru: 'Купить вещи в',
              en: 'Buy things in',
            },
            Description: {
              ru: 'Купить вещи в Таиланде на Bazaar - От одежды до аксессуаров - найдите всё для своего стиля и комфорта, новые вещи и бу',
              en: 'Buy things in Thailand on Bazaar - From clothes to accessories - find everything for your style and comfort, new things and used',
            },
          },
          Womenshoes: {
            Title: {
              ru: 'Купить обувь женскую в',
              en: 'Buy women`s shoes in',
            },
            Description: {
              ru: 'Где купить женскую обувь в Таиланде? Найдите в объявлениях на Bazaar нужную обувь для вас. Поиск и покупка женской обуви по доступной цене в',
              en: 'Where to buy women`s shoes in Thailand? Find the shoes you need in the ads on Bazaar. Search and purchase women`s shoes at an affordable price in',
            },
          },
          Childrensclothing: {
            Title: {
              ru: 'Купить детскую одежду и обувь',
              en: 'Buy children\'s clothes and shoes',
            },
            Description: {
              ru: 'Купить детскую одежду и обувь в Таиланде на Bazaar - Новые и бывшие в употреблении вещи для малышей и детей',
              en: 'Buy things in Thailand on Bazaar - From clothes to accessories - find everything for your style and comfort, new things and used',
            },
          },
          Childrensgoods: {
            Title: {
              ru: 'Купить товары для детей и игрушки в',
              en: 'Buy goods for children and toys at',
            },
            Description: {
              ru: 'Купить товары для игр и развития вашего ребенка - игрушки и товары для детей в Таиланде на Bazaar новые и бывшие в употреблении вещи ',
              en: 'Buy goods for your child’s games and development - toys and goods for children in Thailand on Bazaar, new and used items',
            },
          },
          Beauty: {
            Title: {
              ru: 'Купить товары для красоты и здоровье в',
              en: 'Buy beauty and health products at',
            },
            Description: {
              ru: 'Купить товары для красоты и здоровья на Bazaar - Косметика, парфюмерия, уходовые средства, средства для здоровья, подарочные наборы',
              en: 'Buy beauty and health products on Bazaar - Cosmetics, perfumes, care products, health products, gift sets',
            },
          },
          Jewelry: {
            Title: {
              ru: 'Купить часы и украшения в ',
              en: 'Buy watches and jewelry in',
            },
            Description: {
              ru: 'Купить часы и украшения в Таиланде на Bazaar - новые и бывшие в употреблении часы, бижутерия, браслеты, аксессуары, найдите продавца в Таиланде с помощью сервиса Bazaar',
              en: 'Buy watches and jewelry in Thailand on Bazaar - new and used watches, jewelry, bracelets, accessories, find a seller in Thailand using the Bazaar service',
            },
          },
        },
        Jod: {
          Resume: {
            Title: {
              ru: 'Поиск сотрудников, резюме в',
              en: 'Search for employees, resumes in',
            },
            Description: {
              ru: 'Найти работников и сотрудников в Таиланде с помощью Bazaar - Резюме сотрудников и специалистов для вашей компании и ваших проектов',
              en: 'Find workers and employees in Thailand using Bazaar - Resumes of employees and specialists for your company and your projects',
            },
          },
          Vacancies: {
            Title: {
              ru: 'Поиск работы, вакансии в ',
              en: 'Job search, vacancies in',
            },
            Description: {
              ru: 'Ищете работу в Таиланде? Найдите вакансию на Bazaar, либо разместите своё резюме - Широкий выбор предложений от работодателей в',
              en: 'Looking for work in Thailand? Find a vacancy on Bazaar, or post your resume - Wide selection of offers from employers in',
            },
          },
        },
        Athome: {
          Title: {
            ru: 'Купить товары для дома в ',
            en: 'Buy household goods in',
          },
          Description: {
            ru: 'Купить товары для дома на Bazaar в Таиланде - от мебели до аксессуаров - Широкий ассортимент новых и подержанных товаров для уюта вашего дома',
            en: 'Buy home goods from Bazaar in Thailand - from furniture to accessories - Wide range of new and used products to make your home cozy',
          },
          Repair: {
            Title: {
              ru: 'Купить товары для ремонта и строительства в',
              en: 'Buy goods for repair and construction in',
            },
            Description: {
              ru: 'Купить всё для ремонта и строительства, объявления доступные на Bazaar - Материалы, инструменты, оборудование - новые и подержанные в',
              en: 'Buy everything for repair and construction, ads available on Bazaar - Materials, tools, equipment - new and used in',
            },
          },
          Furniture: {
            Title: {
              ru: 'Купить мебель и товары для интерьера в ',
              en: 'Buy furniture and interior goods in',
            },
            Description: {
              ru: 'Обновите свой дом с помощью мебели и товаров для интерьера на Bazaar - Разнообразие стилей и цен на новую и бывшую в употреблении мебель',
              en: 'Update your home with furniture and home decor products at Bazaar - Variety of styles and prices on new and used furniture',
            },
          },
          Appliances: {
            Title: {
              ru: 'Купить бытовую технику в',
              en: 'Buy household appliances in',
            },
            Description: {
              ru: 'Найти и купить бытовую технику на Bazaar в Таиланде - Широкий выбор новой и б/у техники для дома по доступным ценам',
              en: 'Find and buy household appliances at Bazaar in Thailand - Wide selection of new and used home appliances at affordable prices',
            },
          },
          Food: {
            Title: {
              ru: 'Купить продукты и еду в ',
              en: 'Buy groceries and food at',
            },
            Description: {
              ru: 'Купить или заказать продукты домой в Таиланде на Bazaar - Продукты питания, доставка еды, пищевые добавки, различные магазины, рестораны, кафе и другие точки общественного питания в',
              en: 'Buy or order groceries home in Thailand on Bazaar - Groceries, food delivery, nutritional supplements, various shops, restaurants, cafes and other catering outlets in',
            },
          },
          Plants: {
            Title: {
              ru: 'Купить растения для дома и сада в',
              en: 'Buy plants for home and garden in',
            },
            Description: {
              ru: 'Купить семена растений, саженцы, цветов, овощей, фруктов на Bazaar от продавцов и через объявления в Таиланде, декоративные и плодовые растения',
              en: 'Buy plant seeds, seedlings, flowers, vegetables, fruits on Bazaar from sellers and through advertisements in Thailand, ornamental and fruit plants',
            },
          },
          Kitchenware: {
            Title: {
              ru: 'Купить посуду и товары для кухни в',
              en: 'Buy dishes and kitchen goods in',
            },
            Description: {
              ru: 'Поиск продавцов, объявлений и покупка посуды и кухонных принадлежностей в Таиланде на Bazaar - Всё для комфорта и уюта на вашей кухни',
              en: 'Search for sellers, advertisements and purchase of dishes and kitchen utensils in Thailand on Bazaar - Everything for comfort and coziness in your kitchen',
            },
          },
        },

        Spareparts: {
          Title: {
            ru: 'Купить запчасти и аксессуары для транспорта в  ',
            en: 'Buy spare parts and accessories for transport in',
          },
          Description: {
            ru: 'Купить запчасти и аксессуары для автомобилей и мототехники на Bazaar - Найдите всё необходимое для ремонта и тюнинга в',
            en: 'Купить запчасти и аксессуары для автомобилей и мототехники на Bazaar - Найдите всё необходимое для ремонта и тюнинга в',
          },
          parts: {
            Title: {
              ru: 'Купить запчасти для автомобилей и мототехники в ',
              en: 'Buy spare parts for cars and motorcycles in',
            },
            Description: {
              ru: 'Найти и купить запчасти для любых автомобилей и мотоциклов в Таиланде легко на Bazaar - Широкий выбор новых и б/у запчастей для ремонта и обслуживания в объявлениях',
              en: 'Find and buy spare parts for any cars and motorcycles in Thailand easily on Bazaar - Wide selection of new and used spare parts for repair and maintenance in advertisements',
            },
          },
          tools: {
            Title: {
              ru: 'Купить инструменты для ремонта и обслуживания в',
              en: 'Buy tools for repair and maintenance at',
            },
            Description: {
              ru: 'Купить всё для ремонта и обслуживания автомобилей и мототехники - инструменты различного назначения на Bazaar в',
              en: 'Buy everything for the repair and maintenance of cars and motorcycles - tools for various purposes on Bazaar in',
            },
          },
          tires: {
            Title: {
              ru: 'Купить шины, диски и колёса в',
              en: 'Buy tires, rims and wheels at',
            },
            Description: {
              ru: 'Поиск и покупка шин, дисков и колёс для всех типов транспорта в Таиланде в объявлениях на Bazaar - Подберите идеальное оборудование для вашего автомобиля в ',
              en: 'Search and purchase tires, rims and wheels for all types of vehicles in Thailand in advertisements on Bazaar - Find the ideal equipment for your car in',
            },
          },
          trailers: {
            Title: {
              ru: 'Аренда и покупка прицепа для автомобиля в ',
              en: 'Rent and purchase of a car trailer in',
            },
            Description: {
              ru: 'Объявления о продаже или сдаче в аренду прицепов для автомобиля в Таиланде на Bazaar - Широкий выбор прицепов для разных нужд и грузов от частных лиц и компаний в',
              en: 'Advertisements for the sale or rental of car trailers in Thailand on Bazaar - A wide selection of trailers for different needs and loads from individuals and companies in',
            },
          },
          audiovideo: {
            Title: {
              ru: 'Купить вудио- и видеотехнику для автомобилей в',
              en: 'Buy audio and video equipment for cars in',
            },
            Description: {
              ru: 'Купить аудио- и видеотехнику в Таиланде для автомобиля на Bazaar - Магнитолы, саббуферы, видеорегистраторы, мультимедийные системы и аксессуары в объявлениях от продавцов в ',
              en: 'Buy audio and video equipment in Thailand for a car on Bazaar - Radio tape recorders, subwoofers, DVRs, multimedia systems and accessories in ads from sellers in',
            },
          },
          equipment: {
            Title: {
              ru: 'Купить экипировку для водителей и мотоциклистов в',
              en: 'Buy equipment for drivers and motorcyclists in',
            },
            Description: {
              ru: 'Поиск и покупка экипировки для водителей и мотоциклистов в Таиланде от продавцов в объявлениях на Bazaar - Защитные костюмы, шлемы и аксессуары на продажу и в аренду в ',
              en: 'Search and purchase equipment for drivers and motorcyclists in Thailand from sellers in advertisements on Bazaar - Protective suits, helmets and accessories for sale and rent in',
            },
          },
          accessories: {
            Title: {
              ru: 'Купить аксессуары для автомобиля в ',
              en: 'Buy car accessories at',
            },
            Description: {
              ru: 'Поиск и покупка автомобильных аксессуаров в Таиланде на Bazaar - Найдите в объявлениях необходимые аксессуары для комфорта и функциональности вашей машины',
              en: 'Search and purchase of car accessories in Thailand on Bazaar - Find in the advertisements the necessary accessories for the comfort and functionality of your car',
            },
          },
          oils: {
            Title: {
              ru: 'Купить масло и автохимия в ',
              en: 'Buy oil and auto chemicals in',
            },
            Description: {
              ru: 'Купить масла и жидкости для машин в Таиланде, ассортимент масел и автохимии для автомобилей доступен на Bazaar - Продукты для поддержания вашего авто в идеальном состоянии в ',
              en: 'Buy oils and fluids for cars in Thailand, a range of oils and auto chemicals for cars is available on Bazaar - Products to keep your car in perfect condition in',
            },
          },
          tuning: {
            Title: {
              ru: 'Услуги тюнинга автомобилей в',
              en: 'Car tuning services in',
            },
            Description: {
              ru: 'Преобразите свой автомобиль с помощью услуги тюнинга в Таиланде, найдите объявления на Bazaar - От спортивных деталей до стильных аксессуаров – все для индивидуальности вашего авто в ',
              en: 'Transform your car with a tuning service in Thailand, find ads on Bazaar - From sporty parts to stylish accessories - everything to personalize your car in',
            },
          },
          gps: {
            Title: {
              ru: 'Купить GPS-навигаторы в ',
              en: 'Buy GPS navigators in',
            },
            Description: {
              ru: 'Купить GPS-навигаторы в Таиланде для вашего автомобиля на Bazaar - Современные и точные устройства для легкости навигации по дорогам Таиланда',
              en: 'Buy GPS navigators in Thailand for your car on Bazaar - Modern and accurate devices for easy navigation on the roads of Thailand',
            },
          },
          luggage: {
            Title: {
              ru: 'Купить багажники и фаркопы для автомобилей в ',
              en: 'Buy luggage racks and towbars for cars in',
            },
            Description: {
              ru: 'Расширьте возможности вашего автомобиля с багажниками и фаркопами от Bazaar - Удобные и надежные решения для перевозки грузов в',
              en: 'Expand the capabilities of your car with luggage racks and towbars from Bazaar - Convenient and reliable solutions for transporting goods in',
            },
          },
        },
        Electronics: {
          Title: {
            ru: 'Электроника в ',
            en: 'Electronics in',
          },
          Description: {
            ru: 'Широкий выбор электроники в Таиланде на Bazaar: от смартфонов до бытовой техники. Найдите объявление продавцов для покупки всего необходимого для связи, развлечений и работы',
            en: 'Wide selection of electronics in Thailand on Bazaar: from smartphones to household appliances. Find sellers\' advertisements to purchase everything you need for communication, entertainment and work',
          },
          phones: {
            Title: {
              ru: 'Мобильные телефоны, смартфоны в',
              en: 'Mobile phones, smartphones in',
            },
            Description: {
              ru: 'Купить мобильные телефоны, смартфоны и другую технику в Таиланде - поиск объявлений на Bazaar - Выбирете из широкого ассортимента смартфонов и мобильных телефонов в',
              en: 'Buy mobile phones, smartphones and other equipment in Thailand - search ads on Bazaar - Choose from a wide range of smartphones and mobile phones in',
            },
          },
          photo: {
            Title: {
              ru: 'Купить фототехнику в',
              en: 'Buy photographic equipment in',
            },
            Description: {
              ru: 'Купить фототехнику в Таиланде для профессионалов и любителей - найдите объявления на Bazaar - От зеркальных фотоаппаратов до аксессуаров - все для фотосъемки в',
              en: 'Buy photographic equipment in Thailand for professionals and amateurs - find ads on Bazaar - From SLR cameras to accessories - everything for photography in',
            },
          },
          audiovideo: {
            Title: {
              ru: 'Аудио и видео техника в',
              en: 'Audio and video equipment in',
            },
            Description: {
              ru: 'Поиск и покупка аудио и видео оборудования в Таиланде для вас, вашего дома, работы или развлечений в объявлениях на Bazaar - Качественный звук и изображение с широким выбором техники в',
              en: 'Search and purchase audio and video equipment in Thailand for you, your home, work or entertainment in ads on Bazaar - High-quality sound and image with a wide selection of equipment in',
            },
          },
          tabletsebooks: {
            Title: {
              ru: 'Планшеты и электронные книги в',
              en: 'Tablets and e-readers in',
            },
            Description: {
              ru: 'Поиск и покупка планшета или электронной книги в Таиланде на Bazaar - Объявления от пользователей - Современные устройства для работы, учебы и отдыха в широком ассортименте в',
              en: 'Search and purchase a tablet or e-reader in Thailand on Bazaar - Advertisements from users - Modern devices for work, study and leisure in a wide range in',
            },
          },
          computergoods: {
            Title: {
              ru: 'Tовары для компьютера и ноутбуков в',
              en: 'Products for computers and laptops in',
            },
            Description: {
              ru: 'Всё для вашего компьютера и ноутбука в Таиланде на Bazaar - Поиск объявлений о продаже аксессуаров и комплектующих - широкий выбор товаров для повышения производительности в ',
              en: 'Everything for your computer and laptop in Thailand on Bazaar - Search for advertisements for the sale of accessories and components - a wide selection of products to increase productivity in',
            },
          },
          consumables: {
            Title: {
              ru: 'Оргтехника и расходники в',
              en: 'Office equipment and consumables in',
            },
            Description: {
              ru: 'Купить оргтехнику, картриджи, бумагу и другие расходные материалы в Таиланде - Поиск объявлений о продаже на Bazaar - Широкий ассортимент товаров и оргтехники в',
              en: 'Buy office equipment, cartridges, paper and other consumables in Thailand - Search for advertisements for sale on Bazaar - Wide range of goods and office equipment in',
            },
          },
          consoles: {
            Title: {
              ru: 'Игры и игровые приставки в',
              en: 'Games and game consoles in',
            },
            Description: {
              ru: 'Поиск и покупка игр и игровых приставок в Таиланде - Поиск объявлений на Bazaar - Sony Playstation, XBox, Nintendo, а также компьютерные игры в',
              en: 'Search and purchase of games and game consoles in Thailand - Search ads on Bazaar - Sony Playstation, XBox, Nintendo, as well as computer games in',
            },
          },
          notebooks: {
            Title: {
              ru: 'Ноутбуки в ',
              en: 'Laptops in',
            },
            Description: {
              ru: 'Найти и купить ноутбук в Таиланде - Поиск объявлений на Bazaar - Ноутбуки для работы, учебы или развлечений - Выбор современных ноутбуков в ',
              en: 'Find and buy a laptop in Thailand - Search ads on Bazaar - Laptops for work, study or entertainment - Selection of modern laptops in',
            },
          },
          desktops: {
            Title: {
              ru: 'Купить компьютер в',
              en: 'Buy a computer in',
            },
            Description: {
              ru: 'Купить ПК, настольный компьютер в Таиланде - Поиск объявлений на Bazaar - Для любых задач: от игр до работы - Стационарные компьютеры в Таиланде по выгодным ценам',
              en: 'Buy a PC, desktop computer in Thailand - Search ads on Bazaar - For any task: from games to work - Desktop computers in Thailand at competitive prices',
            },
          },
        },
        Hobbies: {
          Title: {
            ru: 'Хобби и отдых в  ',
            en: 'Hobbies and recreation in',
          },
          Description: {
            ru: 'Объявления о покупки товаров для хобби и отдыха в Таиланде - Поиск объявлений с товарами для хобби и отдыха на Bazaar - Все для активного отдыха, творчества и увлечений в ',
            en: 'Advertisements for the purchase of goods for hobbies and recreation in Thailand - Search for advertisements for goods for hobbies and recreation on Bazaar - Everything for active recreation, creativity and hobbies in',
          },
          tickets: {
            Title: {
              ru: 'Билеты и путешествия в',
              en: 'Tickets and travel to',
            },
            Description: {
              ru: 'Поиск билетов в Таиланде на Bazaar: билеты на мероприятия, туры и экскурсии - Все для незабываемых путешествий и ярких впечатлений в',
              en: 'Search for tickets in Thailand on Bazaar: tickets for events, tours and excursions - Everything for unforgettable travel and vivid impressions in',
            },
          },
          sports: {
            Title: {
              ru: 'Tовары для спорта и отдыха в ',
              en: 'goods for sports and recreation in',
            },
            Description: {
              ru: 'Поиск товаров для спорта и активного отдыха в Таиланде на Bazaar - Оборудование, одежда и аксессуары для всех видов спорта в ',
              en: 'Search for sports and outdoor products in Thailand on Bazaar - Equipment, clothing and accessories for all sports in',
            },
          },
          bikes: {
            Title: {
              ru: 'Купить велосипед в',
              en: 'Buy a bike in                            ',
            },
            Description: {
              ru: 'Велосипеды для города и спорта в Таиланде - Поиск объявлений на Bazaar о продаже велосипедов для взрослых и детей в',
              en: 'Bicycles for the city and sports in Thailand - Search for ads on Bazaar for the sale of bicycles for adults and children in',
            },
          },
          books: {
            Title: {
              ru: 'Книги и журналы в ',
              en: 'Books and magazines in',
            },
            Description: {
              ru: 'Купить книги и журналы в Таиланде - Поиск объявлений на Bazaar - Книги разных жанров, учебники, научные работы и глянцевые издания в',
              en: 'Buy books and magazines in Thailand - Search ads on Bazaar - Books of different genres, textbooks, scientific papers and glossy publications in',
            },
          },
          collecting: {
            Title: {
              ru: 'Товары для коллекционирования в',
              en: 'Collectibles in',
            },
            Description: {
              ru: 'Купить товары и предметы для коллекционирования в Таиланде - Поиск объявлений на Bazaar. Монеты, марки, антиквариат и другие коллекционные вещи в',
              en: 'Buy goods and collectibles in Thailand - Search ads on Bazaar. Coins, stamps, antiques and other collectibles in',
            },
          },
          musicalinstruments: {
            Title: {
              ru: 'Музыкальные инструменты в ',
              en: 'Musical instruments in',
            },
            Description: {
              ru: 'Купить гитару, скрипку, барабан в Таиланде - Поиск объявлений на Bazaar. Музыкальные инструменты и комплектующие к ним в ',
              en: 'Buy a guitar, violin, drum in Thailand - Search ads on Bazaar. Musical instruments and accessories for them in',
            },
          },
          huntingfishing: {
            Title: {
              ru: 'Товары для охоты и рыбалки в ',
              en: 'Goods for hunting and fishing in',
            },
            Description: {
              ru: 'Купить товары для охоты и рыбалки в Таиланде - Объявления о продаже на Bazaar. Снаряжение, экипировка, приманки и аксессуары для любителей активного отдыха на природе в ',
              en: 'Buy goods for hunting and fishing in Thailand - Advertisements for sale on Bazaar. Equipment, equipment, bait and accessories for lovers of active outdoor recreation in',
            },
          },
        },
        Animals: {
          Title: {
            ru: ' Животные в',
            en: 'Animals in',
          },
          Description: {
            ru: 'Купить домашних животных и питомцев в Таиланде - Поиск объявлений о продаже питомцев на Bazaar. От собак и кошек до экзотических питомцев в ',
            en: 'Buy pets and pets in Thailand - Search for advertisements for the sale of pets on Bazaar. From dogs and cats to exotic pets in',
          },
          dogs: {
            Title: {
              ru: 'Купить собаку в ',
              en: 'Buy a dog in',
            },
            Description: {
              ru: 'Купить собаку в Таиланде - Объявление о покупке или продаже собак на Bazaar. Найдите щенка или взрослую собаку, которая станет вашим верным другом в ',
              en: 'Купить собаку в Таиланде - Объявление о покупке или продаже собак на Bazaar. Найдите щенка или взрослую собаку, которая станет вашим верным другом в ',
            },
          },
          cats: {
            Title: {
              ru: 'Купить кошку в ',
              en: 'Buy a cat in',
            },
            Description: {
              ru: 'Купить кошку или котёнка в Таиланде - Поиск объявлений от пользователей на Bazaar - Найдите пушистого друга для вашего дома в',
              en: 'Buy a cat or kitten in Thailand - Search ads from users on Bazaar - Find a furry friend for your home in',
            },
          },
          birds: {
            Title: {
              ru: 'Купить птиц в ',
              en: 'Buy birds at',
            },
            Description: {
              ru: 'Купить домашних птиц в Таиланде - Поиск объявлений на Bazaar - Канарейки, экзотические попугаи, курицы и другие птицы - найдите пернатого питомца для вашего дома или домашнюю птицу в',
              en: 'Buy poultry in Thailand - Search ads on Bazaar - Canaries, exotic parrots, chickens and other birds - find a feathered pet for your home or poultry in',
            },
          },
          aquarium: {
            Title: {
              ru: 'Аквариумные рыбки и оборудование в',
              en: 'Aquarium fish and equipment in',
            },
            Description: {
              ru: 'Оборудование для аквариума в Таиланде - Поиск объявлений на Bazaar. Аквариумные рыбки, растения, оборудование и аксессуары в',
              en: 'Aquarium equipment in Thailand - Search ads on Bazaar. Aquarium fish, plants, equipment and accessories in',
            },
          },
          otherpets: {
            Title: {
              ru: 'Купить других животных в',
              en: 'Buy other animals in',
            },
            Description: {
              ru: 'Купить экзотических и необычных домашних животных в Таиланде - Поиск объявлений на Bazaar. От грызунов до рептилий - уникальные питомцы для любителей животных в',
              en: 'Buy exotic and unusual pets in Thailand - Search ads on Bazaar. From rodents to reptiles, unique pets for animal lovers in',
            },
          },
          petsupplies: {
            Title: {
              ru: 'Товары для животных в',
              en: 'Pet products in',
            },
            Description: {
              ru: 'Купить товары для ухода за питомцами в Таиланде - Поиск объявлений на Bazaar. Корма, аксессуары, игрушки и уходовая продукция для здоровья и счастья ваших животных в ',
              en: 'Buy pet care products in Thailand - Search ads on Bazaar. Food, accessories, toys and care products for the health and happiness of your animals in',
            },
          },
        },
        Business: {
          Title: {
            ru: ' Бизнес и оборудование в',
            en: 'Business and equipment in',
          },
          Description: {
            ru: 'Купить оборудование для вашего бизнеса в Таиланде - Поиск объявлений на Bazaar. Широкий ассортимент инструментов и материалов для развития и поддержки бизнеса в',
            en: 'Buy equipment for your business in Thailand - Search ads on Bazaar. A wide range of tools and materials for business development and support in',
          },
          readybusiness: {
            Title: {
              ru: 'Готовый бизнес в',
              en: 'Ready business in',
            },
            Description: {
              ru: 'Предложения о покупке готового бизнеса в Таиланде - Поиск объявлений на Bazaar. Различные варианты для предпринимательской деятельности в',
              en: 'Offers to purchase a ready-made business in Thailand - Search for advertisements on Bazaar. Various options for business activities in',
            },
          },
          equipment: {
            Title: {
              ru: 'Оборудование для бизнеса в',
              en: 'Equipment for business in',
            },
            Description: {
              ru: 'Купить оборудование для вашего бизнеса в Таиланде - Поиск объявлений на Bazaar. Эффективные решения для производства, торговли и услуг в ',
              en: 'Buy equipment for your business in Thailand - Search ads on Bazaar. Effective solutions for production, trade and services in',
            },
          },
        },
        Services: {
          Title: {
            ru: 'Услуги и помощь в Таиланде - Поиск специалистов ',
            en: 'Services and assistance in Thailand - Search for specialists',
          },
          Description: {
            ru: 'Поиск специалистов, мастеров, компаний, организаций в Таиланде для ваших потребностей - На Bazaar вы сможете найти любую услугу, либо предложить свои услуги в',
            en: 'Search for specialists, craftsmen, companies, organizations in Thailand for your needs - On Bazaar you can find any service, or offer your services in',
          },
          Logistics: {
            Title: {
              ru: 'Услуги грузоперевозки в',
              en: 'Freight transportation services in',
            },
            Description: {
              ru: 'Найдите лучшее предложение по оказанию услуг грузоперевозки в Таиланде на Bazaar - Идеальное решение для поиска перевозчиков мебели, вещей, крупногабаритных грузов и других предметов, подходящих для переездов',
              en: 'Find the best offer for moving services in Thailand on Bazaar - The ideal solution for finding carriers for furniture, belongings, large cargo and other items suitable for moving ',
            },
          },
          maintenance: {
            Title: {
              ru: 'Ремонт и обслуживание техники в',
              en: 'Repair and maintenance of equipment in',
            },
            Description: {
              ru: 'Ремонт электроники и обслуживание техники в Таиланде в объявлениях от мастеров на Bazaar - Частные объявления по ремонту техники в Таиланде. Качественный сервис по ремонту различной техники ',
              en: 'Electronics repair and equipment maintenance in Thailand in advertisements from craftsmen on Bazaar - Private advertisements for equipment repair in Thailand. High-quality repair service for various equipment',
            },
          },
          Delivery: {
            Title: {
              ru: 'Доставка еды и продуктов в ',
              en: 'Delivery of food and groceries in ',
            },
            Description: {
              ru: 'Bazaar предлагает услуги по доставке продуктов и блюд по всем городам Таиланда. На Bazaar пользователи оставляют свои предложения о доставке еды для вас - Широкий выбор продуктов, быстрая доставка и выгодные предложения',
              en: 'Bazaar offers food and grocery delivery services to all cities in Thailand. On Bazaar, users leave their offers for food delivery for you - Wide selection of products, fast delivery and great deals',
            },
          },
          Construction: {
            Title: {
              ru: 'Услуги по строительству в ',
              en: 'Construction services in',
            },
            Description: {
              ru: 'Найдите лучших специалистов, оказывающих строительные услуги в Таиланде на Bazaar - От ремонта до полноценного строительства - Поиск строителей и мастеров для ваших проектов и задач',
              en: 'Find the best specialists providing construction services in Thailand on Bazaar - From renovation to full construction - Find builders and craftsmen for your projects and tasks',
            },
          },
          Education: {
            Title: {
              ru: 'Услуги по обучению и развитию в',
              en: 'Training and development services in',
            },
            Description: {
              ru: 'Найдите репетитора, учителя или компании в Таиланде на Bazaar. От частных уроков до профессиональных курсов - все для вашего образования и развития',
              en: 'Find a tutor, teacher or companies in Thailand on Bazaar. From private lessons to professional courses - everything for your education and development',
            },
          },
          Domestic: {
            Title: {
              ru: 'Услуги бытовые в',
              en: 'Household services in',
            },
            Description: {
              ru: 'Найдите лучших мастеров, которые вам с бытовыми проблемами в Таиланде на Bazaar - легкий способ найти надежных специалистов для ваших бытовых нужд, ремонту',
              en: 'Find the best professionals for your household problems in Thailand on Bazaar - an easy way to find reliable specialists for your household repair needs',
            },
          },
          Handyman: {
            Title: {
              ru: 'Услуги ремонтные "мастер на час" в',
              en: 'Repair services "master per hour" in',
            },
            Description: {
              ru: 'Поиск мастера для ремонтных работ в Таиланде на Bazaar - Быстрый и качественный ремонт, "мастер на час" для любых задач по дому и офису',
              en: 'Searching for a repairman in Thailand on Bazaar - Fast and high-quality repairs, a “master for an hour” for any tasks around the home and office',
            },
          },
          Business: {
            Title: {
              ru: 'Услуги деловые в',
              en: 'Business services in',
            },
            Description: {
              ru: 'Воспользуйтесь профессиональными деловыми услугами в Таиланде на Bazaar - От юридической помощи до бухгалтерских услуг - Поиск специалистов для помощи вам',
              en: 'Benefit from Professional Business Services in Thailand at Bazaar - From Legal Help to Accounting Services - Find Experts to Help You',
            },
          },
          Events: {
            Title: {
              ru: 'Услуги по организации праздников и мероприятий в',
              en: 'Services for organizing holidays and events in',
            },
            Description: {
              ru: 'Поиск организаторов праздников и мероприятий в Таиланде с помощью Bazaar - Найдите у нас профессионалов для праздников, корпоративов, свадеб',
              en: 'Search for organizers of holidays and events in Thailand using Bazaar - Find with us professionals for holidays, corporate events, weddings',
            },
          },
          Passengertransport: {
            Title: {
              ru: 'Услуги пассажирских перевозок в ',
              en: 'Passenger transportation services in',
            },
            Description: {
              ru: 'Поиск перевозчиков в Таиланде на Bazaar - комфортные и безопасные поездки, опытные водители, разнообразие транспортных средств для перездов и попутчиков',
              en: 'Search for carriers in Thailand on Bazaar - comfortable and safe trips, experienced drivers, a variety of vehicles for transfers and travel companions',
            },
          },
          Gardening: {
            Title: {
              ru: 'Услуги по благоустройству сада в ',
              en: 'Garden improvement services in',
            },
            Description: {
              ru: 'Поиск специалистов по благоустройству и уходу за садом и участков в Таиланде от Bazaar - Ландшафтный дизайн, уход за растениями, садовые работы - все для создания идеального участка',
              en: 'Search for specialists in landscaping and caring for gardens and plots in Thailand from Bazaar - Landscape design, plant care, garden work - everything to create the ideal plot',
            },
          },
          Ittelecom: {
            Title: {
              ru: 'Услуги IT, интернет, телеком в ',
              en: 'IT services, internet, telecom in',
            },
            Description: {
              ru: 'Поиск IT специалиста для решения задач связанных с IT решениями, создание сайтов, поисковая оптимизация, графический дизайн, frontend, backend в Таиланде на Bazaar - обслуживание, техническая поддержка',
              en: 'Search for an IT specialist to solve problems related to IT solutions, website creation, search engine optimization, graphic design, frontend, backend in Thailand on Bazaar - service, technical support',
            },
          },
          Security: {
            Title: {
              ru: 'Услуги по охране и безопасности в ',
              en: 'Security and safety services in',
            },
            Description: {
              ru: 'Поиск услуг охраны в Таиланде на Bazaar - Охранные компании и системы безопасности для вашего дома, бизнеса, организаций или мероприятия',
              en: 'Search for security services in Thailand on Bazaar - Security companies and security systems for your home, business, organizations or event',
            },
          },
          Moving: {
            Title: {
              ru: 'Услуги грузчиков и складские услуги в',
              en: 'Loader and warehouse services in',
            },
            Description: {
              ru: 'Поиск грузчиков и воспользуйтесь качественными складскими услугами в Таиланде через Bazaar - Эффективная организация переездов, хранение на складах товаров и материалов',
              en: 'Search for movers and take advantage of high-quality warehouse services in Thailand through Bazaar - Efficient organization of moves, storage of goods and materials in warehouses',
            },
          },
          Computersupport: {
            Title: {
              ru: 'Услуги по компьютерной помощи в ',
              en: 'Computer assistance services in',
            },
            Description: {
              ru: 'Решайте любые компьютерные проблемы с помощью специалистов в Таиланде на Bazaar - Быстрая компьютерная помощь, ремонт, настройка программ и оборудования',
              en: 'Solve any computer problems with the help of specialists in Thailand on Bazaar - Fast computer help, repairs, setting up programs and equipment',
            },
          },
          Advertising: {
            Title: {
              ru: 'Услуги рекламы и полиграфии в',
              en: 'Advertising and printing services in',
            },
            Description: {
              ru: 'Поиск рекламных компаний, создание эффективной рекламы и изготовление полиграфической продукции в Таиланде на Bazaar - Дизайн, печать, разработка рекламных кампаний для вашего бизнеса',
              en: 'Search for advertising companies, create effective advertising and produce printed products in Thailand on Bazaar - Design, printing, development of advertising campaigns for your business',
            },
          },
          Photovideo: {
            Title: {
              ru: 'Услуги фото- и видеосъёмки в',
              en: 'Photo and video shooting services in',
            },
            Description: {
              ru: 'Найдите профессиональную фото- и видеосъёмку в Таиланде на Bazaar - Фотографы для свадеб, мероприятий, корпоративных проектов или личных проектов',
              en: 'Find professional photography and videography in Thailand on Bazaar - Photographers for weddings, events, corporate projects or personal projects',
            },
          },
          Towing: {
            Title: {
              ru: 'Услуги эвакуатора в',
              en: 'Tow truck services in',
            },
            Description: {
              ru: 'Поиск услуг эвакуатора в Таиланде на Bazaar - Эвакуация автомобилей любых размеров, на дорогах в Таиланде, частные эвакуаторы, эвакуация транспорта после аварий',
              en: 'Search for tow truck services in Thailand on Bazaar - Evacuation of cars of any size, on the roads in Thailand, private tow trucks, evacuation of vehicles after accidents',
            },
          },
          Technician: {
            Title: {
              ru: 'Услуги по установке техники в ',
              en: 'Equipment installation services in',
            },
            Description: {
              ru: 'Поиск мастеров и профессиональная установка бытовой и офисной техники в Таиланде через Bazaar - У нас вы найдете специалистов по установке, подключении и настройке всей необходимой техники',
              en: 'Search for craftsmen and professional installation of household and office equipment in Thailand through Bazaar - Here you will find specialists in installation, connection and configuration of all necessary equipment',
            },
          },
          Art: {
            Title: {
              ru: 'Услуги художников, декораторов в ',
              en: 'Services of artists, decorators in',
            },
            Description: {
              ru: 'Поиск художников, иллюстраторов, дизайнеров и декораторов в Таиланде на Bazaar - Индивидуальный подход, оригинальные идеи для украшения дома и офиса',
              en: 'Search for artists, illustrators, designers and decorators in Thailand on Bazaar - Individual approach, original ideas for decorating your home and office',
            },
          },
          Nannies: {
            Title: {
              ru: 'Услуги няни и сиделки в',
              en: 'Nanny and caregiver services in',
            },
            Description: {
              ru: 'Поиск няни, воспитателя или сиделку в Таиланде с помощью Bazaar - Доверенные специалисты для ухода за детьми и пожилыми найдите у нас специалистов для заботы о близких',
              en: 'Search for a nanny, teacher or caregiver in Thailand using Bazaar - Trusted specialists for caring for children and the elderly, find specialists to care for loved ones with us',
            },
          },
          Repair: {
            Title: {
              ru: 'Услуги по ремонту и отделке в',
              en: 'Repair and finishing services in',
            },
            Description: {
              ru: 'Поиск специалистов по ремонту и отделке в Таиланде на Bazaar - У нас вы найдете мастеров для любых задач, связанных с ремонтными работами в Таиланде, опытные мастера',
              en: 'Search for repair and finishing specialists in Thailand on Bazaar - Here you will find craftsmen for any tasks related to repair work in Thailand, experienced craftsmen',
            },
          },
          Equipment: {
            Title: {
              ru: 'Услуги аренды оборудования в ',
              en: 'Equipment rental services in',
            },
            Description: {
              ru: 'Аренда оборудования для промышленности и других нужд в Таиланде на Bazaar - Широкий ассортимент оборудования для строительства, производства, мероприятий, бизнеса и других целей в ',
              en: 'Rental of equipment for industry and other needs in Thailand on Bazaar - Wide range of equipment for construction, production, events, business and other purposes in',
            },
          },
          Cleaning: {
            Title: {
              ru: 'Услуги уборки, клининга в',
              en: 'Cleaning services in',
            },
            Description: {
              ru: 'Поиск убощиков, клининговых компаний, качественные услуги уборки и клининга в Таиланде на Bazaar - Профессиональная чистка домов, офисов, промышленных помещений в ',
              en: 'Search for cleaners, cleaning companies, quality cleaning and cleaning services in Thailand on Bazaar - Professional cleaning of homes, offices, industrial premises in',
            },
          },
          Petcare: {
            Title: {
              ru: 'Услуги ухода за животными в',
              en: 'Animal care services in',
            },
            Description: {
              ru: 'Найдите надежные услуги ухода за животными в Таиланде на Bazaar - Профессиональный уход, груминг, выгул, передержка, зоогостиницы - забота о ваших питомцах от опытных специалистов',
              en: 'Find reliable animal care services in Thailand on Bazaar - Professional care, grooming, walking, boarding, pet hotels - taking care of your pets from experienced specialists',
            },
          },
          Services: {
            Title: {
              ru: 'Услуги по организации праздников и мероприятий в',
              en: 'Services for organizing holidays and events in',
            },
            Description: {
              ru: 'Поиск организаторов праздников и мероприятий в Таиланде с помощью Bazaar - Найдите у нас профессионалов для праздников, корпоративов, свадеб',
              en: 'Search for organizers of holidays and events in Thailand using Bazaar - Find with us professionals for holidays, corporate events, weddings',
            },
          },
          Other: {
            Title: {
              ru: 'Услуги другие в',
              en: 'Other services in',
            },
            Description: {
              ru: 'Услуги в Таиланде на Bazaar -Уникальные и специализированные услуги для вас и ваших проектов, найдите специалистов на Bazaar',
              en: 'Services in Thailand on Bazaar - Unique and specialized services for you and your projects, find specialists on Bazaar',
            },
          },
        },
      },
    },
  },
  events123: {
    ru: 'bazaar.Афиша',
    en: 'bazaar.Events',
  },
  eventsTitle: {
    ru: 'Афиша',
    en: 'Events',
  },
  month: {
    january: {
      ru: 'Январь',
      en: 'January',
    },
    february: {
      ru: 'Февраль',
      en: 'February',
    },
    march: {
      ru: 'Март',
      en: 'March',
    },
    april: {
      ru: 'Апрель',
      en: 'April',
    },
    may: {
      ru: 'Май',
      en: 'May',
    },
    june: {
      ru: 'Июнь',
      en: 'June',
    },
    july: {
      ru: 'Июль',
      en: 'July',
    },
    august: {
      ru: 'Август',
      en: 'August',
    },
    september: {
      ru: 'Сентябрь',
      en: 'September',
    },
    october: {
      ru: 'Октябрь',
      en: 'October',
    },
    november: {
      ru: 'Ноябрь',
      en: 'November',
    },
    december: {
      ru: 'Декабрь',
      en: 'December',
    },
  },
  weeks: {
    sun: {
      ru: 'ВС',
      en: 'SU',
    },
    mon: {
      ru: 'ПН',
      en: 'MO',
    },
    tue: {
      ru: 'ВТ',
      en: 'TU',
    },
    wed: {
      ru: 'СР',
      en: 'WE',
    },
    thu: {
      ru: 'ЧТ',
      en: 'TH',
    },
    fri: {
      ru: 'ПТ',
      en: 'FR',
    },
    sat: {
      ru: 'СБ',
      en: 'SA',
    },
  },
  sort: {
    Found: {
      ru: 'Найдено по вашему запросу',
      en: 'Found according to your request',
    },
    loading: {
      ru: 'История пишется, ожидайте чудо',
      en: 'History is being written, expect a miracle',
    },
    sortby: {
      ru: 'Сортировать по',
      en: 'Sort by',
    },
    newest: {
      ru: 'Самые новые',
      en: 'Newest',
    },
    cheapest: {
      ru: 'Дешевые',
      en: 'Cheapest',
    },
    expensive: {
      ru: 'Дорогие',
      en: 'Most expensive',
    },
    oldest: {
      ru: 'Старые',
      en: 'Oldest',
    },
    select: {
      ru: 'Выберите',
      en: 'Сhoose',
    },
  },
  city: {
    pattaya: {
      ru: 'Паттайя',
      en: 'Pattaya',
    },
    phuket: {
      ru: 'Пхукет',
      en: 'Phuket',
    },
    krabi: {
      ru: 'Краби',
      en: 'Krabi',
    },
    samui: {
      ru: 'Самуи',
      en: 'Samui',
    },
  },
  navpath: {
    home: {
      ru: 'Главная',
      en: 'Home',
    },
  },
  auth: {
    login: {
      title: {
        ru: 'Вход',
        en: 'Login',
      },
      successfully: {
        ru: 'Вы успешно авторизовались!',
        en: 'You have successfully logged in!',
      },
      password: {
        ru: 'Пароль',
        en: 'Password',
      },
      forgot: {
        ru: 'Забыли пароль?',
        en: 'Forgot your password?',
      },
      signIn: {
        ru: 'Войти',
        en: 'Sign in',
      },
      questionRegister: {
        ru: 'Нет аккаунта на ',
        en: 'Don\'t have an account on ',
      },
      register: {
        ru: 'Зарегистрироваться',
        en: 'Register',
      },
      errors: {
        incorrect: {
          ru: 'Логин или пароль не верные',
          en: 'Login or password is incorrect',
        },
      },
    },
    register: {
      title: {
        ru: 'Регистрация',
        en: 'Sign up',
      },
      firstName: {
        ru: 'Имя',
        en: 'First Name',
      },
      email: {
        ru: 'Email',
        en: 'Email',
      },
      phoneNumber: {
        ru: 'Номер телефона',
        en: 'Phone number',
      },
      password: {
        ru: 'Пароль',
        en: 'Password',
      },
      confirmationPassword: {
        ru: 'Подтверждение пароля',
        en: 'Password confirmation',
      },
      registerBtn: {
        ru: 'Зарегистрироваться',
        en: 'Register',
      },
      errors: {
        email: {
          ru: 'Пользователь с таким email уже существует',
          en: 'User with this email already exists',
        },
        phoneNumber: {
          ru: 'Пользователь с таким номером телефона уже существует',
          en: 'User with this phone number already exists',
        },
        occurred: {
          ru: 'Произошла ошибка при регистрации',
          en: 'An error occurred during registration',
        },
      },
    },
    validate: {
      name: {
        ru: 'Имя не может быть пустым',
        en: 'Name can\'t be empty',
      },
      email: {
        ru: 'Email не может быть пустым',
        en: 'Email can\'t be empty',
      },
      phone: {
        ru: 'Телефон не может быть пустым',
        en: 'Phone number can\'t be empty',
      },
      pass1: {
        ru: 'Пароль не может быть пустым',
        en: 'Password can\'t be empty',
      },
      pass2: {
        ru: 'Пароли не совпадают',
        en: 'Passwords do not match',
      },
    },
    oauth: {
      method: {
        ru: 'Продолжить с',
        en: 'Continue with',
      },
    },
    registerCode: {
      confirmation: {
        ru: 'Подтверждение регистрации',
        en: 'Confirmation of registration',
      },
      receive: {
        ru: 'Вам на почту придет 6-значный код. Заполните, пожалуйста.',
        en: 'You will receive a 6-digit code by email. Please fill in.',
      },
      complete: {
        ru: 'Завершить регистрацию',
        en: 'Complete registration',
      },
    },
    registerConfirmation: {
      title: {
        ru: 'Подтвердите регистрацию',
        en: 'Confirm registration',
      },
      description: {
        ru: 'Вам на почту отправлена ссылка для активации Вашего аккаунта',
        en: 'A link to activate your account has been sent to your email',
      },
      sendActivationLinkAgain: {
        ru: 'Отправьте письмо еще раз',
        en: 'Send the email again',
      },
    },
    completeRegistration: {
      loading: {
        title: {
          ru: 'Активируем аккаунт...',
          en: 'Activating the acccount...',
        },
      },
      error: {
        title: {
          en: 'Couldn\'t activate the account',
          ru: 'Невозможно активировать аккаунт',
        },
      },
      success: {
        title: {
          ru: 'Вы успешно прошли регистрацию!',
          en: 'You have successfully registered!',
        },
        homepage: {
          ru: 'На главную страницу',
          en: 'Homepage',
        },
        account: {
          ru: 'В личный кабинет',
          en: 'My account',
        },
      },
    },
    forgotPassword: {
      forgot: {
        ru: 'Забыли пароль?',
        en: 'Forgot your password?',
      },
      enter: {
        ru: 'Введите электронную почту',
        en: 'Enter your email',
      },
      send: {
        ru: 'Отправить',
        en: 'Send',
      },
    },
    resendActivationLink: {
      title: {
        ru: 'Отправьте письмо еще раз',
        en: 'Send the email again',
      },
    },
    agreement: {
      description: {
        ru: 'При использовани и входе вы соглашаетесь с ',
        en: 'By using and logging in, you agree to ',
      },
      terms: {
        ru: 'условиями использования Baazar ',
        en: 'the Baazar terms of use ',
      },
      and: {
        ru: 'и ',
        en: 'and ',
      },
      policy: {
        ru: 'политикой конфиденциальности',
        en: 'privacy policy',
      },
    },
    resetPassword: {
      title: {
        ru: 'Новый пароль',
        en: 'New password',
      },
      password: {
        ru: 'Пароль',
        en: 'Password',
      },
      confirmation: {
        ru: 'Подтверждение пароля',
        en: 'Password confirmation',
      },
      reset: {
        ru: 'Завершить сброс пароля',
        en: 'Complete password reset',
      },
    },
    code: {
      code: {
        ru: 'Введите код',
        en: 'Enter a code',
      },
      receive: {
        ru: 'Вам на почту придет 6-значный код. Заполните, пожалуйста.',
        en: 'You will receive a 6-digit code by email. Please fill in.',
      },
      confirm: {
        ru: 'Подтвердить',
        en: 'Confirm',
      },
    },
    passwordChange: {
      title: {
        ru: 'Поздравляем, вы успешно изменили пароль!',
        en: 'Congratulations, you have successfully changed your password!',
      },
      signIn: {
        ru: 'Войти',
        en: 'Sign in',
      },
    },
    remember: {
      ru: 'Запомнить меня',
      en: 'Remember me',
    },
    Confirm: {
      ru: 'Подтвердить',
      en: 'Confirm',
    },
    filters: {
      default: {
        Accord: {
          ru: 'По вашему запросу',
          en: 'According to your request',
        },
        According: {
          ru: 'По вашему запросу',
          en: 'According to your request',
        },
        Nothing: {
          ru: 'ничего не найдено',
          en: 'nothing found',
        },
        Description: {
          ru: 'Напишите описание вашего мероприятия',
          en: 'Write a description of your event',
        },
        States: {
          ru: 'Состояние',
          en: 'State',
        },
        Excellent: {
          ru: 'Отличное',
          en: 'Excellent',
        },
        good: {
          ru: 'Хорошее',
          en: 'Good',
        },
        Satisfactory: {
          ru: 'Удовлетворительное',
          en: 'Satisfactory',
        },
        Rules: {
          ru: 'Правила',
          en: 'Rules',
        },
        Availability: {
          ru: 'Наличие бассейна',
          en: 'Availability of a swimming pool',
        },
        children: {
          ru: 'Можно с детьми',
          en: 'Possible with children',
        },
        Pets: {
          ru: 'Можно с животными',
          en: 'Pets allowed',
        },
        Smoking: {
          ru: 'Разрешено курить',
          en: 'Smoking allowed',
        },
        Housing: {
          ru: 'Тип жилья',
          en: 'Housing type',
        },
        // Rules: {
        //     ru: 'Правила',
        //     en: 'Rules',
        // },
        Apartments: {
          ru: 'Апартаменты',
          en: 'Apartments',
        },
        Town: {
          ru: 'Таун-Хаус',
          en: 'Town House',
        },
        Villa: {
          ru: 'Вилла',
          en: 'Villa',
        },
        Studio: {
          ru: 'Студия',
          en: 'Studio',
        },
        rooms: {
          ru: 'Количество комнат',
          en: 'Number of rooms',
        },
        room1: {
          ru: '1 комната',
          en: '1 room',
        },
        room2: {
          ru: '2 комнаты',
          en: '2 rooms',
        },
        room3: {
          ru: '3 комнаты',
          en: '3 rooms',
        },
        room4: {
          ru: '4 комнаты',
          en: '4 rooms',
        },
        room5: {
          ru: '5 комнаты и более',
          en: '5 rooms or more',
        },
        engine: {
          ru: 'объем двигателя',
          en: 'Engine capacity',
        },
        hour: {
          ru: 'Почасовая',
          en: 'Hourly',
        },
        Daily: {
          ru: 'Посуточная',
          en: 'Daily',
        },
        Long: {
          ru: 'Долгосрочная',
          en: 'Long-term',
        },
        apply: {
          ru: 'Применить',
          en: 'Apply',
        },
        yes: {
          ru: 'Да',
          en: 'Yes',
        },
        not: {
          ru: 'Нет',
          en: 'No',
        },
        color: {
          ru: 'Цвет',
          en: 'Colour',
        },
        price: {
          ru: 'Стоимость',
          en: 'Price',
        },
        min: {
          ru: 'Минимум',
          en: 'Min',
        },
        max: {
          ru: 'Максимум',
          en: 'Max',
        },
        writeСost: {
          ru: 'Укажите стоимость',
          en: 'Enter the cost',
        },
        desc: {
          ru: 'Слова в описании',
          en: 'Words in the description',
        },
        writesomething: {
          ru: 'Напишите что-нибудь',
          en: 'Write something',
        },
        moreParams: {
          ru: 'Дополнительные параметры',
          en: 'Additional parameters',
        },
        delivery: {
          ru: 'Доставка',
          en: 'Delivery',
        },
        rentalType: {
          ru: 'Тип аренды',
          en: 'Type of rental',
        },
        location: {
          ru: 'Местоположение',
          en: 'Location',
        },
        trans: {
          ru: 'Вид транспорта',
          en: 'Type of transport',
        },
        servicesType: {
          ru: 'Вид услуги',
          en: 'Type of services',
        },
      },
      title: {
        ru: 'Фильтры',
        en: 'Filters',
      },
    },
    notAcc: {
      ru: 'Нет аккаунта?',
      en: 'No account?',
    },
    sigin: {
      ru: 'Register',
      en: 'Register',
    },
    password: {
      ru: 'Пароль',
      en: 'Password',
    },
    pass2: {
      ru: 'Подтверждение пароля',
      en: 'Password confirmation',
    },
    newPass: {
      ru: 'Новый пароль',
      en: 'New password',
    },
    finishPass: {
      ru: 'Завершить сброс пароля',
      en: 'Complete reset',
    },
  },
  header: {
    category: {
      ru: 'Категории',
      en: 'Categories',
    },
    events: {
      ru: 'Мероприятия',
      en: 'Events',
    },
    createAdv: {
      ru: 'Создать объявление',
      en: 'Create ad',
    },
    search: {
      ru: 'Поиск..',
      en: 'Search..',
    },
    events2: {
      ru: 'Афиша',
      en: 'Events',
    },
    dropdown: {
      admin: {
        ru: 'Администратор',
        en: 'Administrator',
      },
      user: {
        ru: 'Пользователь',
        en: 'User',
      },
      moder: {
        ru: 'Модератор',
        en: 'Moderator',
      },
      myAdv: {
        ru: 'Мои объявления',
        en: 'My Ads',
      },
      chat: {
        ru: 'Сообщения',
        en: 'Messages',
      },
      myEvents: {
        ru: 'Мои мероприятия',
        en: 'My events',
      },
      logout: {
        ru: 'Выйти из аккаунта',
        en: 'Log out',
      },
    },
  },
  footer: {
    block1: {
      contact: {
        ru: 'Связаться с нами',
        en: 'Contact us',
        your: {
          ru: 'Введите имя*',
          en: 'Enter your name*',
        },
        email: {
          ru: 'Введите электронную почту*',
          en: 'Enter email*',
        },
        Problems: {
          ru: 'Проблемы',
          en: 'Problems',
        },
        Cooperation: {
          ru: 'Сотрудничество',
          en: 'Cooperation',
        },
        Other: {
          ru: 'Другое',
          en: 'Other',
        },
        Message: {
          ru: 'Сообщение*',
          en: 'Message*',
        },
        Specify: {
          ru: 'Укажите тему*',
          en: 'Specify topic*',
        },
      },
      politica: {
        ru: 'Политика конфиденциальности',
        en: 'Privacy policy',
      },
      pravila: {
        ru: 'Правила пользования',
        en: 'Terms of use',
      },
    },
    social: {
      ru: 'Социальные сети',
      en: 'Social network',
    },
    contact: {
      ru: 'Контакты',
      en: 'Contacts',
    },
    about: {
      ru: 'О нас',
      en: 'About Us',
    },
    rules: {
      ru: 'Правила bazaar',
      en: 'Bazaar rules',
    },
  },
  mainPage: {
    forYou: {
      ru: 'Недавно добавленные',
      en: 'Recently added',
    },
    seo: {
      ru: 'Базар: недвижимость, транспорт, услуги, вещи',
      en: 'Bazaar: real estate, transport, services, things',
    },
    seodescription: {
      ru: 'Bazaar: Ведущая платформа для размещения объявлений о товарах и услугах в Таиланде - Легкий поиск, удобство размещения объявлений и мгновенный доступ к множеству предложений',
      en: 'Bazaar: Thailand\'s leading platform for listing products and services - Easy search, convenient listing and instant access to tons of offers',
    },
    jov: {
      ru: 'Работа',
      en: 'Job',
    },
  },
  userPage: {
    title: {
      ru: 'Страница пользователя на Bazaar',
      en: 'User\'s page on Bazaar',
    },
    onBazaar: {
      ru: 'На bazaar c',
      en: 'On bazaar from',
    },
    online: {
      ru: 'Онлайн',
      en: 'Online',
    },
    message: {
      ru: 'Сообщение',
      en: 'Message',
    },
    phone: {
      ru: 'Показать телефон',
      en: 'Show phone number',
    },
    searchInPage: {
      ru: 'Искать на этой странице',
      en: 'Search on this page',
    },
    sort: {
      ru: 'Сортировать по',
      en: 'Sort by',
    },
    filter: {
      ru: 'Фильтрация объявлений',
      en: 'Filtering ads',
    },
    category: {
      ru: 'Категории',
      en: 'Categories',
    },
    allAdv: {
      ru: 'Все объявления',
      en: 'All ads',
    },
  },
  advCard: {
    postDate: {
      ru: 'Опубликовано',
      en: 'Published',
    },
  },
  reviews: {
    review: {
      ru: 'Отзывы',
      en: 'Reviews',
    },
    leaveReview: {
      ru: 'Оставить отзыв',
      en: 'Leave a feedback',
    },
    footing: {
      ru: 'На основании',
      en: 'On the basis of',
    },
    new: {
      ru: 'Самые новые',
      en: 'Latest',
    },
    comment: {
      ru: 'Комментарии',
      en: 'Comments',
    },
    myReview: {
      ru: 'Оценить',
      en: 'Rate',
    },
    myComment: {
      ru: 'Введите комментарий',
      en: 'Enter a comment',
    },
    back: {
      ru: 'Отмена',
      en: 'Cancel',
    },
    post: {
      ru: 'Публиковать',
      en: 'Publish',
    },
    assessment: {
      ru: ' оценок',
      en: ' assessment',
    },
    rateit: {
      ru: 'Поставить оценку',
      en: 'Rate it',
    },
  },
  advDetail: {
    advertisements: {
      ru: ' - Поиск объявлений о купле-продаже в',
      en: ' - Search for advertisements for purchase and sale in',
    },
    everything: {
      ru: ' вы найдете все, включая товары и услуги, такие как ',
      en: ' you will find everything including goods and services such as ',
    },
    on: {
      ru: 'на',
      en: 'on',
    },
    from: {
      ru: 'от',
      en: 'from',
    },
    description: {
      ru: 'Описание',
      en: 'Description',
    },
    views: {
      ru: 'Просмотры',
      en: 'Views',
    },
    complaint: {
      ru: 'Пожаловаться',
      en: 'Report',
    },
    characteristic: {
      ru: 'Характеристики',
      en: 'Specifications',
    },
    moreParams: {
      ru: 'Дополнительные параметры',
      en: 'Additional parameters',
    },
    allUserAdv: {
      ru: 'Все объявления автора',
      en: 'All ads by the author',
    },
    similar: {
      ru: 'Похожие объявления',
      en: 'Similar Ads',
    },
    viewAll: {
      ru: 'Смотреть все',
      en: 'Watch all',
    },
    seller: {
      ru: 'Продавец',
      en: 'Seller',
    },
    locations: {
      ru: 'Местоположение',
      en: 'Location',
    },
    phoneNumber: {
      ru: 'Показать телефон',
      en: 'Show phone number',
    },
    hide: {
      ru: 'Скрыть',
      en: 'Hide',
    },
    instNotFilled: {
      ru: 'Пользователь не предоставил информацию',
      en: 'User has not provided information',
    },
    since: {
      ru: 'На bazaar с ',
      en: 'On the bazaar from ',
    },
  },
  createAdv: {
    title: {
      ru: 'Создать объявление',
      en: 'Create an ad',
    },
    Title: {
      ru: 'Создать объявление на Bazaar',
      en: 'Create an ad on Bazaar',
    },
    Description: {
      ru: 'Создавай объявления, продавай ненужное и находи то что нужно на платформе бесплатных объявлений Bazaar',
      en: 'Create ads, sell what you don\'t need and find what you need on the free classifieds platform Bazaar',
    },
    titledesc: {
      ru: 'Укажите в подробностях',
      en: 'Specify in details',
    },
    advTitleLabel: {
      ru: 'Укажите заголовок',
      en: 'Specify the title',
    },
    advTitleLabelOne: {
      ru: 'Опишите в подробностях',
      en: 'Describe in detail',
    },

    advTitleInp: {
      ru: 'Название объявления',
      en: 'The name of the ad',
    },
    evTitleInp: {
      ru: 'Название мероприятия',
      en: 'The name of the events',
    },
    createAdv: {
      ru: 'Выберите категорию',
      en: 'Select a category',
    },
    seletCategory2: {
      ru: 'Выбрать категорию',
      en: 'Select a category',
    },
    img: {
      ru: 'Фото',
      en: 'Photo',
    },
    imgP: {
      ru: 'Первое фото будет на обложке объявления',
      en: 'The first photo will be on the cover of the ad',
    },
    descTitle: {
      ru: 'Описание',
      en: 'Description',
    },
    descriptionCount: {
      ru: 'Напишите еще',
      en: 'Write more',
    },
    descSymbol: {
      ru: 'символов',
      en: 'characters',
    },
    locations: {
      ru: 'Местоположение',
      en: 'Location',
    },
    price: {
      ru: 'Стоимость',
      en: 'Price',
    },
    price2: {
      ru: 'Заработная плата',
      en: 'Wage',
    },
    inputData: {
      ru: 'Введите данные',
      en: 'Enter the data',
    },
    addPhoto: {
      ru: 'Добавить фото',
      en: 'Add photo',
    },
    maxCharacter: {
      ru: 'Максимальное количество 25 символов',
      en: 'Maximum 25 characters',
    },
    comeBack: {
      ru: 'Вернуться назад',
      en: 'Come back',
    },
    descAd: {
      ru: 'Описание объявления...',
      en: 'Description of the ad...',
    },
  },
  createAdvFilds: {
    default: {
      typeShoes: {
        ru: 'Тип обуви',
        en: 'Type of shoes',
      },
      typeClothes: {
        ru: 'Тип одежды',
        en: 'Type of clothes',
      },
      month: {
        ru: 'месяца',
        en: 'months',
      },
      months: {
        ru: 'месяцев',
        en: 'months',
      },
      year: {
        ru: 'года',
        en: 'years',
      },
      years: {
        ru: 'лет',
        en: 'years',
      },
      rentalTypeHourly: {
        ru: 'Почасовая',
        en: 'Hourly',
      },
      rentalTypeDaily: {
        ru: 'Посуточная',
        en: 'Daily',
      },
      rentalTypeLongTerm: {
        ru: 'Долгосрочная',
        en: 'Long-term',
      },
      Baby: {
        ru: 'Детское кресло',
        en: 'Baby chair',
      },
      Charger: {
        ru: 'Зарядное устройство',
        en: 'Charger',
      },
      Audio: {
        ru: 'Аудио система',
        en: 'Audio system',
      },
      PhoneMount: {
        ru: 'Крепление для телефона',
        en: 'Phone mount',
      },
      Meeting: {
        ru: 'Встреча с табличкой',
        en: 'Meeting with a sign',
      },
      depo: {
        ru: 'Депозит',
        en: 'Deposit',
      },
      rentalType: {
        ru: 'Тип аренды',
        en: 'Type of rental',
      },
      color: {
        ru: 'Цвет',
        en: 'Color',
      },
      yes: {
        ru: 'Да',
        en: 'Yes',
      },
      not: {
        ru: 'Нет',
        en: 'No',
      },
      swimming_pool: {
        ru: 'Бассейн',
        en: 'Swimming pool',
      },
      delivery: {
        ru: 'Доставка',
        en: 'Delivery',
      },
      moreParams: {
        ru: 'Дополнительные параметры',
        en: 'Additional parameters',
      },
      state: {
        ru: 'Состояние',
        en: 'Condition',
      },
      selectType: {
        ru: 'Выберите тип',
        en: 'Select type',
      },
      notIndicated: {
        ru: 'Не указано',
        en: 'Not indicated',
      },
      new: {
        ru: 'Новый',
        en: 'New',
      },
      old: {
        ru: 'Б/у',
        en: 'old',
      },
      Excellent: {
        ru: 'Отличное',
        en: 'Excellent',
      },
      Good: {
        ru: 'Хорошее',
        en: 'Good',
      },
      Satisfactory: {
        ru: 'Удовлетворительное',
        en: 'Satisfactory',
      },
    },
    auto: {
      ru: 'Объем двигателя',
      en: 'Engine capacity',
    },
    transfers: {
      serviceType: {
        ru: 'Вид услуги',
        en: 'Type of service',
      },
      vehicle_type: {
        ru: 'Тип автомобиля',
        en: 'Type of vehicle',
      },
      number_of_rooms: {
        ru: 'Количество комнат',
        en: 'Number of rooms',
      },
      type_housing: {
        ru: 'Тип жилья',
        en: 'Type of housing',
      },
      TransferAndAirport: {
        ru: 'Трансфер из аэропорта',
        en: 'Airport transfer ',
      },
      Comfort: {
        ru: 'Трансфер класса комфорт/бизнес',
        en: 'Comfort/business class transfer',
      },
      FeedByTime: {
        ru: 'Подача по времени',
        en: 'Feed by time',
      },
      AroundTown: {
        ru: 'По городу',
        en: 'Around town',
      },
      poezdka: {
        ru: 'Поездка',
        en: 'Trip',
      },
      BetweenCities: {
        ru: 'Между городами',
        en: 'Between cities',
      },
      transportType: {
        ru: 'Вид транспорта',
        en: 'Kind of transport',
      },
      auto: {
        ru: 'Легковой автомобиль',
        en: 'Car',
      },
      Minivan: {
        ru: 'Минивэн',
        en: 'Minivan',
      },
      Minibus: {
        ru: 'Микроавтобус',
        en: 'Minibus',
      },
      Bus: {
        ru: 'Автобус',
        en: 'Bus',
      },
      other: {
        ru: 'Другой',
        en: 'Other',
      },
    },
    ned: {
      nedType: {
        ru: 'Тип жилья',
        en: 'Type of housing',
      },
      NotImportant: {
        ru: 'Неважно',
        en: 'Other',
      },
      Apartment: {
        ru: 'Квартира',
        en: 'Apartment',
      },
      Apartments: {
        ru: 'Апартаменты',
        en: 'Apartments',
      },
      Villa: {
        ru: 'Вилла',
        en: 'Villa',
      },

      rooms: {
        ru: 'Кол-во комнат',
        en: 'Number of rooms',
      },
      room: {
        ru: 'комната',
        en: 'room',
      },
      Studio: {
        ru: 'Студия',
        en: 'Studio',
      },
      roomss: {
        ru: 'комнаты',
        en: 'rooms',
      },
      roomMany: {
        ru: '5 комнат и больше',
        en: 'rooms or more',
      },

      swimming: {
        ru: 'Наличие бассейна',
        en: 'Availability of a swimming pool',
      },
    },
    services: {
      ru: 'Наличие грузчиков',
      en: 'Availability of a staff',
    },
    things: {
      brand: {
        ru: 'Бренд',
        en: 'Brand',
      },
      size: {
        ru: 'Размер EU/US',
        en: 'Size EU/US',
      },
      Otherbrand: {
        ru: 'Другой',
        en: 'Other',
      },
      gender: {
        ru: 'Пол',
        en: 'Gender',
      },
      onesize: {
        ru: 'один размер',
        en: 'one size',
      },
      Male: {
        ru: 'Мужской',
        en: 'Male',
      },
      Female: {
        ru: 'Женский',
        en: 'Female',
      },
      Unisex: {
        ru: 'Унисекс',
        en: 'Unisex',
      },
    },
    electronics: {
      type: {
        ru: 'Тип электроники',
        en: 'Type of electronics',
      },
      new: {
        ru: 'Новое',
        en: 'New',
      },
      BY: {
        ru: 'Б/У',
        en: 'Used',
      },
      owner: {
        ru: 'Производитель',
        en: 'Manufacturer',
      },
    },
  },
  filters: {
    default: {
      States: {
        ru: 'Состояние',
        en: 'State',
      },
      Excellent: {
        ru: 'Отличное',
        en: 'Excellent',
      },
      good: {
        ru: 'Хорошее',
        en: 'Good',
      },
      Satisfactory: {
        ru: 'Удовлетворительное',
        en: 'Satisfactory',
      },
      Rules: {
        ru: 'Правила',
        en: 'Rules',
      },
      Availability: {
        ru: 'Наличие бассейна',
        en: 'Availability of a swimming pool',
      },
      children: {
        ru: 'Можно с детьми',
        en: 'Possible with children',
      },
      Pets: {
        ru: 'Можно с животными',
        en: 'Pets allowed',
      },
      Smoking: {
        ru: 'Разрешено курить',
        en: 'Smoking allowed',
      },
      Housing: {
        ru: 'Тип жилья',
        en: 'Housing type',
      },
      // Rules: {
      //   ru: "Правила",
      //   en: "Rules",
      // },
      Apartments: {
        ru: 'Апартаменты',
        en: 'Apartments',
      },
      Town: {
        ru: 'Таун-Хаус',
        en: 'Town House',
      },
      Villa: {
        ru: 'Вилла',
        en: 'Villa',
      },
      Studio: {
        ru: 'Студия',
        en: 'Studio',
      },
      rooms: {
        ru: 'Количество комнат',
        en: 'Number of rooms',
      },
      room1: {
        ru: '1 комната',
        en: '1 room',
      },
      room2: {
        ru: '2 комнаты',
        en: '2 rooms',
      },
      room3: {
        ru: '3 комнаты',
        en: '3 rooms',
      },
      room4: {
        ru: '4 комнаты',
        en: '4 rooms',
      },
      room5: {
        ru: '5 комнаты и более',
        en: '5 rooms or more',
      },
      engine: {
        ru: 'объем двигателя',
        en: 'Engine capacity',
      },
      hour: {
        ru: 'Почасовая',
        en: 'Hourly',
      },
      Daily: {
        ru: 'Посуточная',
        en: 'Daily',
      },
      Long: {
        ru: 'Долгосрочная',
        en: 'Long-term',
      },
      apply: {
        ru: 'Применить',
        en: 'Apply',
      },
      yes: {
        ru: 'Да',
        en: 'Yes',
      },
      not: {
        ru: 'Нет',
        en: 'No',
      },
      color: {
        ru: 'Цвет',
        en: 'Colour',
      },
      price: {
        ru: 'Стоимость',
        en: 'Price',
      },
      min: {
        ru: 'Минимум',
        en: 'Min',
      },
      max: {
        ru: 'Максимум',
        en: 'Max',
      },
      writeСost: {
        ru: 'Укажите стоимость',
        en: 'Enter the cost',
      },
      desc: {
        ru: 'Слова в описании',
        en: 'Words in the description',
      },
      writesomething: {
        ru: 'Напишите что-нибудь',
        en: 'Write something',
      },
      moreParams: {
        ru: 'Дополнительные параметры',
        en: 'Additional parameters',
      },
      delivery: {
        ru: 'Доставка',
        en: 'Delivery',
      },
      rentalType: {
        ru: 'Тип аренды',
        en: 'Type of rental',
      },
      location: {
        ru: 'Местоположение',
        en: 'Location',
      },
      trans: {
        ru: 'Вид транспорта',
        en: 'Type of transport',
      },
      servicesType: {
        ru: 'Вид услуги',
        en: 'Type of services',
      },
    },
    title: {
      ru: 'Фильтры',
      en: 'Filters',
    },
  },
  complaintModal: {
    complaint: {
      ru: 'Пожаловаться',
      en: 'Complain',
    },
    reason: {
      ru: 'Причина жалобы',
      en: 'The reason for the complaint',
    },
    reasonInput: {
      ru: 'Укажите причину',
      en: 'Specify the reason',
    },
    sold_on_bazaar: {
      en: 'Sold on Bazaar',
      ru: 'Заключил(а) сделку на Bazaar',
    },
    sold_somewhere_else: {
      en: 'Sold somewhere else',
      ru: 'Заключил(а) сделку в другом месте',
    },
    other: {
      en: 'Other',
      ru: 'Другая причина',
    },
    duplicate_information: {
      en: 'Duplicated information',
      ru: 'Дублирование информации',
    },
    inappropriate_content: {
      en: 'Inappropriate content',
      ru: 'Неприемлемый контент',
    },
    finished: {
      en: 'Finished',
      ru: 'Завершено',
    },
    event_cancellation: {
      en: 'Event cancellation',
      ru: 'Отмена мероприятия',
    },
    event_rescheduling: {
      en: 'Event rescheduling',
      ru: 'Перенос мероприятия',
    },
    outdated_information: {
      en: 'Outdated information',
      ru: 'Устаревшая информация',
    },
    unsuccessful_event: {
      en: 'Unsuccessful event',
      ru: 'Неудачное мероприятие',
    },
    rules_or_laws_violation: {
      en: 'Rules or laws violation',
      ru: 'Нарушение правил или законов',
    },
    technical_issues: {
      en: 'Technical issues',
      ru: 'Технические проблемы',
    },
    inactivity_or_expiration: {
      en: 'Inactivity or expiration',
      ru: 'Неактивность или истечение срока',
    },
    organizers_plan_change: {
      en: 'Organizers\' plan change',
      ru: 'Изменение планов организаторов',
    },
  },
  contactPage: {
    contactUs: {
      ru: 'Связаться с нами',
      en: 'Contact us',
    },
    name: {
      ru: 'Введите имя',
      en: 'Enter a name',
    },
    email: {
      ru: 'Введите электронную почту',
      en: 'Enter your email address',
    },
    topic: {
      ru: 'Укажите тему',
      en: 'Specify the subject',
    },
    comment: {
      ru: 'Сообщение',
      en: 'Message',
    },
    send: {
      ru: 'Отправить',
      en: 'Send',
    },
  },
  events: {
    title: {
      ru: 'Афиша событий',
      en: 'Event poster',
    },
    addEvents: {
      ru: 'Предложить мероприятие',
      en: 'Propose an event',
    },
    allEvents: { ru: 'Все мероприятия', en: 'All events' },
    adults: { ru: 'Взрослые', en: 'Adult' },
    childish: { ru: 'Детские', en: 'Child' },
    searchEvents: {
      ru: 'Найти мероприятие',
      en: 'Find an event',
    },
    viewMore: {
      ru: 'Посмотреть больше',
      en: 'See more',
    },
    contact: {
      ru: 'Посмотреть контакты',
      en: 'View contacts',
    },
    similar: {
      ru: 'Похожие мероприятия',
      en: 'Similar events',
    },
    noEvents: {
      ru: 'Мероприятий на этот день не назначено.',
      en: 'There are no events scheduled for that day.',
    },
    descriptionNoEvents: {
      ru: 'Сиди и плачь в подушку, либо же выбери другой день, и радуйся ! на Не расстраивайтесь, попробуйте выбрать другой день!',
      en: 'Sit and cry into your pillow, or choose another day and be happy! Don`t worry, try to choose another day!',
    },
  },
  eventDetail: {
    publishDate: {
      ru: 'Опубликовано',
      en: 'Publishied',
    },
  },
  createEvents: {
    addaphoto: {
      ru: 'Добавить фото',
      en: 'Add a photo',
    },
    photoLimit: {
      ru: 'Вы можете добавить только одну фотографию',
      en: 'You can only add one photo',
    },
    publish: {
      ru: 'Опубликовать',
      en: 'Publish',
    },
    Sending: {
      ru: 'Отправка...',
      en: 'Sending...',
    },
    title: {
      ru: 'Создать объявление',
      en: 'Create ad',
    },
    titledesc: {
      ru: 'Укажите в подробностях',
      en: 'Specify in details',
    },
    status: {
      ru: 'Статус мероприятия',
      en: 'Event status',
    },
    adult: {
      ru: 'Взрослое',
      en: 'Adult',
    },
    childish: {
      ru: 'Детское',
      en: 'Child',
    },
    advTitleLabel: {
      ru: 'Укажите заголовок',
      en: 'Specify the title',
    },
    time: {
      ru: 'Время',
      en: 'Time',
    },
    advTitleInp: {
      ru: 'Название объявления',
      en: 'The name of the ad',
    },
    seletCategory: {
      ru: 'Выберите категорию',
      en: 'Select a category',
    },
    seletCategory2: {
      ru: 'Выбрать категорию',
      en: 'Select a category',
    },
    img: {
      ru: 'Фото',
      en: 'Photo',
    },
    imgP: {
      ru: 'Первое фото будет на обложке объявления',
      en: 'The first photo will be on the cover of the ad',
    },
    descTitle: {
      ru: 'Описание',
      en: 'Description',
    },
    descriptionCount: {
      ru: 'Напишите еще',
      en: 'Write more',
    },
    descSymbol: {
      ru: 'символов',
      en: 'characters',
    },
    locations: {
      ru: 'Местоположение',
      en: 'Location',
    },
    price: {
      ru: 'Стоимость',
      en: 'Price',
    },
    contacts: {
      ru: 'Контакты',
      en: 'Contacts',
    },
    costmayvary: {
      ru: 'Стоимость может варьироваться',
      en: 'The cost may vary',
    },
    inputData: {
      ru: 'Введите данные',
      en: 'Enter the data',
    },
  },
  selectCategory: {
    ru: 'Выберите категорию',
    en: 'Select a category',
  },
  selectSubCategory: {
    ru: 'Выберите под-категорию',
    en: 'Select a sub-category',
  },
  sidebars: {
    myAdv: {
      ru: 'Мои объявления',
      en: 'My Ads',
    },
    fav: {
      ru: 'Избранные',
      en: 'Favorites',
    },
    event: {
      ru: 'Мои Мероприятия',
      en: 'My Events',
    },
    security: {
      ru: 'Безопасность и вход',
      en: 'Security and entry',
    },
    deactivateAdv: {
      ru: 'Скрытые объявления',
      en: 'Hidden ads',
    },
    deactivateEvents: {
      ru: 'Скрытые мероприятия',
      en: 'Hidden events',
    },
    chat: {
      ru: 'Чат',
      en: 'Chat',
    },
    adminAdv: {
      ru: 'Объявления',
      en: 'Ads',
    },
    categories: {
      ru: 'Категории и подкатегории',
      en: 'Categories and subcategories',
    },
    users: {
      ru: 'Пользователи',
      en: 'Users',
    },
    stat: {
      ru: 'Статистика',
      en: 'Statistics',
    },
    moders: {
      ru: 'Модераторы',
      en: 'Moderators',
    },
    complaints: {
      ru: 'Жалобы',
      en: 'Complaints',
    },
    deactivateAcc: {
      ru: 'Скрытые аккаунты',
      en: 'Hidden accounts',
    },
    city: {
      ru: 'Города и регионы',
      en: 'Cities and regions',
    },
    words: {
      ru: 'Словарь запрещенных слов',
      en: 'Dictionary of forbidden words',
    },
    onModers: {
      ru: 'На модерации',
      en: 'On moderation',
    },
  },
  user: {
    mainInfo: {
      ru: 'Основная информация',
      en: 'Basic information',
    },
    forms: {
      avatar: {
        ru: 'Фото профиля',
        en: 'Profile Photo',
      },
      update: {
        ru: 'Изменить',
        en: 'To change',
      },
      userfullname: {
        ru: 'Имя Фамилия',
        en: 'First and Last Name',
      },
      email: {
        ru: 'Почта',
        en: 'E-mail',
      },
      phoneNumber: {
        ru: 'Номер телефона',
        en: 'Phone number',
      },
      desc: {
        ru: 'Описание',
        en: 'Description',
      },
    },
    myAdv: {
      title: {
        ru: 'Объявления',
        en: 'Ads',
      },
      like: {
        ru: 'Лайк',
        en: 'Like',
      },
    },
    advTable: {
      title: {
        ru: 'Название',
        en: 'Title',
      },
      price: {
        ru: 'Цена',
        en: 'Price',
      },
      views: {
        ru: 'Кол-во просмотров',
        en: 'Views',
      },
      actions: {
        ru: 'Действия',
        en: 'Actions',
      },
      activate: {
        ru: 'Активация',
        en: 'Activate',
      },
    },
    deactivateAdv: {
      ru: 'Скрытые объявления',
      en: 'Hidden Ads',
    },
    onModers: {
      ru: 'На модерации',
      en: 'On moderation',
      yes: {
        ru: 'Да',
        en: 'Yes',
      },
    },
    fav: {
      ru: 'Избранные',
      en: 'Favorites',
    },
    events: {
      eventTable: {
        address: {
          ru: 'Адрес',
          en: 'Address',
        },
      },
    },
    security: {
      title: {
        ru: 'Изменение пароля',
        en: 'Changing the password',
      },
      currentPass: {
        ru: 'Текущий пароль',
        en: 'Current password',
      },
      newPass: {
        ru: 'Новый пароль',
        en: 'New password',
      },
      confPass: {
        ru: 'Подтвердите пароль',
        en: 'Confirm your password',
      },
      deleteAcc: {
        ru: 'Удалить аккаунт',
        en: 'Delete account',
      },
    },
  },
  loading: {
    ru: 'Пишем историю... Скоро будет готово.',
    en: 'Writing history... Will be ready soon',
  },
  choose: {
    ru: 'Выберите',
    en: 'Choose',
  },
  from: {
    ru: 'От',
    en: 'From',
  },
  too: {
    ru: 'До',
    en: 'To',
  },
  aboutRules: {
    rules: {
      firstTitle: {
        ru: 'Здесь собраны правила, по которым',
        en: 'Here are the rules by which',
      },
      secondTitle: {
        ru: 'живет',
        en: 'lives',
      },
      thirdTitle: {
        ru: 'Авторы размещают объявления и мероприятия, а пользователи выбирают, покупают и пишут в чат свои вопросы.',
        en: 'Authors post announcements and events, and users choose, buy and chat their questions.',
      },
    },
    plus: {
      one: {
        ru: 'Правила размещений объявлений',
        en: 'Rules for posting announcements',
      },
      two: {
        ru: 'Модерация мероприятий',
        en: 'Event moderation',
      },
      three: {
        ru: 'Отзывы',
        en: 'Reviews',
      },
      four: {
        ru: 'Блокировка профиля',
        en: 'Profile lock',
      },
      five: {
        ru: 'Деактивация объявления',
        en: 'Deactivating an advertisement',
      },
    },
    documents: {
      title: {
        ru: 'Документы',
        en: 'Documents',
      },
      first: {
        ru: 'Договор оферта',
        en: 'Offer Contract',
      },
      second: {
        ru: 'Политика конфиденциальности',
        en: 'Privacy Policy',
      },
      third: {
        ru: 'Политика использования файлов cookie',
        en: 'Cookie Policy',
      },
    },
    rulesOfUse: {
      title: {
        ru: 'Правила использования',
        en: 'Rules of use',
      },
      description: {
        ru: 'Добро пожаловать на страницу правил использования [Название вашего сайта]. Ниже приведены основные правила и условия, регулирующие ваше взаимодействие с нашим сайтом.',
        en: 'Welcome to the [Name of your site]. The following are the basic terms and conditions governing your interaction with our site.',
      },
      firstTitle: {
        ru: 'Авторские права',
        en: 'Copyrights',
      },
      secondTitle: {
        ru: 'Коммуникация на сайте',
        en: 'Communication on the website',
      },
      thirdTitle: {
        ru: 'Ответственность пользователя',
        en: 'User Responsibility',
      },
      fourthTitle: {
        ru: 'Отказ от ответственности',
        en: 'Disclaimer',
      },

      firstText: {
        ru: 'Весь контент, размещенный на нашем сайте, защищен авторскими правами. Любое незаконное использование материалов без предварительного согласия владельца авторских прав запрещено.',
        en: 'All content on our website is protected by copyright. Any unauthorized use of materials without the prior consent of the copyright owner is prohibited.',
      },
      secondText: {
        ru: 'Мы приветствуем конструктивные обсуждения и комментарии, но оставляем за собой право удалить контент, нарушающий законы или наши правила использования.',
        en: 'We welcome constructive discussions and comments but We reserve the right to remove content that violates the law or our terms of use.',
      },
      thirdText: {
        ru: 'Пользователи несут ответственность за безопасное хранение и конфиденциальность своих учетных данных. Любые действия, совершенные с использованием их учетных данных, считаются действиями самого пользователя.',
        en: 'Users are responsible for the secure storage and confidentiality of their credentials. confidentiality of their credentials. Any actions taken using their credentials are considered to be the actions of the user. of the user.',
      },
      fourthText: {
        ru: 'Мы не несем ответственности за убытки или ущерб, возникшие в результате использования нашего сайта или его содержимого.',
        en: 'We will not be liable for any loss or damage arising from the use of our website or its contents. as a result of your use of our site or its contents.',
      },
    },
    privacyPolicy: {
      title: {
        ru: 'Политика конфиденциальности',
        en: 'Privacy Policy',
      },
      description: {
        ru: 'Добро пожаловать на страницу политики конфиденциальности [Название вашего сайта]. Мы ценим ваше доверие к нам и стремимся обеспечить максимальную прозрачность в отношении того, как мы собираем, используем, передаем и храним вашу личную информацию. Пожалуйста, внимательно прочтите следующую информацию, чтобы понимать, как мы обрабатываем ваши данные.',
        en: 'Welcome to the privacy policy page of [Name of your site]. We value your trust in us and are committed to providing as transparent as possible about how we collect, use, share and store your personal information. Please, read the following information carefully to understand how we process your data.',
      },
      first: {
        title: {
          ru: 'Сбор и использование личной информации',
          en: 'Collection and use of personal information',
        },
        text: {
          ru: 'Мы собираем только ту личную информацию, которую вы предоставляете добровольно при использовании нашего сайта. Эта информация может включать в себя ваше имя, адрес электронной почты, контактные данные и другую аналогичную информацию. Мы используем эту информацию для улучшения вашего опыта на сайте, предоставления вам запрашиваемых услуг и поддержки.',
          en: 'We only collect personal information that you provide voluntarily when you use our site. This information may include your name, e-mail address, contact information and other similar information. We use this information to improve your experience on the site, to provide you with requested services and support.',
        },
      },
      second: {
        title: {
          ru: 'Предоставление информации третьим лицам',
          en: 'Provision of information to third parties',
        },
        text: {
          ru: 'Ваша личная информация может передаваться третьим лицам только в случаях, когда это необходимо для предоставления вам услуг, к которым вы выразили свое согласие. Мы не раскрываем вашу информацию третьим лицам с целью маркетинга без вашего явного согласия.',
          en: 'Your personal information may be disclosed to third parties only when necessary to provide you with the services to which you have consented. We do not disclose your information to third parties for marketing purposes without your explicit consent.',
        },
      },
      third: {
        title: {
          ru: 'Защита информации',
          en: 'Data protection',
        },
        text: {
          ru: 'Мы прилагаем все разумные усилия для обеспечения безопасности вашей личной информации. Мы используем современные технологии и процедуры, чтобы предотвратить несанкционированный доступ, использование или раскрытие ваших данных.',
          en: 'We make every reasonable effort to ensure the security of your of your personal information. We utilize state-of-the-art technology and procedures to prevent unauthorized access, use or disclosure of your information. disclosure of your information.',
        },
      },
      fourth: {
        title: {
          ru: 'Изменения в политике конфиденциальности',
          en: 'Changes to the privacy policy',
        },
        text: {
          ru: 'Мы оставляем за собой право вносить изменения в нашу политику конфиденциальности по своему усмотрению. Любые изменения будут опубликованы на этой странице, и важные обновления могут быть дополнительно сообщены вам.',
          en: 'We reserve the right to make changes to our privacy policy privacy policy at our sole discretion. Any changes will be posted on this page, and important updates may be further notified to you.',
        },
      },
      cookie: {
        firstBtn: {
          ru: 'Отклонить',
          en: 'Reject',
        },
        secondBtn: {
          ru: 'Принять',
          en: 'Accept',
        },
      },
    },
  },
  giveAway: {
    title: {
      ru: 'Розыгрыш',
      en: 'Giveaway',
    },
    text: {
      ru: 'первая платформа объявлений в Таиланде! Наконец-то появилась удобная платформа, где можно продать ненужное и купить необходимое по адекватной цене.',
      en: 'Thailand`s first classifieds platform! Finally a convenient platform where you can sell what you don`t need. and buy what you need at a reasonable price',
    },
    plus: {
      one: {
        ru: 'Десятки категорий для товаров и услуг',
        en: 'Tens of categories for products and services',
      },
      two: {
        ru: 'Приятный интерфейс',
        en: 'Nice interface',
      },
      three: {
        ru: 'Удобная навигация по районам',
        en: 'Easy navigation by districts',
      },
      four: {
        ru: 'Подробный фильтр',
        en: 'Detailed filter',
      },
      five: {
        ru: 'Рейтинг продавцов',
        en: 'Seller Rating',
      },
      six: {
        ru: 'Чат для общения',
        en: 'Chat for communication',
      },
      seven: {
        ru: 'Афиша со всеми мероприятиями',
        en: 'Poster with all events',
      },
    },
    secondTitle: {
      title: {
        ru: 'В честь грандиозного запуска проводим розыгрыш',
        en: 'In honor of the grand launch, we`re holding a giveaway',
      },
      partOfTitle: {
        ru: 'среди всех новых пользователей платформы',
        en: 'among all new users of the Bazaar platform',
      },
      conditions: {
        one: {
          ru: 'Условия участия в розыгрыше',
          en: 'Conditions of participation in the drawing',
        },
        two: {
          ru: 'Зарегистрируйтесь на платформе Bazaar',
          en: 'Sign up on the Bazaar platform',
        },
        three: {
          ru: 'Сделайте репост публикации о розыгрыше в сторис в',
          en: 'Make a repost of the giveaway post in the stories at',
        },
        four: {
          ru: 'Отметьте друга в комментариях',
          en: 'Tag a friend in the comments below the',
        },
        fourLink: {
          ru: 'под публикацией о розыгрыше',
          en: 'giveaway post',
        },
        five: {
          ru: 'Этапы выбора победителя',
          en: 'Stages of selecting a winner',
        },
        six: {
          ru: 'Мы выберем одного комментатора',
          en: 'We`ll pick one commenter below',
        },
        sixLink: {
          ru: 'под публикацией',
          en: 'the post',
        },
        seven: {
          ru: 'Попросим претендента на победу предоставить скриншот архива сторис с репостом публикации',
          en: 'We`ll ask the runner-up to provide a screenshot of a story archive with a repost of the publication',
        },
        eight: {
          ru: 'И прислать адрес электронной почты, по которой была совершена регистрация на платформе Bazaar',
          en: 'And send the e-mail address where the registration was made registration on the Bazaar',
        },
        nine: {
          ru: 'Попросим претендента на победу предоставить скриншот архива сторис с репостом публикации',
          en: 'Ask the challenger to provide a screenshot of the archive repost story',
        },
        ten: {
          ru: 'И прислать адрес электронной почты, по которой была совершена регистрация на платформе Bazaar',
          en: 'And send the e-mail address, which was used to make a registration on the Bazaar',
        },
        eleven: {
          ru: 'Участвуйте и получите шанс стать обладателем самого актуального смартфона',
          en: 'Enter for a chance to own the most up-to-date smartphone',
        },
        twelve: {
          ru: 'ВАЖНО',
          en: 'IMPORTANT',
        },
        threteen: {
          ru: 'О результатах розыгрыша победитель будет оповещен по электронной почте, поэтому при регистрации на Bazaar укажите действующий адрес.',
          en: 'The winner will be notified about the results of the drawing by e-mail. The winner will be notified by e-mail, so please provide a valid address when registering at Bazaar.',
        },
        fourteen: {
          ru: ' Доставка приза осуществляется только на территории королевства Таиланд, и мы с удовольствием доставим его победителю в любую его точку.',
          en: 'Delivery of the prize is only within the Kingdom of              Thailand, and we will be happy to deliver it to the winner anywhere in the Kingdom of Thailand.',
        },
        fiveteen: {
          ru: 'Дата регистрации пользователя на платформе должна быть до 15 января 2024 года.',
          en: 'The user`s registration date on the platform must be before January 15, 2024.',
        },
      },
      qandn: {
        title: {
          ru: 'Ответы на вопросы',
          en: 'Answers to questions',
        },
        one: {
          question: {
            ru: 'Как стать участником розыгрыша',
            en: 'How to become a participant of the giveaway',
          },
          answer: {
            ru: 'Мы выберем одного комментатора под публикацией ({link}). Сделайте репост публикации о розыгрыше в сторис в Instagram. Отметьте друга в комментариях под публикацией',
            en: 'We will choose one commenter below the post ({link}). Repost the raffle post in your Instagram Stories. Tag a friend in the comments under the publication',
          },
        },
        two: {
          question: {
            ru: 'Как выберете победителей?',
            en: 'How will you choose the winners?',
          },
          answer: {
            ru: 'С помощью генератора случайных чисел среди всех, кто выполнил условия розыгрыша. Победитель должен будет предоставить скриншот архива сторис с репостом публикации, а также адрес электронной почты, с помощью которых была осуществлена регистрация на платформе.',
            en: 'By using a random number generator to randomize everyone who fulfills the terms and conditions of the raffle. The winner will be required to provide a screenshot screenshot of the reposted Stories archive, as well as the e-mail address, which was used to register on the site. email address, which was used to register on the platform platform.',
          },
          answerSecond: {
            ru: ' В случае, если какое-то из условий не будет выполнено - мы будем выбирать новых претендентов до тех пор, пока не обнаружится первый человек, который выполнил полностью условия розыгрыша.',
            en: 'In the event that any of the conditions are not met, we will to select new applicants until the first person is found the first person to fulfill the conditions of the raffle.',
          },
        },
        three: {
          question: {
            ru: 'Когда состоится розыгрыш',
            en: 'When the drawing will take place',
          },
          answer: {
            ru: '25 января 2024 г. при условии оперативного предоставления данных от претендента на победу.',
            en: 'January 25, 2024, subject to prompt submission of data from the winning bidder.',
          },
        },
        four: {
          question: {
            ru: 'Где узнать о результатах розыгрыша',
            en: 'Where to find out about the results of the draw',
          },
          answer: {
            ru: 'Мы свяжемся с победителем в директ в Instagram и по электронной почте, которую он указал при регистрации на платформе Bazaar.',
            en: 'We will contact the winner in Instagram direct and by e-mail. We will contact the winner via direct message on Instagram and by the email he/she indicated when registering on the Bazaar platform.',
          },
        },
        five: {
          question: {
            ru: 'Как забрать приз',
            en: 'How to claim your prize',
          },
          answer: {
            ru: 'Мы бесплатно доставим приз победителю в любую точку Таиланда. Вручение приза возможно только на территории королевства Таиланд.',
            en: 'We will deliver the prize free of charge to the winner anywhere in Thailand. Prize delivery is only possible within the Kingdom of Thailand.',
          },
        },
        six: {
          question: {
            ru: 'Надо ли платить налог на выигрыш',
            en: 'Whether to pay tax on winnings',
          },
          answer: {
            ru: 'Нет, мы заплатим налог сами.',
            en: 'No, we`ll pay the tax ourselves.',
          },
        },
        more: {
          ru: 'Для получения подробных условий акции напишите запрос на',
          en: 'For detailed terms and conditions of the promotion write a request to',
        },
      },
    },
  },
  contest: {
    watchTheVideo: {
      ru: 'смотреть видео',
      en: 'watch the video',
    },
    mainTitle: {
      ru: 'КОНКУРС! Разместите объявление и выиграйте THB 40,000 в',
      en: 'CONTEST! Post an ad and win THB 40,000 in',
    },
    invitationText: {
      ru: 'Приглашаем принять участие в новогоднем конкурсе на платформе Bazaar и выиграть сертификаты в CENTRAL',
      en: 'Join the New Year`s contest on Bazaar platform and win certificates at CENTRAL',
    },
    contestConditions: {
      ru: 'Условия участия в конкурсе',
      en: 'Conditions of participation in the contest',
    },
    conditionOne: {
      ru: 'Зарегистрироваться на bazaarorigin.com',
      en: 'Register on the bazaarorigin.com',
    },
    conditionTwo: {
      ru: 'Выложить минимум одно реальное объявление',
      en: 'Post at least one real ad',
    },
    conditionNote: {
      ru: 'Один раз в неделю среди всех объявлений методом генератора случайных чисел будут выбраны победители!',
      en: 'Once a week, winners will be selected among all ads using a random number generator!',
    },
    contestStages: {
      ru: 'Этапы конкурса',
      en: 'Stages of the Contest',
    },
    winner: {
      ru: ' 8 победителей конкурса станут счастливыми обладателями новогодних сертификатов в ',
      en: '8 winners of the contest will be the lucky winners of New Year`s Eve certificates in certificates in ',
    },
    winnerAnnouncement: {
      ru: 'Победитель объявлен',
      en: 'The winner has been announced',
    },
    winnerAnnouncement7: {
      ru: 'победитель будет объявлен  19 февраля',
      en: 'winner announced on February 19',
    },
    winnerAnnouncement8: {
      ru: 'победитель будет объявлен 26 февраля',
      en: 'winner announced on February 26',
    },

    winnerDate1: {
      ru: '24-27 декабря',
      en: 'December 24-27',
    },
    winnerDate2: {
      ru: '28 декабря-7 января ',
      en: 'December 28- January 7',
    },
    winnerDate3: {
      ru: '08-14 января',
      en: 'January 8-14',
    },
    winnerDate4: {
      ru: '15-21 января',
      en: 'January 15-21 ',
    },
    winnerDate5: {
      ru: '22-28 января',
      en: 'January 22-28',
    },
    winnerDate6: {
      ru: '29 января-04 февраля ',
      en: 'January 29- February 04',
    },
    winnerDate7: {
      ru: '5-18 февраля',
      en: 'February 5-18 ',
    },
    winnerDate8: {
      ru: '19-25 февраля',
      en: 'February 19-25',
    },
    additionalNoteOne: {
      ru: 'Чем больше объявлений вы размещаете на платформе Bazaar, тем больше шансов выиграть.',
      en: 'The more ads you post on Bazaar platform, the higher your chances of winning.',
    },
    additionalNoteTwo: {
      ru: 'Каждое размещенное объявление дает вам дополнительный лотерейный билет, который увеличивает ваши шансы стать счастливым обладателем приза',
      en: 'Each ad you post gives you an additional lottery ticket, increasing your chances of winning the prize',
    },
    certificateInfo: {
      ru: 'Сертификат действует в следующих магазинах сети CENTRAL:',
      en: 'The certificate is valid in the following CENTRAL network stores:',
    },
    detailedConditions: {
      ru: 'Для получения подробных условий акции напишите запрос на',
      en: 'For detailed conditions of the offer, send a request to',
    },
  },
  about: {
    welcomeTitle: {
      ru: 'Добро пожаловать',
      en: 'Welcome',
    },
    to: {
      ru: 'на',
      en: 'to',
    },
    welcomeSubtitle: {
      ru: 'это первая уникальная платформа объявлений в Таиланде, которая создана с целью упростить и обезопасить покупку/продажу товаров и услуг.',
      en: 'the first unique classifieds platform in Thailand, created to simplify and secure the buying/selling of goods and services.',
    },
    whatIsBazaar: {
      ru: 'Что такое ',
      en: 'What is  ',
    },
    bazaarIsPlace: {
      ru: ' это место, где вы можете найти все, что вам нужно, и продать то, что вам больше не нужно.',
      en: ' is a place where you can find everything you need, and sell what you no longer need.',
    },
    bazaarForAll: {
      ru: ' - это место для всех - как для предпринимателей, стремящихся расширить свой бизнес, так и для обычных людей, желающих найти выгодные предложения и продать ненужные вещи.',
      en: ' is a place for everyone - both for entrepreneurs looking to expand their business and for ordinary people wanting to find great deals and sell unwanted items.',
    },
    bazaarAssortment: {
      ru: 'На платформе предоставлен широкий ассортимент товаров и услуг в различных категориях: от бытовой техники и мебели до продажи и аренды автомобилей и недвижимости.',
      en: 'The platform offers a wide range of products and services in various categories: from home appliances and furniture to car sales and real estate.',
    },
    whyBazaarBestChoice: {
      ru: 'Почему Bazaar - это ваш лучший выбор?',
      en: 'Why is Bazaar your best choice?',
    },
    except: {
      ru: 'ВЫГОДА',
      en: 'PROFIT',
    },
    comfort: {
      ru: 'КОМФОРТ',
      en: 'COMFORT',
    },
    adventages: {
      ru: 'УДОБСТВО',
      en: 'ADVANTAGES.',
    },
    kaif: {
      ru: 'Ловите кайф от удобства платформы',
      en: 'Catch a high from the convenience of the platform',
    },
    fast: {
      ru: 'Быстро и легко совершайте сделки',
      en: 'Quickly and easily complete transactions',
    },
    bazaarAdvantages: {
      ru: 'Bazaar предлагает простой интуитивный интерфейс, который позволяет легко находить нужные товары и услуги. А инструменты безопасности помогают снизить риски мошенничества, и вы можете быть уверены в надежности сделок.',
      en: 'Bazaar offers a simple intuitive interface that makes it easy to find the necessary goods and services. Security tools help reduce the risk of fraud, ensuring the reliability of transactions.',
    },
    bazaarCommunity: {
      ru: 'Мы - сообщество торговцев и покупателей',
      en: 'We are a community of traders and merchants',
    },
    bazaarCommunityDescription: {
      ru: 'Bazaar - это не просто площадка, это инклюзивное сообщество. Здесь вы можете общаться с другими пользователями, делиться опытом и находить интересные предложения. Мы считаем, что лучшие сделки заключаются, когда люди объединяются.',
      en: 'Bazaar is not just a platform, it is an inclusive community. Here you can communicate with other users, share experiences, and find interesting offers. We believe that the best deals are made when people come together.',
    },
    joinUs: {
      ru: 'Присоединяйтесь к нам!',
      en: 'Join us!',
    },
    joinUsDescription: {
      ru: 'Мы приглашаем вас зарегистрироваться на платформе Bazaar и начать свой путь к увлекательным сделкам и уникальным находкам',
      en: 'We invite you to register on the Bazaar platform and start your journey to exciting deals and unique finds',
    },
    bazaarFirstPlatform: {
      ru: 'Bazaar - это первая платформа объявлений в Таиланде. Продайте ненужное или найдите то, что вам нужно.',
      en: 'Bazaar is the first classifieds platform in Thailand. Sell what you don`t need or find what you do.',
    },
    signin: {
      ru: 'Зарегистироваться',
      en: 'Sign in',
    },
  },


  categories: {
    renovation: {
      ru: 'Ремонт квартир и домов под ключ',
      en: 'Turnkey renovation of apartments and houses',
    },
    sweatersAndTshirts: {
      ru: 'Кофты и футболки',
      en: 'Sweaters and T-shirts',
    },
    holidays: {
      ru: 'Организация праздников и мероприятий',
      en: 'Organization of holidays and events',
    },
    jacketsAndSuits: {
      ru: 'Пиджаки и костюмы',
      en: 'Jackets and Suits',
    },
    jeans: {
      ru: 'Джинсы',
      en: 'Jeans',
    },
    trousers: {
      ru: 'Брюки',
      en: 'Trousers',
    },
    shirts: {
      ru: 'Рубашки',
      en: 'Shirts',
    },
    sportSuits: {
      ru: 'Спортивные костюмы',
      en: 'Sport Suits',
    },
    shorts: {
      ru: 'Шорты',
      en: 'Shorts',
    },
    underwear: {
      ru: 'Нижнее белье',
      en: 'Underwear',
    },
    otherClothes: {
      ru: 'Другое',
      en: 'Other',
    },
    dresses: {
      ru: 'Платья',
      en: 'Dresses',
    },
    skirts: {
      ru: 'Юбки',
      en: 'Skirts',
    },
    jumpersSweatersCardigans: {
      ru: 'Джемперы, свитеры, кардиганы',
      en: 'Jumpers, Sweaters, Cardigans',
    },
    weddingDresses: {
      ru: 'Свадебные платья',
      en: 'Wedding Dresses',
    },
    shirtsAndBlouses: {
      ru: 'Рубашки и блузки',
      en: 'Shirts and Blouses',
    },
    topsAndTshirts: {
      ru: 'Топы и футболки',
      en: 'Tops and T-shirts',
    },
    swimsuits: {
      ru: 'Купальники',
      en: 'Swimsuits',
    },
    sneakers: {
      ru: 'Кроссовки',
      en: 'Sneakers',
    },
    shoes: {
      ru: 'Туфли',
      en: 'Shoes',
    },
    plimsolls: {
      ru: 'Кеды',
      en: 'Plimsolls',
    },
    loafersAndMoccasins: {
      ru: 'Мoкасины и лоферы',
      en: 'Loafers and Moccasins',
    },
    sportShoes: {
      ru: 'Спортивная обувь',
      en: 'Sport Shoes',
    },
    workShoes: {
      ru: 'Рабочая обувь',
      en: 'Work Shoes',
    },
    Shoes123: {
      ru: 'Обувь',
      en: 'Shoes',
    },
    rubberShoes: {
      ru: 'Резиновая обувь',
      en: 'Rubber Shoes',
    },
    sandals: {
      ru: 'Сандалии',
      en: 'Sandals',
    },
    flipFlopsAndSlides: {
      ru: 'Шлепанцы и сланцы',
      en: 'Flip Flops and Slides',
    },
    homeShoes: {
      ru: 'Домашняя обувь',
      en: 'Home Shoes',
    },
    slipOnsAndEspadrilles: {
      ru: 'Слипоны и эспадрильи',
      en: 'Slip-ons and Espadrilles',
    },
    shoeCare: {
      ru: 'Уход за обувью',
      en: 'Shoe Care',
    },
    pumps: {
      ru: 'Туфли',
      en: 'Pumps',
    },
    sandalsForWomen: {
      ru: 'Босоножки',
      en: 'Sandals for Women',
    },
    ankleBootsAndBooties: {
      ru: 'Батинки и полуботинки',
      en: 'Ankle Boots and Booties',
    },
    sneakersAndPlimsolls: {
      ru: 'Кроссовки и кеды',
      en: 'Sneakers and Plimsolls',
    },
    balletFlats: {
      ru: 'Балетки',
      en: 'Ballet Flats',
    },
    clogsAndMules: {
      ru: 'Сабо и мюли',
      en: 'Clogs and Mules',
    },

    headwear: {
      ru: 'Головные уборы',
      en: 'Headwear',
    },
    glasses: {
      ru: 'Очки',
      en: 'Glasses',
    },
    beltsAndBraces: {
      ru: 'Ремни, пояса, подтяжки',
      en: 'Belts, Belts, and Braces',
    },
    jewelry: {
      ru: 'Ювелирные украшения',
      en: 'Jewelry',
    },
    watches: {
      ru: 'Часы',
      en: 'Watches',
    },
    umbrellasAndRaincoats: {
      ru: 'Зонты и дождевики',
      en: 'Umbrellas and Raincoats',
    },
    hairAccessories: {
      ru: 'Аксессуары для волос',
      en: 'Hair Accessories',
    },
    tiesAndBowties: {
      ru: 'Галстуки и бабочки',
      en: 'Ties and Bowties',
    },
    socksStockingsTights: {
      ru: 'Носки, чулки, колготки',
      en: 'Socks, Stockings, and Tights',
    },
    ManicurePedicure: {
      ru: 'Маникюр, педикюр',
      en: 'Manicure, pedicure',
    },
    HairdresserServices: {
      ru: 'Услуги парикмахера',
      en: 'Hairdresser services',
    },
    EyelashesEyebrows: {
      ru: 'Ресницы, брови',
      en: 'Eyelashes, eyebrows',
    },
    Cosmetology: {
      ru: 'Косметология',
      en: 'Cosmetology',
    },
    Epilation: {
      ru: 'Эпиляция',
      en: 'Epilation',
    },
    SpaServicesMassage: {
      ru: 'СПА-услуги, массаж',
      en: 'Spa services, massage',
    },
    TattooPiercing: {
      ru: 'Тату, пирсинг',
      en: 'Tattoo, piercing',
    },
    Psychology: {
      ru: 'Психология',
      en: 'Psychology',
    },
    Dietetics: {
      ru: 'Диетология',
      en: 'Dietetics',
    },
    WorkspaceRental: {
      ru: 'Аренда рабочего места',
      en: 'Workspace rental',
    },
    Tickets: {
      ru: 'Билеты и путешествия',
      en: 'Tickets and travel',
    },
    Loaders: {
      ru: 'Грузчики, складские услуги',
      en: 'Loaders, warehouse services',
    },
    Repair: {
      ru: 'Ремонт и обслуживание техники',
      en: 'Repair and maintenance technicians',
    },
    Computerhelp: {
      ru: 'Компьютерная помощь',
      en: 'Computer help',
    },
    Equipment: {
      ru: 'Оборудование и производство',
      en: 'Equipment and production',
    },
    Advertising: {
      ru: 'Реклама, полиграфия',
      en: 'Advertising, printing',
    },
    Cleaning1: {
      ru: 'Уборка',
      en: 'Cleaning',
    },
    Vacancies: {
      ru: 'Вакансии',
      en: 'Vacancies',
    },
    Summary: {
      ru: 'Резюме',
      en: 'Summary',
    },
    Tow: {
      ru: 'Услуги эвакуатора',
      en: 'Tow truck services',
    },
    decoration: {
      ru: 'Ремонт и отделка',
      en: 'Repair and decoration',
    },
    Delivery1: {
      ru: 'Доставка еды и продуктов',
      en: 'Food and grocery delivery',
    },
    shooting: {
      ru: 'Фото и видио съемка',
      en: 'Photo and video shooting',
    },
    Nannies: {
      ru: 'Няни, сиделки',
      en: 'Nannies, caregivers',
    },
    Photoandvideoshooting: {
      ru: 'Фото и видеосъёмка',
      en: 'Photo and video shooting',
    },
    Animalcare: {
      ru: 'Уход за животными',
      en: 'Animal care',
    },
    projectors: {
      ru: 'Телевизоры и проекторы',
      en: 'TVs and projectors',
    },
    transportRental: {
      ru: 'Транспорт',
      en: 'Transport',
    },
    cloth: {
      ru: 'Одежда',
      en: 'Clothes',
    },
    services2: {
      ru: 'Услуги',
      en: 'Services',
    },
    cars: {
      ru: 'Автомобили',
      en: 'Cars',
    },
    motorcycles: {
      ru: 'Мотоциклы',
      en: 'Motorcycles',
    },
    trucks: {
      ru: 'Грузовики и спецтехника',
      en: 'Trucks and special equipment',
    },
    airtransport: {
      en: 'Air transport',
      ru: 'Воздушный транспорт',
    },
    Watertransport: {
      ru: 'Водный транспорт',
      en: 'Water transport',
    },
    scooters: {
      ru: 'Скутеры',
      en: 'Scooters',
    },
    transfer: {
      ru: 'Трансфер',
      en: 'Transfer',
    },
    // propertiesForRent: {
    //   ru: "Недвижимость",
    //   en: "Properties for rent",
    // },
    // rent: {
    //   ru: "Снять посуточно",
    //   en: "To rent daily",
    // },
    // long: {
    //   ru: "Снять посуточно",
    //   en: "To rent long-term",
    // },
    // buy: {
    //   ru: "Купить жильё",
    //   en: "Buy a house",
    // },
    autoParts: {
      ru: 'Запчасти',
      en: 'Auto Parts',
    },
    // tools: {
    //   ru: "Инструменты",
    //   en: "Tools",
    // },
    tiresWheels: {
      ru: 'Шины, диски и колёса',
      en: 'Tires, Wheels and Rims',
    },
    trailers: {
      ru: 'Прицепы',
      en: 'Trailers',
    },
    audioVideo: {
      ru: 'Аудио и видео',
      en: 'Audio and Video Equipment',
    },
    gear: {
      ru: 'Экипировка',
      en: 'Gear',
    },
    accessories: {
      ru: 'Аксессуары',
      en: 'Accessories',
    },
    ForBoys: {
      ru: 'Для мальчиков',
      en: 'For boys',
    },
    ForGirls: {
      ru: 'Для девочек',
      en: 'For girls',
    },
    oilsChemicals: {
      ru: 'Масла и автохимия',
      en: 'Oils and Auto Chemicals',
    },
    tuning: {
      ru: 'Тюнинг',
      en: 'Tuning',
    },
    gps: {
      ru: 'GPS-навигаторы',
      en: 'GPS Navigators',
    },
    racksHitches: {
      ru: 'Багажники и фаркопы',
      en: 'Racks and Hitches',
    },
    supplies: {
      ru: 'Оргтехника и расходники',
      en: 'Office Equipment and Supplies',
    },

    huntingFishing: {
      ru: 'Рыбалка',
      en: 'Fishing',
    },
    collectibles: {
      ru: 'Коллекционирование',
      en: 'Collectibles',
    },
    petSupplies: {
      ru: 'Товары для животных',
      en: 'Pet Supplies',
    },
    employment: {
      ru: 'Ищу сотрудника',
      en: 'Employment',
    },
    educationCourses: {
      ru: 'Обучение и курсы',
      en: 'Education and Courses',
    },
    events: {
      ru: 'Праздники и мероприятия',
      en: 'Events and Celebrations',
    },
    passengerTransport: {
      ru: 'Пассажирские перевозки',
      en: 'Passenger Transport',
    },
    IT: {
      ru: 'IT, интернет, телеком',
      en: 'IT, internet, telecom',
    },

    propertiesForRent: {
      ru: 'Недвижимость',
      en: 'Properties',
    },
    rent: {
      ru: 'Снять посуточно',
      en: 'To Rent Daily',
    },
    long: {
      ru: 'Снять долгосрочно',
      en: 'To Rent Long-term',
    },
    buy: {
      ru: 'Купить жильё',
      en: 'Buy a House',
    },
    services: {
      ru: 'Услуги',
      en: 'Services',
    },
    cargoTransportation: {
      ru: 'Грузоперевозки',
      en: 'Cargo transportation',
    },
    moversWarehouseServices: {
      ru: 'Грузчики складские услуги',
      en: 'Movers warehouse services',
    },
    towTruckServices: {
      ru: 'Услуги эвакуатора',
      en: 'Tow truck services',
    },
    repairAndFinishing: {
      ru: 'Ремонт и строительство',
      en: 'Repair and finishing',
    },
    turnkeyRenovation: {
      ru: 'Ремонт квартир и домов под ключ',
      en: 'Turnkey renovation of apartments and houses',
    },
    plumbing: {
      ru: 'Сантехника',
      en: 'Plumbing',
    },
    BuildingMaterials: {
      ru: 'Стройматериалы',
      en: 'Building materials',
    },
    Tools: {
      ru: 'Инструменты',
      en: 'Tools',
    },
    PlumbingWaterSupplySauna: {
      ru: 'Сантехника, водоснабжение и сауна',
      en: 'Plumbing, water supply, and sauna',
    },
    Doors: {
      ru: 'Двери',
      en: 'Doors',
    },
    GardenEquipment: {
      ru: 'Садовая техника',
      en: 'Garden equipment',
    },
    electrics: {
      ru: 'Электрика',
      en: 'Electrics',
    },
    furnitureAssemblyRepair: {
      ru: 'Сборка и ремонт мебели',
      en: 'Furniture assembly and repair',
    },
    wallpaperingPainting: {
      ru: 'Поклейка обоев и малярные работы',
      en: 'Wallpapering and painting',
    },
    floorsFloorCoverings: {
      ru: 'Полы и напольные покрытия',
      en: 'Floors and floor coverings',
    },
    plasteringWorks: {
      ru: 'Штукатурные работы',
      en: 'Plastering works',
    },
    doors: {
      ru: 'Двери',
      en: 'Doors',
    },
    tileWork: {
      ru: 'Плиточные работы',
      en: 'Tile work',
    },
    carpentry: {
      ru: 'Столярные и плотницкие работы',
      en: 'Carpentry',
    },
    drywallWork: {
      ru: 'Гипсокартонные работы',
      en: 'Drywall work',
    },
    highAltitudeWork: {
      ru: 'Высотные работы',
      en: 'High-altitude work',
    },
    metalStructuresForgedProducts: {
      ru: 'Металлоконструкции и кованые изделия',
      en: 'Metal structures and forged products',
    },
    insulation: {
      ru: 'Изоляция',
      en: 'Insulation',
    },
    officeRenovation: {
      ru: 'Ремонт офиса',
      en: 'Office renovation',
    },
    ventilation: {
      ru: 'Вентиляция',
      en: 'Ventilation',
    },
    other: {
      ru: 'Другое',
      en: 'Other',
    },
    /// /////////////////////////////
    TVs: {
      ru: 'Телевизоры',
      en: 'TVs',
    },
    MobileDevices: {
      ru: 'Мобильные устройства',
      en: 'Mobile devices',
    },
    PhotoAudioVideoEquipment: {
      ru: 'Фото, аудио, видеотехника',
      en: 'Photo, audio, video equipment',
    },
    AirConditionersVentilation: {
      ru: 'Кондиционеры, вентиляция',
      en: 'Air conditioners, ventilation',
    },
    WashingDryingMachines: {
      ru: 'Стиральные, сушильные машины',
      en: 'Washing, drying machines',
    },
    Dishwashers: {
      ru: 'Посудомоечные машины',
      en: 'Dishwashers',
    },
    RefrigeratorsFreezers: {
      ru: 'Холодильники, морозильные камеры',
      en: 'Refrigerators, freezers',
    },
    CooktopsOvens: {
      ru: 'Варочные панели, духовые шкафы',
      en: 'Cooktops, ovens',
    },
    WaterHeaters: {
      ru: 'Водонагреватели',
      en: 'Water heaters',
    },
    CoffeeMachines: {
      ru: 'Кофемашины',
      en: 'Coffee machines',
    },
    SewingMachinesOverlocks: {
      ru: 'Швейные машины, оверлоки',
      en: 'Sewing machines, overlocks',
    },
    EquipmentRental: {
      ru: 'Аренда оборудования',
      en: 'Equipment rental',
    },
    EquipmentInstallationMaintenance: {
      ru: 'Монтаж и обслуживание оборудования',
      en: 'Equipment installation and maintenance',
    },
    /// //////////////
    building: {
      ru: 'Строительство',
      en: 'Building',
    },
    turnkeyHouseConstruction: {
      ru: 'Строительство домов под ключ',
      en: 'Turnkey house construction',
    },
    masonryWork: {
      ru: 'Кладочные работы',
      en: 'Masonry work',
    },
    roofingWorks: {
      ru: 'Кровельные работы',
      en: 'Roofing works',
    },
    weldingWorks: {
      ru: 'Сварочные работы',
      en: 'Welding works',
    },
    concreteWorks: {
      ru: 'Бетонные работы',
      en: 'Concrete works',
    },
    foundationWorks: {
      ru: 'Фундаментные работы',
      en: 'Foundation works',
    },
    demolitionDismantling: {
      ru: 'Снос и демонтаж',
      en: 'Demolition and dismantling',
    },
    facadeWorks: {
      ru: 'Фасадные работы',
      en: 'Facade works',
    },
    designEstimates: {
      ru: 'Проектирование и сметы',
      en: 'Design and estimates',
    },
    handyman: {
      ru: 'Мастер на час',
      en: 'Handyman',
    },
    gardenLandscaping: {
      ru: 'Сад, благоустройство',
      en: 'Garden, landscaping',
    },
    reservoirsFountains: {
      ru: 'Водоемы и фонтаны',
      en: 'Reservoirs and fountains',
    },
    roadConstruction: {
      ru: 'Дорожное строительство',
      en: 'Road construction',
    },
    fencesCanopies: {
      ru: 'Заборы, ограждения, навесы',
      en: 'Fences, fences, canopies',
    },
    earthworks: {
      ru: 'Земляные работы',
      en: 'Earthworks',
    },
    greeningGardening: {
      ru: 'Озеленение, уход за садом и огородом',
      en: 'Greening, gardening and gardening',
    },
    rollerShuttersGates: {
      ru: 'Рольставни и ворота',
      en: 'Roller shutters and gates',
    },
    beautyHealth: {
      ru: 'Красота и здоровье',
      en: 'Beauty and health',
    },
    manicurePedicure: {
      ru: 'Маникюр, педикюр',
      en: 'Manicure, pedicure',
    },
    hairdressingServices: {
      ru: 'Услуги парикмахера',
      en: 'Hairdressing services',
    },
    eyelashesEyebrows: {
      ru: 'Ресницы, брови',
      en: 'Eyelashes, eyebrows',
    },
    cosmetology: {
      ru: 'Косметология',
      en: 'Cosmetology',
    },
    hairRemoval: {
      ru: 'Эпиляция',
      en: 'Hair removal',
    },
    makeup: {
      ru: 'Макияж',
      en: 'Makeup',
    },
    spaMassage: {
      ru: 'СПА-услуги, массаж',
      en: 'SPA services, massage',
    },
    tattoosPiercings: {
      ru: 'Тату, пирсинг',
      en: 'Tattoos, piercings',
    },
    psychology: {
      ru: 'Психология',
      en: 'Psychology',
    },
    dietetics: {
      ru: 'Диетология',
      en: 'Dietetics',
    },
    workplaceRental: {
      ru: 'Аренда рабочего места',
      en: 'Workplace rental',
    },
    repairMaintenanceOfEquipment: {
      ru: 'Ремонт и обслуживание техники',
      en: 'Repair and maintenance of equipment',
    },
    maintenance: {
      ru: 'Ремонт и обслуживание техники',
      en: 'Repair and maintenance of equipment',
    },
    televisions: {
      ru: 'Телевизоры',
      en: 'Televisions',
    },
    mobileDevices: {
      ru: 'Мобильные устройства',
      en: 'Mobile devices',
    },
    photoAudioVideoEquipment: {
      ru: 'Фото-, аудио-, видеотехника',
      en: 'Photo, audio, video equipment',
    },
    airConditionersVentilation: {
      ru: 'Кондиционеры, вентиляция',
      en: 'Air conditioners, ventilation',
    },
    washingDryingMachines: {
      ru: 'Стиральные, сушильные машины',
      en: 'Washing machines, dryers',
    },
    dishwashers: {
      ru: 'Посудомоечные машины',
      en: 'Dishwashers',
    },
    refrigeratorsFreezers: {
      ru: 'Холодильники, морозильные камеры',
      en: 'Refrigerators, freezers',
    },
    cooktopsOvens: {
      ru: 'Варочные панели, духовые шкафы',
      en: 'Cooktops, ovens',
    },
    waterHeaters: {
      ru: 'Водонагреватели',
      en: 'Water heaters',
    },
    coffeeMachines: {
      ru: 'Кофемашины',
      en: 'Coffee machines',
    },
    sewingMachinesOverlocks: {
      ru: 'Швейные машины, оверлоки',
      en: 'Sewing machines, overlocks',
    },
    computerHelp: {
      ru: 'Компьютерная помощь',
      en: 'Computer help',
    },
    computers: {
      ru: 'Компьютеры',
      en: 'Computers',
    },
    printers: {
      ru: 'Принтеры',
      en: 'Printers',
    },
    gameConsoles: {
      ru: 'Игровые приставки',
      en: 'Game consoles',
    },
    osAndPrograms: {
      ru: 'ОС и программы',
      en: 'OS and programs',
    },
    internetAndOtherNetworks: {
      ru: 'Интернет и другие сети',
      en: 'The Internet and other networks',
    },
    equipmentProduction: {
      ru: 'Оборудование, производство',
      en: 'Equipment, production',
    },
    equipmentRental: {
      ru: 'Аренда оборудования',
      en: 'Equipment rental',
    },
    installationMaintenanceOfEquipment: {
      ru: 'Монтаж и обслуживание оборудования',
      en: 'Installation and maintenance of equipment',
    },
    trainingCourses: {
      ru: 'Обучение, курсы',
      en: 'Training, courses',
    },
    foreignLanguages: {
      ru: 'Иностранные языки',
      en: 'Foreign languages',
    },
    childDevelopmentSpeechTherapists: {
      ru: 'Детское развитие, логопеды',
      en: 'Child development, speech therapists',
    },
    itBusiness: {
      ru: 'IT, бизнес',
      en: 'IT, business',
    },
    designDrawing: {
      ru: 'Дизайн, рисование',
      en: 'Design, drawing',
    },
    sportsDancing: {
      ru: 'Спорт, танцы',
      en: 'Sports, dancing',
    },
    driving: {
      ru: 'Вождение',
      en: 'Driving',
    },
    musicTheater: {
      ru: 'Музыка, театр',
      en: 'Music, theater',
    },
    businessServices: {
      ru: 'Деловые услуги',
      en: 'Business services',
    },
    accountingFinance: {
      ru: 'Бухгалтерия, финансы',
      en: 'Accounting, finance',
    },
    translation: {
      ru: 'Перевод',
      en: 'Translation',
    },
    insurance: {
      ru: 'Страхование',
      en: 'Insurance',
    },
    legalServices: {
      ru: 'Юридические услуги',
      en: 'Legal services',
    },
    itMarketing: {
      ru: 'IT, маркетинг',
      en: 'IT, marketing',
    },
    websiteCreationPromotion: {
      ru: 'Cоздание и продвижение сайтов',
      en: 'Creation and promotion of websites',
    },
    softwareInstallationConfiguration: {
      ru: 'Установка и настройка ПО',
      en: 'Software installation and configuration',
    },
    internetNetworksSetup: {
      ru: 'Настройка интернета и сетей',
      en: 'Setting up the Internet and networks',
    },
    advertisingMarketingPromotion: {
      ru: 'Реклама, маркетинг, продвижение',
      en: 'Advertising, marketing, promotion',
    },
    programmingCRMSetup: {
      ru: 'Программирование, настройка CRM',
      en: 'Programming, CRM setup',
    },
    websiteApplicationCreation: {
      ru: 'Создание сайтов и приложений',
      en: 'Creating websites and applications',
    },
    Softwareconfiguration: {
      ru: 'Установки и настройка ПО',
      en: 'Software installations and configuration',
    },
    advertisingPrinting: {
      ru: 'Реклама, полиграфия',
      en: 'Advertising, printing',
    },
    marketingAdvertisingPR: {
      ru: 'Маркетинг, реклама, PR',
      en: 'Marketing, advertising, PR',
    },
    printingDesign: {
      ru: 'Полиграфия, дизайн',
      en: 'Printing, design',
    },
    cleaning: {
      ru: 'Уборка',
      en: 'Cleaning',
    },
    generalCleaning: {
      ru: 'Генеральная уборка',
      en: 'General cleaning',
    },
    disinfectionDisinsection: {
      ru: 'Дезинфекция, дезинсекция',
      en: 'Disinfection, disinsection',
    },
    windowCleaning: {
      ru: 'Мойка окон',
      en: 'Window cleaning',
    },
    simpleCleaning: {
      ru: 'Простая уборка',
      en: 'Simple cleaning',
    },
    cleaningAfterRepair: {
      ru: 'Уборка после ремонта',
      en: 'Cleaning after repair',
    },
    carpetCleaning: {
      ru: 'Чистка ковров',
      en: 'Carpet cleaning',
    },
    upholsteredFurnitureCleaning: {
      ru: 'Чистка мягкой мебели',
      en: 'Cleaning of upholstered furniture',
    },
    householdServices: {
      ru: 'Бытовые услуги',
      en: 'Household services',
    },
    keyMaking: {
      ru: 'Изготовление ключей',
      en: 'Key making',
    },
    tailoringClothesRepair: {
      ru: 'Пошив и ремонт одежды',
      en: 'Tailoring and repair of clothes',
    },
    watchRepair: {
      ru: 'Ремонт часов',
      en: 'Watch repair',
    },
    dryCleaningLaundry: {
      ru: 'Химчистка, стирка',
      en: 'Dry cleaning, laundry',
    },
    jewelryServices: {
      ru: 'Ювелирные услуги',
      en: 'Jewelry services',
    },
    holidaysEvents: {
      ru: 'Организация праздников и мероприятий',
      en: 'Organization of holidays and events',
    },
    foodGroceryDelivery: {
      ru: 'Доставка еды и продуктов',
      en: 'Food and grocery delivery',
    },
    photoVideoShooting: {
      ru: 'Фото- и видеосъемка',
      en: 'Photo and video shooting',
    },
    nanniesNurses: {
      ru: 'Няни, сиделки',
      en: 'Nannies, nurses',
    },
    animalCare: {
      ru: 'Уход за животными',
      en: 'Animal care',
    },
    safetySecurity: {
      ru: 'Охрана, безопасность',
      en: 'Safety and security',
    },
    personalBelongings: {
      ru: 'Личные вещи',
      en: 'Personal belongings',
    },
    clothingShoesAccessories: {
      ru: 'Одежда',
      en: 'Clothing, shoes, accessories',
    },
    mensClothing: {
      ru: 'Мужская одежда',
      en: 'Men`s clothing',
    },
    sweatshirtsTshirts: {
      ru: 'Кофты и футболки',
      en: 'Sweatshirts and T-shirts',
    },
    jacketsSuits: {
      ru: 'Пиджаки и костюмы',
      en: 'Jackets and suits',
    },
    tracksuits: {
      ru: 'Спортивные костюмы',
      en: 'Tracksuits',
    },
    Moccasins: {
      ru: 'Мокасины и лоферы',
      en: 'Moccasins and loafers',
    },
    womensClothing: {
      ru: 'Женская одежда',
      en: 'Women`s clothing',
    },
    jumpersCardigans: {
      ru: 'Джемперы, свитеры, кардиганы',
      en: 'Jumpers, sweaters, cardigans',
    },
    womensJacketsSuits: {
      ru: 'Пиджаки и костюмы',
      en: 'Jackets and suits',
    },
    womensTrousers: {
      ru: 'Брюки',
      en: 'Trousers',
    },
    womensJeans: {
      ru: 'Джинсы',
      en: 'Jeans',
    },
    womensUnderwear: {
      ru: 'Нижнее бельё',
      en: 'Underwear',
    },
    shirtsBlouses: {
      ru: 'Рубашки и блузки',
      en: 'Shirts and blouses',
    },
    topsTshirts: {
      ru: 'Топы и футболки',
      en: 'Tops and T-shirts',
    },
    mensShoes: {
      ru: 'Мужская обувь',
      en: 'Men`s shoes',
    },
    slipOnsEspadrilles: {
      ru: 'Слипоны и эспадрильи',
      en: 'Slip-ons and espadrilles',
    },
    womensShoes: {
      ru: 'Женская обувь',
      en: 'Women`s shoes',
    },
    bootsHalfBoots: {
      ru: 'Ботинки и полуботинки',
      en: 'Boots and half-boots',
    },
    clogsMules: {
      ru: 'Сабо и мюли',
      en: 'Clogs and mules',
    },
    flipFlopsSlates: {
      ru: 'Шлёпанцы и сланцы',
      en: 'Flip-flops and slates',
    },
    hats: {
      ru: 'Головные уборы',
      en: 'Hats',
    },
    beltsSuspenders: {
      ru: 'Ремни, пояса, подтяжки',
      en: 'Belts, belts, suspenders',
    },
    theClock: {
      ru: 'Часы',
      en: 'The clock',
    },
    umbrellasRaincoats: {
      ru: 'Зонты и дождевики',
      en: 'Umbrellas and raincoats',
    },
    tiesBowTies: {
      ru: 'Галстуки и бабочки',
      en: 'Ties and bow ties',
    },
    childrensClothingShoes: {
      ru: 'Детская одежда и обувь',
      en: 'Children`s clothing and shoes',
    },
    childrensProductsToys: {
      ru: 'Товары для детей и игрушки',
      en: 'Children`s products and toys',
    },
    babyStrollers: {
      ru: 'Детские коляски',
      en: 'Baby strollers',
    },
    childrensFurniture: {
      ru: 'Детская мебель',
      en: 'Children`s furniture',
    },
    bicyclesScooters: {
      ru: 'Велосипеды и самокаты',
      en: 'Bicycles and scooters',
    },
    feedingProducts: {
      ru: 'Товары для кормления',
      en: 'Feeding products',
    },
    carSeats: {
      ru: 'Автомобильные кресла',
      en: 'Car seats',
    },
    toys: {
      ru: 'Игрушки',
      en: 'Toys',
    },
    bathingProducts: {
      ru: 'Товары для купания',
      en: 'Bathing products',
    },
    schoolSupplies: {
      ru: 'Товары для школы',
      en: 'School supplies',
    },
    DevicesAccessories: {
      ru: 'Приборы и аксессуары',
      en: 'Devices and accessories',
    },
    Perfumery: {
      ru: 'Парфюмерия',
      en: 'Perfumery',
    },
    HygieneProducts: {
      ru: 'Средства гигиены',
      en: 'Hygiene products',
    },
    Cosmetics: {
      ru: 'Косметика',
      en: 'Cosmetics',
    },
    HairCareProducts: {
      ru: 'Средства для волос',
      en: 'Hair care products',
    },
    MedicalDevices: {
      ru: 'Медицинские изделия',
      en: 'Medical devices',
    },

    beautyAndHealth: {
      ru: 'Красота и здоровье',
      en: 'Beauty and health',
    },
    appliancesAndAccessories: {
      ru: 'Приборы и аксессуары',
      en: 'Appliances and accessories',
    },
    perfumery: {
      ru: 'Парфюмерия',
      en: 'Perfumery',
    },
    hygieneProducts: {
      ru: 'Средства гигиены',
      en: 'Hygiene products',
    },
    cosmetics: {
      ru: 'Косметика',
      en: 'Cosmetics',
    },
    hairProducts: {
      ru: 'Средства для волос',
      en: 'Hair products',
    },
    medicalDevices: {
      ru: 'Медицинские изделия',
      en: 'Medical devices',
    },
    forTheHome: {
      ru: 'Для дома',
      en: 'For the home',
    },
    repairAndConstruction: {
      ru: 'Ремонт и строительство',
      en: 'Repair and construction',
    },
    buildingMaterials: {
      ru: 'Стройматериалы',
      en: 'Building materials',
    },
    tools: {
      ru: 'Инструменты',
      en: 'Tools',
    },
    sanitaryWaterSupplySauna: {
      ru: 'Сантехника, водоснабжение и сауна',
      en: 'Sanitary, water supply and sauna',
    },
    doorsCategory: {
      ru: 'Двери',
      en: 'Doors',
    },
    gardenEquipment: {
      ru: 'Садовая техника',
      en: 'Garden equipment',
    },
    furnitureAndInterior: {
      ru: 'Мебель и интерьер',
      en: 'Furniture and interior',
    },
    bedsSofasArmchairs: {
      ru: 'Кровати, диваны и кресла',
      en: 'Beds, sofas and armchairs',
    },
    cupboardsDressersShelving: {
      ru: 'Шкафы, комоды и стеллажи',
      en: 'Cupboards, dressers and shelving',
    },
    tablesChairs: {
      ru: 'Столы и стулья',
      en: 'Tables and chairs',
    },
    textilesCarpets: {
      ru: 'Текстиль и ковры',
      en: 'Textiles and carpets',
    },
    lighting: {
      ru: 'Освещение',
      en: 'Lighting',
    },
    computerDesksChairs: {
      ru: 'Компьютерные столы и кресла',
      en: 'Computer desks and chairs',
    },
    householdAppliances: {
      ru: 'Бытовая техника',
      en: 'Household appliances',
    },
    forTheKitchen: {
      ru: 'Для кухни',
      en: 'For the kitchen',
    },
    forHome: {
      ru: 'Для дома',
      en: 'For the home',
    },
    climateControlEquipment: {
      ru: 'Климатическое оборудование',
      en: 'Climate control equipment',
    },
    forIndividualCare: {
      ru: 'Для индивидуального ухода',
      en: 'For individual care',
    },
    storageOfProducts: {
      ru: 'Хранение продуктов',
      en: 'Storage of products',
    },
    preparationOfDrinks: {
      ru: 'Приготовление напитков',
      en: 'Preparation of drinks',
    },
    televisionsProjectors: {
      ru: 'Телевизоры и проекторы',
      en: 'Televisions and projectors',
    },
    equipmentAccessories: {
      ru: 'Оборудование и аксессуары',
      en: 'Equipment and accessories',
    },
    foodProducts: {
      ru: 'Продукты питания',
      en: 'Food products',
    },
    plants: {
      ru: 'Растения',
      en: 'Plants',
    },
    tablewareKitchenGoods: {
      ru: 'Посуда и товары для кухни',
      en: 'Tableware and kitchen goods',
    },
    tableSetting: {
      ru: 'Сервировка стола',
      en: 'Table setting',
    },
    cooking: {
      ru: 'Приготовление пищи',
      en: 'Cooking',
    },
    foodStorage: {
      ru: 'Хранение продуктов',
      en: 'Storage of products',
    },
    drinkPreparation: {
      ru: 'Приготовление напитков',
      en: 'Preparation of drinks',
    },
    householdGoods: {
      ru: 'Хозяйственные товары',
      en: 'Household goods',
    },
    kitchenAccessories: {
      ru: 'Кухонные аксессуары',
      en: 'Kitchen accessories',
    },
    sparePartsAccessories: {
      ru: 'Запчасти и аксессуары',
      en: 'Spare parts and accessories',
    },
    forVehicles: {
      ru: 'Для автотранспорта',
      en: 'For vehicles',
    },
    forMotorVehicles: {
      ru: 'Для мототранспорта',
      en: 'For motor vehicles',
    },
    electronics: {
      ru: 'Электроника',
      en: 'Electronics',
    },
    phones: {
      ru: 'Телефоны',
      en: 'Phone',
    },
    mobilePhones: {
      ru: 'Мобильные телефоны',
      en: 'Mobile phones',
    },
    phoneAccessories: {
      ru: 'Аксессуары',
      en: 'Accessories',
    },
    tvsProjectors: {
      ru: 'Телевизоры и проекторы',
      en: 'Televisions and projectors',
    },
    headphones: {
      ru: 'Наушники',
      en: 'Headphones',
    },
    ForeignLanguages: {
      ru: 'Иностранные языки',
      en: 'Foreign languages',
    },
    ChildDevelopmentSpeechTherapists: {
      ru: 'Детское развитие, логопеды',
      en: 'Child development, speech therapists',
    },
    ITBusiness: {
      ru: 'IT, бизнес',
      en: 'IT, business',
    },
    DesignDrawing: {
      ru: 'Дизайн, рисование',
      en: 'Design, drawing',
    },
    BeautyHealth: {
      ru: 'Красота и здоровье',
      en: 'Beauty and health',
    },
    SportsDance: {
      ru: 'Спорт, танцы',
      en: 'Sports, dance',
    },
    Driving: {
      ru: 'Вождение',
      en: 'Driving',
    },
    MusicTheater: {
      ru: 'Музыка, театр',
      en: 'Music, theater',
    },
    city: {
      ru: 'По городу',
      en: 'Within the city',
    },
    /// ////////////////////////
    WebsiteCreationPromotion: {
      ru: 'Создание и продвижение сайтов',
      en: 'Website creation and promotion',
    },
    SoftwareInstallationConfiguration: {
      ru: 'Установка и настройка ПО',
      en: 'Software installation and configuration',
    },
    InternetNetworkConfiguration: {
      ru: 'Настройка интернета и сетей',
      en: 'Internet and network configuration',
    },
    AdvertisingMarketingPromotion: {
      ru: 'Реклама, маркетинг, продвижение',
      en: 'Advertising, marketing, promotion',
    },
    ProgrammingCRMConfiguration: {
      ru: 'Программирование, настройка CRM',
      en: 'Programming, CRM configuration',
    },
    WebsiteAppCreation: {
      ru: 'Создание сайтов и приложений',
      en: 'Website and app creation',
    },
    /// ////////////////////////////
    between: {
      ru: 'Между городами',
      en: 'Between cities',
    },
    acousticsSpeakersSubwoofers: {
      ru: 'Акустика, колонки, сабвуферы',
      en: 'Acoustics, speakers, subwoofers',
    },
    computerProducts: {
      ru: 'Товары для компьютера',
      en: 'Computer products',
    },
    computerAccessories: {
      ru: 'Комплектующие',
      en: 'Accessories',
    },
    monitors: {
      ru: 'Мониторы',
      en: 'Monitors',
    },
    keyboardsMice: {
      ru: 'Клавиатуры и мыши',
      en: 'Keyboards and mice',
    },
    gamesConsolesPrograms: {
      ru: 'Игры, приставки и программы',
      en: 'Games, consoles and programs',
    },
    gameConsoles2: {
      ru: 'Игровые приставки',
      en: 'Game consoles',
    },
    gamesForConsoles: {
      ru: 'Игры для приставок',
      en: 'Games for consoles',
    },
    computerGames: {
      ru: 'Компьютерные игры',
      en: 'Computer games',
    },
    laptops: {
      ru: 'Ноутбуки',
      en: 'Laptops',
    },
    desktopComputers: {
      ru: 'Настольные компьютеры',
      en: 'Desktop computers',
    },
    systemBlocks: {
      ru: 'Системные блоки',
      en: 'System blocks',
    },
    monoblocks: {
      ru: 'Моноблоки',
      en: 'Monoblocks',
    },
    photographicEquipment: {
      ru: 'Фототехника',
      en: 'Photographic equipment',
    },
    photoEquipmentAccessories: {
      ru: 'Оборудование и аксессуары',
      en: 'Equipment and accessories',
    },
    lenses: {
      ru: 'Объективы',
      en: 'Lenses',
    },
    compactCameras: {
      ru: 'Компактные фотоаппараты',
      en: 'Compact cameras',
    },
    filmCameras: {
      ru: 'Плёночные фотоаппараты',
      en: 'Film cameras',
    },
    slrCameras: {
      ru: 'Зеркальные фотоаппараты',
      en: 'SLR cameras',
    },
    binocularsTelescopes: {
      ru: 'Бинокли и телескопы',
      en: 'Binoculars and telescopes',
    },
    tabletsEbooks: {
      ru: 'Планшеты и электронные книги',
      en: 'Tablets and e-books',
    },
    tablets2: {
      ru: 'Планшеты',
      en: 'Tablets',
    },
    tabletAccessories: {
      ru: 'Аксессуары',
      en: 'Accessories',
    },
    eBooks: {
      ru: 'Электронные книги',
      en: 'E-books',
    },
    hobbiesRecreation: {
      ru: 'Хобби и отдых',
      en: 'Hobbies and recreation',
    },
    ticketsTravel: {
      ru: 'Билеты и путешествия',
      en: 'Tickets and travel',
    },
    concerts: {
      ru: 'Концерты',
      en: 'Concerts',
    },
    travel: {
      ru: 'Путешествия',
      en: 'Travel',
    },
    sports2: {
      ru: 'Спорт',
      en: 'Sports',
    },
    bicycles: {
      ru: 'Велосипеды',
      en: 'Bicycles',
    },
    booksMagazines: {
      ru: 'Книги и журналы',
      en: 'Books and magazines',
    },
    musicalInstruments: {
      ru: 'Музыкальные инструменты',
      en: 'Musical instruments',
    },
    fishing: {
      ru: 'Рыбалка',
      en: 'Fishing',
    },
    sportsRecreation: {
      ru: 'Спорт и отдых',
      en: 'Sports and recreation',
    },
    billiardsBowling: {
      ru: 'Бильярд и боулинг',
      en: 'Billiards and bowling',
    },
    divingWaterSports: {
      ru: 'Дайвинг и водный спорт',
      en: 'Diving and water sports',
    },
    martialArts: {
      ru: 'Единоборства',
      en: 'Martial arts',
    },
    ballGames: {
      ru: 'Игры с мячом',
      en: 'Ball games',
    },
    boardGames: {
      ru: 'Настольные игры',
      en: 'Board games',
    },
    rollerSkatingSkateboarding: {
      ru: 'Ролики и скейтбординг',
      en: 'Roller skates and skateboarding',
    },
    tennisBadmintonPingPong: {
      ru: 'Теннис, бадминтон, пинг-понг',
      en: 'Tennis, badminton, ping pong',
    },
    tourism: {
      ru: 'Туризм',
      en: 'Tourism',
    },
    fitnessExerciseEquipment: {
      ru: 'Фитнес и тренажёры',
      en: 'Fitness and exercise equipment',
    },
    sportsNutrition: {
      ru: 'Спортивное питание',
      en: 'Sports nutrition',
    },
    animals: {
      ru: 'Животные',
      en: 'Animals',
    },
    dogs: {
      ru: 'Собаки',
      en: 'Dogs',
    },
    cats: {
      ru: 'Кошки',
      en: 'Cats',
    },
    birds: {
      ru: 'Птицы',
      en: 'Birds',
    },
    aquarium: {
      ru: 'Аквариум',
      en: 'Aquarium',
    },
    otherAnimals: {
      ru: 'Другие животные',
      en: 'Other animals',
    },
    businessEquipment: {
      ru: 'Бизнес и оборудование',
      en: 'Business and equipment',
    },
    readyMadeBusiness: {
      ru: 'Готовый бизнес',
      en: 'Ready-made business',
    },
    businessEquipmentForBusiness: {
      ru: 'Оборудование для бизнеса',
      en: 'Business equipment',
    },
    job: {
      ru: 'Работа',
      en: 'Job',
    },
    lookingForAJob: {
      ru: 'Ищу работу',
      en: 'Looking for a job',
    },
    itJob: {
      ru: 'IT',
      en: 'IT',
    },
    automotiveBusiness: {
      ru: 'Автомобильный бизнес',
      en: 'Automotive business',
    },
    administrativeWork: {
      ru: 'Административная работа',
      en: 'Administrative work',
    },
    withoutExperience: {
      ru: 'Без опыта',
      en: 'Without experience',
    },
    accountingFinanceJob: {
      ru: 'Бухгалтерия, финансы',
      en: 'Accounting, finance',
    },
    homeStaff: {
      ru: 'Домашний персонал',
      en: 'Home staff',
    },
    artEntertainment: {
      ru: 'Искусство, развлечения',
      en: 'Art, entertainment',
    },
    consulting: {
      ru: 'Консультирование',
      en: 'Consulting',
    },
    marketingAdvertisingPRJob: {
      ru: 'Маркетинг, реклама, PR',
      en: 'Marketing, advertising, PR',
    },
    educationScience: {
      ru: 'Образование, наука',
      en: 'Education, science',
    },
    securitySafety: {
      ru: 'Охрана, безопасность',
      en: 'Security, safety',
    },
    sales: {
      ru: 'Продажи',
      en: 'Sales',
    },
    tourismRestaurants: {
      ru: 'Туризм, рестораны',
      en: 'Tourism, restaurants',
    },
    personnelManagement: {
      ru: 'Управление персоналом',
      en: 'Personnel management',
    },
    fitnessBeautySalons: {
      ru: 'Фитнес, салоны красоты',
      en: 'Fitness, beauty salons',
    },
    jurisprudence: {
      ru: 'Юриспруденция',
      en: 'Jurisprudence',
    },
    lookingForAnEmployee: {
      ru: 'Ищу сотрудника',
      en: 'Looking for an employee',
    },
    writeSomething: {
      ru: 'Напишите что-нибудь',
      en: 'Write something',
    },
    workSchedule: {
      ru: 'График работы',
      en: 'Work schedule',
    },
    fullDay: {
      ru: 'Полный день',
      en: 'Full day',
    },
    partTime: {
      ru: 'Неполный день',
      en: 'Part-time',
    },
    flexible: {
      ru: 'Плавающий',
      en: 'Flexible',
    },
    shifts: {
      ru: 'Сменный',
      en: 'Shifts',
    },
    salary: {
      ru: 'Заработная плата',
      en: 'Salary',
    },
    workExperience: {
      ru: 'Опыт работы',
      en: 'Work experience',
    },
    type_of_cloth: {
      ru: 'Тип одежды',
      en: 'Type of cloth',
    },
    type_of_shoes: {
      ru: 'Тип обуви',
      en: 'Type of shoes',
    },
    withoutExperienceJob: {
      ru: 'Без опыта',
      en: 'Without experience',
    },
    moreThanOneYear: {
      ru: 'Более одного года',
      en: 'More than one year',
    },
    moreThan3Years: {
      ru: 'Более 3 лет',
      en: 'More than 3 years',
    },
    moreThan5Years: {
      ru: 'Более 5 лет',
      en: 'More than 5 years',
    },
    moreThan10Years: {
      ru: 'Более 10 лет',
      en: 'More than 10 years',
    },
    payment: {
      ru: 'Оплата',
      en: 'Payment',
    },
    hourly: {
      ru: 'Почасовая',
      en: 'Hourly',
    },
    byMonth: {
      ru: 'По месяцам',
      en: 'By month',
    },
    oneTimeFeeForProject: {
      ru: 'Разовая оплата за проект',
      en: 'One-time fee for the project',
    },
  },
  underUnderSubCategory: {
    estate: {
      all: {
        ru: 'Все',
        en: 'All',
      },
      secondary: {
        ru: 'Вторичка',
        en: 'Secondary',
      },
      building: {
        ru: 'Новостройка',
        en: 'New building',
      },
    },
    interests: {
      SystemUnits: {
        ru: 'Системные блоки',
        en: 'System units',
      },
      Monoblocks: {
        ru: 'Моноблоки',
        en: 'Monoblocks',
      },
      EquipmentAccessories: {
        ru: 'Оборудование и аксессуары',
        en: 'Equipment and accessories',
      },
      Lenses: {
        ru: 'Объективы',
        en: 'Lenses',
      },
      CompactCameras: {
        ru: 'Компактные фотоаппараты',
        en: 'Compact cameras',
      },
      FilmCameras: {
        ru: 'Пленочные фотоаппараты',
        en: 'Film cameras',
      },
      DSLRCameras: {
        ru: 'Зеркальные фотоаппараты',
        en: 'DSLR cameras',
      },
      BinocularsTelescopes: {
        ru: 'Бинокли и телескопы',
        en: 'Binoculars and telescopes',
      },
      Nannies: {
        ru: 'Няни, сиделки',
        en: 'Nannies, caregivers',
      },
      Tablets: {
        ru: 'Планшеты',
        en: 'Tablets',
      },
      Concerts: {
        ru: 'Концерты',
        en: 'Concerts',
      },
      Travel: {
        ru: 'Путешествия',
        en: 'Travel',
      },
      Sports: {
        ru: 'Спорт',
        en: 'Sports',
      },
      BilliardsBowling: {
        ru: 'Бильярд и боулинг',
        en: 'Billiards and bowling',
      },
      DivingWaterSports: {
        ru: 'Дайвинг и водные виды спорта',
        en: 'Diving and water sports',
      },
      MartialArts: {
        ru: 'Единоборства',
        en: 'Martial arts',
      },
      BallGames: {
        ru: 'Игры с мячом',
        en: 'Ball games',
      },
      BoardGames: {
        ru: 'Настольные игры',
        en: 'Board games',
      },
      RollerSkatingSkateboarding: {
        ru: 'Ролики и скейтбординг',
        en: 'Roller skating and skateboarding',
      },
      TennisBadmintonPingPong: {
        ru: 'Теннис, бадминтон, пинг-понг',
        en: 'Tennis, badminton, ping pong',
      },
      Tourism: {
        ru: 'Туризм',
        en: 'Tourism',
      },
      FitnessExerciseMachines: {
        ru: 'Фитнес и тренажеры',
        en: 'Fitness and exercise machines',
      },
      SportsNutrition: {
        ru: 'Спортивное питание',
        en: 'Sports nutrition',
      },
      MobilePhones: {
        ru: 'Мобильные телефоны',
        en: 'Mobile phones',
      },
      Accessories: {
        ru: 'Аксессуары',
        en: 'Accessories',
      },
    },
    jobCategories: {
      Automotive: {
        ru: 'Автомобильный бизнес',
        en: 'Automotive business',
      },
      developer: {
        ru: 'IT',
        en: 'IT',
      },
      Administrative: {
        ru: 'Административная работа',
        en: 'Administrative work',
      },
      NoExperience: {
        ru: 'Без опыта',
        en: 'No experience',
      },
      AccountingFinance: {
        ru: 'Бухгалтерия, финансы',
        en: 'Accounting, finance',
      },
      HouseholdStaff: {
        ru: 'Домашний персонал',
        en: 'Household staff',
      },
      ArtsEntertainment: {
        ru: 'Искусство, развлечения',
        en: 'Arts, entertainment',
      },
      Consulting: {
        ru: 'Консультирование',
        en: 'Consulting',
      },
      MarketingPR: {
        ru: 'Маркетинг, реклама, PR',
        en: 'Marketing, advertising, PR',
      },
      EducationScience: {
        ru: 'Образование, наука',
        en: 'Education, science',
      },
      Security: {
        ru: 'Охрана, безопасность',
        en: 'Security',
      },
      Sales: {
        ru: 'Продажи',
        en: 'Sales',
      },
      TourismRestaurants: {
        ru: 'Туризм, рестораны',
        en: 'Tourism, restaurants',
      },
      PersonnelManagement: {
        ru: 'Управление персоналом',
        en: 'Personnel management',
      },
      FitnessBeauty: {
        ru: 'Фитнес, салоны красоты',
        en: 'Fitness, beauty salons',
      },
      Legal: {
        ru: 'Юриспруденция',
        en: 'Legal',
      },
    },
    services: {
      TableSetting: {
        ru: 'Сервировка стола',
        en: 'Table setting',
      },
      Cooking: {
        ru: 'Приготовление пищи',
        en: 'Cooking',
      },
      FoodStorage: {
        ru: 'Хранение продуктов',
        en: 'Food storage',
      },
      BeveragePreparation: {
        ru: 'Приготовление напитков',
        en: 'Beverage preparation',
      },
      HouseholdGoods: {
        ru: 'Хозяйственные товары',
        en: 'Household goods',
      },
      KitchenGoods: {
        ru: 'Кухонные товары',
        en: 'Kitchen goods',
      },

      Ventilation: {
        ru: 'Вентиляция',
        en: 'Ventilation',
      },
      individual: {
        ru: 'Для индивидуального ухода',
        en: 'For individual care',
      },
      Climatic: {
        ru: 'Климатические оборудование',
        en: 'Climatic equipmenting',
      },
      Forhome: {
        ru: 'Для дома',
        en: 'For home',
      },
      Forkitchen: {
        ru: 'Для кухни',
        en: 'For kitchen',
      },
      CookingLighting: {
        ru: 'Освещение',
        en: 'Lighting',
      },
      Computertables: {
        ru: 'Компьютерные столы и кресла',
        en: 'Computer desks and chairs',
      },

      Textiles: {
        ru: 'Текстиль и ковры',
        en: 'Textiles and carpets',
      },
      Tables: {
        ru: 'Столы и стулья',
        en: 'Tables and chairs',
      },
      Wardrobes: {
        ru: 'Шкафы, комоды и стеллажи',
        en: 'Wardrobes, chests of drawers and shelving',
      },
      armchairs: {
        ru: 'Кровати, диваны и кресла',
        en: 'Beds, sofas and armchairs',
      },
      trucking: {
        city: {
          ru: 'По городу',
          en: 'Around town',
        },
        between: {
          ru: 'Между городами',
          en: 'Between cities',
        },
      },
      repair: {
        flat: {
          ru: 'Ремонт квартир и домов под ключ',
          en: 'Turnkey repair of apartments and houses',
        },
        plumbing: {
          ru: 'Сантехника',
        },
      },
    },
  },
};
