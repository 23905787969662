import { useMemo } from 'react';
import { infiniteListQueryFactory } from '@/modules/shared/queries';
import { useCustomWindowWidth } from '@/modules/shared/hooks';
import { SIMILAR_ADS_LIST_QUERY_KEY } from '../consts';
import { AdsApi } from '../api';

export const useInfinitySimilarAdsList = (id: string, options = {}) => {
  const { windowWidth } = useCustomWindowWidth();

  const quantity = useMemo(() => {
    if (windowWidth && windowWidth <= 1200) return 12;
    return 10;
  }, [windowWidth]);

  return infiniteListQueryFactory(
    (config) => AdsApi.similarAds({ id, ...config }),
    [SIMILAR_ADS_LIST_QUERY_KEY, id],
  )({
    pageSize: quantity,
    ...options,
  });
};
