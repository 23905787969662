import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useGlobalData } from '@/context/GlobalContext';
import { useHomeCity } from '@/modules/locations/hooks';
import { useCustomWindowWidth, useInfiniteListRef } from '@/modules/shared/hooks';
import { InfiniteListOfAdsLayout } from '@/modules/shared/components';
import { IAd } from '@/modules/ads/types';
import { useRecommendedAdsInfinityList, useAdsInfiniteListQuery } from '@/modules/ads/queries';
import { scrollElementToNextCard } from '@/modules/shared/helpers';
import { AdCard } from '../ad-card';
import styles from './ads-block.module.scss';

export const AdsBlock = () => {
  const { t } = useTranslation();
  const { homeCity = 'allcities' } = useGlobalData();
  const adsContainerRef = useRef<HTMLDivElement>(null);
  const cardRefs = useRef<Record<number, HTMLDivElement | null>>({});
  const { windowWidth } = useCustomWindowWidth();
  const [currentIndex, setCurrentIndex] = useState(0);

  const lastAdsQuantity = useMemo(() => {
    if (windowWidth && windowWidth <= 740) return 2;
    if (windowWidth && windowWidth <= 1200) return 3;
    return 5;
  }, [windowWidth]);

  const lastAdsGap = useMemo(() => {
    if (windowWidth && windowWidth <= 768 && windowWidth > 480) return 9;
    if (windowWidth && windowWidth <= 480) return 12;
    return 16;
  }, [windowWidth]);

  const recommendedAdsQuantity = useMemo(() => {
    if (windowWidth && windowWidth <= 1200) return 12;
    return 10;
  }, [windowWidth]);

  const { cityId, error: citiesError } = useHomeCity();
  const filters = useMemo(
    () => ({
      city: cityId,
    }),
    [cityId],
  );
  const { pages: lastAdsPages, isLoading: lastAdsLoading } = useAdsInfiniteListQuery({
    pageSize: 10,
    filters,
    page: 1,
    disabled: homeCity !== 'allcities' && !filters.city,
    refetchOnMount: false,
  });

  const {
    pages: recommendedPages,
    error,
    fetchMore,
    hasMore,
    isLoading: recommendedLoading,
  } = useRecommendedAdsInfinityList({
    pageSize: recommendedAdsQuantity,
    filters,
    disabled: homeCity !== 'allcities' && !filters.city,
    refetchOnMount: false,
  });

  const handleScroll = useCallback((index: number) => {
    if (!lastAdsPages?.[0]?.data || !lastAdsPages?.[0]?.data?.length) return;

    const targetIndex = currentIndex + index;

    if (targetIndex <= 0) {
      setCurrentIndex(0);
    } else if (targetIndex >= lastAdsPages[0].data.length - 1) {
      setCurrentIndex(lastAdsPages[0].data.length - 1);
    } else {
      setCurrentIndex(targetIndex);
    }
  }, [currentIndex, lastAdsPages]);

  useEffect(() => {
    scrollElementToNextCard(cardRefs.current[currentIndex], currentIndex, lastAdsGap);
  }, [currentIndex]);

  const infiniteListRef = useInfiniteListRef(hasMore, fetchMore, recommendedLoading);
  if (error || citiesError) return <div>Error loading data</div>;

  return (
    <div className={styles.ads_wrapper}>
      <InfiniteListOfAdsLayout
        title={t('index-page:adsBlock.recent')}
        isLoading={lastAdsLoading}
        isRecent
        marginTop
        onScroll={handleScroll}
        disabledPrev={currentIndex === 0}
        disabledNext={currentIndex === (lastAdsPages?.[0]?.data?.length || 0) - lastAdsQuantity}
      >
        {lastAdsPages?.map((page) => page?.data?.map((ad: IAd, i: number) => (
          <AdCard
            key={ad.id}
            ad={ad}
            extendedCard={false}
            refs={cardRefs}
            index={i}
          />
        )))}
      </InfiniteListOfAdsLayout>
      <InfiniteListOfAdsLayout
        title={t('index-page:adsBlock.recommendations')}
        ref={adsContainerRef}
        infiniteListRef={infiniteListRef}
      >
        {recommendedPages?.map((page) => page?.data?.map((ad: IAd) => (
          <AdCard key={ad.id} ad={ad} extendedCard={false} />
        )))}
      </InfiniteListOfAdsLayout>
    </div>
  );
};
