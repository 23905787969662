import { useMatchMedia } from '@/modules/shared/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdsApi } from '../api';
import { MY_ADS_LIST_QUERY_KEY, MY_ADS_INFINITY_LIST_QUERY_KEY } from '../consts';

export const useToggleMyAdVisibility = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useMatchMedia();

  return useMutation({
    mutationFn: AdsApi.toggleAdVisibility,
    onSuccess: async () => {
      const targetKey = isDesktop
        ? MY_ADS_LIST_QUERY_KEY
        : MY_ADS_INFINITY_LIST_QUERY_KEY;

      await queryClient.refetchQueries({
        predicate: (query) => query.queryKey.includes(targetKey),
      });
    },
    onError: (e) => console.error('Error:', e),
  });
};
