import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from '@/modules/shared/components/modal';
import {
  MemoryRouter, NavigateFunction, Route, Routes, useLocation,
} from 'react-router-dom';
import { useRouter } from 'next/router';
import { setNextEmailResendTimestamp } from '@/modules/shared/helpers';
import { toast } from 'react-toastify';
import { useAuth } from '@/hooks/auth/useAuth';
import { useCloseAuthModal, useOpenAuthModal } from '../../hooks';
import { CompletePhone } from './components/registration/complete-phone.component';
import { Login } from './components/registration/login.component';
import { useAuthContext } from '../../contexts';
import { RouterListener } from './components/router-listener.component';


import styles from './auth-modal.module.scss';

export const RegistrationModal = () => {
  const { locale } = useRouter();
  const closeModal = useCloseAuthModal();
  const { t } = useTranslation();
  const [router, setCurrentRouter] = useState<{
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction
  }>();
  const setModalType = useOpenAuthModal();

  const schema = useMemo(() => yup.object().shape({
    authType: yup.mixed<string>().oneOf(['email', 'phone']),
    phone: yup.string().when('authType', {
      is: 'phone',
      then: () => yup.string()
        .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.phoneNumber') }))
        .test('valid-phone', t('forms:validationErrors.invalidPhone'), (value) => {
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber ? phoneNumber.isValid() : false;
        }),
    }),
    email: yup.string().when('authType', {
      is: 'email',
      then: () => yup.string()
        .email(t('forms:validationErrors.invalidEmail'))
        .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('forms:validationErrors.invalidEmail')),
    }),
  })
    .required(), [t]);

  const { register: registerUser } = useAuth();


  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      authType: 'email',
      phone: '',
      email: '',
    },
  });

  const {
    handleSubmit,
    setError,
    setValue,
  } = formMethods;

  const {
    isAuthenticated,
  } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      closeModal();
    }
  }, [closeModal, isAuthenticated]);

  const onSubmit = async (data) => {
    const {
      email,
      fullName,
      phone,
      password,
      password2,
    } = data;

    const { response, error } = await registerUser(
      data.authType === 'email' ? email : undefined,
      fullName,
      password,
      password2,
      data.authType === 'phone' ? phone : undefined,
      locale,
    );

    if (response) {
      if (data.authType === 'email') {
        setNextEmailResendTimestamp(email);
        setModalType('registerConfirmation');
      } else {
        router?.nav('/complete-phone');
      }
    } else if (error?.message?.includes?.('EMAIL_ALREADY_IN_USE')) {
      setError('email', {
        type: 'manual',
        message: t('forms:validationErrors.userEmailExists'),
      });
      setValue('authType', 'email');
      router?.nav('/');
    } else if (error?.message?.includes?.('Enter a valid email address')) {
      setError('email', {
        type: 'manual',
        message: t('forms:validationErrors.invalidEmail'),
      });
      setValue('authType', 'email');
      router?.nav('/');
    } else if (error?.response?.validation_errors?.phone_number) {
      const errorMessage = (error.response.validation_errors.phone_number?.['0']) as string || '';
      setValue('authType', 'phone');
      router?.nav('/');

      if (errorMessage.includes('This field may not be blank')) {
        setError('phone', {
          type: 'manual',
          message: t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.phoneNumber') }),
        });
      } else if (errorMessage.includes('user with this phone number already exists')) {
        setError('phone', {
          type: 'manual',
          message: t('forms:validationErrors.userPhoneExists'),
        });
      } else {
        setError('phone', {
          type: 'manual',
          message: errorMessage,
        });
      }
    } else if (error?.response?.validation_errors?.code?.includes('PHONE_NUMBER_ALREADY_IN_USE')) {
      setError('phone', {
        type: 'manual',
        message: t('forms:validationErrors.userPhoneExists'),
      });
    } else if (error?.response?.validation_errors?.email) {
      const errorMessage = (error.response.validation_errors.email?.['0']) as string || '';
      setValue('authType', 'email');
      router?.nav('/');

      if (errorMessage.includes('This field may not be blank')) {
        setError('email', {
          type: 'manual',
          message: t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }),
        });
      } else {
        setError('email', {
          type: 'manual',
          message: errorMessage,
        });
      }
    } else if (error.code === 'THROTTLED') {
      toast.error(t('auth:register.throttled'));
    } else {
      toast.error(t('auth:register.error'));
    }
  };

  const handleClickBack = useCallback(() => {
    if (router?.location.pathname !== '/') {
      router?.nav?.('/');
    } else {
      setModalType('login');
    }
  }, [router, setModalType]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <Modal
      fullHeight
      showBackButton
      onClickBack={handleClickBack}
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
      open
      onClose={closeModal}
    >
      <FormProvider {...formMethods}>
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          <MemoryRouter initialEntries={['/']}>
            <RouterListener onRouteChange={setCurrentRouter} />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/complete-phone" element={<CompletePhone />} />
            </Routes>
          </MemoryRouter>
        </form>
      </FormProvider>
    </Modal>
  );
};
