import {
  Controller, useFormContext,
} from 'react-hook-form';
import {
  Button, ButtonSwitcher, InputText, InputPhone, SvgIcon,
} from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { useNavigate } from 'react-router-dom';
import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import getConfig from 'next/config';
import { useSearchParams } from 'next/navigation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import styles from '../../auth-modal.module.scss';

import { GoogleOauth } from './google-oauth.component';
import { TelegramOauth } from './telegram-oauth.component';
import { useOpenAuthModal } from '../../../../hooks';

const { publicRuntimeConfig: { googleOAuthClientId } } = getConfig();

const CUSTOM_HEADER = {
  addAds: {
    title: 'auth:customHeaders.addAds.title',
    description: 'auth:customHeaders.addAds.description',
  },
  addFavorites: {
    title: 'auth:customHeaders.addFavorites.title',
    description: 'auth:customHeaders.addFavorites.description',
  },
  __default: {
    title: 'auth:titleLogin',
    description: false,
  },
} as const;

export const Login = memo(() => {
  const searchParams = useSearchParams();
  const { isDesktop } = useMatchMedia();
  const router = useRouter();
  const [isWebview, setIsWebview] = useState<boolean>(false);

  const customHeader = useMemo(() => {
    const customHeaderKey = decodeURIComponent(searchParams.get('customHeader') || '/');

    return (Object.entries(CUSTOM_HEADER)
      .find(([path]) => customHeaderKey.includes(path)) || ['__default', CUSTOM_HEADER.__default])[1];
  }, [searchParams]);

  const setModalType = useOpenAuthModal();
  const {
    control, trigger, watch, formState: { errors },
  } = useFormContext<{
    authType: string,
    email: string,
    phone_number: string,
  }>();

  const authType = watch('authType');

  const { t } = useTranslation();
  const memoNavigate = useNavigate();

  const handleNextClick = async () => {
    const isValidStep = !(await Promise.all([
      trigger('email'),
      trigger('phone_number'),
    ])).some((v) => !v);

    if (isValidStep) {
      memoNavigate('/password');
    }
  };

  useEffect(() => {
    const detectIosWebview = () => {
      const isBadSafari = /^((?!chrome|crios|android|fxios|edgios|opr).)*safari(?!.*opt)/i.test(navigator.userAgent);

      // Method #1
      // See: https://developer.apple.com/documentation/webkit/wkscriptmessagehandler
      // window.webkit.messageHandlers is defined in WKWebView with WKScriptMessageHandler
      // 1) if window.webkit.messageHandlers is present then it is 100% WKWebView
      // 2) if window.webkit.messageHandlers is not present then it could be:
      //    2.1) WKWebView without WKScriptMessageHandler
      //    2.2) not a WKWebView
      // @ts-expect-error костыль для вебвью
      if (window?.webkit?.messageHandlers && isBadSafari) {
        return true;
      }

      return false;
    };

    setIsWebview(detectIosWebview());
  }, []);

  return (
    <>
      <div className={styles.title}>
        <h2>{t(customHeader.title)}</h2>
        {customHeader.description && (
          <div className={styles.subTitle}>{t(customHeader.description)}</div>
        )}
      </div>
      <div>
        <Controller
          control={control}
          name="authType"
          render={({ field: { value, onChange } }) => (
            <ButtonSwitcher
              buttonSize="l"
              kind="outline"
              value={value}
              onChange={onChange}
              buttons={[
                {
                  name: t('auth:label.email'),
                  value: 'email',
                },
                {
                  name: t('auth:label.phoneNumber'),
                  value: 'phone',
                },
              ]}
            />
          )}
        />
      </div>
      {authType === 'email' ? (
        <div>
          <Controller
            key="email"
            control={control}
            name="email"
            render={({ field }) => (
              <InputText
                {...field}
                key="email"
                error={!!errors.email}
                fullWidth
                placeholder={t('auth:label.email')}
              />
            )}
          />
          {errors.email && <div className={styles.error}>{errors.email.message}</div>}
        </div>
      ) : (
        <div>
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <InputPhone
                error={!!errors.phone_number}
                {...field}
                onChange={(v) => field.onChange(v || '')}
                international
                fullWidth
              />
            )}
          />
          {errors.phone_number && <div className={styles.error}>{errors.phone_number.message}</div>}
        </div>
      )}
      <div>
        <Button
          type="submit"
          onClick={handleNextClick}
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:login.button')}
        </Button>
      </div>
      <div>
        <Button
          type="button"
          fullWidth
          appearance="secondary"
          onClick={() => setModalType('register')}
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:register.buttonRegister')}
        </Button>
      </div>
      <div className={clsx(styles.oauth, {
        [styles.oauth_not_available]: isWebview,
      })}
      >
        <div className={styles.oauth__title}>{isWebview ? t('auth:label.oauthNotAvailable') : t('auth:label.oauth')}</div>
        <div className={styles.oauth__items}>
          <TelegramOauth />
          <GoogleOAuthProvider clientId={googleOAuthClientId || 'nope'}>
            <GoogleOauth />
          </GoogleOAuthProvider>
        </div>
      </div>
      {!isDesktop && (
        <div>
          <Button
            type="button"
            fullWidth
            appearance="secondary"
            onClick={() => router.push('/support/form')}
            buttonColor="black"
            buttonSize="s"
          >
            <SvgIcon style={{ fontSize: '24' }} name="support_service" />
            {t('common:header.support')}
          </Button>
        </div>
      )}
    </>
  );
});
