import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RESOLVE_COMPLAINT_EVENT_KEY, COMPLAINTS_QUERY_KEY } from '../consts';
import type { EventComplaint } from '../types';
import { ComplaintApi } from '../api';

export const useResolveEventComplaint = () => {
  const queryClient = useQueryClient();

  const {
    mutate, isPending, error,
  } = useMutation<EventComplaint, Error, EventComplaint>({
    mutationKey: RESOLVE_COMPLAINT_EVENT_KEY,
    mutationFn: (complaintData) => ComplaintApi.resolveEventComplaint(complaintData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: COMPLAINTS_QUERY_KEY,
      });
    },
  });

  return {
    mutate,
    isLoading: isPending,
    error,
  };
};
