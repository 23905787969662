import React, {
  RefCallback, RefObject, useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { AutoScrollContainer } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { AdDataForFeedback, MessageDetails } from '../../../types';
import { useTimeMessages } from '../../../hooks';
import { groupMessagesByDateAndType, mergeMessages } from '../../../helpers';
import { Message } from '../message';
import s from './messages-container.module.scss';

const isEmptyArray = (arr) => Array.isArray(arr)
  && arr.length === 0;

export type MessagesContainerProps = {
  isLoading: boolean | null;
  messages: MessageDetails[];
  userId: number | null;
  scrollInfinityOffset: number
  autoScrollRef: RefObject<{
    scrollToBottom:(behavior: 'auto' | 'smooth' | 'instant') => void;
    getElement: () => HTMLElement | undefined
  }>;
  refLoadMore?: RefCallback<HTMLDivElement>;
  isPreloading: boolean;
  draftMessages: { message: string; timestamp: string }[];
  errorMessages: { message: string; timestamp: string }[];
  adData: AdDataForFeedback;
  onErrorClick?: () => void;
  onChangeReadStatus?: (msgId: number) => void;
  isSupport?: boolean;
};

const minHeight = 640;
export const MessagesContainer = ({
  isLoading,
  messages,
  userId,
  autoScrollRef,
  isPreloading,
  draftMessages,
  errorMessages,
  onChangeReadStatus,
  scrollInfinityOffset,
  onErrorClick,
  refLoadMore,
  isSupport = false,
  adData,
}: MessagesContainerProps) => {
  const { t, lang } = useTranslation();
  const dateMessages = useTimeMessages;
  const { isDesktop } = useMatchMedia();

  const { groupedByDateAndType } = useMemo(
    () => groupMessagesByDateAndType(mergeMessages(messages || [], userId)),
    [messages, userId],
  );

  return (
    <div className={s.wrapper}>
      <AutoScrollContainer offset={scrollInfinityOffset} ref={autoScrollRef} size="large">
        <div className={s.container} style={{ minHeight: isDesktop ? `${minHeight}px` : '100%' }}>
          {isLoading && <p className={s.information}>{t('chat:loading')}</p>}
          {!isLoading && isEmptyArray(messages) && (
            <p className={s.information}>{t('chat:chat.messages')}</p>
          )}

          {!isLoading && <div ref={refLoadMore} />}
          {!isLoading && !isEmptyArray(messages) && (
          <>
            {Array.from(groupedByDateAndType.entries())
              .map(([date, messageGroups]) => (
                <div key={date} className={s.day}>
                  <p className={s.day_time}>{dateMessages(date, t, lang)}</p>
                  <div className={s.day_messages}>
                    {messageGroups.map((group, i) => (
                      <div
                        key={i}
                        className={clsx(
                          s.day_block,
                          {
                            [s.day_block_system_last]: i + 1 === messageGroups.length && group[0].type === 'system',
                            [s.day_block_system_middle]: i + 1 !== messageGroups.length && group[0].type === 'system',
                          },
                        )}
                      >
                        {group.map((el) => (
                          <Message
                            onChangeReadStatus={onChangeReadStatus}
                            key={el.id}
                            msg={el}
                            senderType={el.sender.id === userId ? 'sender' : 'recipient'}
                            messageStatus={el.is_read ? 'read' : 'unread'}
                            messageType={el.type}
                            isSupport={el.sender.id === userId ? false : isSupport}
                            adData={adData}
                          />
                        ))}
                      </div>
                    ))}

                  </div>
                </div>
              ))}
          </>
          )}
          {isPreloading && draftMessages.length > 0 && draftMessages.map((el, index) => (
            <Message key={index} msg={el} senderType="sender" messageStatus="sending" />
          ))}

          {errorMessages.length > 0 && errorMessages.map((el, index) => (
            <Message key={index} msg={el} senderType="sender" messageStatus="error" onErrorClick={onErrorClick} />
          ))}
        </div>
      </AutoScrollContainer>
    </div>
  );
};
