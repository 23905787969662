import React from 'react';
import styles from './user-avatar.module.scss';

interface UserAvatarProps {
  srcAvatar: string | null;
  userName: string;
  diameter?: '16px' | '24px' | '32px' | '36px' | '44px' | '48px' | '64px' | '66px' | '100px' | '140px';
}

const fontSizeMap: Record<string, string> = {
  '140px': '96px',
  '100px': '48px',
  '66px': '40px',
  '64px': '40px',
  '48px': '32px',
  '44px': '24px',
  '36px': '24px',
  '32px': '20px',
  '24px': '16px',
  '16px': '11px',
};

export const UserAvatar = ({ srcAvatar, userName, diameter = '66px' } : UserAvatarProps) => {
  const fontSize = fontSizeMap[diameter];
  const userNameAvatar = userName?.slice(0, 1)?.toUpperCase();

  return (
    <div className={styles.avatar} style={{ width: diameter, height: diameter }}>
      {srcAvatar
        ? (
          <span className={styles.avatar_img}>
            <img src={srcAvatar} alt={userNameAvatar} />
          </span>
        )
        : <span style={{ fontSize }}>{userNameAvatar}</span>}
    </div>
  );
};
